import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { Box, Button, ButtonBase, Checkbox, IconButton, Table, TableCell, TableHead, TableRow, Modal, Typography, FormControl, InputLabel, Select, MenuItem, TextField } from "@mui/material";
import "./invoice.css";
import jsondecode from "jwt-decode";
import { currencyconverter } from "../../../common/currencyconverter";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
const moment = require("moment");

const baseUrl = process.env.REACT_APP_BASE_URL
const useStyles = makeStyles((theme) => ({
    address_text_common: {
        fontSize: "22px",
        fontWeight: "300",
        color: 'black'
    },
    table_titles: {
        fontSize: "24px",
        fontWeight: "700",
        color: 'black'

    },
    table_body: {
        fontSize: "22px",
        fontWeight: "300",
        padding: theme.spacing(0.25),
        color: 'black'

    },
    footer_text: {
        fontSize: "20px",
        fontWeight: "300",
        padding: theme.spacing(0.25),
        color: 'black'
    },
    totals: {
        fontSize: "22px",
        padding: theme.spacing(0.25),
        color: 'black'
    },
    p1: {
        padding: theme.spacing(0.25),
        color: 'black'
    },
    p2: {
        padding: theme.spacing(0.5),
        color: 'black'
    },
    p3: {
        padding: theme.spacing(0.5),
        color: 'black'
    },
    mt4: {
        marginTop: theme.spacing(0.5),
    },
    m1: {
        margin: theme.spacing(0.5),
    },
    textLeft: {
        textAlign: "left",
    },
    textCenter: {
        textAlign: "right",
    },
    textRight: {
        textAlign: "right",
    },

    totalCell: {
        paddingRight: 0,
        marginRight: 0,
    },

}));

export const PrintInvoice = (props) => {
    const invoiceData = props?.invoiceData;
    const invoicePayments = props?.invoicePayments
    const invoiceBalance = props?.invoiceBalance
    const businessInfo = props?.businessInfo
    const discountAmount = props?.discountAmount
    const backgroundImg = props?.backgroundImg
    console.log(backgroundImg, 'backgroundImgbackgroundImgbackgroundImg')

    const theme = useTheme();
    const isMobileUI = useMediaQuery(theme.breakpoints.down("sm"));
    const classes = useStyles(props)
    const formatDate = (date) => {
        return moment(date).format("dddd, MMMM Do, YYYY");
    };
    const businessCategory = localStorage.getItem("businessCategory");

    let localCurrency = localStorage.getItem('localCurrency')
    const localToken = localStorage.getItem("X-Authorization");
    const decoded = jsondecode(localToken)
    if (!localCurrency) {
        localCurrency = decoded?.localCurrency
    }
    if (!localCurrency || localCurrency === 'undefined' || localCurrency === undefined) {
        localCurrency = 'KES'
    }
    const numberFormat = (value) => new Intl.NumberFormat("en-US", { style: "currency", currency: localCurrency }).format(value);
    const numberFormatNoCurrency = (value) => 
        new Intl.NumberFormat("en-US", { 
          minimumFractionDigits: 2, 
          maximumFractionDigits: 2 
    }).format(value);
      

    const formatPaymentDate = (unFormatted) => {
        const date = new Date(unFormatted);
        const options = { day: "numeric", month: "short", year: "numeric", hour: "numeric", minute: "numeric", hour12: true };
        const formattedDate = date.toLocaleString("en-US", options);
        const dateSuffix = function () {
            const date = new Date();
            const dateNumber = date.getDate();
            switch (dateNumber) {
                case 1:
                case 21:
                case 31:
                    return "st";
                case 2:
                case 22:
                    return "nd";
                case 3:
                case 23:
                    return "rd";
                default:
                    return "th";
            }
        };
        return `${date.getDate()}${dateSuffix()} ${formattedDate.slice(0, 3)} ${formattedDate.slice(7)}`;
    };

    return (
        <div style={isMobileUI ? { width: "100%" } : { width: "80%", marginLeft: "10%" }} >

            <div>
                <div>
                    <div style={isMobileUI ? { display: "block" } : { display: "flex", justifyContent: "space-between" }}>

                        <div style={{ padding: 10, display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", overflow: "visible", height: "auto" }} id="downloadable">
                        <div className={classes.p3} style={{ backgroundColor: `${invoiceData?.invoiceStatus === "Unpaid" ? "rgba(220,53,69,0.07)" : invoiceData?.invoiceStatus === "Paid" ? "rgba(23,174,123, 0.07)" : "rgba(247,137,41, 0.05)"}`, height: "130px", display: "flex", justifyContent: "space-between" }}>
                            <div className={classes.p1} style={{ display: "flex", flexDirection: "column" }}>
                                <span style={{ color: "black", fontSize: "30px", fontWeight: "700" }}>INVOICE</span>
                                <span style={{ color: "black", fontSize: "18px", fontWeight: "700" }}>Date:</span>
                                <span style={{ fontSize: "18px", color: "black", }}>{formatDate(invoiceData?.createdAt)}</span>
                            </div>
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                {isMobileUI ? null : <img alt="" src={`${baseUrl}/staticimages/logos/${invoiceData?.businessLogo}`} style={{ width: "180px", height: "180px", objectFit: "contain" }} />}
                            </div>

                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <span style={{ color: `${invoiceData?.invoiceStatus === "Paid" ? "#17ae7b" : invoiceData?.invoiceStatus === "Unpaid" ? "#dc3545" : "#e07c28"}`, fontSize: "40px", fontWeight: "700" }}>{invoiceData?.invoiceStatus}</span>
                                <span style={{ color: "black", fontSize: "18px", fontWeight: "700" }}>Invoice No:</span>
                                <span style={{ fontSize: "18px", color: "black", }}>{invoiceData?.invoiceNumber}</span>
                            </div>
                        </div>

                        <br />
                        <div className={""} style={isMobileUI ? { display: "block" } : { display: "flex", justifyContent: "space-between", flexDirection: "row" }}>
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <span style={{ fontSize: "22px", color: "black",fontWeight: "700" }}>From:</span>
                                <span className={classes.address_text_common} style={{ fontWeight: "700", color: "black" }}>
                                    {
                                        invoiceData?.salesOrPurchaseInvoice === "Supplier Invoice" ? invoiceData?.supplierName : businessInfo?.businessName
                                    }
                                </span>
                                <span className={classes.address_text_common}>
                                {invoiceData?.salesOrPurchaseInvoice === "Supplier Invoice" 
                                    ? invoiceData?.supplierAddress 
                                    : `${businessInfo?.businessOwnerAddress}, ${businessInfo?.country}`}
                                </span>
                                <span className={classes.address_text_common}>
                                    {invoiceData?.salesOrPurchaseInvoice === "Supplier Invoice" ? invoiceData?.supplierEmail : businessInfo?.businessOwnerEmail}

                                </span>
                                <span className={classes.address_text_common}>
                                    {invoiceData?.salesOrPurchaseInvoice === "Supplier Invoice" ? invoiceData?.supplierPhoneNumber : businessInfo?.businessOwnerPhone}

                                </span>
                            </div>
                            <div className={""} style={{ display: "flex", flexDirection: "column", textAlign: "right" }}>
                                <span className={classes.address_text_common} style={{ fontWeight: "700" }}>
                                    Billed to:
                                </span>
                                <span className={classes.address_text_common}>
                                    {invoiceData?.salesOrPurchaseInvoice === "Supplier Invoice" ? businessInfo?.businessName : invoiceData?.customerName}
                                </span>
                                <span className={classes.address_text_common}>
                                    {invoiceData?.salesOrPurchaseInvoice === "Supplier Invoice" ? businessInfo?.businessOwnerAddress : invoiceData?.sentTo}
                                </span>
                                <span className={classes.address_text_common}>
                                    {invoiceData?.salesOrPurchaseInvoice === "Supplier Invoice" ? businessInfo?.businessOwnerPhone : invoiceData?.customerPhoneNumber}
                                </span>
                            </div>
                        </div>
                        <br />
                        {businessCategory == "School" ? (
                            <div style={{ border: "dotted 0.5px", display: isMobileUI ? "block" : "flex", justifyContent: isMobileUI ? null : "space-between", padding: "0.625rem" }}>
                                <div style={{fontSize:'20px'}}>STUDENT ID: <span style={{ fontWeight: "bold", }}>{invoiceData?.itemNumber}</span></div>
                                <div style={{fontSize:'20px'}}>STUDENT NAME: <span style={{ fontWeight: "bold", }} > {invoiceData?.studentName} </span> </div>
                                <div style={{fontSize:'20px'}}>GRADE: <span style={{ fontWeight: "bold", }}>{invoiceData?.grade}</span></div>
                                <div style={{fontSize:'20px'}}>TERM: <span style={{ fontWeight: "bold", }}>{invoiceData?.term}</span></div>
                            </div>
                        ) : (
                            <div style={{ border: "dotted 1px" }}></div>
                        )}
                        <br />
                        <Box sx={{
                            position: 'relative',
                            width: '100%',
                            height: 'auto',
                            padding: theme.spacing(2),
                            overflow: 'hidden',
                            '&::before': {
                                content: '""',
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                backgroundImage: `url("${backgroundImg}")`,
                                backgroundSize: 'contain',
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                                opacity: 0.2,
                                zIndex: 1,
                            },
                            '& > *': {
                                position: 'relative',
                                zIndex: 2,
                            },
                        }}>

                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.textLeft} style={{ fontSize:'20px', fontWeight: "600", }}>Description</TableCell>
                                        <TableCell className={classes.textCenter} style={{ fontSize:'20px', fontWeight: "600", }}>Qty</TableCell>
                                        <TableCell className={classes.textCenter} style={{ fontSize:'20px', fontWeight: "600",textAlign: 'right' }}>Price ({localCurrency})</TableCell>
                                        {invoiceData?.batchStatus == "PENDING" && businessCategory !== 'School' ? <TableCell className={classes.textRight} style={{ fontSize:'20px', fontWeight: "600", textAlign: 'right'}}>Discount</TableCell> : null}
                                        <TableCell className={classes.textRight} style={{ fontSize:'20px', fontWeight: "600",  textAlign: 'right' }}>Total ({localCurrency})</TableCell>

                                    </TableRow>
                                </TableHead>
                                {invoiceData?.items?.map((product, index) => (
                                    <TableRow key={index}>
                                        <TableCell className={classes.textLeft} style={{ width: '50%' }}>
                                            <span className={classes.table_body}>{product.productName}</span>
                                        </TableCell>
                                        <TableCell className={classes.textRight} style={{ width: '15%' }}>
                                            <span className={classes.table_body}>{product.quantity}</span>
                                        </TableCell>
                                        <TableCell className={classes.textRight} style={{ width: '15%', textAlign: 'right' }}>
                                            <span className={classes.table_body} style={{ display: 'block'}}>
                                                {numberFormatNoCurrency(product.productPrice)}
                                            </span>
                                        </TableCell>
                                        <TableCell 
                                            className={`${classes.textRight} ${classes.totalCell}`} 
                                            style={{ width: '15%', textAlign: 'right' }}
                                        >
                                            <span className={classes.table_body} style={{ display: 'block' }}>
                                                {numberFormatNoCurrency(product.productPrice * product.quantity)}
                                            </span>
                                        </TableCell>

                                    </TableRow>

                                ))}
                            </Table>
                        </Box>

                        <div
                            className={classes.p3}
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                gap: '20px'
                            }}
                        >
                            <div>

                            </div>
                            {invoiceData?.invoiceStatus === "Paid" ? (
                            <div className={classes.p1} style={{ backgroundColor: "#f7f7f7", width: isMobileUI ? "50%" : "50%", height: "130px", display: "flex", flexDirection: "column", justifyContent: "center", marginTop: '100px'}}>
                                <div className={classes.totals} style={{ display: "flex", justifyContent: "space-between" }}>
                                    <span style={{ fontWeight: "bold",}}>Subtotal</span>
                                    <span>{numberFormat(invoiceData?.invoiceAmount + discountAmount)}</span>
                                </div>
                                <div className={classes.totals} style={{ display: "flex", justifyContent: "space-between" }}>
                                    <span style={{ fontWeight: "bold",}}>Discount</span>
                                    <span> {numberFormat(discountAmount)} </span>
                                </div>
                                <div className={classes.totals} style={{ display: "flex", justifyContent: "space-between" }}>
                                    <span style={{ color: `${invoiceData?.invoiceStatus === "Unpaid" ? "#dc3545" : "#17ae7b"}`, fontWeight: "bold" }}>Total</span>
                                    <span
                                        style={{
                                            color: `${invoiceData?.invoiceStatus === "Unpaid" ? "#dc3545" : "#17ae7b"}`,
                                            fontWeight: "bold",
                                        }}
                                    >
                                        {numberFormat(invoiceData?.invoiceAmount - discountAmount)}
                                    </span>
                                </div>
                            </div>
                            ):(
                                <div className={classes.p1} style={{ backgroundColor: "#f7f7f7", width: isMobileUI ? "50%" : "50%", height: "130px", display: "flex", flexDirection: "column", justifyContent: "center", marginTop: '100px'}}>
                                <div className={classes.totals} style={{ display: "flex", justifyContent: "space-between" }}>
                                    <span style={{ fontWeight: "bold",}}>Subtotal</span>
                                    <span>{numberFormat(invoiceBalance + discountAmount)}</span>
                                </div>
                                <div className={classes.totals} style={{ display: "flex", justifyContent: "space-between" }}>
                                    <span style={{ fontWeight: "bold",}}>Discount</span>
                                    <span> {numberFormat(discountAmount)} </span>
                                </div>
                                <div className={classes.totals} style={{ display: "flex", justifyContent: "space-between" }}>
                                    <span style={{ color: `${invoiceData?.invoiceStatus === "Unpaid" ? "#dc3545" : "#17ae7b"}`, fontWeight: "bold" }}>Total</span>
                                    <span
                                        style={{
                                            color: `${invoiceData?.invoiceStatus === "Unpaid" ? "#dc3545" : "#17ae7b"}`,
                                            fontWeight: "bold",
                                        }}
                                    >
                                        {numberFormat(invoiceBalance - discountAmount)}
                                    </span>
                                </div>
                            </div>

                            )}

                        </div>
                        <br />
                        <div style={{ border: "dotted 1px" }}></div>
                        <br />

                        {invoiceData?.invoiceStatus === "Partially Paid" || invoiceData?.invoiceStatus === "Paid" ? (
                            <>
                                <div>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className={classes.textLeft}>Mode of Payment</TableCell>
                                                <TableCell className={classes.textCenter}>Date</TableCell>
                                                <TableCell className={classes.textRight} style={{textAlign: 'right'}}>Amount</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        {invoicePayments?.map((payment, index) => (
                                            <TableRow key={index}>
                                                <TableCell className={classes.textLeft}>
                                                    <span className={classes.table_body}>
                                                        {payment.modeOfPayment}: {payment.ReferenceNumber}
                                                    </span>
                                                </TableCell>
                                                <TableCell className={classes.textCenter}>
                                                    <span className={classes.table_body}>{formatPaymentDate(payment.date)}</span>
                                                </TableCell>
                                                <TableCell className={classes.textRight} style={{textAlign: 'right'}}>
                                                    <span className={classes.table_body} style={{textAlign: 'right'}}>{
                                                        invoiceData?.salesOrPurchaseInvoice === "Supplier Invoice" ? numberFormat(invoiceData?.invoiceAmount) : numberFormat(payment.amount)
                                                    }</span>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </Table>
                                </div>
                                <br />
                                <div style={{ display: "flex", justifyContent: "end" }}>
                                    <div style={{ color: "#dc3545", fontWeight: "bold" }}>
                                        {invoiceData?.invoiceStatus !== "Paid" ?
                                            <span style={{ fontSize: "22px",}}>Balance: {numberFormat(invoiceBalance)}</span>
                                            : null}
                                    </div>
                                </div>
                                <br />
                                <div style={{ border: "dotted 1px" }}></div>
                                <br />
                            </>
                        ) : (
                            <></>
                        )}

                        <br />
                        <div style={{ border: "dotted 1px" }}></div>
                        <br />
                        <div className={classes.p2} style={{ display: "flex", justifyContent: "center" }} id="footer-text">
                            <div className={classes.textCenter} style={{ fontSize: "17px", fontWeight: "300" }} id="footer-text-content">
                                This is a system-generated invoice is created without anyalteration whatsoever.
                                <br />
                            <div style={{textAlign:'center'}}>
                            
                            Thank you for your business.
                            </div>
                               
                            </div>
                           
                            
                        </div>
                        <div className={classes.p2} style={{ backgroundColor: "#f7f7f7", display: "flex", justifyContent: "center", }} id="footer-info">
                            <div id="footer-info-text">
                                <span className={classes.footer_text}>Powered by{" "}
                                    <span style={{ fontWeight: "700", }}>
                                        ZED
                                    </span>
                                    <span className={classes.footer_text}>.</span>
                                </span>
                            </div>
                            <div id="footer-info-email">
                                <span className={classes.footer_text}>info@zed.business</span>
                                <span className={classes.footer_text}>.</span>
                                <span className={classes.footer_text}>v1.0.2</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};