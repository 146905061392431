import React, { useEffect, useState } from 'react';
import { Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import CustomInputLabel from "./CustomInputLabel";
import CustomSelectField from "./CustomSelectField";
import HttpComponent from "./MakeRequest";
import { setCategories } from "../../features/servicesSlice";
import { useSelector } from "react-redux";
import { ErrorAlert } from "../snackBar Alerts/errorAlert";
import CustomSearchInput from "./CustomSearchInput";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import CustomTextFieldSmall from "./CustomTextFieldSmall";
import CustomTable from "./CustomTable";
import { escape } from 'lodash';


const ServicesInvoiceForm = (props) => {
    const [options, setOptions] = useState([]);
    const [checkboxData, setCheckboxData] = useState([]);
    const { userId, X_Authorization } = useSelector((store) => store.user);
    const [showData, setShowData] = useState([])
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })
    const { schoolTypeName, schoolTypeId } = useSelector((store) => store.schoolType.schoolTypeDetail)
    const [accountStatus, setAccountStatus] = useState({})
    const [expenseAccount, setExpenseAccount] = useState([])
    const [students, setStudents] = useState(props?.students || [])
    const [variablePriceId, setVariablePrice] = useState(false)
    const setServicePricing = props.setServicePricing
    const gradeSearch = props?.grade;
    const variablePricePage = props?.variablePricePage
    const setVariablePricePage = props?.setVariablePricePage
    const setSrviceChecked = props?.setSrviceChecked

    const [rowChecked, setRowChecked] = useState([])

    const handleAmountChange = (index, value) => {
        const updatedStudents = [...students];
        updatedStudents[index].amount = value;
        setStudents(updatedStudents);
    }

    const cancel = (serviceId) => {
        const updatedServices = props.services
            .map((student) => {
                const filteredServices = student.services.filter(service => service.productId !== serviceId);
                return {
                    studentId: student.studentId,
                    services: filteredServices
                };
            })
            .filter(student => student.services.length > 0);

        props.setServices(updatedServices);
        setRowChecked(prev => prev.filter(item => item !== serviceId));
        setVariablePricePage(false)
    }

    const AddServices = () => {
        const newService = (student) => ({
            productId: variablePriceId,
            quantity: 1,
            accountId: "",
            variablePrice: true,
            productAmount: parseInt(student.amount) || 0,
        });

        const serviceMap = {};

        props.services.forEach(student => {
            serviceMap[student.studentId] = {
                studentId: student.studentId,
                services: student.services || []
            };
        });

        students.forEach(student => {
            const service = newService(student);

            if (serviceMap[student.studentId]) {
                serviceMap[student.studentId].services.push(service);
            } else {
                serviceMap[student.studentId] = {
                    studentId: student.studentId,
                    services: [service]
                };
            }
        });

        const updatedServices = Object.values(serviceMap);

        props.setServices(updatedServices);

        console.log('updatedServices ', props.services)
        setVariablePricePage(false)
    }

    const allAmountsFilled = students.every((student) => student.amount && student.amount.trim() !== "")
    const isServicesEmpty = props.services.length === 0 || props.services.every(student => student.services.length === 0);


    function getExpenseAccount() {
        try {
            HttpComponent({
                method: 'POST',
                url: `/api/v1/business/get_general_ledger_accounts`,
                body: {
                    businessID: localStorage.getItem('businessID')
                },
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data.status === 200) {
                    let expenseAccount = data?.response?.data
                    console.log(expenseAccount, 'expenseAccount????')
                    expenseAccount = expenseAccount.map((account) => {
                        return { label: account?.name, value: account?.accountId }
                    })
                    setExpenseAccount(expenseAccount)
                }

            })

        } catch (error) {

        }
    }

    useEffect(() => {
        getExpenseAccount()
    }, [])
    function getAccountingStatus() {
        try {
            HttpComponent({
                method: "POST",
                url: '/api/v1/check_accounting_status',
                body: {
                    businessID: localStorage.getItem('businessID')
                },
                token: localStorage.getItem('X-Authorization'),
            }).then((data) => {
                if (data.status === 200) {
                    setAccountStatus(data.response?.data?.accountingEnabled)
                }
            })
        } catch (error) {
        }
    }

    useEffect(() => {
        getAccountingStatus()
    }, [])



    const [formData, setFormData] = useState({
        search: "",
        categoryName: ""
    })

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const fetchCategories = () => {
        HttpComponent({
            method: 'GET',
            url: `/api/listCategories/Active?productService=Service`,
            body: null,
            token: X_Authorization
        }).then((data) => {
            console.log("here store is data", data);
            if (data.status === 200) {
                const mappedData = data.response.data.map(({ categoryName, _id }) => ({ label: categoryName, value: _id }));
                console.log("mapped complete", mappedData)
                setOptions(mappedData)
            } else {
                console.error("Error setting info")
                setErrorShow({ state: true, message: data.response.message })
            }
        }).catch((error) => {
            console.error(error.message);
        })
    }

    const [url, setUrl] = useState(`/api/listProducts/Active?search=&productService=Service&grade=${escape(gradeSearch)}`)
    const fetchServices = () => {
        if (formData.categoryName === "") {
            setUrl(`/api/listProducts/Active?search=&productService=Service&grade=${escape(gradeSearch)}`)

        } else {
            setUrl(`/api/listByProducts?categoryId=${formData.categoryName}&page=1&limit=1000&productService=Service`)
        }


        HttpComponent({
            method: 'GET',
            url: url,
            body: null,
            token: X_Authorization
        }).then((data) => {

            if (data.status === 200) {
                if (formData.categoryName === "") {
                    console.log(data?.response?.data,)
                    setCheckboxData(data?.response?.data?.filter((product) => product?.productName !== 'Convenience Fee' && product?.productName !== 'Zpm Activation Fee'))
                    setData()
                } else {
                    console.log(data?.response?.products, 'jjjjjjjjjjjjj')
                    setCheckboxData(data.response.products)
                    setData()
                }
            } else {
                console.error("Error setting info")
                setErrorShow({ state: true, message: data.response.message })
            }
        }).catch((error) => {
            console.error(error.message);
        })
    }

    useEffect(() => {
        fetchCategories()
    }, []);

    useEffect(() => {
        fetchServices()
    }, [formData.categoryName, url]);


    const setData = () => {
        if (formData.search !== "") {
            const searchTerm = formData.search.toLowerCase();
            const filteredData = checkboxData.filter(obj => obj.productName.toLowerCase().includes(searchTerm));
            setShowData(filteredData);
        } else {
            setShowData(checkboxData)
        }
    }

    useEffect(() => {
        setData()
    }, [formData.search, checkboxData]);


    const handleCheckboxChange = (event, productId, row) => {
        console.log(event.target.checked, productId, row, 'event.target.checked, productId, row')
        if (row?.priceStatus === "Variable Price Service" || row?.priceStatus === "Variable Price Item") {
            if (event.target.checked) {
                setVariablePricePage(true)
                setSrviceChecked(row?.productDescription)
                setVariablePrice(productId)
                setServicePricing(true)
                setRowChecked(prev => [...prev, row?._id]);
            } else {
                const updatedServices = props.services
                    .map((student) => {
                        const filteredServices = student.services.filter(service => service.productId !== row?._id);
                        return {
                            studentId: student.studentId,
                            services: filteredServices
                        };
                    })
                    .filter(student => student.services.length > 0);

                props.setServices(updatedServices);
                setRowChecked(prev => prev.filter(item => item !== row?._id));
            }
        } else {
            // setServices
            if (event.target.checked) {
                const updatedServices = students.map((student) => {
                    const existingStudent = props.services.find(s => s.studentId === student.studentId);

                    const newService = {
                        productId,
                        quantity: 1,
                        accountId: "",
                    };

                    if (existingStudent) {
                        return {
                            studentId: existingStudent.studentId,
                            services: [
                                ...existingStudent.services,
                                newService,
                            ],
                        };
                    }

                    return {
                        studentId: student.studentId,
                        services: [newService],
                    };
                });
                props.setServices(updatedServices);
                setRowChecked(prev => [...prev, row?._id])
                setServicePricing(true)

            } else {
                const updatedServices = props.services
                    .map((student) => {
                        const filteredServices = student.services.filter(service => service.productId !== row?._id);
                        return {
                            studentId: student.studentId,
                            services: filteredServices
                        };
                    })
                    .filter(student => student.services.length > 0);

                props.setServices(updatedServices);
                setRowChecked(prev => prev.filter(item => item !== row?._id));
            }
        }

    }

    const handleGlAccountChange = (event, row) => {
        // check if service is already checked if not tell him to check first   
        const checkStatus = props.services.find((service) => service.productId === row._id)
        if (!checkStatus) {
            setErrorShow({ state: true, message: "Please check the service first" })
            return
        }
        console.log(event, row, 'event, row')
        const services = props.services.map((service) => {
            if (service.productId === row._id) {
                return { ...service, accountId: event.target.value }
            } else {
                return service
            }
        })
        props.setServices(services)

    }

    useEffect(() => {
        console.log("services", [...props.services])
    }, [props.services]);

    const columns = [
        {
            headerName: "Service Name", field: "checked", flex: 1,
            renderCell: (params) => (
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={rowChecked.includes(params.row._id)}
                            onChange={(event) =>
                                handleCheckboxChange(event, params.row._id, params?.row)} />
                    } label={params.row.productName} />
            ),
        },
        { headerName: "Description", field: "productDescription", flex: 1, },
        { headerName: "Grade", field: "grade", flex: 1, },
        { headerName: "Term", field: "term", flex: 1 },
        accountStatus ? {
            field: 'GL Account', headerName: 'accountName', flex: 1.5,
            renderHeader: (params) => <p style={{ fontWeight: "bold", textTransform: "uppercase" }}>GL Account</p>,
            renderCell: (params) => {
                return <CustomSelectField
                    label={"GL Account"}
                    defaultValue={params?.row?.glAccountName}
                    value={params?.row?.accountName}
                    onChange={(e) => handleGlAccountChange(e, params?.row)}
                    options={expenseAccount}

                />
            }

        } : null,
        {
            headerName: "Amount",
            field: "productPrice",
            flex: 1,
            renderCell: (params) => (
                <Typography
                    style={{
                        color: params.row.priceStatus === "Variable Price Service" || params.row.priceStatus === "Variable Price Item" ? "#FFA500" : "inherit",
                    }}
                >
                    {params.row.priceStatus === "Variable Price Service" || params.row.priceStatus === "Variable Price Item" ? "Variable Price" : params.value}
                </Typography>
            ),
        }
    ];
    //                   

    const columnsCollege = [
        {
            headerName: "Service Name", field: "checked", flex: 1,
            renderCell: (params) => (
                <FormControlLabel
                    control={
                        <Checkbox onChange={(event) =>
                            handleCheckboxChange(event, params.row._id)} />
                    } label={params.row.productName} />
            ),
        },
        { headerName: "Description", field: "productDescription", flex: 1, },
        { headerName: "Course", field: "grade", flex: 1, },
        { headerName: "Semester", field: "term", flex: 1 },
        accountStatus ? {
            field: 'GL Account', headerName: 'accountName', flex: 1.5,
            renderHeader: (params) => <p style={{ fontWeight: "bold", textTransform: "uppercase" }}>GL Account</p>,
            renderCell: (params) => {
                return <CustomSelectField
                    label={"GL Account"}
                    defaultValue={params?.row?.glAccountName}
                    value={params?.row?.accountId}
                    onChange={(e) => handleGlAccountChange(e, params?.row)}
                    options={expenseAccount}

                />
            }

        } : null,

        {
            headerName: "Amount", field: "productPrice", flex: 1,
            renderCell: (params) => (
                <Typography>
                    {params.value}
                </Typography>
            )
        },
    ];

    return (

        <Box sx={{ width: "100%" }}>
            {variablePricePage ? (

                <Box component="div" sx={{ width: "70%" }}>
                    <Box sx={{ marginTop: "40px" }}>
                        <TableContainer
                            component={Paper}
                            sx={{ boxShadow: 'none' }}
                        >
                            <Table>
                                <TableHead>
                                    <TableRow >
                                        <TableCell sx={{ borderBottom: 'none' }}>Admission Number</TableCell>
                                        <TableCell sx={{ borderBottom: 'none' }}>Student Name</TableCell>
                                        <TableCell sx={{ borderBottom: 'none' }}>Grade</TableCell>
                                        <TableCell sx={{ borderBottom: 'none' }}>Amount</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>

                                    {students.map((student, index) => (
                                        <TableRow
                                            key={student.studentId}
                                            sx={{
                                                '& > *': {
                                                    border: 'none',
                                                    // padding: '10px',
                                                },
                                                //  gap: '10px',
                                            }}
                                        >
                                            <TableCell>
                                                <Box sx={{ backgroundColor: 'rgb(245, 246, 247)', padding: '15px', borderRadius: '8px', textAlign: 'center', height: '50px' }}>{student.itemNumber}</Box>
                                            </TableCell>
                                            <TableCell>
                                                <Box sx={{ backgroundColor: 'rgb(245, 246, 247)', padding: '15px', borderRadius: '8px', textAlign: 'center', height: '50px' }}>{student.firstName} {student.lastName}</Box>
                                            </TableCell>
                                            <TableCell>
                                                <Box sx={{ backgroundColor: 'rgb(245, 246, 247)', padding: '15px', borderRadius: '8px', textAlign: 'center', height: '50px' }}>{student.grade}</Box>
                                            </TableCell>

                                            <TableCell>
                                                <TextField
                                                    fullWidth
                                                    placeholder="Enter amount"
                                                    value={student.amount || ''}
                                                    onChange={(e) => {
                                                        const value = e.target.value;
                                                        if (value === '' || (!isNaN(value) && value.trim() !== '')) {
                                                            handleAmountChange(index, value);
                                                        }
                                                    }}
                                                    variant="outlined"
                                                    size="small"
                                                    type="number"
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Box sx={{ textAlign: 'right' }}>

                            <Button
                                sx={{
                                    backgroundColor: "#fff",
                                    border: '1px solid red',
                                    width: "141px",
                                    height: "3.438rem",
                                    marginBottom: 1,
                                    color: "red",
                                    "&:hover": {
                                        backgroundColor: "#fff",
                                        color: "red",
                                    },

                                }}
                                onClick={() => cancel(variablePriceId)}
                            >
                                Cancel
                            </Button>

                            <Button

                                sx={{
                                    backgroundColor: "#032541",
                                    width: "141px",
                                    height: "3.438rem",
                                    marginBottom: 1,
                                    marginLeft: '10px',
                                    color: "#fff",
                                    "&:hover": {
                                        backgroundColor: "#032541",
                                        color: "white",
                                    },

                                }}
                                onClick={AddServices}
                                disabled={!allAmountsFilled}
                            >
                                Add
                            </Button>


                        </Box>

                    </Box>
                </Box>


            ) : (
                <Box component="div" sx={{ width: "100%" }}>
                    <ErrorAlert
                        vertical="top"
                        horizontal="right"
                        onClose={() => setErrorShow({ ...errorShow, state: false })}
                        open={errorShow.state}
                        message={errorShow.message} />

                    <Box component="div" sx={{ display: "flex", margin: 1 }}>
                        <Box component="div">
                            <CustomSearchInput sm name={"search"} value={formData.search} placeholder={"Search:"} onChange={handleInputChange} />
                        </Box>
                        <Box component="div" sx={{ marginLeft: 1 }}>
                            <CustomSelectField sm value={formData.categoryName} onChange={handleInputChange} name={"categoryName"} placeholder={"All"} options={options} />
                        </Box>
                        <Box component="div" className='ml-auto' sx={{ marginLeft: 1, display: "flex", flexDirection: "column", justifyContent: "center" }}>
                            <Button sx={{
                                backgroundColor: "#032541",
                                color: 'white',
                                "width": "8.813rem",
                                "height": "3.25rem",
                                marginBottom: 1,
                                '&:hover': {
                                    backgroundColor: '#032541',
                                    color: 'white'
                                },
                                disabled: { isServicesEmpty }
                            }}
                                onClick={() => props.generateInvoices()}
                                disabled={isServicesEmpty}
                            >
                                Generate
                            </Button>
                        </Box>
                    </Box>



                    {/*<Box component="div" sx={{}}>*/}
                    {/*    <B*/}
                    {/*</Box>*/}

                    <Box sx={{ width: "100%" }}>
                        {/*{showData?.length ?*/}
                        {/*    <>*/}
                        {/*        {showData?.map(data => (*/}
                        {/*            <Box component="div" key={data._id} style={{ display: "flex", justifyContent: "space-between", padding: "0.5rem" }}>*/}
                        {/*                <FormControlLabel control={<Checkbox onChange={(event)=>handleCheckboxChange(event, data._id)} />} label={data.productName} />*/}
                        {/*                <CustomTextFieldSmall required label="Amount" defaultValue={`KES ${data.productPrice}`} disabled={true}/>*/}
                        {/*                /!*<TextField id="outlined-basic"  variant="outlined" style={{ width: "60%", height: "2.188rem" }} />*!/*/}
                        {/*            </Box>*/}
                        {/*        ))}*/}
                        {/*    </>*/}
                        {/*    :*/}
                        {/*    "No Services Found"*/}
                        {/*}*/}
                        <CustomTable fontSize={'10px'} data={showData} columns={schoolTypeName?.includes("University") ? columnsCollege : columns} rowsPerPage={5} />

                    </Box>


                </Box>

            )}

        </Box>

    )
}

export default ServicesInvoiceForm;