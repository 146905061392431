import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextField, Button, Grid, Box } from "@mui/material";
import { customerstyles } from "./styles";
import { removeInvoiceItems } from "../../../features/startorderSlice";
import { useDispatch, useSelector } from "react-redux";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

export const CustomDate = (props) => {
  const dispatch = useDispatch();
  const setDateFrom = props?.setDateFrom;
  const setDateTo = props?.setDateTo;

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const gridFormatDate = (inputDate) => {
    // const today = new Date();
    const yyyy = new Date(inputDate)?.getFullYear();
    let mm = new Date(inputDate)?.getMonth() + 1; // Months start at 0!
    let dd = new Date(inputDate)?.getDate();

    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;

    const formattedToday = yyyy + "-" + mm + "-" + dd;

    return formattedToday;
  };

  useEffect(() => {
    const newStartDate = gridFormatDate(startDate);
    setDateFrom(newStartDate);
    const newEndDate = gridFormatDate(endDate);
    setDateTo(newEndDate);
  }, [startDate, endDate]);

  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        {/* <DatePicker label="Uncontrolled picker" defaultValue={dayjs(new Date())} /> */}

        <Grid container>
          <Grid item width={"50%"}>
            <DatePicker label="Start" value={startDate} onChange={(newValue) => setStartDate(newValue)} renderInput={(params) => <TextField {...params} style={{ margin: "0px 15px 0px 15px" }} />} />
          </Grid>

          <Grid item width={"50%"}>
            <DatePicker label="End" value={endDate} onChange={(newValue) => setEndDate(newValue)} renderInput={(params) => <TextField {...params} />} />
          </Grid>
        </Grid>
      </LocalizationProvider>
    </div>
  );
};

export const CustomizedDate = ({ style, setDateFrom, setDateTo, styles }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const gridFormatDate = (inputDate) => {
    // const today = new Date();
    const yyyy = new Date(inputDate)?.getFullYear();
    let mm = new Date(inputDate)?.getMonth() + 1; // Months start at 0!
    let dd = new Date(inputDate)?.getDate();

    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;

    const formattedToday = yyyy + "-" + mm + "-" + dd;

    return formattedToday;
  };
  useEffect(() => {
    const today = dayjs().startOf('day');
    if (startDate && dayjs(startDate).isAfter(today)) {
      setStartDate(today);
    }
    const newStartDate = gridFormatDate(startDate);
    startDate ? setDateFrom(newStartDate) : setDateFrom("");
    if (endDate && dayjs(endDate).isAfter(today)) {
      setEndDate(today);
    }
    const newEndDate = gridFormatDate(endDate);
    endDate ? setDateTo(newEndDate) : setDateTo("");
  }, [startDate, endDate, setDateFrom, setDateTo]);


  // useEffect(() => {
  //   const newStartDate = gridFormatDate(startDate);
  //   startDate ? setDateFrom(newStartDate) : setDateFrom("");
  //   const newEndDate = gridFormatDate(endDate);
  //   endDate ? setDateTo(newEndDate) : setDateTo("");
  // }, [startDate, endDate]);

  return (
    <div style={style ? style : {}}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <DatePicker
              value={startDate}
              onChange={(newValue) => setStartDate(newValue)}
              label="Start"
              renderInput={(params) => (
                  <TextField
                      {...params}
                      sx={{ ...styles, width: '200px' }}
                  />
              )}
          />
          <DatePicker
              label="End"
              value={endDate}
              onChange={(newValue) => setEndDate(newValue)}
              renderInput={(params) => (
                  <TextField
                      {...params}
                      sx={{ ...styles, width: '200px' }}
                  />
              )}
          />
          {/*<DatePicker label="Start" value={startDate} onChange={(newValue) => setStartDate(newValue)} renderInput={(params) => <TextField {...params} sx={styles} />} />*/}
          {/*<DatePicker label="End" value={endDate} onChange={(newValue) => setEndDate(newValue)} renderInput={(params) => <TextField {...params} sx={styles} />} />*/}
        </Box>
      </LocalizationProvider>
    </div>
  );
};

export const CustomizedDateTimepicker = ({ style, setDateFrom, setDateTo, styles }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  useEffect(() => {
    if (setDateFrom && startDate) {
      const utcStartDate = dayjs(startDate).utc().format();
      setDateFrom(utcStartDate);
    }
  }, [startDate, setDateFrom]);

  useEffect(() => {
    if (setDateTo && endDate) {
      const utcEndDate = dayjs(endDate).utc().format();
      setDateTo(utcEndDate);
    }
  }, [endDate, setDateTo]);
  return (
    <div style={style ? style : {}}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box component={"DateTimePicker"} sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <DateTimePicker label="Start" value={startDate} onChange={(newValue) => setStartDate(newValue)} renderInput={(params) => <TextField {...params} sx={styles} />} />
          <DateTimePicker label="End" value={endDate} onChange={(newValue) => setEndDate(newValue)} renderInput={(params) => <TextField {...params} sx={styles} />} />
        </Box>
      </LocalizationProvider>
    </div>
  );
};

export const gridDateFormater = (inputDate) => {
  // const today = new Date();
  const yyyy = new Date(inputDate)?.getFullYear();
  let mm = new Date(inputDate)?.getMonth() + 1; // Months start at 0!
  let dd = new Date(inputDate)?.getDate();

  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;

  const formattedToday = dd + "/" + mm + "/" + yyyy;

  return formattedToday;
};

export const PreviewBtn = (props) => {
  const setInvoiceArray = props?.setInvoiceArray;
  const setPreviewInvoiceItemsShow = props?.setPreviewInvoiceItemsShow;
  const invoiceTotals = props?.invoiceTotals;
  const setError = props?.setError;
  const dispatch = useDispatch();
  return (
    <div>
      {/* <Button onClick={() => setInvoiceArray([])} style={customerstyles.cancelBtn}> */}
      <Button
        onClick={() => {
          dispatch(removeInvoiceItems());
          setInvoiceArray([]);
        }}
        style={customerstyles.cancelBtn}
      >
        Cancel
      </Button>
      <Button style={customerstyles.approvalButton} onClick={() => (invoiceTotals > 0 ? setPreviewInvoiceItemsShow(true) : setError({ state: true, message: "Check the invoice Amount" }))}>
        Preview Invoice
      </Button>
    </div>
  );
};

export default function BasicDatePicker({onChange, styles, label, value,}) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateTimePicker value={value ? value : new Date()} onChange={onChange} label={label} renderInput={(params) => <TextField {...params} sx={styles} />} />
      {/* <DatePicker label="Basic date picker" /> */}
    </LocalizationProvider>
  );
}
