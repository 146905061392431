import { useState, useEffect } from 'react'
import { Box, Grid, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { ArrowBack, Forward } from "@mui/icons-material";
import { styled } from '@mui/material/styles';
import Switch from "@mui/material/Switch";
import { useSelector, useDispatch } from 'react-redux';
import KCBMobileMoney from './KCBMobileMoney';
import KCBBankDeposit from './KCBBankDeposit';
import EquityMobileMoney from './EquityMobileMoney';
import EquityBankDeposit from './EquityBankDeposit';
import CopMobileMoney from './CopMobileMoney';
import CopBankDeposit from './CopBankDeposit';
import { resetPaymentOptions, setCopMobileStatus, setKCBBankDepositStatus, setKCBMobileStatus ,setAccessBankStatus } from '../../../features/paymentOptionsSlice';
import HttpComponent from '../MakeRequest';
import KCBPocketMoney from './KCBPocketmoney';
import KcbDebitAccountSetUp from './kcbDebitAccount';
import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos';
import Accessbankdeposit from './acessbankdeposit';
import AccessBankDebit from './accessdebitaccount';
import { GetDefaultCurrency } from '../../../common/currencyconverter';
import KCBCreditAccountSetUp from './kcbCreditAccountSetup';

const BankOptions = () => {

  const [formState, setFormState] = useState(false)
  const [localCurrency, setLocalCurrency] = useState(GetDefaultCurrency())
  const [showKCBOptions, setShowKCBOptions] = useState(false);
  const [showEquityOptions, setShowEquityOptions] = useState(false);
  const [showCoperativeOptions, setShowCoperativeOptions] = useState(false);
  const [kcbMobileChecked, setKCBMobileChecked] = useState(false);
  const [kcbDepositChecked, setKCBDepositChecked] = useState(false);
  const [kcbPocketMoneyChecked, setKCBPocketChecked] = useState(false);
  const [equityMobileChecked, setEquityMobileChecked] = useState(false);
  const [equityDepositChecked, setEquityDepositChecked] = useState(false);
  const [copMobileChecked, setCopMobileChecked] = useState(false);
  const [copDepositChecked, setCopDepositChecked] = useState(false);
  const [showKCBMobileMoneySetup, setShowKCBMobileMoneySetup] = useState(false);
  const [showKCBBankDepositSetup, setShowKCBBankDepositSetup] = useState(false);
  const [showKCBBankDebitSetup, setShowKCBBankDebitSetup] = useState(false);
  const [showKCBBankCreditSetup, setShowKCBBankCreditSetup] = useState(false);
  const [kcbDebitAccountChecked, setKCBDebitAccountChecked] = useState(false);
  const [kcbDebitAccountCount, setKCBDebitAccountCount] = useState(0);
  const [kcbCreditAccountChecked, setKCBCreditAccountChecked] = useState(false);
  const [kcbCreditAccountCount, setKCBCreditAccountCount] = useState(0);
  const [showEquityMobileMoneySetup, setShowEquityMobileMoneySetup] = useState(false);
  const [showEquityBankDepositSetup, setShowEquityBankDepositSetup] = useState(false);
  const [showCopMobileMoneySetup, setShowCopMobileMoneySetup] = useState(false);
  const [showCopBankDepositSetup, setShowCopBankDepositSetup] = useState(false);
  const [errorShow, setErrorShow] = useState({ state: false, message: "" })
  const [successShow, setSuccessShow] = useState({ state: false, message: "" })
  const dispatch = useDispatch();

  // ACCESS BANK SETUP
  const [showAccessBankOptions, setShowAccessBankOptions] = useState(false)
  const [showAccessBankChecked, setShowAccessBankChecked] = useState(false)
  const [showAccessBankDepositSetUp, setShowAccessBankDepositSetUp] = useState(false)

  // ACCESS BANK DEBIT ACCOUNTS;
  const [showAccessDebitOptions, setShowAccessDebitOptions] = useState(false);
  const [showAccessDebitChecked, setShowAccessChecked] = useState(false)
  const [accessBankCount, setAccessBankCount] = useState(0)
  const [showAccessDebitSetUp, setShowAccessDebitSetup] = useState(false)

// console.log("showCopBankDepositSetup ===->", showCopBankDepositSetup)

  const { userId, X_Authorization } = useSelector((store) => store.user);
  const [kcbpocketMoneyStatus, setKcbpocketMoneystatus] = useState()
  const [showKcbpocketMoneySetUp, setShowKcbPocketMoneySetUp] = useState(false)

  // redux toolkit imports
  let { KCBMobileStatus, KCBBankDepositStatus, equityMobileMoneyStatus, equityBankDepositStatus, copMobileMoneyStatus, copBankDepositStatus, accessBankStatus } = useSelector((store) => store.payment);

  // option switch
  const CustomSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 45,
    height: 23,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 18,
      height: 18,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }));

  const changeFormState = () => {
    if (formState) {
      setFormState(false)
      setShowKCBBankDebitSetup(false)
      setShowAccessDebitOptions(false)
    } if (!formState) {
      setFormState(true)
    }

  }

  //payit status

  const [payitStatus, setPayitStatus] = useState()

  function getPayitStatus() {
    try {
      HttpComponent({
        method: "GET",
        url: '/api/v1/getZedPayItStatus',
        token: localStorage.getItem('X-Authorization'),
      }).then((data) => {
        //console.log(data , 'payit status')
        if (data.status === 200) {
          setPayitStatus(data?.response?.data)
        }
      })
    } catch (error) {
    }
  }

  useEffect(() => {
    getPayitStatus()
  }, [])

  // form toggling effects
  useEffect(() => {
    if (kcbMobileChecked) {
      setShowKCBMobileMoneySetup(true)
      setShowKCBBankDepositSetup(false)
      setShowKcbPocketMoneySetUp(false)
      setShowEquityMobileMoneySetup(false)
      setShowEquityBankDepositSetup(false)
      setShowCopMobileMoneySetup(false)
      setShowCopBankDepositSetup(false)
    } else {
      setShowKCBMobileMoneySetup(false)
      setShowKCBBankDepositSetup(false)
      setShowEquityMobileMoneySetup(false)
      setShowEquityBankDepositSetup(false)
      setShowCopMobileMoneySetup(false)
      setShowCopBankDepositSetup(false)
    }
  }, [kcbMobileChecked]);

  // pocket money

  useEffect(() => {
    if (kcbPocketMoneyChecked) {
      setShowKCBMobileMoneySetup(false)
      setShowKCBBankDepositSetup(false)
      setShowKcbPocketMoneySetUp(true)
      setShowEquityMobileMoneySetup(false)
      setShowEquityBankDepositSetup(false)
      setShowCopMobileMoneySetup(false)
      setShowCopBankDepositSetup(false)
    } else {
      setShowKCBMobileMoneySetup(false)
      setShowKCBBankDepositSetup(false)
      setShowEquityMobileMoneySetup(false)
      setShowEquityBankDepositSetup(false)
      setShowCopMobileMoneySetup(false)
      setShowCopBankDepositSetup(false)
    }
  }, [kcbPocketMoneyChecked])


  //   bank option rendering
  useEffect(() => {
    if (kcbDepositChecked) {
      setShowKCBMobileMoneySetup(false)
      setShowKcbPocketMoneySetUp(false)
      setShowKCBBankDepositSetup(true)
      setShowEquityMobileMoneySetup(false)
      setShowEquityBankDepositSetup(false)
      setShowCopMobileMoneySetup(false)
      setShowCopBankDepositSetup(false)
      setShowAccessBankDepositSetUp(false)
    } else {
      setShowKCBMobileMoneySetup(false)
      setShowKCBBankDepositSetup(false)
      setShowKcbPocketMoneySetUp(false)
      setShowEquityMobileMoneySetup(false)
      setShowEquityBankDepositSetup(false)
      setShowCopMobileMoneySetup(false)
      setShowCopBankDepositSetup(false)
      setShowAccessBankDepositSetUp(false)
    }
  }, [kcbDepositChecked]);



  //   bank paybill option rendering
  useEffect(() => {
    if (equityMobileChecked) {
      setShowEquityMobileMoneySetup(true)
      setShowKCBMobileMoneySetup(false)
      setShowKCBBankDepositSetup(false)
      setShowKcbPocketMoneySetUp(false)
      setShowEquityBankDepositSetup(false)
      setShowCopMobileMoneySetup(false)
      setShowCopBankDepositSetup(false)
      setShowAccessBankDepositSetUp(false)
    } else {
      setShowKCBMobileMoneySetup(false)
      setShowKCBBankDepositSetup(false)
      setShowKcbPocketMoneySetUp(false)
      setShowEquityMobileMoneySetup(false)
      setShowEquityBankDepositSetup(false)
      setShowCopMobileMoneySetup(false)
      setShowCopBankDepositSetup(false)
      setShowAccessBankDepositSetUp(false)
    }
  }, [equityMobileChecked]);

  //   card option rendering
  useEffect(() => {
    if (equityDepositChecked) {
      setShowKCBMobileMoneySetup(false)
      setShowKCBBankDepositSetup(false)
      setShowKcbPocketMoneySetUp(false)
      setShowEquityMobileMoneySetup(false)
      setShowEquityBankDepositSetup(true)
      setShowCopMobileMoneySetup(false)
      setShowCopBankDepositSetup(false)
      setShowAccessBankDepositSetUp(false)
    } else {
      setShowKCBMobileMoneySetup(false)
      setShowKCBBankDepositSetup(false)
      setShowEquityMobileMoneySetup(false)
      setShowKcbPocketMoneySetUp(false)
      setShowEquityBankDepositSetup(false)
      setShowCopMobileMoneySetup(false)
      setShowCopBankDepositSetup(false)
      setShowAccessBankDepositSetUp(false)
    }
  }, [equityDepositChecked]);

  //   MTN MoMo option rendering
  useEffect(() => {
    if (copMobileChecked) {
      setShowKCBMobileMoneySetup(false)
      setShowKCBBankDepositSetup(false)
      setShowKcbPocketMoneySetUp(false)
      setShowEquityMobileMoneySetup(false)
      setShowEquityBankDepositSetup(false)
      setShowCopMobileMoneySetup(true)
      setShowCopBankDepositSetup(false)
      setShowAccessBankDepositSetUp(false)
    } else {
      setShowKCBMobileMoneySetup(false)
      setShowKCBBankDepositSetup(false)
      setShowEquityMobileMoneySetup(false)
      setShowEquityBankDepositSetup(false)
      setShowKcbPocketMoneySetUp(false)
      setShowCopMobileMoneySetup(false)
      setShowCopBankDepositSetup(false)
      setShowAccessBankDepositSetUp(false)
    }
  }, [copMobileChecked]);


  // paypal option rendering
  useEffect(() => {
    if (copDepositChecked) {
      setShowKCBMobileMoneySetup(false)
      setShowKCBBankDepositSetup(false)
      setShowEquityMobileMoneySetup(false)
      setShowKcbPocketMoneySetUp(false)
      setShowEquityBankDepositSetup(false)
      setShowCopMobileMoneySetup(false)
      setShowCopBankDepositSetup(true)
      setShowAccessBankDepositSetUp(false)
    } else {
      setShowKCBMobileMoneySetup(false)
      setShowKCBBankDepositSetup(false)
      setShowEquityMobileMoneySetup(false)
      setShowEquityBankDepositSetup(false)
      setShowKcbPocketMoneySetUp(false)
      setShowCopMobileMoneySetup(false)
      setShowCopBankDepositSetup(false)
      setShowAccessBankDepositSetUp(false)
    }
  }, [copDepositChecked]);

  useEffect(() => {
    if(showAccessBankChecked){
      setShowKCBMobileMoneySetup(false)
      setShowKCBBankDepositSetup(false)
      setShowEquityMobileMoneySetup(false)
      setShowKcbPocketMoneySetUp(false)
      setShowEquityBankDepositSetup(false)
      setShowCopMobileMoneySetup(false)
      setShowCopBankDepositSetup(false)
      setShowAccessBankDepositSetUp(true)
    }else{
      setShowKCBMobileMoneySetup(false)
      setShowKCBBankDepositSetup(false)
      setShowEquityMobileMoneySetup(false)
      setShowKcbPocketMoneySetUp(false)
      setShowEquityBankDepositSetup(false)
      setShowCopMobileMoneySetup(false)
      setShowCopBankDepositSetup(false)
      setShowAccessBankDepositSetUp(false)
    }
  }, [showAccessBankChecked])
  const checkStatus = () => {
    console.log('here check status');
    dispatch(resetPaymentOptions())

    HttpComponent({
      method: 'GET',
      url: `/api/get_payment_methods_with_status`,
      body: null,
      token: X_Authorization
    }).then((data) => {
      // console.log("all payment status", data);
      if (data?.status === 201) {
        let banks = data?.response?.data?.find(method => method?.name === "Banks") || []
        // ACCESS BANKS
        let accessBanks = banks?.paymentOptions?.find(method => method?.name === "Accessbank") || []
        let accessbankBankStatus = accessBanks?.accessbank?.find(method => method?.name === "ACCESS CARD")?.status
        dispatch(setAccessBankStatus(accessbankBankStatus))
        let accessbankChecked = accessBanks?.accessbank?.find(method => method?.name === "Access Bank Deposit")?.status
        let accessBankCount = accessBanks?.accessbank?.find(method => method?.name === "Access Bank Deposit")?.count
        setShowAccessChecked(accessbankChecked)
        setAccessBankCount(accessBankCount)
        // KCB BANKS
        let kcbBanks = banks?.paymentOptions?.find(method => method?.name === "KCB") || []
        KCBMobileStatus = kcbBanks?.kcb?.find(method => method?.name === "Mobile Money")?.status
        dispatch(setKCBMobileStatus(KCBMobileStatus))
 
        KCBBankDepositStatus = kcbBanks?.kcb?.find(method => method?.name === "KCB DEPOSIT")?.status
        dispatch(setKCBBankDepositStatus(KCBBankDepositStatus))

        let kcbDebitAccountStatus = kcbBanks?.kcb?.find(method => method?.name === "Kcb Debit Accounts")?.status
        let kcbDebitAccountCount = kcbBanks?.kcb?.find(method => method?.name === "Kcb Debit Accounts")?.count || 0
        let kcbCreditAccountStatus = kcbBanks?.kcb?.find(method => method?.name === "Kcb Credit Accounts")?.status
        let kcbCreditAccountCount = kcbBanks?.kcb?.find(method => method?.name === "Kcb Credit Accounts")?.count || 0
        setKcbpocketMoneystatus(kcbBanks?.kcb?.find(method => method?.name === "KCB POCKET MONEY")?.status)
        setKCBDebitAccountCount(kcbDebitAccountCount)
        setKCBDebitAccountChecked(kcbDebitAccountStatus)
        setKCBCreditAccountChecked(kcbCreditAccountStatus)
        setKCBCreditAccountCount(kcbCreditAccountCount)

        // COOP BANKS
        let coopBanks = banks?.paymentOptions?.find(method => method?.name === "Cooperative") || []
        copBankDepositStatus = coopBanks?.coOperative?.find(method => method?.name === "COOP DEPOSIT")?.status
        dispatch(setCopMobileStatus(copBankDepositStatus)) 

      } else {
        console.error("Error setting info")
        setErrorShow({ state: true, message: data.response.message })
      }
    }).catch((error) => {
      console.error(error.message);
    })
  }
  useEffect(() => {
    checkStatus()

  }, [showKCBBankDebitSetup])
  const parentDebitAccountShowState = (childData) => {
    console.log('childData', childData);
    let state = childData.state
    setShowKCBBankDebitSetup(state)
  }

  const parentCheckStatusKCBMobileMoney = (childData) => {
    console.log('childData', childData);
    let state = childData.state
    setShowKCBMobileMoneySetup(state)
  }
  const parentCheckStatusKCBBankDeposit = (childData) => {
    console.log('childData', childData);
    let state = childData.state
    setShowKCBBankDepositSetup(state)
  }


  return (
    <Box component="div" sx={{ display: 'flex', flexDirection: 'column', fontFamily: 'Poppins', width: "100%", minHeight: "20.938rem", "borderRadius": "6px", "border": "solid 1px #eaeaea", marginTop: "4.6%" }}>
      <Box style={{ display: "flex", flexDirection: "row", marginTop: "15px", marginLeft: "16px" }}>
        <ArrowBack onClick={changeFormState} style={{ fontSize: "30px", cursor: "pointer" }} />
        <span style={{ fontSize: "16px", color: "#032541", fontWeight: "600", marginLeft: "20px" }}> {showKCBBankDebitSetup ? " KCB BANK Debit Accounts" : showAccessDebitOptions ?  "ACCESS BANK Debit Accounts"  :"Banks"} </span>
      </Box>

      {/* bank payment option selections */}
      {showKCBBankDebitSetup  ?
        <Box style={{padding:"20px"}}>
          <KcbDebitAccountSetUp parentDebitAccountShowState={parentDebitAccountShowState} />
        </Box> : showKCBBankCreditSetup ? <KCBCreditAccountSetUp setShowKCBBankCreditSetup={setShowKCBBankCreditSetup}/>: showAccessDebitOptions ?  <AccessBankDebit setShowAccessDebitOptions={setShowAccessDebitOptions} /> : 
        <>
          {(!showCopBankDepositSetup || !showCopMobileMoneySetup || !showKCBBankDepositSetup || !showKcbpocketMoneySetUp || !showKCBMobileMoneySetup || !showEquityBankDepositSetup || !showEquityMobileMoneySetup)  &&
            <Box component="div" style={{ display: 'flex', flexDirection: 'column', width: '100%', padding: '50px' }}>
              {/* kcb grid */}
              {
                (localCurrency === "KES") &&    <Grid container style={{ display: 'flex', flexDirection: 'column' }} mb={localCurrency === "KES" ? 2 : null}>
                <Grid item style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer' ,}} onClick={() => setShowKCBOptions(!showKCBOptions)}>
               <Typography variant='h6' style={{ fontSize: '14px', fontWeight: 500, color: '#032541', margin: '0 0 0 1.5px', lineHeight: 1.14, letterSpacing: '0.47px' }}>KCB Bank</Typography> {!showKCBOptions ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                  
                </Grid>
                { (showKCBOptions)  && <Grid item>

                  {/* KCB Mobile Money Setup */}
                  <Box component="div" sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}>
                    <Box component="div" sx={{ width: "100%", display: "flex", justifyContent: "space-between", padding: 0.5, cursor: 'pointer' }} onClick={() => {
                      setKCBMobileChecked(!kcbMobileChecked);
                    }}>

                      <Box component="div" sx={{ display: "flex" }}>
                        <Box component="div" sx={{ display: "flex" }}>
                          <Typography sx={{ color: "#707070", fontSize: '14px', lineHeight: 2.57 }}>
                            Mobile Money
                          </Typography>
                        </Box>
                      </Box>

                      <Box component="div" sx={{ display: "flex", alignItems: 'center', justifyContent: "center" }}>
                        {!KCBMobileStatus ? <Typography style={{ color: "#707070", fontSize: '14px', marginRight: '5px', lineHeight: 2.57 }}>Not Set</Typography> : <Typography style={{ color: '#33cf4d', fontSize: '14px', lineHeight: 2.57, marginRight: '5px' }}>Active</Typography>}
                        <CustomSwitch
                          checked={KCBMobileStatus}
                          onChange={() => setKCBMobileChecked(!kcbMobileChecked)}
                          inputProps={{ 'aria-label': 'controlled' }}
                          color="warning"
                        />
                      </Box>
                    </Box>
                  </Box>

                  {/* KCB Bank Deposit Setup */}
                  <Box component="div" sx={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", }}>
                    <Box component="div" sx={{ width: "100%", display: "flex", justifyContent: "space-between", padding: 0.5, cursor: 'pointer' }} onClick={() => {
                      // setKCBDepositChecked(!kcbDepositChecked);
                    }}>

                      <Box component="div" sx={{ display: "flex" }}>
                        <Box component="div" sx={{ display: "flex" }}>
                          <Typography sx={{ color: "#707070", fontSize: '14px', lineHeight: 2.57 }}>
                            Bank Deposit
                          </Typography>
                        </Box>
                      </Box>

                      <Box component="div" sx={{ display: "flex", alignItems: 'center', justifyContent: "center" }}>
                        {!KCBBankDepositStatus ? <Typography style={{ color: "#707070", fontSize: '14px', marginRight: '5px', lineHeight: 2.57 }}>Not Set</Typography> : <Typography style={{ color: '#33cf4d', fontSize: '14px', lineHeight: 2.57, marginRight: '5px' }}>Active</Typography>}
                        <CustomSwitch
                          checked={KCBBankDepositStatus}
                          onChange={() => setKCBDepositChecked(!kcbDepositChecked)}
                          inputProps={{ 'aria-label': 'controlled' }}
                          color="warning"
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Box component="div" sx={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", }}>
                    <Box component="div" sx={{ width: "100%", display: "flex", justifyContent: "space-between", padding: 0.5, cursor: 'pointer' }} onClick={() => {
                      setShowKCBBankDebitSetup(!showKCBBankDebitSetup);
                    }}>

                      <Box component="div" sx={{ display: "flex" }}>
                        <Box component="div" sx={{ display: "flex" }}>
                          <Typography sx={{ color: "#707070", fontSize: '14px', lineHeight: 2.57 }}>
                            Debit Accounts
                          </Typography>
                        </Box>
                      </Box>

                      <Box component="div" sx={{ display: "flex", alignItems: 'center', justifyContent: "center" }}>
                        {!kcbDebitAccountChecked ? <Typography style={{ color: "#707070", fontSize: '14px', marginRight: '5px', lineHeight: 2.57 }}>Not Set</Typography> : <Typography style={{ color: '#33cf4d', fontSize: '14px', lineHeight: 2.57, marginRight: '5px' }}> {kcbDebitAccountCount} Active</Typography>}
                        {/* <CustomSwitch
                      checked={kcbDebitAccountChecked}
                      onChange={() => setShowKCBBankDebitSetup(!showKCBBankDebitSetup)}
                      inputProps={{ 'aria-label': 'controlled' }}
                      color="warning"
                    /> */}
                        <ArrowForwardIos style={{ fontSize: "14px" }} onClick={() => {
                          setShowKCBBankDebitSetup(!showKCBBankDebitSetup);
                        }} />
                      </Box>
                    </Box>
                  </Box>
                  <Box component="div" sx={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", }}>
                    <Box component="div" sx={{ width: "100%", display: "flex", justifyContent: "space-between", padding: 0.5, cursor: 'pointer' }} onClick={() => {
                      setShowKCBBankCreditSetup(!showKCBBankCreditSetup);
                    }}>

                      <Box component="div" sx={{ display: "flex" }}>
                        <Box component="div" sx={{ display: "flex" }}>
                          <Typography sx={{ color: "#707070", fontSize: '14px', lineHeight: 2.57 }}>
                            Credit Accounts
                          </Typography>
                        </Box>
                      </Box>

                      <Box component="div" sx={{ display: "flex", alignItems: 'center', justifyContent: "center" }}>
                        {!kcbCreditAccountChecked ? <Typography style={{ color: "#707070", fontSize: '14px', marginRight: '5px', lineHeight: 2.57 }}>Not Set</Typography> : <Typography style={{ color: '#33cf4d', fontSize: '14px', lineHeight: 2.57, marginRight: '5px' }}> {kcbCreditAccountCount} Active</Typography>}
                        <ArrowForwardIos style={{ fontSize: "14px" }} onClick={() => {
                          setShowKCBBankCreditSetup(!showKCBBankCreditSetup);
                        }} />
                      </Box>
                    </Box>
                  </Box>
                </Grid>}
              </Grid>
              }
            
              {/* <br /> */}

              {/* equity grid */}
              {(localCurrency === "KES") &&      <Grid container style={{ display: 'flex', flexDirection: 'column' }} mb={localCurrency === "KES" ? 2 : null} >
                <Grid item style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer' }} onClick={() => {

                  //  setShowEquityOptions(!showEquityOptions)
                }}>
                  {(localCurrency !== "NGN") && <><Typography variant='h6' style={{ fontSize: '14px', fontWeight: 500, color: '#032541', margin: '0 0 0 1.5px', lineHeight: 1.14, letterSpacing: '0.47px' }}>Equity Bank <span style={{ color: "gray" }}>COMING SOON</span></Typography>
                  {!showEquityOptions ? <ExpandMoreIcon /> : <ExpandLessIcon />} </>}
                  
                </Grid>
                {showEquityOptions && <Grid item>

                  {/* Equity Mobile Money Setup */}
                  <Box component="div" sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}>
                    <Box component="div" sx={{ width: "100%", display: "flex", justifyContent: "space-between", padding: 0.5, cursor: 'pointer' }} onClick={() => {
                      setEquityMobileChecked(!equityMobileChecked);
                      // console.log('Mobile Money Option!');
                    }}>

                      <Box component="div" sx={{ display: "flex" }}>
                        <Box component="div" sx={{ display: "flex" }}>
                          <Typography sx={{ color: "#707070", fontSize: '14px', lineHeight: 2.57 }}>
                            Mobile Money
                          </Typography>
                        </Box>
                      </Box>

                      <Box component="div" sx={{ display: "flex", alignItems: 'center', justifyContent: "center" }}>
                        {!equityMobileMoneyStatus ? <Typography style={{ color: "#707070", marginRight: '5px', fontSize: '14px', lineHeight: 2.57 }}>Not Set</Typography> : <Typography style={{ color: '#33cf4d', marginRight: '5px', fontSize: '14px', lineHeight: 2.57 }}>Active</Typography>}
                        <CustomSwitch
                          checked={equityMobileMoneyStatus}
                          onChange={() => setEquityMobileChecked(!equityMobileChecked)}
                          inputProps={{ 'aria-label': 'controlled' }}
                          color="warning"
                        />
                      </Box>
                    </Box>
                  </Box>

                  {/* Equity Bank Deposit Setup */}
                  <Box component="div" sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}>
                    <Box component="div" sx={{ width: "100%", display: "flex", justifyContent: "space-between", padding: 0.5, cursor: 'pointer' }} onClick={() => {
                      setEquityDepositChecked(!equityDepositChecked);
                    }}>

                      <Box component="div" sx={{ display: "flex" }}>
                        <Box component="div" sx={{ display: "flex" }}>
                          <Typography sx={{ color: "#707070", fontSize: '14px', lineHeight: 2.57 }}>
                            Bank Deposit
                          </Typography>
                        </Box>
                      </Box>

                      <Box component="div" sx={{ display: "flex", alignItems: 'center', justifyContent: "center" }}>
                        {!equityBankDepositStatus ? <Typography style={{ color: "#707070", marginRight: '5px', fontSize: '14px', lineHeight: 2.57 }}>Not Set</Typography> : <Typography style={{ color: '#33cf4d', marginRight: '5px', fontSize: '14px', lineHeight: 2.57 }}>Active</Typography>}
                        <CustomSwitch
                          checked={equityBankDepositStatus}
                          onChange={() => setEquityDepositChecked(!equityDepositChecked)}
                          inputProps={{ 'aria-label': 'controlled' }}
                          color="warning"
                        />
                      </Box>
                    </Box>
                  </Box>
                </Grid>}
              </Grid>}
          
              {/* <br style={{ color: '#c2cbd1' }} /> */}

              {/* co-operative bank grid */}
              {
                (localCurrency === "KES" &&    <Grid container style={{ display: 'flex', flexDirection: 'column' }} mb={localCurrency === "KES" ? 2 : null}>
                  {/* <Grid item style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer' }} onClick={() => {
                    setShowCoperativeOptions(!showCoperativeOptions)
                  }}>
                    <Typography variant='h6' style={{ fontSize: '14px', fontWeight: 500, color: '#032541', margin: '0 0 0 1.5px', lineHeight: 1.14, letterSpacing: '0.47px' }}>Co-operative Bank</Typography>
                    {!showCoperativeOptions ? <ExpandMoreIcon /> : <ExpandLessIcon />} 
           
                  </Grid> */}
                  {showCoperativeOptions && <Grid item>
  
                    {/* Co-operative Mobile Money Setup */}
                    <Box component="div" sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}>
                      <Box component="div" sx={{ width: "100%", display: "flex", justifyContent: "space-between", padding: 0.5, cursor: 'pointer' }} onClick={() => {
                        setCopMobileChecked(!copMobileChecked);
                      }}>
  
                        <Box component="div" sx={{ display: "flex" }}>
                          <Box component="div" sx={{ display: "flex" }}>
                            <Typography sx={{ color: "#707070", fontSize: '14px', lineHeight: 2.57 }}>
                              Mobile Money
                            </Typography>
                          </Box>
                        </Box>
  
                        <Box component="div" sx={{ display: "flex", alignItems: 'center', justifyContent: "center" }}>
                          {!copMobileMoneyStatus ? <Typography style={{ color: "#707070", marginRight: '5px', fontSize: '14px', lineHeight: 2.57 }}>Not Set</Typography> : <Typography style={{ color: '#33cf4d', marginRight: '5px', fontSize: '14px', lineHeight: 2.57 }}>Active</Typography>}
                          <CustomSwitch
                            checked={copMobileMoneyStatus}
                            onChange={() => setCopMobileChecked(!copMobileChecked)}
                            inputProps={{ 'aria-label': 'controlled' }}
                            color="warning"
                          />
                        </Box>
                      </Box>
                    </Box>
  
                    {/* Co-operative Bank Deposit Setup */}
                    <Box component="div" sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}>
                      <Box component="div" sx={{ width: "100%", display: "flex", justifyContent: "space-between", padding: 0.5, cursor: 'pointer' }} onClick={() => {
                        setCopDepositChecked(!copDepositChecked);
                      }}>
  
                        <Box component="div" sx={{ display: "flex" }}>
                          <Box component="div" sx={{ display: "flex" }}>
                            <Typography sx={{ color: "#707070", fontSize: '14px', lineHeight: 2.57 }}>
                              Bank Deposit
                            </Typography>
                          </Box>
                        </Box>
  
                        <Box component="div" sx={{ display: "flex", alignItems: 'center', justifyContent: "center" }}>
                          {!copBankDepositStatus ? <Typography style={{ color: "#707070", marginRight: '5px', fontSize: '14px', lineHeight: 2.57 }}>Not Set</Typography> : <Typography style={{ color: '#33cf4d', marginRight: '5px', fontSize: '14px', lineHeight: 2.57 }}>Active</Typography>}
                          <CustomSwitch
                            checked={copBankDepositStatus}
                            onChange={() => setCopDepositChecked(!copDepositChecked)}
                            inputProps={{ 'aria-label': 'controlled' }}
                            color="warning"
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Grid>}
                </Grid>)
              }
           
                  {/* <br style={{color:"#c2cbd1"}} /> */}
                  {
                    (localCurrency === "NGN") && (    <Grid container style={{ display: 'flex', flexDirection: 'column' }} mb={localCurrency === "NGN" ? 2 : null}>
                      <Grid item style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer' }} onClick={() => {
                        setShowAccessBankOptions(!showAccessBankOptions)
                      }}>
                        <Typography variant='h6' style={{ fontSize: '14px', fontWeight: 500, color: '#032541', margin: '0 0 0 1.5px', lineHeight: 1.14, letterSpacing: '0.47px' }}>Access Bank</Typography>
                        {!showAccessBankOptions ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                      </Grid>
                      {showAccessBankOptions && <Grid item>
      
                        {/* Co-operative Mobile Money Setup */}
                        <Box component="div" sx={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}>
                          <Box component="div" sx={{ width: "100%", display: "flex", justifyContent: "space-between", padding: 0.5, cursor: 'pointer' }} onClick={() => {
                            setCopMobileChecked(!copMobileChecked);
                          }}>
                          </Box>
                        </Box>
      
                        {/* Co-operative Bank Deposit Setup */}
                        <Box component="div" sx={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}>
                          <Box component="div" sx={{ width: "100%", display: "flex", justifyContent: "space-between", padding: 0.5, cursor: 'pointer' }} onClick={() => {
                            setShowAccessBankChecked(!showAccessBankChecked);
                          }}>
      
                            <Box component="div" sx={{ display: "flex" }}>
                              <Box component="div" sx={{ display: "flex" }}>
                                <Typography sx={{ color: "#707070", fontSize: '14px', lineHeight: 2.57 }}>
                                  Bank Deposit
                                </Typography>
                              </Box>
                            </Box>
      
                            <Box component="div" sx={{ display: "flex", alignItems: 'center', justifyContent: "center" }}>
                              {!accessBankStatus ? <Typography style={{ color: "#707070", marginRight: '5px', fontSize: '14px', lineHeight: 2.57 }}>Not Set</Typography> : <Typography style={{ color: '#33cf4d', marginRight: '5px', fontSize: '14px', lineHeight: 2.57 }}>Active</Typography>}
                              <CustomSwitch
                                checked={accessBankStatus}
                                onChange={() => setShowAccessBankChecked(!showAccessBankChecked)}
                                inputProps={{ 'aria-label': 'controlled' }}
                                color="warning"
                              />
                            </Box>
                          </Box>
                        </Box>
      
                          {/* ACCESS BANK DEBIT ACCOUNTS */}
                        <Box component="div" sx={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}>
                          <Box component="div" sx={{ width: "100%", display: "flex", justifyContent: "space-between", padding: 0.5, cursor: 'pointer' }} onClick={() => {
                            setShowAccessDebitOptions(!showAccessDebitOptions);
                          }}>
      
                            <Box component="div" sx={{ display: "flex" }}>
                              <Box component="div" sx={{ display: "flex" }}>
                                <Typography sx={{ color: "#707070", fontSize: '14px', lineHeight: 2.57 }}>
                                  Debit Accounts
                                </Typography>
                              </Box>
                            </Box>
      
                            <Box component="div" sx={{ display: "flex", alignItems: 'center', justifyContent: "center" }}>
                              {!showAccessDebitChecked ? <Typography style={{ color: "#707070", marginRight: '5px', fontSize: '14px', lineHeight: 2.57 }}>Not Set</Typography> : <Typography style={{ color: '#33cf4d', marginRight: '5px', fontSize: '14px', lineHeight: 2.57 }}>Active {accessBankCount}  </Typography>}
                              <CustomSwitch
                                checked={showAccessDebitChecked}
                                onChange={() => setShowAccessBankChecked(!showAccessBankChecked)}
                                inputProps={{ 'aria-label': 'controlled' }}
                                color="warning"
                              />
                            </Box>
                          </Box>
                        </Box>
                      </Grid>}
                    </Grid>)
                  }
              
            </Box>}


          {/* payment options forms  */}
          {(showCopBankDepositSetup || showCopMobileMoneySetup || showKcbpocketMoneySetUp || showKCBBankDepositSetup || showKCBMobileMoneySetup || showEquityBankDepositSetup || showCopMobileMoneySetup || showKCBBankDebitSetup || showAccessBankDepositSetUp) &&
            <Box component="div" sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
              {/* {showKcbpocketMoneySetUp && <KCBPocketMoney setSuccessShow={setSuccessShow} setErrorShow={setErrorShow} errorShow={errorShow} successShow={successShow} />} */}
              {showKCBMobileMoneySetup && <KCBMobileMoney setSuccessShow={setSuccessShow} setErrorShow={setErrorShow} errorShow={errorShow} successShow={successShow}  parentCheckStatusKCBMobileMoney={parentCheckStatusKCBMobileMoney}/>}
              {showKCBBankDepositSetup && <KCBBankDeposit setSuccessShow={setSuccessShow} setErrorShow={setErrorShow} errorShow={errorShow} successShow={successShow} parentCheckStatusKCBBankDeposit={parentCheckStatusKCBBankDeposit} />}
              {showEquityMobileMoneySetup && <EquityMobileMoney setSuccessShow={setSuccessShow} setErrorShow={setErrorShow} errorShow={errorShow} successShow={successShow} />}
              {showEquityBankDepositSetup && <EquityBankDeposit setSuccessShow={setSuccessShow} setErrorShow={setErrorShow} errorShow={errorShow} successShow={successShow} />}
              {showCopMobileMoneySetup && <CopMobileMoney setCopMobileChecked={setCopMobileChecked} setSuccessShow={setSuccessShow} setErrorShow={setErrorShow} errorShow={errorShow} successShow={successShow} />}
              {showCopBankDepositSetup && <CopBankDeposit setSuccessShow={setSuccessShow} setErrorShow={setErrorShow} errorShow={errorShow} successShow={successShow} />}
              {showAccessBankDepositSetUp && <Accessbankdeposit setSuccessShow={setSuccessShow} setErrorShow={setErrorShow} errorShow={errorShow} successShow={successShow} setShowAccessBankChecked={setShowAccessBankChecked}/>}
            </Box>}
        </>}
    </Box>
  )
}

export default BankOptions;