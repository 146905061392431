import { Breadcrumbs, Button, Grid, Typography } from "@mui/material";
import { SuccessAlert } from "../../snackBar Alerts/successAlert";
import { ErrorAlert } from "../../snackBar Alerts/errorAlert";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import PromoteStudentNext from "./studentTrans";
import StudentPromotionNotification from "./promotionsNotifications";
import HttpComponent from "../MakeRequest";

const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>DashBoard</Typography>,
    <Typography key={2} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Student Promotion</Typography>
]

export default function PromoteStudentSetup() {
    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })
    const navigate = useNavigate()

    const queryparams = decodeURIComponent(window.location.search);
    const state = queryparams.slice(1)


    const [allNotifications, setAllNotification] = useState([])

    //get notifications

    function getAllStudentNotification() {
        try {
            HttpComponent({
                method: 'GET',
                url: `/api/v1/students/getPromotions`,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data.status === 200) {
                    setAllNotification(data?.response?.data)
                }
            })


        } catch (error) {

        }
    }
    useEffect(() => {
        getAllStudentNotification()
    }, [state])


    return (
        <Grid direction={'column'} container>
            {state === 'transferstudent' ? <PromoteStudentNext /> : <>
                <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
                <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
                <Grid item>
                    <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }}>Student Promotion</Typography>
                </Grid>
                <Grid item display={'flex'} mt={2} alignItems={'center'}>
                    <Grid container display={'flex'} alignItems={'center'} justifyContent={'space-between'} alignContent={'center'}>
                        <Grid item>
                            <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                                {breadcrumbs}
                            </Breadcrumbs>
                        </Grid>
                        <Grid item>
                            {allNotifications?.length > 0 ?  <Button onClick={() => navigate('/school/studentpromotion?transferstudent')} style={{ textTransform: "none", marginTop: '10px', textDecoration: "none", background: '#032541', color: '#fff', fontSize: `14px`, fontWeight: 600, height: '45px', width: '219px' }} >Promote Students</Button>:null }
                           
                        </Grid>
                    </Grid>
                </Grid>
                {allNotifications?.length > 0 ? <StudentPromotionNotification /> :
                    <Grid container width={'100%'} height={'100%'} direction={'column'} alignContent={'center'} justifyContent={'center'} display={'flex'}>
                        <Grid item display={'flex'} mt={20} justifyContent={'center'} alignItems={'center'} alignContent={'center'} direction={'column'} height={'400px'} width={'400px'} >
                            <Typography sx={{ color: '#032541', fontSize: '20px', fontWeight: 'bold' }}>No Activity !</Typography>
                            <Typography sx={{ fontSize: '16px' }}>Promote students and track the activities here!</Typography>
                            <Button onClick={() => navigate('/school/studentpromotion?transferstudent')} style={{ textTransform: "none", marginTop: '10px', textDecoration: "none", background: '#032541', color: '#fff', fontSize: `14px`, fontWeight: 600, height: '45px', width: '219px' }} >Promote Students</Button>
                        </Grid>
                </Grid>}

            </>}
        </Grid>
    )

}