import React, { useEffect, useState } from 'react';
import { Grid, Breadcrumbs, Typography, Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { ErrorAlert } from '../snackBar Alerts/errorAlert';
import { SuccessAlert } from '../snackBar Alerts/successAlert';
import CustomInputLabel from '../School/CustomInputLabel';
import CustomTextField from '../School/CustomTextField';
import PhoneInput from 'react-phone-input-2';
import GeoLocation from '../../hooks/useLocation';
import CustomSelect from '../School/CustomSelectField';
import HttpComponent from '../School/MakeRequest';

const AddUsers = () => {
    const navigate = useNavigate();
    const [errorShow, setErrorShow] = useState({ state: false, message: "" });
    const [successShow, setSuccessShow] = useState({ state: false, message: "User created successfully" });
    const [validEmail, setValidEmail] = useState(true);
    const [selectedValue, setSelectedValue] = useState('');
    const [options, setOptions] = useState([]);

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        userName: '',
        emailAddress: '',
        phoneNumber: '',
        location: '',
        userGroup: '',
    });
    const getBusinessRoles = async () => {
        try {
            const response = await HttpComponent({
                method: 'POST',
                url: `/api/get_business_roles`,
                token: localStorage.getItem('X-Authorization'),
            });
            if (response?.status === 200) {
                const rolesOptions = response.response?.data.map(role => ({
                    value: role.type,
                    label: role.name
                })) || [];
                setOptions(rolesOptions);
            }
        } catch (error) {
            console.warn("Error fetching roles:", error);
        }
    };

    useEffect(() => {
        getBusinessRoles();
    }, []);

    const breadcrumbs = [
        <Typography key="dashboard" sx={{ color: "#707070", fontSize: "0.875rem" }}>
            Dashboard
        </Typography>,
        <Typography key="users" sx={{ color: "#707070", fontSize: "0.875rem" }}>
            Users
        </Typography>,
        <Typography key="create-user" sx={{ color: "#dc3545", fontSize: "0.875rem" }}>
            Create User
        </Typography>
    ];

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    useEffect(() => {
        if (formData.emailAddress) {
            setValidEmail(validateEmail(formData.emailAddress));
        } else {
            setValidEmail(true);
        }
    }, [formData.emailAddress]);

    const handleLocationChange = (address) => {
        setSelectedValue(address);
        setFormData((prevState) => ({
            ...prevState,
            location: address,
        }));
    };

    const handleSubmit = async () => {
        const {firstName,lastName,userName,emailAddress,phoneNumber,userGroup,location, } = formData;

        const payload = {
            firstName: firstName,
            secondName: lastName,
            userName: userName,
            userEmail: emailAddress,
            userPhone: phoneNumber,
            userGroup: userGroup,
            createdBy: localStorage.getItem("username"),
            assignedBusiness: localStorage.getItem("businessId"),
            assignToBusiness: "assignToBusiness",
            userState: "Active",
            loginState: "Active",
            businessShortCode: "",
            businessName: localStorage.getItem('businessName'),
            businessLocation: location,
            Equitel: "",
            Paybill: "",
            Till: "",
            Vooma: "",
        };
        console.log('payload.data', payload)

        try {
            const response = await HttpComponent({
                method: 'POST',
                url: '/api/addNewUser',
                body: payload,
                headers: {
                    'Content-Type': 'application/json',
                    'X-Authorization': localStorage.getItem('X-Authorization'),
                },
            });
            console.log('response.data.response', response)

            if (response?.status === 201) {
                setSuccessShow({ ...successShow, state: true });
                setTimeout(() => {
                    navigate('/users');
                }, 2000);
            } else {
                setErrorShow({ ...errorShow, state: true, message: "Failed to create user" });
            }
        } catch (error) {
            setErrorShow({ ...errorShow, state: true, message: "An error occurred while creating user" });
        }
    };

    return (
        <Grid container direction="column" sx={{ mx: 1 }}>
            <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            <Grid item container alignItems="center" sx={{ mb: 2 }}>
                <ArrowBackIosIcon sx={{ cursor: "pointer", color: "#032541", mr: 1 }} onClick={() => navigate("/users")} />
                <Typography variant="h6" sx={{ color: '#032541', fontWeight: 700, fontSize: "25px", }}>Create User</Typography>
            </Grid>
            <Grid item sx={{ mb: 2 }}>
                <Breadcrumbs sx={{ fontFamily: 'Poppins', fontSize: '14px' }} separator={<FiberManualRecordIcon sx={{ fontSize: "0.625rem", color: "#e1e5e8" }} />} aria-label="breadcrumb">
                    {breadcrumbs}
                </Breadcrumbs>
            </Grid>
            <Grid item sx={{ mb: 2 }}>
                <Typography variant="body2" sx={{ color: '#707070', fontSize: "18px", }}>Create a New User</Typography>
            </Grid>
            <Grid container>
                <Grid item>
                    <CustomInputLabel required={true} label={"First Name"} />
                    <CustomTextField value={formData.firstName} onChange={handleInputChange} name={"firstName"} placeholder={"First Name"} />
                </Grid>
                <Grid item sx={{ marginLeft: '6px' }}>
                    <CustomInputLabel required={true} label={"Last Name"} />
                    <CustomTextField value={formData.lastName} onChange={handleInputChange} name={"lastName"} placeholder={"Last Name"} />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item>
                    <CustomInputLabel required={true} label={"User Name"} />
                    <CustomTextField value={formData.userName} onChange={handleInputChange} name={"userName"} placeholder={"User Name"} />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item>
                    <CustomInputLabel required={true} label={"Email"} />
                    {!validEmail && (
                        <Typography sx={{ color: "#dc3545", fontSize: "0.75rem" }}>
                            Please enter a valid email
                        </Typography>
                    )}
                    <CustomTextField value={formData.emailAddress} onChange={handleInputChange} name={"emailAddress"} placeholder={"Email Address"} />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item>
                    <CustomInputLabel required={true} label={"Mobile Number"} />
                    <Box component="div" sx={{ marginTop: 1, marginBottom: 2, width: '410px' }}>
                        <PhoneInput inputStyle={{ height: '3.438rem' }} required country={"ke"} enableSearch={true} value={formData.phoneNumber} name={"phoneNumber"} onChange={(phone) => setFormData({ ...formData, phoneNumber: phone })} />
                    </Box>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item>
                    <CustomInputLabel required={true} label={"Location"} />
                    <GeoLocation required={true} sx={{ marginBottom: "10px", marginTop: "10px", width: '410px' }} selectedValue={selectedValue} onValueChange={handleLocationChange} />
                </Grid>
                <Grid item sx={{ marginLeft: '6px' }}>
                    <CustomInputLabel required={true} label={"Role"} />
                    <CustomSelect xs={true} onChange={handleInputChange} value={formData.userGroup} placeholder={"Select User Role"} options={options} name={"userGroup"} />
                </Grid>
            </Grid>
            <Box component="div" sx={{ display: "flex", justifyContent: "center", marginTop: '10px' }}>
                <Button onClick={() => navigate('/users')} style={{ backgroundColor: "#fff", border: "1px solid #dc3545", color: "#dc3545", textTransform: "none", fontSize: "14px", fontWeight: 600, marginRight: "10px", width: "10rem", height: "2.5rem" }}>Cancel</Button>
                <Button disabled={!formData.firstName || !formData.lastName || !formData.emailAddress || !validEmail} onClick={handleSubmit} style={{ backgroundColor: "#032541", color: "#fff", textTransform: "none", fontSize: "14px", fontWeight: 600, width: "10rem", height: "2.5rem" }} >
                    Create 
                </Button>
            </Box>
        </Grid>
    );
};

export default AddUsers;
