import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";

import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import {
  Button,
  ButtonBase,
  IconButton,
  Table,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import mpesaIcon from "../../common/images/mpesa-icn.svg";
import cardIcon from "../../common/images/card-icn.svg";
import creditIcon from "../../common/images/creditbal-icon.svg";

import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import SyncIcon from "@mui/icons-material/Sync";
import "../../components/customerAccounts/customerComponents/invoice.css";
import HttpComponent from "./MakeRequest";

import { useSelector } from "react-redux";

import { DeleteOutline } from "@mui/icons-material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { PaymentMethods } from "../Modals/Invoice/paymentMethods";
import { ReceivedPayment } from "../Modals/Invoice/receivedPayment";
import { ErrorAlert } from "../snackBar Alerts/errorAlert";
import { SuccessAlert } from "../snackBar Alerts/successAlert";
import { currencyconverter } from "../../common/currencyconverter";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import html2pdf from 'html2pdf.js';

const moment = require("moment");

const baseUrl = process.env.REACT_APP_BASE_URL;

const useStyles = makeStyles((theme) => ({
  address_text_common: {
    fontSize: "13px",
    fontWeight: "300",
  },
  table_titles: {
    fontSize: "14px",
    fontWeight: "700",
  },
  table_body: {
    fontSize: "13px",
    fontWeight: "300",
    padding: theme.spacing(0.25),

    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0),
    },
  },
  footer_text: {
    fontSize: "10px",
    fontWeight: "300",
    padding: theme.spacing(0.25),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0),
    },
  },
  totals: {
    fontSize: "14px",
    padding: theme.spacing(0.25),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0),
    },
  },
  p1: {
    padding: theme.spacing(0.25),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0),
    },
  },
  p2: {
    padding: theme.spacing(0.5),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0),
    },
  },
  p3: {
    padding: theme.spacing(0.5),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0),
    },
  },
  mt4: {
    marginTop: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(6),
    },
  },
  m1: {
    margin: theme.spacing(0.5),
    [theme.breakpoints.down("sm")]: {
      // margin: theme.spacing(1),
    },
  },
  textLeft: {
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      display: "flex",
      fontWeight: 600,
    },
  },
  textCenter: {
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      display: "flex",
      fontWeight: 600,
    },
  },
  textRight: {
    textAlign: "right",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      display: "flex",
      fontWeight: 600,
    },
  },
}));

const UnauthInvoice = () => {


  const navigate = useNavigate();
  const { invoiceNumber, darajaConfigId } = useParams();
  const classes = useStyles();
  const [total, setTotal] = useState(0);
  const [businessInfo, setBusinessInfo] = useState();
  const [invoiceData, setInvoiceData] = useState();
  const [receivePaymentOpen, setReceivePaymentOpen] = useState(false);
  const [paymentMethodsOpen, setPaymentMethodsOpen] = useState(false);
  const [currentEmail, setCurrentEmail] = useState();
  const [errorFetching, setErrorFetching] = useState(false);
  const [invoicePayments, setInvoicePayments] = useState();
  const [invoiceBalance, setInvoiceBalance] = useState();
  const theme = useTheme();
  const isMobileUI = useMediaQuery(theme.breakpoints.down("sm"));
  const downloadPDF = () => {
    const input = document.getElementById("downloadable");
    html2pdf(input, {
      margin: 10,
      filename: 'invoice.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
    }).from(input).save();


  };
  console.log("invoiceData", invoiceData);
  const getInvoiceDetails = async () => {
    try {
      const invoiceDetailsResponse = await fetch(
        baseUrl +
        `/api/get_invoice_by_invoice_number_no_login?invoiceNumber=${invoiceNumber}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-Authorization": localStorage.getItem("X-Authorization"),
          },
        }
      );

      if (invoiceDetailsResponse.status === 200) {
        const invoiceDetails = await invoiceDetailsResponse.json();
        setInvoiceData(invoiceDetails.data);
        const category = invoiceDetails.data.businessCategory;
        localstorage.setItem("businessCategoryData", category)
      } else {
        setErrorFetching(true);
      }
    } catch (error) {
      console.error("error fetching invoice details", error.message);
    }
  };


  const formatDate = (date) => {
    return moment(date).format("dddd, MMMM Do, YYYY");
  };

  const formatPaymentDate = (unFormatted) => {
    const date = new Date(unFormatted);
    const options = {
      day: "numeric",
      month: "short",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    const formattedDate = date.toLocaleString("en-US", options);
    const dateSuffix = function () {
      const date = new Date();
      const dateNumber = date.getDate();
      switch (dateNumber) {
        case 1:
        case 21:
        case 31:
          return "st";
        case 2:
        case 22:
          return "nd";
        case 3:
        case 23:
          return "rd";
        default:
          return "th";
      }
    };
    return `${date.getDate()}${dateSuffix()} ${formattedDate.slice(
      0,
      3
    )} ${formattedDate.slice(7)}`;
  };

  const getBusinessDetails = async () => {
    try {
      const businessDetailsResponse = await fetch(
        baseUrl + "/api/get_business_info",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-Authorization": localStorage.getItem("X-Authorization"),
          },
        }
      );

      if (businessDetailsResponse.status === 200) {
        const businessDetailsData = await businessDetailsResponse.json();
        console.log("business data ", businessDetailsData.data);
        setBusinessInfo(businessDetailsData.data);
      } else {
        setErrorFetching(true);
      }
    } catch (error) {
      console.error("error fetching customer details", error.message);
    }
  };

  const calculateTotal = () => {
    if (invoiceData) {
      let calculatedTotal = 0;
      invoiceData?.items?.forEach((product) => {
        calculatedTotal += product.productPrice * product.quantity;
      });
      setTotal(calculatedTotal);
    }
  };

  const back = () => {
    navigate(-1);
  };

  const [isSpinning, setIsSpinning] = useState(false);

  const refresh = () => {
    setIsSpinning(true);
    getInvoiceDetails().then(() => {
      console.log("done fetching invoice data");
      setIsSpinning(false);
    });
  };

  const getCurrentEmail = () => {
    setCurrentEmail(localStorage.getItem("email"));
  };

  const fetchBusiInvoiceData = async () => {
    const businessInvoiceDetailsResponse = await fetch(
      baseUrl + `/api/get_invoice_and_business_data/${invoiceNumber}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    if (businessInvoiceDetailsResponse.status === 200) {
      const businessDetailsData = await businessInvoiceDetailsResponse.json();
      console.log("business Invoice data ", businessDetailsData.data);
      setBusinessInfo(businessDetailsData.data);
      // setInvoiceData(businessDetailsData.data);
    } else {
      setErrorFetching(true);
    }
  };

  const getInvoicePayments = async () => {
    const invoicePaymentDetailsResponse = await fetch(
      baseUrl + `/api/get_invoice_payments/${invoiceNumber}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
      }
    );
    if (invoicePaymentDetailsResponse.status === 200) {
      const paymentDetailsData = await invoicePaymentDetailsResponse.json();
      console.log("Invoice payment data ", paymentDetailsData.data);
      let { invoicePayments, invoiceBalance } = paymentDetailsData.data;
      console.log("ip", invoicePayments);
      setInvoicePayments(invoicePayments);
      setInvoiceBalance(invoiceBalance);
    }
  };

  const { userId, X_Authorization } = useSelector((store) => store.user);
  const [errorShow, setErrorShow] = useState({ state: false, message: "" });
  const [successShow, setSuccessShow] = useState({ state: false, message: "" });
  const resendEmail = () => {
    HttpComponent({
      method: "GET",
      url: `/api/resendInvoice?invoiceNumber=${invoiceNumber}`,
      body: null,
      token: X_Authorization,
    })
      .then((data) => {
        console.log("here store is data", data);
        if (data.status === 200) {
          setSuccessShow({
            state: true,
            message: `${invoiceNumber} successfully resent to ${data.response.data.sentTo}`,
          });
        } else {
          setErrorShow({ state: true, message: data.response.message });
        }
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  useEffect(() => {
    getBusinessDetails()
    getInvoiceDetails()
    getCurrentEmail();
    getInvoicePayments()
  }, []);

  useEffect(() => {
    if (errorFetching) {
      console.log("we had an error fetching");
      fetchBusiInvoiceData().then(() => console.log("Fallback function used"));
    }
  }, [errorFetching]);

  useEffect(() => {
    calculateTotal();
  }, [invoiceData]);

  const businessCategory = localStorage.getItem("businessCategory");

  const localCurrency = businessInfo?.localCurrency


  return (
    <>
      <div style={ isMobileUI ?  {width: "100%"} : { width: "80%", marginLeft: "10%" }} id="auth-invoice">
        <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
        <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
        <div className="invoice-contents">
          <div id="holder-container">
            <div style={{ padding: 10, display: "flex", justifyContent: "end" }}>
              {invoiceData?.invoiceStatus != "Paid" ? (
                <>
                  <IconButton
                    aria-label="refresh icon"
                    style={{ color: "#032541", height: "45px", width: "45px" }}
                    onClick={() => refresh()}
                  >
                    <SyncIcon
                      className={`${isSpinning ? "sync-icon spinning" : ""}`}
                    />
                  </IconButton>
                </>
              ) : null}

              <span style={{ backgroundColor: "rgba(3, 37, 65, 0.05)", width: "45px", height: "45px", display: "flex", justifyContent: "center", marginLeft: 5, borderRadius: 5, }}>
                <IconButton aria-label="download" onClick={downloadPDF}>
                  <FileDownloadOutlinedIcon
                    sx={{ color: "#032541", fontSize: "25px" }}
                  />
                </IconButton>
              </span>
            </div>
            <div style={{ display: "flex", flexDirection: "column", overflow: "visible", height: "auto", }} id="downloadable">
              <div className={classes.p3} style={{ backgroundColor: `${invoiceData?.invoiceStatus === "Unpaid" ? "rgba(220,53,69,0.07)" : invoiceData?.invoiceStatus === "Paid" ? "rgba(23,174,123, 0.07)" : "rgba(247,137,41, 0.05)"}`, height: "130px", display: "flex", justifyContent: "space-between", alignItems:'center' }}>
                <div className={classes.p1} style={{ display: "flex", flexDirection: "column" }}>
                  <span style={{ color: "black", fontSize: "30px", fontWeight: "700" }}>INVOICE</span>
                  <span style={{ color: "black", fontSize: "14px", fontWeight: "700" }}>Date:</span>
                  <span style={{ fontSize: "14px" }}>{formatDate(invoiceData?.createdAt)}</span>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                 {isMobileUI ? null :<img alt="" src={`${baseUrl}/staticimages/logos/${invoiceData?.businessLogo}`} style={{ width: isMobileUI ? "100px": "180px", height:isMobileUI ? '100px' :"180px", objectFit: "contain" }} />} 
                </div>

                <div style={{ display: "flex", flexDirection: "column" }}>
                  <span style={{ color: `${invoiceData?.invoiceStatus === "Paid" ? "#17ae7b" : invoiceData?.invoiceStatus === "Unpaid" ? "#dc3545" : "#e07c28"}`, fontSize: "40px", fontWeight: "700" }}>{invoiceData?.invoiceStatus}</span>
                  <span style={{ color: "black", fontSize: "14px", fontWeight: "700" }}>Invoice No:</span>
                  <span style={{ fontSize: "14px" }}>{invoiceData?.invoiceNumber}</span>
                </div>
              </div>

              <div
                className={classes.p3}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                }}
                id="invoice-from"
              >
                <div
                  style={{ display: "flex", flexDirection: "column" }}
                  id="from-details"
                >
                  <span style={{ fontSize: "18px" }}>From:</span>
                  <span
                    className={classes.address_text_common}
                    style={{ fontWeight: "700" }}
                  >
                    {businessInfo?.businessName}
                  </span>
                  <span className={classes.address_text_common}>
                    {businessInfo?.businessOwnerAddress},{" "}
                    {businessInfo?.country}
                  </span>
                  <span className={classes.address_text_common}>
                    {businessInfo?.businessOwnerEmail}
                  </span>
                  <span className={classes.address_text_common}>
                    {businessInfo?.businessOwnerPhone}
                  </span>
                </div>
                <div
                  className={classes.mt4}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "right",
                  }}
                  id="billed"
                >
                  <span
                    className={classes.address_text_common}
                    style={{ fontWeight: "700" }}
                  >
                    Billed to:
                  </span>
                  <span className={classes.address_text_common}>
                    {invoiceData?.customerName}
                  </span>
                  <span className={classes.address_text_common}>
                    {invoiceData?.sentTo}
                  </span>
                  <span className={classes.address_text_common}>
                    {invoiceData?.customerPhoneNumber}
                  </span>
                </div>
              </div>
              <br />
              {/*<br/>*/}
              {invoiceData?.itemNumber &&
                invoiceData?.studentName &&
                invoiceData?.grade &&
                invoiceData?.term ? (
                <div style={{ border: "dotted 0.5px", display: "flex", justifyContent: "space-between", padding: "0.625rem" }}>
                  <div>STUDENT ID:<span style={{ fontWeight: "bold", }}>{invoiceData?.itemNumber}</span></div>
                  <div>STUDENT NAME:<span style={{ fontWeight: "bold", }} > {invoiceData?.studentName} </span> </div>
                  <div>GRADE:<span style={{ fontWeight: "bold", }}>{invoiceData?.grade}</span></div>
                  <div>TERM:<span style={{ fontWeight: "bold", }}>{invoiceData?.term}</span></div>
                </div>
              ) : (
                <div style={{ border: "dotted 1px" }}></div>
              )}
              <br />
              <div>
                <Grid container direction={'row'} justifyContent={'flex-start'} style={{border : isMobileUI ? '': 'dotted #b2b4bb 2px', paddingLeft : '25px', paddingTop : '8px', paddingBottom : '8px', width:'95%'}}>
                  
                  {/*House Number*/}
                  {businessInfo?.businessCategory === 'Rental' && 
                  <Grid item mr={15}>
                    <span style={{fontSize : '16px', color : '#000'}}>HOUSE NUMBER: </span>
                    <span style={{fontSize : '16px', color : '#000', fontWeight : 'bold'}}>{invoiceData?.itemNumber}</span>
                  </Grid>}

                  {/*House Type*/}
                  {businessInfo?.businessCategory === 'Rental' && 
                  <Grid item>
                    <span style={{fontSize : '16px', color : '#000'}}>HOUSE TYPE: </span>
                    <span style={{fontSize : '16px', color : '#000', fontWeight : 'bold'}}>{invoiceData?.houseType}</span>
                  </Grid>}
                </Grid>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.textLeft} id="t-cell">
                        Description
                      </TableCell>
                      <TableCell className={classes.textCenter} id="t-cell">
                        Price({localCurrency})
                      </TableCell>
                      <TableCell className={classes.textCenter} id="t-cell">
                        Qty
                      </TableCell>
                      <TableCell className={classes.textRight} id="t-cell">
                        Total({localCurrency})
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {invoiceData?.items?.map((product, index) => (
                    <TableRow key={index}>
                      <TableCell className={classes.textLeft}>
                        <span className={classes.table_body}>
                          {product.productName}
                        </span>
                      </TableCell>
                      <TableCell className={classes.textCenter}>
                        <span className={classes.table_body}>
                          {currencyconverter(product.productPrice, localCurrency)}
                        </span>
                      </TableCell>
                      <TableCell className={classes.textCenter}>
                        <span className={classes.table_body}>
                          {product.quantity}
                        </span>
                      </TableCell>
                      <TableCell className={classes.textRight}>
                        <span className={classes.table_body}>
                          {currencyconverter(
                            product.productPrice * product.quantity,
                            localCurrency
                          )}
                        </span>
                      </TableCell>
                      {/*{invoiceData?.batchStatus == "PENDING" ?*/}
                      {/*    <TableCell className={classes.textRight}>*/}
                      {/*        <ButtonBase onClick={() => console.log("haha")}>*/}
                      {/*            <DeleteOutline style={{color : '#dc3545'}} />*/}
                      {/*        </ButtonBase>*/}
                      {/*    </TableCell>*/}
                      {/*    :*/}
                      {/*    null*/}
                      {/*}*/}
                    </TableRow>
                  ))}
                </Table>
              </div>

              {/*{invoiceData?.batchStatus == "PENDING" ?*/}
              {/*    <Button onClick={() => navigate('/school/invoice/edit')} style={{border: 'solid 1px rgba(3, 37, 65, 0.1)',fontWeight : 'bold',color : '#032541',borderRadius : '2px', alignContent : 'center', background : '#fff', fontSize : '14px'}}>*/}
              {/*        <AddCircleOutlineIcon style={{fontSize : '18px', marginRight : '12px'}} /> Add Services</Button>*/}
              {/*    :null*/}
              {/*}*/}

              <div className={classes.p3} style={{ display: "flex", justifyContent: "end", }} id="amount-details-container">
                <div className={classes.p1} style={{ backgroundColor: "#f7f7f7", width: "288px", height: "100px", display: "flex", flexDirection: "column", justifyContent: "center", }} id="amount-details-contents">
                  <div className={classes.totals} style={{ display: "flex", justifyContent: "space-between" }} id="subtotal">
                    <span>Subtotal</span>
                    <span>{currencyconverter(total, localCurrency)} </span>
                  </div>
                  <div className={classes.totals} style={{ display: "flex", justifyContent: "space-between" }} id="discount">
                    <span>Discount</span>
                    <span>{localCurrency} {invoiceData?.discountAmount}</span>
                  </div>
                  <div className={classes.totals} style={{ display: "flex", justifyContent: "space-between" }} id="total">
                    <span style={{ color: `${invoiceData?.invoiceStatus === "Unpaid" ? "#dc3545" : "#17ae7b"}`, fontWeight: "bold", }}>
                      Total
                    </span>
                    <span style={{ fontSize: "16px", color: `${invoiceData?.invoiceStatus === "Unpaid" ? "#dc3545" : "#17ae7b"}`, fontWeight: "bold", }}>
                      {invoiceData?.invoiceStatus === 'Unpaid' ?  currencyconverter(invoiceData?.invoiceBalance, localCurrency): currencyconverter((invoiceData?.invoiceAmount * 1)  - (invoiceData?.discountAmount || 0), localCurrency)}
                    </span>
                  </div>
                </div>
              </div>
              <br />
              <div style={{ border: "dotted 1px" }}></div>
              <br />

              {invoiceData?.invoiceStatus === "Partially Paid" ||
                invoiceData?.invoiceStatus === "Paid" ? (
                <>
                  <div>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell className={classes.textLeft}>
                            Mode of Payment
                          </TableCell>
                          <TableCell className={classes.textCenter}>
                            Date
                          </TableCell>
                          <TableCell className={classes.textRight}>
                            Amount
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      {invoicePayments?.map((payment, index) => (
                        <TableRow key={index}>
                          <TableCell className={classes.textLeft}>
                            <span className={classes.table_body}>
                              {payment.modeOfPayment}: {payment.ReferenceNumber}
                            </span>
                          </TableCell>
                          <TableCell className={classes.textCenter}>
                            <span className={classes.table_body}>
                              {formatPaymentDate(payment.date)}
                            </span>
                          </TableCell>
                          <TableCell className={classes.textRight}>
                            <span className={classes.table_body}>
                              {currencyconverter(payment.amount, localCurrency)}
                            </span>
                          </TableCell>
                        </TableRow>
                      ))}
                    </Table>
                  </div>
                  <br />
                  <div style={{ display: "flex", justifyContent: "end" }}>
                    <div style={{ color: "#dc3545", fontWeight: "bold" }}>
                      {invoiceData?.invoiceStatus !== "Paid" ?
                        <span>Balance: {currencyconverter(invoiceBalance)}</span>
                        : null}
                    </div>
                  </div>
                  <br />
                  <div style={{ border: "dotted 1px" }}></div>
                  <br />
                </>
              ) : (
                <></>
              )}
              <div className={""} style={{ display: "flex", flexDirection: "column" }} id="payment-info">
                {/* <div className={""}><span style={{ color: "#032541", fontWeight: "700", fontSize: "16px", }}>Accepted Payment Methods</span></div>
                <div style={{ display: "flex" }} id="payments">
                  <div style={{ display: "flex", flexDirection: "column", }} className="icon">
                    <img className={""} src={mpesaIcon} alt="M-pesa" />
                    <div style={{ display: "flex", justifyContent: "center", fontSize: "12px", }}>  <span>M-Pesa</span></div>
                  </div>
                  <div style={{ display: "flex", flexDirection: "column", }} className="icon" >
                    <img className={classes.p2} src={cardIcon} alt="Visa" />
                    <div style={{ display: "flex", justifyContent: "center", fontSize: "12px", }} >
                      <span>Card</span>
                    </div>
                  </div>
                </div> */}
                {invoiceData?.invoiceStatus !== "Paid" && invoiceData?.invoiceStatus !== 'CANCELLED' && invoiceData?.invoiceStatus !== 'DELETED' ? (<div style={{ display: "flex", width: "100%", justifyContent: "center", }} id="pay">
                  <Button style={{ width: "180px", height: "45px", borderRadius: "5px", backgroundColor: "#032541", color: "white", }} onClick={() => { navigate(`/pay/invoice/${invoiceNumber}/${darajaConfigId}`); }} > Pay </Button>
                </div>
                ) : null}
              </div>
              <br />
              <div style={{ border: "dotted 1px" }}></div>
              <br />
              <div className={classes.p2} style={{ display: "flex", justifyContent: "center" }} id="footer-text">
                <div className={classes.textCenter} style={{ fontSize: "10px", fontWeight: "300" }} id="footer-text-content">
                  This is a system-generated invoice is created without anyalteration whatsoever.
                  <br />
                  Thank you for your business.
                </div>
              </div>
              <div className={classes.p2} style={{ backgroundColor: "#f7f7f7", display: "flex", justifyContent: "center", }} id="footer-info">
                <div id="footer-info-text">
                  <span className={classes.footer_text}>Powered by{" "}
                    <span style={{ fontWeight: "700", }}>
                      ZED Payments Limited
                    </span>
                    <span className={classes.footer_text}>.</span>
                  </span>
                </div>
                <div id="footer-info-email">
                  <span className={classes.footer_text}>info@zed.business</span>
                  <span className={classes.footer_text}>.</span>
                  <span className={classes.footer_text}>v1.0.2</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*Received Payment Modal*/}
      <ReceivedPayment
        paymentOpen={receivePaymentOpen}
        onClose={() => setReceivePaymentOpen(false)}
      />

      {/*    Payment Methods*/}
      <PaymentMethods
        paymentMethodsOpen={paymentMethodsOpen}
        onClose={() => setPaymentMethodsOpen(false)}
        invoiceBalance={invoiceData?.invoiceBalance}
        invoiceNumber={invoiceNumber}
      />
    </>
  );
};

export default UnauthInvoice;
