import { Box, Breadcrumbs, Grid, IconButton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import HttpComponent from '../School/MakeRequest';
import { ErrorAlert } from '../snackBar Alerts/errorAlert';
import { SuccessAlert } from '../snackBar Alerts/successAlert';
import { useNavigate, useParams } from "react-router-dom";
import { ArrowBackIos } from "@mui/icons-material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import RedeemedIcon from '../../images/evoucher-icn10.webp';
import UnusedIcon from '../../images/evoucher-icn.webp';
import ExpiredVoucherIcon from '../../images/evoucher-red.png'

function OpenEvoucherDetails() {
    const { voucherId } = useParams();
    const localCurrency = localStorage.getItem("localCurrency");
    const navigate = useNavigate();
    const [voucherDetails, setVoucherDetails] = useState();
    const [errorShow, setErrorShow] = useState({ state: false, message: "" });
    const [successShow, setSuccessShow] = useState({ state: false, message: "" });
    const handleCancelModal = () => {
        navigate(-1);
    };
    useEffect(() => {
        const fetchVoucherDetails = async () => {
            try {
                const url = `/api/v1/evoucher/getEvoucherDetails?voucherId=${voucherId}`;
                const response = await HttpComponent({
                    method: "GET",
                    url: url,
                    token: localStorage.getItem("X-Authorization"),
                });
                if (response.response.status === "SUCCESS") {
                    setVoucherDetails(response.response.data);
                } else {
                    setErrorShow(response.data.message);
                }
            } catch (error) {
                setErrorShow(error.response ? error.response.data.message : error.message);
            }
        };
        fetchVoucherDetails();
    }, [voucherId]);
    const breadcrumbs = [
        <Typography key="X" sx={{ color: "#707070", fontSize: "0.875rem" }}>
            Dashboard
        </Typography>,
        <Typography key={"add"} sx={{ color: "#707070", fontSize: "0.875rem" }}>
            Open E-Vouchers
        </Typography>,
        <Typography key={"add"} sx={{ color: "#707070", fontSize: "0.875rem" }}>
            E-Voucher Batch
        </Typography>,
        <Typography key={"add"} sx={{ color: "#dc3545", fontSize: "0.875rem" }}>
            E-Voucher Details
        </Typography>
    ];
    const calculateDaysUntilExpiry = (createdAt, expiryDate) => {
        const createdDate = new Date(createdAt);
        const expiry = new Date(expiryDate);
        const diffTime = expiry - createdDate;
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return diffDays;
    };
    const formatDate = (dateString) => {
        const options = { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' };
        const date = new Date(dateString);
        return date.toLocaleDateString('en-GB', options);
    };
    return (
        <div>
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <div style={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
                <div style={{ flexGrow: 1, overflow: "hidden" }}>
                    <Box sx={{ flexGrow: 1, overflowY: "auto", overflowX: "hidden", p: 2, display: 'flex', justifyContent: 'space-between' }}>
                        <Box>
                            <IconButton style={{ position: 'relative', top: '40px', right: '10px' }} onClick={handleCancelModal}>
                                <ArrowBackIos />
                            </IconButton>
                            <Typography variant="h3" sx={{ color: "#032541", fontSize: "1.563rem", fontWeight: 700, marginLeft: '30px' }}>
                                E-Voucher Details
                            </Typography>
                            <Breadcrumbs sx={{ marginTop: '20px' }} separator={<FiberManualRecordIcon sx={{ fontSize: "0.625rem", color: "#e1e5e8" }} />} aria-label="breadcrumb">
                                {breadcrumbs}
                            </Breadcrumbs>
                        </Box>
                    </Box>
                    <Grid item>
                        <Typography style={{ fontWeight: 600, fontSize: "18px", color: "#032541", marginTop: "10px", fontFamily: 'Poppins', marginLeft: '20px' }}>
                            E-Voucher Details
                        </Typography>
                    </Grid>
                    <Grid container spacing={2} style={{ width: '100%', marginLeft: '10px', marginTop: '20px' }}>
                        <Grid item xs={12} sm={6} md={3}>
                            <div style={{
                                display: "flex", alignItems: "center", justifyContent: "space-between", padding: "15px", borderRadius: "12px", boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.1)", backgroundColor:
                                    voucherDetails?.status === "Redeemed" ? "#f3fbf8" :
                                        voucherDetails?.status === "EXPIRED" ? "#fef4f5" : "#fff9f2"
                            }}>
                                <Box>
                                    <Typography variant="h6" sx={{ color: "#000", fontSize: "16px" }}>
                                        Amount:
                                    </Typography>
                                    <Typography variant="h6" sx={{
                                        fontWeight: 600, fontSize: "21px", color:
                                            voucherDetails?.status === "Redeemed" ? "#17ae7b" :
                                                voucherDetails?.status === "EXPIRED" ? "#dc3545" : "#f79009"
                                    }}>
                                        {localCurrency} {voucherDetails ? `${voucherDetails.amount.toFixed(2)}` : 'Loading...'}
                                    </Typography>
                                    <Typography variant="h6" sx={{ fontSize: "12px", color: "#707070", lineHeight: '1.67' }}>
                                        {voucherDetails?.status === "EXPIRED" ? (
                                            <>
                                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    ID: {voucherDetails ? `${voucherDetails.voucherNumber}` : 'Loading...'}.
                                                    <Typography variant="h6" sx={{ color: "#dc3545", fontSize: "14px", marginLeft: '10px' }}>
                                                        Expired on: {formatDate(voucherDetails.expiryDate)}
                                                    </Typography>
                                                </Box>
                                                <Typography variant="h6" sx={{ color: "#000", fontSize: "14px", fontWeight: 500 }}>
                                                    Amount: {localCurrency} {voucherDetails.balance.toFixed(2)}
                                                </Typography>
                                            </>
                                        ) : (
                                            <>
                                                ID: {voucherDetails ? `${voucherDetails.voucherNumber}` : 'Loading...'} . Expiring in {voucherDetails ? `${calculateDaysUntilExpiry(voucherDetails.createdAt, voucherDetails.expiryDate)} days` : 'Loading...'}
                                            </>
                                        )}
                                    </Typography>
                                </Box>
                                <img alt="Voucher Status Icon" style={{ width: "40px", height: "40px", objectFit: "contain" }}
                                    src={voucherDetails?.status === "Redeemed" ? RedeemedIcon :
                                        voucherDetails?.status === "EXPIRED" ? ExpiredVoucherIcon : UnusedIcon} />
                            </div>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Typography style={{ fontWeight: 600, fontSize: "14px", color: "#000", marginTop: "20px", fontFamily: 'Poppins', marginLeft: '20px' }}>
                            Recipient Details
                        </Typography>
                    </Grid>
                    <Grid container spacing={2} style={{ width: '100%', marginLeft: '10px', marginTop: '5px' }}>
                        <Grid item xs={12} sm={6} md={3}>
                            <Box>
                                <Typography variant="h6" sx={{ color: "#707070", fontSize: "14px" }}>
                                    {voucherDetails ? `${voucherDetails.name}` : 'Loading...'}
                                </Typography>
                                <Typography variant="h6" sx={{ fontWeight: 600, fontSize: "14px", color: "#707070" }}>
                                    {voucherDetails ? `${voucherDetails.phone}` : 'Loading...'}
                                </Typography>
                                <Typography variant="h6" sx={{ fontSize: "12px", color: "#707070", lineHeight: '1.67' }}>
                                    {voucherDetails ? `${voucherDetails.email}` : 'Loading...'}
                                </Typography>
                            </Box>
                            <hr style={{ marginTop: '10px' }} />
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Typography style={{ fontWeight: 600, fontSize: "14px", color: "#000", marginTop: "20px", fontFamily: 'Poppins', marginLeft: '20px' }}>
                            Payment Details
                        </Typography>
                    </Grid>
                    <Grid container spacing={2} style={{ width: '100%', marginLeft: '10px', marginTop: '5px' }}>
                        <Grid item xs={12} sm={6} md={3}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Box>
                                    <Typography variant="h6" sx={{ color: "#707070", fontSize: "14px" }}>
                                        {voucherDetails ? `${voucherDetails.paymentDetails.referenceNo}` : 'Loading...'}
                                    </Typography>
                                    <Typography variant="h6" sx={{ fontSize: "14px", color: "#707070" }}>
                                        Paid On: {voucherDetails ? formatDate(voucherDetails.paymentDetails.paymentDate) : 'Loading...'}
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography variant="h6" sx={{ color: "#707070", fontSize: "14px" }}>
                                        {voucherDetails ? `${voucherDetails.paymentDetails.phone}` : 'Loading...'}
                                    </Typography>
                                    <Typography variant="h6" sx={{ fontSize: "14px", color: "#707070" }}>
                                        Paid Via: {voucherDetails ? `${voucherDetails.paymentDetails.paymentChannel}` : 'Loading...'}
                                    </Typography>
                                </Box>
                            </div>
                            <hr style={{ marginTop: '10px' }} />
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Typography style={{ fontWeight: 600, fontSize: "14px", color: "#000", marginTop: "20px", fontFamily: 'Poppins', marginLeft: '20px' }}>
                            Redeem History
                        </Typography>
                    </Grid>
                    {(voucherDetails?.status === "Redeemed" || voucherDetails?.status === "EXPIRED") &&
                        voucherDetails?.redeemHistory?.length > 0 ? (
                        <Grid container spacing={2} style={{ width: '100%', marginLeft: '10px', marginTop: '5px' }}>
                            <Grid item xs={12} sm={6} md={3}>
                                <Box>
                                    <Typography variant="h6" sx={{ color: "#707070", fontSize: "14px" }}>
                                        {localCurrency} {voucherDetails.redeemHistory[0]?.amount?.toFixed(2)} redeemed on {formatDate(voucherDetails.redeemHistory[0]?.createdAt)}
                                    </Typography>
                                    <Typography variant="h6" sx={{ fontSize: "12px", color: "#707070", lineHeight: '1.67' }}>
                                        Invoice: {voucherDetails.redeemHistory[0]?.invoiceNumber}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid container spacing={2} style={{ width: '100%', marginLeft: '10px', marginTop: '5px' }}>
                            <Grid item xs={12} sm={6} md={3}>
                                <Typography variant="h6" sx={{ color: "#707070", fontSize: "14px" }}>
                                    This voucher has not been redeemed yet.
                                </Typography>
                            </Grid>
                        </Grid>
                    )}
                </div>
            </div>
        </div>
    )

}

export default OpenEvoucherDetails