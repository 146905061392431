import { Grid, Typography, Breadcrumbs, InputLabel, Select, FormControl, MenuItem, TextField, InputAdornment } from '@mui/material'
import { useEffect, useState } from 'react';
import HttpComponent from '../../School/MakeRequest';
import { useNavigate } from 'react-router-dom'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CustomDataGrid from '../../products/stockComponents/customDataGrid';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Exports } from '../../customerAccounts/customerComponents/exports';
import SearchIcon from '@mui/icons-material/Search';
import { currencyconverter } from '../../../common/currencyconverter';
import { DoMaskValue } from '../../../utils/dateFormatter';
import AutoCompleteFiltertransport from './autoselectFilter';
import { CustomizedDate } from '../../customerAccounts/customerComponents/customDate';
import CustomSelectField from '../../../components/School/CustomSelectField'


const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Dashboard</Typography>,
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Reports</Typography>,
    <Typography key={4} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Transaction Reports</Typography>
]

const inputStyle = { width: '100%', '& input': { border: '1px solid #bdbdbd', borderRadius: '4px', }, };


export default function SaccoTransactionReports() {
    const [days, setDays] = useState([
        { value: "Default", label: "Default" },
        { value: "Today", label: "Today" },
        { value: "Yesterday", label: "Yesterday" },
        { value: "Last 7 Days", label: "Last 7 Days" },
        { value: "This Month", label: "This Month" },
        { value: "Last Month", label: "Last Month" },
        { value: "Custom Range", label: "Custom Range" },
    ]);
    const [dataGridPageSize, setPageSize] = useState(10);
    const [pageState, setPageState] = useState({ isLoading: true, data: [], count: 0, page: 1, pageSize: dataGridPageSize });
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [searchValue, setSearchValue] = useState('')
    const [handleDaysChange, setHandleDaysChange] = useState("This Month");
    const navigate = useNavigate();
    const [exportData, setExportData] = useState({ headers: [], data: [] });
    const [csvExport, setCsvExport] = useState({ headers: [], data: [] });
    const [excelHeaders, setExcelHeaders] = useState({ headers: [] });
    const [excelData, setExcelData] = useState({ data: [] });
    const [dateFrom, setDateFrom] = useState("");
    const [dateTo, setDateTo] = useState("");

    useEffect(() => {
        switch (handleDaysChange) {
            case "Today":
                const today = new Date().toLocaleDateString();
                setStartDate(today);
                setEndDate(today);
                break;
            case "Yesterday":
                let date = new Date();
                const yeseterday = new Date(date.setDate(date.getDate() - 1)).toLocaleDateString();
                // console.log(yeseterday);
                setStartDate(yeseterday);
                setEndDate(yeseterday);
                break;
            case "Last 7 days":
                function Last7Days() {
                    var result = [];
                    for (var i = 0; i < 7; i++) {
                        var d = new Date();
                        result.push(d.setDate(d.getDate() - i));
                    }
                    const lastSevenFirst = new Date(result[0]).toLocaleDateString();
                    const lastSevenLast = new Date(result[result.length - 1]).toLocaleDateString();

                    setStartDate(lastSevenLast);
                    setEndDate(lastSevenFirst);
                }
                Last7Days();
                break;
            case "This Month":
                const date1 = new Date();
                const firstDay = new Date(date1.getFullYear(), date1.getMonth(), 1).toLocaleDateString();
                const currentDay = new Date().toLocaleDateString();
                setStartDate(firstDay);
                setEndDate(currentDay);

                break;
            case "Last Month":
                const date2 = new Date();
                const firstDayLastMonth = new Date(date2.getFullYear(), date2.getMonth() - 1).toLocaleDateString();
                const lastDayLastMonth = new Date(date2.setDate(1)).toLocaleDateString();
                setStartDate(firstDayLastMonth);
                setEndDate(lastDayLastMonth);
                break;
            case "Default":
                setStartDate("");
                setEndDate("");
                break;
            case "Custom Range":
                setStartDate(dateFrom);
                setEndDate(dateTo);
                break;
            default:
                return;
        }
    }, [handleDaysChange, dateFrom, dateTo]);


    // get vehicle owners

    const [vehicleOwners, setVehicleOwner] = useState([])
    const [selectedVehicleOwner, setSelectedVehicleOwner] = useState('')
    const [vehicleOwnerid, setVehicleOwneriD] = useState('')

    useEffect(() => {
        if (selectedVehicleOwner && selectedVehicleOwner?.value) {
            const extractedNumber = selectedVehicleOwner.value;
            setVehicleOwneriD(extractedNumber);
        }
    }, [selectedVehicleOwner]);


    function getVehicleOwners() {
        try {
            HttpComponent({
                method: "GET",
                url: `/api/customers_list?status=Active&customerClassification=vehicleowner`,
                body: null,
                token: localStorage.getItem("X-Authorization"),
            }).then((data) => {
                if (data?.status === 200 || data?.status === 201) {
                    const foundVehicleOwners = data?.response?.data?.map((vehcile) => ({
                        value: vehcile?._id,
                        label: vehcile?.customerName
                    }))
                    setVehicleOwner(foundVehicleOwners)
                }
            })
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getVehicleOwners();
    }, [])


    //get vehicles for the owner

    const [myvehicle, setmyvehicle] = useState([])
    const [selectedVehicleRegNo, setselectedVehicleRegNo] = useState('')
    const [vehicleOwnerregNo, setvehicleOwnerregNo] = useState('')


    useEffect(() => {
        if (selectedVehicleRegNo && selectedVehicleRegNo?.value) {
            const extractedNumber = selectedVehicleRegNo.value;
            setvehicleOwnerregNo(extractedNumber);
        }
    }, [selectedVehicleRegNo]);

    function getVehicles() {
        try {
            HttpComponent({
                method: "GET",
                url: `/api/v1/listvehicles?customerId=${vehicleOwnerid}`,
                body: null,
                token: localStorage.getItem("X-Authorization"),
            }).then((data) => {
                if (data?.status === 200 || data?.status === 201) {
                    const myfoundvehicl = data?.response?.data?.map((item) => ({
                        value: item?.regNo,
                        label: item?.regNo
                    }))
                    setmyvehicle(myfoundvehicl)
                }
            })
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getVehicles();
    }, [vehicleOwnerid])


    function getRecentTransactions() {
        setPageState({ ...pageState, isLoading: true })
        try {
            HttpComponent({
                method: "GET",
                url: `/api/v1/getFareTransactions?page=${pageState.page}&limit=${dataGridPageSize}&search=${searchValue}&startDate=${startDate}&endDate=${endDate}&regNo=${vehicleOwnerregNo}&customerId=${vehicleOwnerid}`,
                body: null,
                token: localStorage.getItem("X-Authorization"),
            }).then((data) => {
                if (data?.status === 200 || data?.status === 201) {
                    setPageState({ ...pageState, data: data?.response?.data, isLoading: false, count: data?.response?.count })
                } else {
                    setPageState({ ...pageState, isLoading: false })
                }
            })
        } catch (error) {
            console.log(error)
        }
    }


    useEffect(() => {
        getRecentTransactions()
    }, [dataGridPageSize, pageState.page, startDate, endDate, searchValue, vehicleOwnerid, vehicleOwnerregNo])

    useEffect(() => {

        const pdfData = pageState?.data?.map((config) => [
            config?.transactionID,
            config?.transtime,
            config?.customerFirstName
                ? config?.customerSecondName
                    ? `${config.customerFirstName} ${config.customerSecondName}`
                    : config.customerFirstName
                : "",
            DoMaskValue(config?.customerPhone),
            config?.regNo,
            config?.operatorName,
            config?.transamount,
            config?.transactionType
        ]);

        const pdfHeaders = [['Transaction ID', 'Date & Time', 'Name', 'Phone No', 'Vehicle', 'Operator', 'Amount', 'Transaction Type']];

        setExportData({ headers: pdfHeaders, data: pdfData });

        const csvHeaders = [
            { label: 'Transaction ID', key: 'Transaction ID' },
            { label: 'Date & Time', key: 'Date & Time' },
            { label: 'Name', key: 'Name' },
            { label: 'Phone No', key: 'Phone No' },
            { label: 'Vehicle', key: 'Vehicle' },
            { label: 'Operator', key: 'Operator' },
            { label: 'Amount (KES)', key: 'Amount (KES)' },
            { label: 'Transaction Type', key: 'Transaction Type' },
        ];

        const csvData = pageState?.data?.map((config) => ({
            "Transaction ID": config?.transactionID,
            "Date & Time": config?.transtime,
            "Name": config?.customerFirstName
                ? config?.customerSecondName
                    ? `${config.customerFirstName} ${config.customerSecondName}`
                    : config.customerFirstName
                : "",
            "Phone No": DoMaskValue(config?.customerPhone),
            "Vehicle": config?.regNo,
            "Operator": config?.operatorName,
            "Amount (KES)": config?.transamount,
            "Transaction Type": config?.transactionType
        }));

        setCsvExport({ data: csvData, headers: csvHeaders });

        const excelExportHeaders = [
            { label: 'Transaction ID', key: 'Transaction ID' },
            { label: 'Date & Time', key: 'Date & Time' },
            { label: 'Name', key: 'Name' },
            { label: 'Phone No', key: 'Phone No' },
            { label: 'Vehicle', key: 'Vehicle' },
            { label: 'Operator', key: 'Operator' },
            { label: 'Amount (KES)', key: 'Amount (KES)' },
            { label: 'Transaction Type', key: 'Transaction Type' }
        ];

        setExcelHeaders(excelExportHeaders)
        setExcelData(csvData)

    }, [dataGridPageSize, pageState.data])


    // data
    const configData = pageState?.data?.map((config) => {
        const transactionTime = new Date(config?.transtime);
        const formattedTime = transactionTime.toLocaleString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
        });

        return {
            id: config._id,
            name: config?.customerFirstName
                ? config?.customerSecondName
                    ? `${config.customerFirstName} ${config.customerSecondName}`
                    : config.customerFirstName
                : "",
            operator: config?.operatorName,
            phone: DoMaskValue(config?.customerPhone),
            vehicle: config?.regNo,
            transactionId: config?.transactionID,
            transtime: formattedTime,
            amount: currencyconverter(config?.transamount),
            transactionType: config?.transactionType
        };
    });

    // columns

    const recenttransaction = [
        { field: "transactionId", headerName: "Transaction ID", flex: 1, },
        { field: "transtime", headerName: "Date & Time", flex: 1 },
        { field: 'name', headerName: 'Name', flex: 1 },
        { field: 'phone', headerName: 'Phone No', flex: 1 },
        { field: 'vehicle', headerName: 'Vehicle', flex: 1 },
        { field: 'operator', headerName: 'Operator', flex: 1 },
        { field: 'amount', headerName: 'Amount', flex: 1 },
        { field: 'transactionType', headerName: 'Transaction Type', flex: 1 },
    ]

    return (
        <Grid container direction={'column'} width={'100%'}>
            <Grid item mt={2} mb={2}>
                <Grid container gap={2} display={'flex'} direction={'column'}>
                    <Grid item width={'100%'} alignItems={'center'} display={'flex'} justifyContent={'space-between'}>
                        <Grid item display={'flex'} alignItems={'center'}>
                            <ArrowBackIosNewIcon onClick={() => navigate(-1)} style={{ color: "#707070", cursor: 'pointer' }} />
                            <Typography variant='h4' sx={{ fontWeight: 600, fontSize: '20px' }}>Transactions</Typography>
                        </Grid>
                    </Grid>
                    <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                        {breadcrumbs}
                    </Breadcrumbs>
                </Grid>
            </Grid>
            <Grid item display={'flex'} mt={4} justifyContent={'space-between'} alignItems={'center'}>
                <Grid container display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                    <Grid item mt={-1}>
                        <TextField value={searchValue} onChange={(e) => setSearchValue(e.target.value)} name={'search'} placeholder={'search'} variant="outlined" margin="dense"
                            InputProps={{
                                startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
                            }}
                        />
                    </Grid>
                    <Grid item display={'flex'} alignItems={'center'} style={{ width: '200px' }}>
                        <AutoCompleteFiltertransport size={'lg'} label={'Vehicle Owners'} value={selectedVehicleOwner} options={vehicleOwners} onChange={(e, newValue) => { setSelectedVehicleOwner(newValue) }} />
                    </Grid>
                    <Grid item mx={1} display={'flex'} alignItems={'center'} style={{ width: '200px' }}>
                        <AutoCompleteFiltertransport label={'Vehicles'} size={'lg'} options={myvehicle} value={selectedVehicleRegNo} onChange={(e, newValue) => { setselectedVehicleRegNo(newValue) }} />
                    </Grid>
                    {/* <Grid item display={'flex'}  alignItems={'center'} style={{ width: '200px' }}>
                                                                                                                                                                                                                                                                                                                                                                                                                                                            <AutoCompleteFiltertransport label={'Operators'}/>
                                                                                                                                                                                                                                                                                                                                                                                                                                                        </Grid> */}
                    <Grid item>
                        {handleDaysChange !== "Custom Range" && <CustomSelectField onChange={(e) => setHandleDaysChange(e.target.value)} value={handleDaysChange} placeholder={"Select Day"} options={days} name={"Transaction Type"} />}

                        {handleDaysChange === "Custom Range" && <CustomizedDate setDateFrom={setDateFrom} setDateTo={setDateTo} style={{ display: "flex", alignItems: "center" }} />}
                        {/* <FormControl sx={{ width: '190px' }}>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                <InputLabel id="demo-simple-select-label">Date</InputLabel>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                <Select
                                                                                                                                                                                                                                                                                                                                                                                                                                                                    labelId="demo-simple-select-label"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                    id="demo-simple-select"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                    label="Date"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                    value={handleDaysChange}
                                                                                                                                                                                                                                                                                                                                                                                                                                                                    onChange={(e) => setHandleDaysChange(e.target.value)}
                                                                                                                                                                                                                                                                                                                                                                                                                                                                >
                                                                                                                                                                                                                                                                                                                                                                                                                                                                    <MenuItem value=''>All</MenuItem>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                    <MenuItem value={'Today'}>Today</MenuItem>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                    <MenuItem value={'Yesterday'}>Yesterday</MenuItem>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                    <MenuItem value={'Last7days'}>Weekly</MenuItem>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                    <MenuItem value={'This Month'}>This Month</MenuItem>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                    <MenuItem value={'Last Month'}>Last Month</MenuItem>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                    <MenuItem value={'Custom Range'}>Custom Range</MenuItem>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                </Select>
                                                                                                                                                                                                                                                                                                                                                                                                                                                            </FormControl> */}
                    </Grid>
                    <Grid item>
                        <Exports exportData={exportData} csvExport={csvExport} activeTabs={"Transactions Report"} excelHeaders={excelHeaders} excelData={excelData} />

                    </Grid>
                </Grid>
            </Grid>
            <Grid item width={"100%"}>
                <CustomDataGrid customStyles={{ border: "none", height: "450px", width: "100%" }} column={recenttransaction} setPageSize={setPageSize} setPageState={setPageState} pageState={pageState} dataGridPageSize={dataGridPageSize} row={configData} />
            </Grid>
        </Grid>
    )
}