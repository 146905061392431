import React, { useEffect, useRef, useState } from "react";
import MpesaIcon from '../../common/images/mpesa.svg';
import MobileBankIcon from '../../images/Mobile To Bank Icn.svg';
import BankSlipIcon from '../../images/bankslip.svg';
import CashIcon from '../../components/School/Images/cash-icon.svg';
import UploadStatement from '../../images/uploadBank.svg'
import MomoIcon from '../../images/mtn-momo-icn.svg'
import CardIcon from '../../common/images/card-icon.svg'
import EcitizenIcon from '../../components/School/Images/ecitizen.png'
import SettleInvoice from '../../images/settle-invoice-icn.svg'
import CARDREADY from '../../components/School/Images/cardlinkready.svg'
import { useNavigate, useParams } from "react-router-dom";
import { Box, Button, ButtonBase, Card, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";
import PhoneInput from "react-phone-input-2";
import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from '@mui/icons-material/Send';
import { SuccessAlert } from "../snackBar Alerts/successAlert";
import { ErrorAlert } from "../snackBar Alerts/errorAlert";
import HttpComponent from "../School/MakeRequest";
import DateFormatter from "../../utils/dateFormatter";
import { currencyconverter, GetDefaultCurrency } from "../../common/currencyconverter";

function PayPersonalVoucher({ batchNumber, amount }) {
    console.log("Batch Number:", batchNumber);
    console.log("Amount:", amount);
    const textRef = useRef(null);
    const theme = useTheme();
    const navigate = useNavigate();
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const userGroup = localStorage.getItem("group");
    let localCurrency = localStorage.getItem('localCurrency')
    const businessNumberID = localStorage.getItem('businessId')
    const isMobileUI = useMediaQuery(theme.breakpoints.down("sm"));
    const X_Authorization = localStorage.getItem('X-Authorization')
    // const { X_Authorization } = useSelector((store) => store.user)
    // const [errorOpen, setErrorOpen] = useState(false);
    // const [successOpen, setSuccessOpen] = useState(false);
    // const [errorMessage, setErrorMessage] = useState("");
    // const [successMessage, setSuccessMessage] = useState("");
    const [foundbid, setfoundbid] = useState('')
    const [MpesaStatus, setMpesaStatus] = useState(false)
    const [CardStatus, setCardStatus] = useState(false)
    const [KCBBankPaybillStatus, setKCBSBankPaybillstatus] = useState(false)
    const [KCBBankDeposit, setKCBBankDeposit] = useState(false)
    const [BankStatus, setBankStatus] = useState(false)
    const [CashStatus, setCashStatus] = useState(false)
    const [momoStatus, setMomoStatus] = useState(false)
    const [isSettleInvoiceEbaled, setISsettleInvoiceEnabled] = useState(false)
    const [numberofPaymentMethodsSet, setNumberofPaymentMethods] = useState(0)
    const businessCat = localStorage.getItem('businessCategory')
    const queryParams = decodeURIComponent(window.location.search)
    let { invoiceNumber, darajaConfigId } = useParams();
    const [isInvoiceBatch, setIsInvoiceBatch] = useState(false);
    const [successNotificationOpen, setSuccessNotificationOpen] = React.useState(false);
    const handleSuccessNotificationClick = () => { setSuccessNotificationOpen(true); };
    const handleSuccessNotificationClose = (event, reason) => { if (reason === 'clickaway') { return } setSuccessNotificationOpen(false); };
    const [successNotificationOpen2, setSuccessNotificationOpen2] = React.useState(false);
    const handleSuccessNotificationClick2 = () => { setSuccessNotificationOpen2(true); };
    const handleSuccessNotificationClose2 = (event, reason) => { if (reason === 'clickaway') { return } setSuccessNotificationOpen(false); };
    const [successNotificationOpen1, setSuccessNotificationOpen1] = React.useState(false);
    const handleSuccessNotificationClick1 = () => { setSuccessNotificationOpen1(true); };
    const handleSuccessNotificationClose1 = (event, reason) => { if (reason === 'clickaway') { return } setSuccessNotificationOpen1(false); };
    const [SuccessShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })
    const [successNotificationReceivedPaymentOpen, setSuccessNotificationReceivedPaymentOpen] = React.useState(false);
    const handleSuccessNotificationPaymentReceivedClick = () => { setSuccessNotificationReceivedPaymentOpen(true); };
    const handleSuccessNotificationReceivedPaymentClose = (event, reason) => { if (reason === 'clickaway') { return } setSuccessNotificationReceivedPaymentOpen(false); };
    const [errorNotificationOpen1, setErrorNotificationOpen1] = React.useState(false);
    const handleErrorNotificationClick1 = () => { setErrorNotificationOpen1(true); };
    const handleErrorNotificationClose1 = (event, reason) => { if (reason === 'clickaway') { return } setErrorNotificationOpen1(false); };
    const [errorNotificationOpen, setErrorNotificationOpen] = React.useState(false);
    const handleErrorNotificationClick = () => { setErrorNotificationOpen(true); };
    const handleErrorNotificationClose = (event, reason) => { if (reason === 'clickaway') { return } setErrorNotificationOpen(false); };
    const [errorNotificationTimeoutOpen, setErrorNotificationTimeoutOpen] = React.useState(false);
    const handleErrorNotificationTimeoutClick = () => { setErrorNotificationTimeoutOpen(true); };
    const handleErrorNotificationTimeoutClose = (event, reason) => { if (reason === 'clickaway') { return } setErrorNotificationTimeoutOpen(false); };
    const [errorNotificationNoBusinessConfigOpen, setErrorNotificationNoBusinessConfigOpen] = React.useState(false);
    const handleErrorNotificationNoBusinessConfigClick = () => { setErrorNotificationNoBusinessConfigOpen(true); };
    const handleErrorNotificationNoBusinessConfigClose = (event, reason) => { if (reason === 'clickaway') { return } setErrorNotificationNoBusinessConfigOpen(false); };
    const [defaultCurrency, setDefaultCurrency] = useState(GetDefaultCurrency())
    const [activeButton, setActiveButton] = useState('')
    const [ectizenStatus, setEctizenStatus] = useState(false)
    const [batchData, setBatchData] = useState({})
    const numberFormat = (value) => new Intl.NumberFormat("en-US", { style: "currency", currency: localCurrency }).format(value);
    let [initialBalance, setInitialBalance] = useState('')
    const [userPhone, setUserPhone] = useState('')
    const [paymentAmount, setPaymentAmount] = useState("")
    const [mpesaLoading, setMpesaLoading] = useState(false)
    const [kcbloading, setkcbloading] = useState(false)
    const [Cardloading, setCardloading] = useState(false)
    const [counter, setCounter] = useState(300);
    const [GenerteLink, setGeneratedLink] = useState(false)
    const [GenerateLinkStatus, setGenerateLinkStatus] = useState(false)
    const [invoiceBalance, setInvoiceBalance] = useState();
    const [dataLINK, setDatagenerated] = useState('')
    const [customerInfo, setCustomerInfo] = useState({})
    const [bankAccount, setBankAccount] = useState('')
    const [availableBanks, setAvailableBanks] = useState([{ name: "KCB", value: "KCB" }])
    const [kcbphone, setkcbphone] = useState('')
    const [kcbAmount, setKcbAmount] = useState('')
    const [bankAccountsKCB, setBankAccountsKCB] = useState([])
    const [bankAccountKCB, setBankAccountKCB] = useState('')
    const [idNumber, setIdNumber] = useState('')
    const [referenceNo, setReferenceNo] = useState('')
    const [cashAmount, setCashAmount] = useState('')
    const [invoiceDiscount, setInvoiceDiscount] = useState(0);
    const [invoiceData, setInvoiceData] = useState()
    const [errorFetching, setErrorFetching] = useState(false);
    const [bankAccounts, setBankAccounts] = useState([])
    const [selectedBankAccount, setSelectedBankAccount] = useState('')
    const [bankSlipBalance, setBankSlipBalance] = useState(0);
    const [bankslipRefNo, setBankslipRef] = useState()
    const [refDetailsFound, setRefDetailsFound] = useState([])
    const [isValidated, setIsValidated] = useState(false)
    const [bankSlipAccountAmount, setBankSlipAccountAmount] = useState(0);
    const [refId, setRefId] = useState(null)
    const [selectedSettleInvoiceMethod, setSelectedSettleInvoiceMethod] = useState('')
    const [slipAccountNumber, setSlipAccountNumber] = useState('')
    const [settleInvoiceAmount, setSettleInvoiceAmount] = useState()
    const [settleInvoiceRefNo, setSettleInvoiceRefNo] = useState()
    const [settleinvoiveOptions, setSettleInvoiceOptions] = useState([])
    const [bankSlipAmount, setBankSlipAmount] = useState(0)
    const handleCopy = () => {
        textRef.current.select();
        document.execCommand('copy');
        handleSuccessNotificationClick2()
    };
    const [cardAmount, setCardAmout] = useState('')

    // const handleCloseError = () => {
    //     setErrorOpen(false);
    // };
    // const handleCloseSuccess = () => {
    //     setSuccessOpen(false);
    // };
    let numberOfPaymentMethods = 0;
    const state = queryParams.slice(1)
    useEffect(() => {
        if (state.startsWith('businessNumber')) {
            const businessNumber = state.split('=')[1];
            setfoundbid(businessNumber)
        }
    }, [state])
    useEffect(() => {
        if (invoiceNumber?.length === 24) {
            setIsInvoiceBatch(true)
        } else {
            setIsInvoiceBatch(false)
        }
    }, [invoiceNumber])

    const checkPaymentStatus = async () => {
        const businessNumber = localStorage.getItem('businessId');
        if (!businessNumber) {
            console.error("Business number not found");
            return;
        }

        try {
            const response = await fetch(
                `${baseUrl}/api/get_payment_methods_with_status?businessNumber=${businessNumber}`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                        "X-Authorization": localStorage.getItem("X-Authorization"),
                    },
                }
            );

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();
            const paymentMethods = data?.data || [];

            const mpesaStatus = paymentMethods.find(method => method.name === "Mpesa")?.status ?? false;
            const cashStatus = paymentMethods.find(method => method.name === "Cash")?.status ?? false;
            const voucherStatus = paymentMethods.find(method => method.name === "Evoucher")?.status ?? false;
            const cardStatus = paymentMethods.find(method => method.name === "Card")?.status ?? false;
            const banks = paymentMethods.find(method => method.name === "Banks") ?? {};
            const bankStatus = banks?.status ?? false;
            const kcbBanks = banks.paymentOptions?.find(method => method.name === "KCB") ?? {};
            const paybillStatus = kcbBanks?.kcb?.find(method => method.name === "Mobile Money")?.status ?? false;
            const kcbDepositStatus = kcbBanks?.kcb?.find(method => method.name === "KCB DEPOSIT")?.status ?? false;
            const momoPayStatus = paymentMethods.find(method => method.name === "MTN Momo")?.status ?? false;
            const settleInvoiceStatus = paymentMethods.find(method => method.name === "settleInvoiceStatus")?.status ?? false;
            const accessBanks = banks.paymentOptions?.find(method => method.name === "Accessbank") ?? {};

            setMpesaStatus(mpesaStatus);
            setCardStatus(cardStatus || (accessBanks?.accessbank?.length > 0));
            setKCBSBankPaybillstatus(paybillStatus);
            setKCBBankDeposit(kcbDepositStatus);
            setBankStatus(bankStatus);
            setCashStatus(cashStatus);
            setMomoStatus(momoPayStatus);
            setISsettleInvoiceEnabled(settleInvoiceStatus);

            const paymentMethodCount = [mpesaStatus, cashStatus, cardStatus, paybillStatus, kcbDepositStatus, bankStatus, momoPayStatus].filter(Boolean).length;
            setNumberofPaymentMethods(paymentMethodCount);

        } catch (error) {
            console.error("Error fetching payment methods:", error);
        }
    };

    useEffect(() => {
        checkPaymentStatus();
    }, [foundbid, state]);
    function handleButtonClick(name) {
        setActiveButton(name)
    }
    useEffect(() => {
        let timer;
        if (mpesaLoading && counter > 0) {
            timer = setInterval(() => {
                setCounter(prevCounter => prevCounter - 1);
            }, 1000);
        } else if (counter === 0) {
            setCounter(300); 
            setMpesaLoading(false);
        }

        return () => clearInterval(timer); 
    }, [mpesaLoading, counter]);
    const handleClick = async (orderID) => {
        let object = { amount: amount || initialBalance, phone: userPhone, orderID: orderID };
        console.log('object', object);
        setMpesaLoading(true);
        setCounter(300);
        if (object.phone == '') {
            setMpesaLoading(false);
            setErrorShow({ state: true, message: 'Please provide the phone number' })
            // console.error("Please provide the phone number");
            handleErrorNotificationClick();
        } else if (object.amount === '' || object.amount === 0) {
            setMpesaLoading(false);
            setErrorShow({ state: true, message: 'Please provide the amount ' })
            handleErrorNotificationClick();
        } else {
            try {
                HttpComponent({
                    method: "POST",
                    url: "/api/pushstk",
                    token: X_Authorization,
                    body: {
                        amount: object.amount,
                        phone: Math.ceil(object.phone),
                        orderID: batchNumber,
                        type: "evoucher",
                        // batchId: isInvoiceBatch ? invoiceNumber : "",
                        businessID: businessNumberID
                    }

                })
                    .then((data) => {
                        if (data.response.data.status === 200) {
                            handleSuccessNotificationClick();
                        } else {
                            setMpesaLoading(false);
                            console.error("Problem Processing StK Payment");
                            handleErrorNotificationClick();
                        }
                    })
                    .catch((e) => {
                        setMpesaLoading(false);
                        console.error(`Error Sending STK`, e.message);
                    });
            } catch (error) {
                console.error(error);
                handleErrorNotificationClick();
            }

        }

    };
    const handleCardPayment = async (orderID) => {
        const obj = { email: customerInfo?.email, amount: cardAmount || invoiceBalance, orderID: orderID }
        if (obj.cardAmount === '' || obj.cardAmount === 0) {
            setErrorShow({ state: true, message: 'kindly add  a amount' })
        } else {
            try {
                let url = `/api/v1/payments/card/pay`
                if (defaultCurrency === "NGN") {
                    url = `/api/v1/payment/card/accessbank`
                }
                HttpComponent({
                    method: 'POST',
                    url: url,
                    body: {
                        amount: cardAmount || invoiceBalance,
                        // email: email,
                        orderID: orderID,
                        type: "invoice",
                        paymentChanel: "WEB",
                        businessNumber: customerInfo?.businessId
                    },
                    token: localStorage.getItem("X-Authorization")
                }).then((data) => {
                    if (data.status === 200) {
                        if (data.response.data.status === 500) {
                            setErrorShow({ state: true, message: data.response.data.error.message })
                        } else if (data.status === 200) {
                            handleSuccessNotificationClick1();
                            setGeneratedLink(true)
                            setGenerateLinkStatus(true)
                            setCardloading(true)
                            let paymentURL = data?.response?.data?.url
                            if (defaultCurrency === "KES") {
                                setDatagenerated(data.response.data.data.invoiceInformation.paymentLink)
                            } else {
                                setDatagenerated(data?.response?.data?.url)
                            }

                        }
                    } else if (data.status === '401') {
                        setErrorShow({ state: true, message: data.response.message })
                    } else if (data.status === '400') {
                        setErrorShow({ state: true, message: data.response.error })
                    } else {
                        handleErrorNotificationClick1();
                        setErrorShow({ state: true, message: 'Erriirr  r' })
                    }

                }).catch((e) => {

                })
            } catch (error) {
                handleErrorNotificationClick1();
            }
        }

    }
    const handleMtnMomoPay = async (orderID) => {
        let object = { amount: amount || initialBalance, phone: userPhone, orderID: orderID };
        //TODO: Currently hard coded to do stk push for sweeton
        setMpesaLoading(true);
        if (object.phone === '') {
            setMpesaLoading(false);
            setErrorShow({ state: true, message: 'Please provide the phone number' })
            // console.error("Please provide the phone number");
            handleErrorNotificationClick();
        } else if (object.amount === '' || object.amount === 0) {
            setMpesaLoading(false);
            setErrorShow({ state: true, message: 'Please provide the amount ' })
            handleErrorNotificationClick();
        } else {
            try {
                HttpComponent({
                    method: "POST",
                    url: "/api/v1/payments/momo/pay",
                    token: X_Authorization,
                    body: {
                        amount: object.amount,
                        phone: object.phone,
                        orderID: object.orderID
                    }

                })
                    .then((data) => {
                        console.log(data.response, 'lets goooooo')
                        if (data.response?.statusCode === 202) {
                            handleSuccessNotificationClick();
                        } else {
                            setMpesaLoading(false);
                            console.error("Problem Processing StK Payment");
                            handleErrorNotificationClick();
                        }
                    })
                    .catch((e) => {
                        setMpesaLoading(false);
                        console.error(`Error Sending STK`, e.message);
                    });
            } catch (error) {
                console.error(error);
                handleErrorNotificationClick();
            }

        }
    };
    const handleKcbPush = async (orderID) => {
        let object = { amount: kcbAmount || invoiceBalance, phone: kcbphone, orderID: orderID };
        const payurl = bankAccount === "KCB" ? `/api/v1/payments/initiate_kcb_stk_push` : `/api/v1/initiatecoopstkpush`
        if (kcbphone == '') {
            setErrorShow({ state: true, message: 'kindly add a phone number' })
            setkcbloading(false)
        } else if (bankAccount == '') {
            setErrorShow({ state: true, message: 'kindly select  a bank account' })
            setkcbloading(false)
        } else if (invoiceBalance == 0 || object.amount == '' || object.amount == 0) {
            setErrorShow({ state: true, message: 'kindly add  a amount' })
            setkcbloading(false)
        } else {
            setkcbloading(true)
            try {
                HttpComponent({
                    method: 'POST',
                    url: payurl,
                    body: {
                        amount: Math.ceil(kcbAmount) || Math.ceil(amount),
                        phone: kcbphone,
                        orderID: batchNumber,
                        type: "evoucher",
                        paymentChanel: "web",
                        // orderIds: [orderID],
                        // batchId: isInvoiceBatch ? invoiceNumber : ""
                    },
                    token: localStorage.getItem("X-Authorization")
                }).then((data) => {
                    if (data.status == 200) {
                        handleSuccessNotificationClick();
                    }
                    else if (data.status === 400) {
                        setErrorShow({ state: true, message: data.response.message })
                        setkcbloading(false)
                    }
                    else {
                        setErrorShow({ state: true, message: data.response.message })
                        setkcbloading(false)
                        handleErrorNotificationClick();
                        setErrorShow({ state: true, message: data.response.message })
                    }
                })
                    .catch((e) => {
                        setkcbloading(false)
                    })
            } catch (error) {
                console.error(error);
                handleErrorNotificationClick();
            }
        }
    }
    const handleBankSlip = async (orderID) => {
        const obj = { reference: referenceNo, creditAccount: bankAccountKCB, invoiceNumber: orderID }
        if (obj.reference === '' || obj.creditAccount === '') {
            setErrorShow({ state: true, message: "Please fill in all fields" })
            setTimeout(() => {
                setErrorShow({ state: false, message: "" })
            }, 3000)
        } else {
            try {
                const response = await fetch(baseUrl + `/api/v1/validate_kcb_bank_slip`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                        "X-Authorization": localStorage.getItem("X-Authorization"),
                    },
                    body: JSON.stringify(obj)
                })
                const data = response.json()
                    .then((response) => {
                        if (response.status === 'success') {
                            navigate(`/invoice/${invoiceNumber}`)
                        } else {
                            setErrorShow({ state: true, message: response.message + `${response.data}` })
                            setTimeout(() => {
                                setErrorShow({ state: false, message: "" })
                            }, 3000)
                        }
                    })

            } catch (error) {
                console.log(error);
            }
        }
    }
    const payEcitizen = async (invoiceNumber) => {
        try {
            const response = await fetch(baseUrl + `/api/v1/ecitizen/processEcitizenPayment`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    "X-Authorization": localStorage.getItem("X-Authorization"),
                },
                body: JSON.stringify({
                    invoiceNumber: invoiceNumber,
                    email: customerInfo?.email,
                    phone: customerInfo?.phone,
                    id_number: idNumber,
                    fullName: `${customerInfo?.firstName} ${customerInfo?.lastName}`,
                })
            });
            if (!response.ok) {
                const error = await response.json();
                setErrorShow({ state: true, message: error?.message || 'Error processing payment' })
                return
            }
            const html = await response.text();
            const newWindow = window.open();
            newWindow.document.write(html);
        } catch (error) {
            console.error(error);
        }
    }
    const getInvoiceDetails = async () => {
        try {
            const invoiceDetailsResponse = await fetch(baseUrl + `/api/get_invoice_by_invoice_number?invoiceNumber=${invoiceNumber}&businessNumber=${foundbid ?? foundbid}`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                        "X-Authorization": localStorage.getItem("X-Authorization"),
                    },
                }
            );
            if (invoiceDetailsResponse.status === 200) {
                const invoiceDetails = await invoiceDetailsResponse.json();
                setInvoiceData(invoiceDetails.data);
                setInitialBalance(invoiceDetails.data.invoiceBalance)
                const disc = invoiceDetails?.data?.invoiceDiscountAmount || 0
                setInvoiceDiscount(disc)
                if (invoiceDetails.data?.paymentLink !== '' && invoiceDetails.data.invoiceStatus === 'Unpaid') {
                    setGenerateLinkStatus(true)
                    setDatagenerated(invoiceDetails.data.paymentLink)

                }
            } else {
                setErrorFetching(true)
            }
        } catch (error) {
            console.error("error fetching invoice details", error.message);
        }
    };
    const payViaCash = async () => {
        if (initialBalance === 0) {
            setErrorShow({ state: true, message: "Please fill in cash amount received" });
            setTimeout(() => {
                setErrorShow({ state: false, message: "" });
            }, 3000);
        } else {
            const response = await fetch(baseUrl + `/api/v1/evoucher/payCash`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    "X-Authorization": localStorage.getItem("X-Authorization"),
                },
                body: JSON.stringify({
                    batchNumber: batchNumber,
                    amount: cashAmount || Math.ceil(amount),
                    paymentChanel: 'Web',
                })
            });

            await response.json()
                .then((response) => {
                    console.log('Payment successfully processed', response);
                    if (response?.status === "Success" || response?.status === "SUCCESS") {
                        const paymentId = response.data._id;
                        console.log('paymentId', paymentId);
                        setSuccessNotificationReceivedPaymentOpen(true);
                        setTimeout(() => {
                            setSuccessNotificationReceivedPaymentOpen(false);
                            navigate(`/evoucher/receipt/${paymentId}`);
                        }, 3000);
                    } else {
                        setErrorShow({ state: true, message: response.message });
                        setTimeout(() => {
                            setErrorShow({ state: false, message: "" });
                        }, 3000);
                    }
                });
        }
    };
    // const payViaCash = async () => {
    //     if (initialBalance === 0) {
    //         setErrorShow({ state: true, message: "Please fill in cash amount received" })
    //         setTimeout(() => {
    //             setErrorShow({ state: false, message: "" })
    //         }, 3000)
    //     } else {
    //         // const reference = new Date().toLocaleString()
    //         const response = await fetch(baseUrl + `/api/v1/evoucher/payCash`, {
    //             method: "POST",
    //             headers: {
    //                 "Content-Type": "application/json",
    //                 Accept: "application/json",
    //                 "X-Authorization": localStorage.getItem("X-Authorization"),
    //             },
    //             body: JSON.stringify({
    //                 batchNumber: batchNumber,
    //                 // amount: cashAmount || Math.ceil(amount),
    //                 // referenceNo: reference,
    //                 // businessNumber: businessNumberID,
    //                 // batchId: isInvoiceBatch ? invoiceNumber : '',
    //                 // invoiceNumber: invoiceNumber,
    //                 // paymentMethod: "Cash Payment",
    //                 paymentChanel: 'Web',
    //                 // type: isInvoiceBatch ? 'tickets' : ''
    //             })
    //         })
    //         await response.json()
    //             .then((response) => {
    //                 console.log('Payment successfully processed', response);
    //                 if (response?.status === "Success" || response?.status === "SUCCESS") {
    //                     const paymentId = response.data._id; 
    //                     console.log('paymentId', paymentId)
    //                     setSuccessNotificationReceivedPaymentOpen(true)
    //                     setTimeout(() => {
    //                         setSuccessNotificationReceivedPaymentOpen(false)
    //                         getInvoiceDetails()
    //                         if (isInvoiceBatch) {
    //                             navigate(`/school/batch/view/${invoiceNumber}`)
    //                         } else {
    //                             navigate(`/admin/evoucher/receipt/${paymentId}`)
    //                         }
    //                     }, 3000)
    //                 } else {
    //                     setErrorShow({ state: true, message: response.message })
    //                     setTimeout(() => {
    //                         setErrorShow({ state: false, message: "" })
    //                     }, 3000)
    //                 }
    //             })
    //     }
    // }
    const payViaValidatedSlip = () => {
        try {
            HttpComponent({
                method: 'POST',
                url: `/api/v1/processBankSlipPayment`,
                body: {
                    invoiceNumber: invoiceNumber,
                    invoiceAmount: bankSlipAccountAmount ? bankSlipAccountAmount : initialBalance,
                    referenceId: refId,
                    referenceNumber: bankslipRefNo,
                    accountNumber: slipAccountNumber
                },
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data?.status === 200) {
                    setSuccessShow({ state: true, message: data?.response?.message })

                    setTimeout(() => {
                        navigate(`/school/invoice/${invoiceNumber}`)
                    }, 3000);

                } else if (data?.status === 400) {
                    setErrorShow({ state: true, message: data?.response?.message })
                } else {
                    setErrorShow({ state: true, message: data?.response?.message })
                }
            })
        } catch (error) {
            setErrorShow({ state: true, message: error?.message })
        }
    }
    function validateUploadedTransactionRef() {
        try {
            HttpComponent({
                method: 'POST',
                url: `/api/v1/validatebankslip`,
                body: {
                    accountNumber: selectedBankAccount,
                    referenceNumber: bankslipRefNo
                },
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                // console.log(data , 'data >>>>>>>>>>>>')
                if (data.status === 200) {
                    setSuccessShow({ state: true, message: data?.response?.message });
                    setRefDetailsFound(data?.response?.data)
                    setRefId(data?.response?.data[0]?._id)
                    setBankSlipAmount(data?.response?.data[0]?.creditAmount)
                    setSlipAccountNumber(data?.response?.data[0]?.accountNumber)
                    setIsValidated(true)
                    setBankSlipBalance(data?.response?.data[0]?.balance)
                } else if (data.status === 400) {
                    setErrorShow({ state: true, message: data?.response?.message })
                }
            })
        } catch (error) {
            setErrorShow({ state: true, message: error.message })

        }
    }
    const payViaSettleInvoice = async () => {
        if (initialBalance === 0) {
            return setErrorShow({ state: true, message: "Please fill in  amount  field" })
        } else {

            const response = await fetch(baseUrl + `/api/v1/payments/generalpayment`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    "X-Authorization": localStorage.getItem("X-Authorization"),
                },
                body: JSON.stringify({
                    amount: settleInvoiceAmount || Math.ceil(initialBalance),
                    referenceNo: settleInvoiceRefNo,
                    businessNumber: businessNumberID,
                    invoiceNumber: invoiceNumber,
                    paymentMethod: selectedSettleInvoiceMethod,
                    paymentChanel: 'Web'
                })
            })
            await response.json()
                .then((response) => {
                    if (response.status === "Success") {
                        setSuccessNotificationReceivedPaymentOpen(true)
                        setTimeout(() => {
                            setSuccessNotificationReceivedPaymentOpen(false)
                            getInvoiceDetails()
                            navigate(`/school/invoice/${invoiceNumber}`)
                        }, 3000)
                    } else {
                        setErrorShow({ state: true, message: response.message })
                        setTimeout(() => {
                            setErrorShow({ state: false, message: "" })
                        }, 3000)
                    }
                })
        }
    }
    const handleChangeInCustomerInfo = (e) => {
        setCustomerInfo({ ...customerInfo, [e.target.name]: e.target.value })
    }

    return (
        <div style={{}}>
            <SuccessAlert message={'You will receive a prompt on your phone'} open={successNotificationOpen} onClose={handleSuccessNotificationClose} horizontal={'right'} vertical={'top'} />
            <SuccessAlert message={'Payment link generated successfully'} open={successNotificationOpen1} onClose={handleSuccessNotificationClose1} horizontal={'right'} vertical={'top'} />
            <SuccessAlert message={'Link copied successfully'} open={successNotificationOpen2} onClose={handleSuccessNotificationClose2} horizontal={'right'} vertical={'top'} />
            <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...SuccessShow, state: false })} open={SuccessShow.state} message={SuccessShow.message} />
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            <SuccessAlert message={`Payment received`} open={successNotificationReceivedPaymentOpen} onClose={handleSuccessNotificationReceivedPaymentClose} horizontal={'right'} vertical={'top'} />
            <ErrorAlert message={`Error With Stk`} open={errorNotificationOpen} onClose={handleErrorNotificationClose} horizontal={'right'} vertical={'top'} />
            <ErrorAlert message={`Error With Sending the payment`} open={errorNotificationOpen1} onClose={handleErrorNotificationClose1} horizontal={'right'} vertical={'top'} />
            <ErrorAlert message={`Timeout On Awaiting Payment`} open={errorNotificationTimeoutOpen} onClose={handleErrorNotificationTimeoutClose} horizontal={'right'} vertical={'top'} />
            <ErrorAlert message={`Missing Mpesa STK Config Id!`} open={errorNotificationNoBusinessConfigOpen} onClose={handleErrorNotificationNoBusinessConfigClose} horizontal={'right'} vertical={'top'} />
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            <Grid container direction={isMobileUI ? "column" : 'row'} justifyContent={isMobileUI ? null : 'space-between'} sx={{ marginLeft: "5%" }}>
                <Grid style={{ width: isMobileUI ? "100%" : '30%' }} item>
                    <Grid container mb={2} mt={2}>
                        <Grid item>
                            <span style={{ color: '#032541', fontSize: '20px', fontWeight: '600' }} >Select Payment Method</span>
                        </Grid>
                    </Grid>
                    <Grid container direction={'column'} justifyContent={'flex-start'} spacing={7}>
                        {MpesaStatus && defaultCurrency !== "NGN" ?
                            <Grid item>
                                <ButtonBase onClick={() => { handleButtonClick('mpesa') }}>
                                    <Card style={{ background: `${activeButton === 'mpesa' ? 'rgba(23, 174, 123, 0.06)' : '#fff'}`, height: '94px', width: '350px', boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.16)', borderRadius: '6px' }}>
                                        <Grid container direction={'row'} justifyContent={'flex-start'} alignContent={'center'} spacing={-2}>
                                            <Grid item>
                                                <img src={MpesaIcon} alt="Mpesa" style={{ height: "40px", width: "100px", marginTop: "30px", marginLeft: '15px' }} />
                                            </Grid>
                                            <Grid item style={{ marginTop: "30px" }}>
                                                <span style={{ color: '#032541', marginLeft: '15px', fontSize: '18px', fontWeight: 600 }}>Mpesa</span>
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </ButtonBase>
                            </Grid>
                            : null}
                        {KCBBankPaybillStatus && defaultCurrency !== "NGN" ?
                            <Grid item>
                                <ButtonBase onClick={() => handleButtonClick('bankpaybill')}>
                                    <Card style={{ background: activeButton === 'bankpaybill' ? '#f0f2f3' : '#fff', height: '120px', width: isMobileUI ? '345px' : '355px', boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.16)', borderRadius: '6px', display: 'flex', alignItems: 'center', padding: '10px' }} >
                                        <Grid container alignItems="center">
                                            <Grid item>
                                                <img src={MobileBankIcon} alt="BankPayBillIcon" style={{ height: '60px', width: '100px', marginLeft: '10px' }} />
                                            </Grid>
                                            <Grid item>
                                                <span style={{ color: '#032541', marginLeft: '15px', fontSize: '18px', fontWeight: 600 }}>
                                                    Mobile to Bank
                                                </span>
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </ButtonBase>
                            </Grid>
                            : null}
                        {momoStatus && defaultCurrency !== "NGN" ?
                            <Grid item mt={2}>
                                <ButtonBase onClick={() => { handleButtonClick('MTN Momo') }}>
                                    <Card style={{ background: `${activeButton === 'MTN Momo' ? '#f0f2f3' : '#fff'}`, height: '94px', width: isMobileUI ? '345px' : "355px", boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.16)', borderRadius: '6px' }}>
                                        <Grid container direction={'row'} justifyContent={'flex-start'} alignContent={'center'} spacing={-2}>
                                            <Grid item>
                                                <img src={MomoIcon} alt="Mpesa" style={{ height: "32px", width: "100px", marginTop: "30px", marginLeft: '10px' }} />
                                            </Grid>
                                            <Grid item style={{ marginTop: "30px" }}>
                                                <span style={{ color: '#032541', marginLeft: '15px', fontSize: '18px', fontWeight: 600 }}>MTN Momo</span>
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </ButtonBase>
                            </Grid>
                            : null}
                        {CardStatus ?
                            <Grid item>
                                <ButtonBase onClick={() => { handleButtonClick('card') }}>
                                    <Card style={{ background: `${activeButton === 'card' ? '#f0f2f3' : '#fff'}`, height: '94px', width: isMobileUI ? '345px' : "355px", boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.16)', borderRadius: '6px' }}>
                                        <Grid container direction={'row'} justifyContent={'flex-start'} alignContent={'center'} spacing={-2}>
                                            <Grid item>
                                                <img src={CardIcon} alt="Mpesa" style={{ height: "32px", width: "100px", marginTop: "30px", marginLeft: '10px' }} />
                                            </Grid>
                                            <Grid item style={{ marginTop: "30px" }}>
                                                <span style={{ color: '#032541', marginLeft: '15px', fontSize: '18px', fontWeight: 600 }}>Card</span>
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </ButtonBase>
                            </Grid>
                            : null}
                        {KCBBankDeposit && KCBBankPaybillStatus && MpesaStatus && CardStatus && userGroup === "Merchant" || userGroup === "Owner" ?
                            <Grid item>
                                <span>Request Payment</span>
                            </Grid>
                            :
                            !KCBBankDeposit && !KCBBankPaybillStatus && !momoStatus && !MpesaStatus && !CardStatus && userGroup === 'Customer' ?
                                <Grid item>
                                    <span style={{ textTransform: "uppercase" }}>NO PAYMENT ACTIVATED KINDLY CONTACT THE  {localStorage.getItem("businessName")}</span>
                                </Grid>
                                : null}
                        {KCBBankDeposit ?
                            <>
                                <Grid item>
                                    <ButtonBase onClick={() => { handleButtonClick('bankSlip') }}>
                                        <Card style={{ background: `${activeButton === 'bankSlip' ? '#f0f2f3' : '#fff'}`, height: '94px', width: isMobileUI ? '345px' : "355px", boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.16)', borderRadius: '6px' }}>
                                            <Grid container direction={'row'} justifyContent={'flex-start'} alignContent={'center'} spacing={-2}>
                                                {/*Card image*/}
                                                <Grid item>
                                                    <img src={BankSlipIcon} alt="Mpesa" style={{ height: "32px", width: "100px", marginTop: "30px", marginLef: '0px' }} />
                                                </Grid>
                                                {/*Card name*/}
                                                <Grid item style={{ marginTop: "30px" }}>
                                                    <span style={{ color: '#032541', marginLeft: '15px', fontSize: '18px', fontWeight: 600 }}>Bank Slip</span>
                                                </Grid>
                                            </Grid>
                                        </Card>
                                    </ButtonBase>
                                </Grid>
                            </>
                            : ectizenStatus ? <Grid item>
                                <ButtonBase onClick={() => { handleButtonClick('ecitizen') }}>
                                    <Card style={{ background: `${activeButton === 'ecitizen' ? '#f0f2f3' : '#fff'}`, height: '94px', width: isMobileUI ? '345px' : "355px", boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.16)', borderRadius: '6px' }}>
                                        <Grid container direction={'row'} justifyContent={'flex-start'} alignContent={'center'} spacing={-2}>
                                            {/*Card image*/}
                                            <Grid item>
                                                <img src={EcitizenIcon} alt="EcitizenPng" style={{ height: "32px", width: "100px", marginTop: "30px", marginLef: '0px', objectFit: "contain" }} />
                                            </Grid>
                                            {/*Card name*/}
                                            <Grid item style={{ marginTop: "30px" }}>
                                                <span style={{ color: '#032541', marginLeft: '15px', fontSize: '18px', fontWeight: 600 }}>Ecitizen</span>
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </ButtonBase>
                            </Grid>
                                : null}
                    </Grid>
                    <Grid container mt={3} direction={'column'}>
                        {
                            (localCurrency !== "NGN") && <Grid item mb={2} mt={2}>
                                <Typography variant="h6" style={{ color: '#032541', fontSize: '20px', fontWeight: '600' }}>Receive Payment</Typography>
                            </Grid>
                        }
                        {userGroup === 'Customer' || (localCurrency === "NGN") || (businessCat === 'Distributor' && foundbid) ? null :
                            <Grid item mb={2}>
                                <ButtonBase onClick={() => { handleButtonClick('uploadeBankSlips') }}>
                                    <Card style={{ background: `${activeButton === 'cash' ? '#f0f2f3' : '#fff'}`, height: '94px', width: isMobileUI ? '345px' : "355px", boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.16)', borderRadius: '6px' }}>
                                        <Grid container direction={'row'} justifyContent={'flex-start'} alignContent={'center'} spacing={-2}>
                                            <Grid item>
                                                <img src={UploadStatement} alt="Mpesa" style={{ height: "32px", width: "100px", marginTop: "30px" }} />
                                            </Grid>
                                            <Grid item style={{ marginTop: "30px" }}>
                                                <span style={{ color: '#032541', marginLeft: '15px', fontSize: '16px', fontWeight: 600 }}>Uploaded Bank Statements</span>
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </ButtonBase>
                            </Grid>}
                        {isSettleInvoiceEbaled && userGroup !== 'Customer' ?
                            <Grid item mb={2}>
                                <ButtonBase onClick={() => { handleButtonClick('settleInvoice') }}>
                                    <Card style={{ background: `${activeButton === 'cash' ? '#f0f2f3' : '#fff'}`, height: '94px', width: isMobileUI ? '345px' : "355px", boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.16)', borderRadius: '6px' }}>
                                        <Grid container direction={'row'} justifyContent={'flex-start'} alignContent={'center'} alignItems={'center'} spacing={-2}>
                                            <Grid item>
                                                <img src={SettleInvoice} alt="Mpesa" style={{ height: "32px", width: "100px", marginTop: "30px", marginLef: '0px' }} />
                                            </Grid>
                                            <Grid item style={{ marginTop: "30px" }}>
                                                <span style={{ color: '#032541', marginLeft: '15px', fontSize: '16px', fontWeight: 600 }}>Settle Invoice</span>
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </ButtonBase>
                            </Grid> : null}
                        {CashStatus && (localCurrency !== "NGN") && userGroup !== "Customer" ?
                            <Grid item>
                                <ButtonBase onClick={() => { handleButtonClick('cash') }}>
                                    <Card style={{ background: `${activeButton === 'cash' ? '#f0f2f3' : '#fff'}`, height: '94px', width: isMobileUI ? '345px' : "355px", boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.16)', borderRadius: '6px' }}>
                                        <Grid container direction={'row'} justifyContent={'flex-start'} alignContent={'center'} spacing={-2}>
                                            <Grid item>
                                                <img src={CashIcon} alt="Mpesa" style={{ height: "32px", width: "100px", marginTop: "30px", marginLef: '0px' }} />
                                            </Grid>
                                            <Grid item style={{ marginTop: "30px" }}>
                                                <span style={{ color: '#032541', marginLeft: '15px', fontSize: '18px', fontWeight: 600 }}>Cash</span>
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </ButtonBase>
                            </Grid>
                            : null}
                    </Grid>
                </Grid>
                {activeButton === 'mpesa' ?
                    <Grid item style={{ background: 'rgba(23, 174, 123, 0.06)', width: '60%', display: 'flex', flexShrink: 1, marginRight: '2.5%' }}>
                        <Grid container direction={'column'} ml={1} spacing={3}>
                            <Grid item mt={5} >
                                <span style={{ fontStyle: '16px', fontWeight: '600', color: '#032541' }} >Pay invoice-Mpesa</span>
                            </Grid>
                            <Grid item>
                                <Grid container direction={'row'} spacing={2}>
                                    <Grid item style={{ width: '70%' }}>
                                        <Grid container direction={'column'} spacing={0.5} style={{ borderRadius: '10px', background: 'rgba(112, 112, 112, 0.05)' }}>
                                            <Grid item>
                                                <span style={{ color: '#707070', fontSize: '14px' }}>Invoice No:</span>
                                            </Grid>
                                            <Grid item>
                                                <span style={{ color: '#032541', fontWeight: 600, fontSize: '18px' }}>{isInvoiceBatch ? batchData?.batchNumber : batchNumber}</span>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item style={{ width: '30%' }}>
                                        <Grid container direction={'column'} spacing={0.5} style={{ borderRadius: '10px' }}>
                                            <Grid item>
                                                <span style={{ color: '#707070', fontSize: '14px' }}>Invoice Balance:</span>
                                            </Grid>
                                            <Grid item>
                                                <span style={{ color: '#dc3545', fontWeight: 600, fontSize: '18px' }}>{numberFormat(Math.ceil(amount))}</span>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                </Grid>

                            </Grid>
                            <Grid item mt={-2}>
                                <span style={{ fontSize: '14px' }} >Kindly enter your mobile number to receive the payment prompt and enter you M-Pesa Pin.</span>
                            </Grid>
                            <Grid item>
                                <div style={{ marginBottom: '10px' }}>
                                    <span style={{ fontSize: '12px', color: '#032541' }}>Mobile no.</span>
                                </div>
                                <PhoneInput required inputStyle={{ height: '55px', width: '500px' }} country={"ke"} enableSearch={true} value={userPhone} onChange={(phone) => setUserPhone(phone)} />
                            </Grid>

                            {/*Amount Input*/}
                            <Grid item>
                                <div style={{ marginBottom: '10px' }}>
                                    <span style={{ fontSize: '12px', color: '#032541' }}>Amount.</span>
                                </div>
                                <TextField defaultValue={Math.ceil(amount)} onChange={(e) => { setPaymentAmount(e.target.value) }} type='number' InputProps={{ style: { fontFamily: 'Poppins', background: '#fff' } }} InputLabelProps={{ style: { fontFamily: 'Poppins', fontSize: "13px", color: "#032541" } }} style={{ width: '500px', border: "solid 0px #e4e4e4", fontSize: "12px" }} id="outlined-basic" label="Amount" variant="outlined" required />
                            </Grid>

                            {/*Send prompt button*/}
                            <Grid item mt={10} mb={9}>
                                <Grid container justifyContent={'center'} alignContent={'center'}>
                                    <Grid item>
                                        {mpesaLoading ?
                                            <>
                                                <LoadingButton sx={{ width: "407px", backgroundColor: "#34A353", color: "white", '&:hover': { backgroundColor: '#34A353', color: 'white' } }} endIcon={<SendIcon />} loading={true} loadingPosition="end" variant="contained">
                                                    <span>Awaiting Payment</span>
                                                </LoadingButton>
                                                <Box sx={{ display: "flex", textAlign: "center", justifyContent: "center", width: "100%" }}>
                                                    <Typography sx={{ alignSelf: "center", marginLeft: "auto", marginRight: "auto" }}>
                                                        Resend Prompt: {counter}
                                                    </Typography>
                                                </Box>
                                            </>

                                            :
                                            <>
                                                <Button onClick={() => { handleClick(invoiceNumber) }} style={{ width: '407px', background: "#032541", textTransform: 'none', color: '#fff', fontWeight: 'bold', fontSize: '14px' }} >Pay Now</Button>
                                            </>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid> : activeButton === 'card' ?
                        <Grid item style={{ background: 'rgba(23, 174, 123, 0.06)', width: isMobileUI ? "95%" : '60%', display: 'flex', flexShrink: 1, marginRight: '2.5%' }}>
                            <Grid container direction={'column'} ml={1} spacing={3}>
                                {GenerteLink ? <>
                                    <div className="pt-5 mt-5">
                                        <div className="div-card-ready" style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                                            <img src={CARDREADY} alt="" />
                                            <div className="pt-3">
                                                <h5 style={{ fontSize: "20px", color: "#032541", textAlign: "center", fontWeight: "bold" }}>Your Payment Link is Ready</h5>
                                                <h4 style={{ fontSize: "14px", color: "#707070", textAlign: "center" }}>Open or Copy your payment link for </h4>
                                                <h4 style={{ fontSize: "14px", color: "#032541", textAlign: "center" }}>Invoice NO: {invoiceNumber}</h4>
                                                <h4 style={{ fontSize: "16px", color: "#dc3545", textAlign: "center" }}>Balance  {numberFormat(invoiceBalance)} </h4>
                                                <input style={{ display: "", opacity: "0" }} ref={textRef} type="text" value={dataLINK} readOnly />
                                                <div className="button-group" style={{ display: "flex", justifyContent: "center", alignContent: "center" }}>
                                                    <Button style={{ height: "40px", marginTop: "19px", padding: "0px 20px", fontSize: "16px", fontWeight: "500", color: "#032541", border: "2px solid #032541", }} onClick={handleCopy} >Copy Link</Button>
                                                    <Button style={{ height: "40px", marginTop: "19px", padding: "0px 20px", fontSize: "16px", fontWeight: "500", color: "#fff", backgroundColor: "#032541", border: "2px solid #032541" }} className="ml-3" onClick={() => {
                                                        window.location.assign(dataLINK)
                                                    }} >Open Link</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </> : <>
                                    <>
                                        <Grid item mt={5} >
                                            <span style={{ fontStyle: '16px', fontWeight: '600', color: '#032541' }} >Pay invoice-Card</span>
                                        </Grid>
                                        <Grid item>
                                            <Grid container direction={isMobileUI ? "column" : 'row'} spacing={2}>
                                                <Grid item style={{ width: isMobileUI ? '90%' : '70%' }}>
                                                    <Grid container direction={'column'} spacing={0.5} style={{ borderRadius: '10px', background: 'rgba(112, 112, 112, 0.05)' }}>
                                                        <Grid item>
                                                            <span style={{ color: '#707070', fontSize: '14px' }}>Invoice No:</span>
                                                        </Grid>
                                                        <Grid item>
                                                            <span style={{ color: '#032541', fontWeight: 600, fontSize: '18px' }}>{invoiceNumber}</span>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item style={{ width: isMobileUI ? "90%" : '30%' }}>
                                                    <Grid container direction={'column'} spacing={0.5} style={{ borderRadius: '10px' }}>
                                                        <Grid item>
                                                            <span style={{ color: '#707070', fontSize: '14px' }}>Invoice Balance:</span>
                                                        </Grid>
                                                        <Grid item>
                                                            <span style={{ color: '#dc3545', fontWeight: 600, fontSize: '18px' }}>{numberFormat(invoiceBalance)}</span>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item mt={-2}>
                                        </Grid>

                                        <Grid item>
                                            <div style={{ marginBottom: '20px', }}>
                                                <span style={{ fontSize: '12px', color: '#032541' }}>EMAIL ADDRESS.</span>
                                            </div>
                                            <TextField required InputProps={{ style: { fontFamily: 'Poppins', background: '#fff' } }} InputLabelProps={{ style: { fontFamily: 'Poppins', fontSize: "13px", color: "#032541" } }} style={{ width: isMobileUI ? "300px" : '500px', border: "solid 0px #e4e4e4", fontSize: "12px" }} value={customerInfo?.email} disabled id="outlined-basic" label="Email Address" variant="outlined" />


                                            {/* <TextField required InputProps={{ style: { fontFamily: 'Poppins', background: '#fff' } }} InputLabelProps={{ style: { fontFamily: 'Poppins', fontSize: "13px", color: "#032541" } }} style={{ width: '500px', border: "solid 0px #e4e4e4", fontSize: "12px" }} value={email} onChange={(e) => setEmailAddress(e.target.value)} id="outlined-basic" label="Email Address" variant="outlined" /> */}
                                        </Grid>

                                        {/*Amount Input*/}
                                        <Grid item>
                                            <div style={{ marginBottom: '10px' }}>
                                                <span style={{ fontSize: '12px', color: '#032541' }}>Amount.</span>
                                            </div>
                                            <TextField defaultValue={invoiceBalance} onChange={(e) => { setCardAmout(e.target.value) }} type='number' InputProps={{ style: { fontFamily: 'Poppins', background: '#fff' } }} InputLabelProps={{ style: { fontFamily: 'Poppins', fontSize: "13px", color: "#032541" } }} style={{ width: isMobileUI ? "300px" : '500px', border: "solid 0px #e4e4e4", fontSize: "12px" }} id="outlined-basic" label="Amount" variant="outlined" required />
                                        </Grid>

                                        {/*Send prompt button*/}
                                        <Grid item mt={10} mb={9}>
                                            <Grid container justifyContent={'center'} alignContent={'center'}>
                                                <Grid item>
                                                    {/* 
                                                {GenerteLink ? <>
                                                    <Button style={{ width: '407px', background: "#032541", textTransform: 'none', color: '#fff', fontWeight: 'bold', fontSize: '14px' }} >CLICK HERE TO PAY</Button>

                                                </> : <>
                                                <Button onClick={() => { handleCardPayment(invoiceNumber) }} style={{ width: '407px', background: "#032541", textTransform: 'none', color: '#fff', fontWeight: 'bold', fontSize: '14px' }} >Generate  Link</Button>

                                                </>} */}

                                                    {Cardloading ?
                                                        <>
                                                            <LoadingButton sx={{ width: "407px", backgroundColor: "#34A353", color: "white", '&:hover': { backgroundColor: '#34A353', color: 'white' } }}
                                                                endIcon={<SendIcon />}
                                                                loading={true}
                                                                loadingPosition="end"
                                                                variant="contained">
                                                                <span>Awaiting </span>
                                                            </LoadingButton>
                                                            <Box sx={{ display: "flex", textAlign: "center", justifyContent: "center", width: "100%" }}>
                                                                <Typography sx={{ alignSelf: "center", marginLeft: "auto", marginRight: "auto" }}>
                                                                    Generating Link: {counter}
                                                                </Typography>
                                                            </Box>
                                                        </>

                                                        :
                                                        <>
                                                            {/* {GenerateLinkStatus  ?  <div className="d-flex">
                                                        <div className="d-flex justify-content-space-between">
                                                            <Link to={`${dataLINK}`}>
                                                        
                                                        <Button  style={{ width: '300px', background: "#032541", textTransform: 'none', color: '#fff', fontWeight: 'bold', fontSize: '14px' }} >Open LINK</Button>
                                                        </Link>
                                                        <Button onClick={() => { handleCardPayment(invoiceNumber) }} className="ml-2" style={{ width: '300px', background: "#032541", textTransform: 'none', color: '#fff', fontWeight: 'bold', fontSize: '14px' }} >Generate Link</Button>

                                                        </div>
                                                    </div> :
                                                <>
                                                <Button onClick={() => { handleCardPayment(invoiceNumber) }} style={{ width: '407px', background: "#032541", textTransform: 'none', color: '#fff', fontWeight: 'bold', fontSize: '14px' }} >Generate Link</Button> </> */}
                                                            {/* } */}

                                                            <Button onClick={() => { handleCardPayment(invoiceNumber) }} style={{ width: isMobileUI ? "250px" : '407px', background: "#032541", textTransform: 'none', color: '#fff', fontWeight: 'bold', fontSize: '14px' }} >Generate Link</Button>

                                                        </>
                                                    }

                                                </Grid>
                                            </Grid>

                                        </Grid>
                                    </>
                                </>}
                            </Grid>

                        </Grid> : activeButton === 'MTN Momo' ? <>
                            <Grid item style={{ background: 'rgba(23, 174, 123, 0.06)', width: '60%', display: 'flex', flexShrink: 1, marginRight: '2.5%' }}>

                                <Grid container direction={'column'} ml={1} spacing={3}>

                                    {/*Header lable*/}
                                    <Grid item mt={5} >
                                        <span style={{ fontStyle: '16px', fontWeight: '600', color: '#032541' }} >Pay invoice-MTN Momo</span>
                                    </Grid>

                                    {/*Invoice card Information*/}
                                    <Grid item>

                                        <Grid container direction={'row'} spacing={2}>
                                            <Grid item style={{ width: '70%' }}>
                                                {/*PlyCard*/}
                                                <Grid container direction={'column'} spacing={0.5} style={{ borderRadius: '10px', background: 'rgba(112, 112, 112, 0.05)' }}>
                                                    <Grid item>
                                                        <span style={{ color: '#707070', fontSize: '14px' }}>Invoice No:</span>
                                                    </Grid>
                                                    <Grid item>
                                                        <span style={{ color: '#032541', fontWeight: 600, fontSize: '18px' }}>{invoiceNumber}</span>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item style={{ width: '30%' }}>
                                                {/*Amount*/}
                                                <Grid container direction={'column'} spacing={0.5} style={{ borderRadius: '10px' }}>
                                                    <Grid item>
                                                        <span style={{ color: '#707070', fontSize: '14px' }}>Invoice Balance:</span>
                                                    </Grid>
                                                    <Grid item>
                                                        <span style={{ color: '#dc3545', fontWeight: 600, fontSize: '18px' }}>{numberFormat(Math.ceil(initialBalance))}</span>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                        </Grid>

                                    </Grid>

                                    {/*Mpesa Instruction*/}
                                    <Grid item mt={-2}>
                                        <span style={{ fontSize: '14px' }} >Kindly enter your mobile number to receive the payment prompt and enter you  Pin.</span>
                                    </Grid>

                                    {/*Mobile input*/}
                                    <Grid item>
                                        <div style={{ marginBottom: '10px' }}>
                                            <span style={{ fontSize: '12px', color: '#032541' }}>Mobile no.</span>
                                        </div>

                                        <PhoneInput required inputStyle={{ height: '55px', width: '500px' }} country={"ug"} enableSearch={true} value={userPhone} onChange={(phone) => setUserPhone(phone)} />
                                    </Grid>

                                    {/*Amount Input*/}
                                    <Grid item>
                                        <div style={{ marginBottom: '10px' }}>
                                            <span style={{ fontSize: '12px', color: '#032541' }}>Amount.</span>
                                        </div>
                                        <TextField defaultValue={Math.ceil(initialBalance)} onChange={(e) => { setPaymentAmount(e.target.value) }} type='number' InputProps={{ style: { fontFamily: 'Poppins', background: '#fff' } }} InputLabelProps={{ style: { fontFamily: 'Poppins', fontSize: "13px", color: "#032541" } }} style={{ width: '500px', border: "solid 0px #e4e4e4", fontSize: "12px" }} id="outlined-basic" label="Amount" variant="outlined" required />
                                    </Grid>

                                    {/*Send prompt button*/}
                                    <Grid item mt={10} mb={9}>
                                        <Grid container justifyContent={'center'} alignContent={'center'}>
                                            <Grid item>
                                                {mpesaLoading ?
                                                    <>
                                                        <LoadingButton sx={{ width: "407px", backgroundColor: "#34A353", color: "white", '&:hover': { backgroundColor: '#34A353', color: 'white' } }}
                                                            endIcon={<SendIcon />}
                                                            loading={true}
                                                            loadingPosition="end"
                                                            variant="contained">
                                                            <span>Awaiting Payment</span>
                                                        </LoadingButton>
                                                        <Box sx={{ display: "flex", textAlign: "center", justifyContent: "center", width: "100%" }}>
                                                            <Typography sx={{ alignSelf: "center", marginLeft: "auto", marginRight: "auto" }}>
                                                                Resend Prompt: {counter}
                                                            </Typography>
                                                        </Box>
                                                    </>

                                                    :
                                                    <>
                                                        <Button onClick={() => { handleMtnMomoPay(invoiceNumber) }} style={{ width: '407px', background: "#032541", textTransform: 'none', color: '#fff', fontWeight: 'bold', fontSize: '14px' }} >Pay Now</Button>
                                                    </>
                                                }
                                            </Grid>
                                        </Grid>

                                    </Grid>

                                </Grid>

                            </Grid>
                        </> : activeButton === 'bankpaybill' ?
                            <>
                                <Grid item style={{ background: 'rgba(23, 174, 123, 0.06)', width: isMobileUI ? "90%" : '60%', display: 'flex', flexShrink: 1, marginRight: '2.5%' }}>

                                    <Grid container direction={'column'} ml={1} spacing={3}>

                                        {/*Header lable*/}
                                        <Grid item mt={5} >
                                            <span style={{ fontStyle: '16px', fontWeight: '600', color: '#032541' }} >{isInvoiceBatch ? "Pay Invoice Batch via Bank Paybill" : "Pay invoice via Bank Paybill"}</span>
                                        </Grid>
                                        <Grid item>
                                            <Grid container direction={isMobileUI ? "column" : 'row'} spacing={2}>
                                                <Grid item style={{ width: isMobileUI ? "80%" : '70%' }}>
                                                    <Grid container direction={'column'} spacing={isMobileUI ? 0 : 0.5} style={{ width: isMobileUI ? "280px" : "500px", borderRadius: '10px', background: 'rgba(112, 112, 112, 0.05)' }}>
                                                        <Grid item>
                                                            <span style={{ color: '#707070', fontSize: '14px' }}>Invoice No:</span>
                                                        </Grid>
                                                        <Grid item>
                                                            <span style={{ color: '#032541', fontWeight: 600, fontSize: '18px' }}>{isInvoiceBatch ? batchData.batchNumber : batchNumber}</span>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item style={{ width: isMobileUI ? "100%" : '30%' }}>
                                                    <Grid container direction={'column'} spacing={0.5} style={{ borderRadius: '10px' }}>
                                                        <Grid item>
                                                            <span style={{ color: '#707070', fontSize: '14px' }}>Invoice Balance:</span>
                                                        </Grid>
                                                        <Grid item>
                                                            <span style={{ color: '#dc3545', fontWeight: 600, fontSize: '18px' }}>{numberFormat(Math.ceil(amount))}</span>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                            </Grid>

                                        </Grid>
                                        <Grid item>
                                            <div style={{ marginBottom: '10px' }}>
                                                <span style={{ fontSize: '12px', color: '#032541' }}>Bank </span>
                                            </div>
                                            <TextField select label="Select Bank Name" id="demo-select-small" style={{ height: "55px", width: isMobileUI ? "280px" : "500px", borderRadius: "1px", fontFamily: "Poppins", }}
                                                defaultValue={bankAccount}
                                                onChange={(e) => setBankAccount(e.target.value)}
                                                required>
                                                {availableBanks?.map((bank, index) => {
                                                    return <MenuItem key={index} value={bank?.name}>{bank?.name}</MenuItem>
                                                })}

                                            </TextField>
                                        </Grid>
                                        <Grid item>
                                            <div style={{ marginBottom: '10px' }}>
                                                <span style={{ fontSize: '12px', color: '#032541' }}>Mobile no.</span>
                                            </div>

                                            <PhoneInput required inputStyle={{ height: '55px', width: isMobileUI ? "280px" : '500px' }} country={"ke"} enableSearch={true} value={kcbphone} onChange={(kcbphone) => setkcbphone(kcbphone)} />
                                        </Grid>

                                        {/*Amount Input*/}
                                        <Grid item>
                                            <div style={{ marginBottom: '10px' }}>
                                                <span style={{ fontSize: '12px', color: '#032541' }}>Amount.</span>
                                            </div>
                                            <TextField defaultValue={Math.ceil(amount)} onChange={(e) => { setKcbAmount(e.target.value) }} type='number' InputProps={{ style: { fontFamily: 'Poppins', background: '#fff' } }} InputLabelProps={{ style: { fontFamily: 'Poppins', fontSize: "13px", color: "#032541" } }} style={{ width: isMobileUI ? "280px" : '500px', border: "solid 0px #e4e4e4", fontSize: "12px" }} id="outlined-basic" label="Amount" variant="outlined" required />
                                        </Grid>

                                        {/*Send prompt button*/}
                                        <Grid item mt={10} mb={9}>
                                            <Grid container justifyContent={'center'} alignContent={'center'}>
                                                <Grid item>
                                                    {kcbloading ?
                                                        <>
                                                            <LoadingButton sx={{ width: isMobileUI ? "200px" : '407px', backgroundColor: "#34A353", color: "white", '&:hover': { backgroundColor: '#34A353', color: 'white' } }}
                                                                endIcon={<SendIcon />}
                                                                loading={true}
                                                                loadingPosition="end"
                                                                variant="contained">
                                                                <span>Awaiting Payment</span>
                                                            </LoadingButton>
                                                            <Box sx={{ display: "flex", textAlign: "center", justifyContent: "center", width: "100%" }}>
                                                                <Typography sx={{ alignSelf: "center", marginLeft: "auto", marginRight: "auto" }}>
                                                                    Resend Prompt: {counter}
                                                                </Typography>
                                                            </Box>
                                                        </>

                                                        :
                                                        <>
                                                            <Button onClick={() => { handleKcbPush(invoiceNumber) }} style={{ width: isMobileUI ? "200px" : '407px', background: "#032541", textTransform: 'none', color: '#fff', fontWeight: 'bold', fontSize: '14px' }} >Send Prompt</Button>
                                                        </>
                                                    }
                                                </Grid>
                                            </Grid>

                                        </Grid>

                                    </Grid>

                                </Grid>
                            </> : activeButton === 'bankSlip' ? <>
                                <Grid item style={{ background: 'rgba(23, 174, 123, 0.06)', width: '60%', display: 'flex', flexShrink: 1, marginRight: '2.5%' }}>

                                    <Grid container direction={'column'} ml={1} spacing={3}>

                                        {/*Header lable*/}
                                        <Grid item mt={5} >
                                            <span style={{ fontStyle: '16px', fontWeight: '600', color: '#032541' }} >Bank Slip Validation</span>
                                        </Grid>

                                        {/*Invoice card Information*/}
                                        <Grid item>

                                            <Grid container direction={'row'} spacing={2}>
                                                <Grid item style={{ width: '70%' }}>
                                                    {/*PlyCard*/}
                                                    <Grid container direction={'column'} spacing={0.5} style={{ borderRadius: '10px', background: 'rgba(112, 112, 112, 0.05)' }}>
                                                        <Grid item>
                                                            <span style={{ color: '#707070', fontSize: '14px' }}>Invoice No:</span>
                                                        </Grid>
                                                        <Grid item>
                                                            <span style={{ color: '#032541', fontWeight: 600, fontSize: '18px' }}>{invoiceNumber}</span>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item style={{ width: '30%' }}>
                                                    {/*Amount*/}
                                                    <Grid container direction={'column'} spacing={0.5} style={{ borderRadius: '10px' }}>
                                                        <Grid item>
                                                            <span style={{ color: '#707070', fontSize: '14px' }}>Invoice Balance:</span>
                                                        </Grid>
                                                        <Grid item>
                                                            <span style={{ color: '#dc3545', fontWeight: 600, fontSize: '18px' }}>{numberFormat(Math.ceil(invoiceBalance))}</span>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                            </Grid>

                                        </Grid>
                                        <Grid item>
                                            <div style={{ marginBottom: '20px', }}>
                                                <span style={{ fontSize: '12px', color: '#032541' }}>Select    Banks </span>
                                            </div>
                                            {console.log(bankAccountsKCB, "bankAccountsKCB")}
                                            <TextField select label="Select Bank Name" id="demo-select-small" style={{ height: "55px", width: "500px", borderRadius: "1px", fontFamily: "Poppins", }}

                                                defaultValue={bankAccountKCB}
                                                onChange={(e) => setBankAccountKCB(e.target.value)}
                                                required>
                                                {bankAccountsKCB.map((option) => (
                                                    <MenuItem key={option.id} value={option.accountNumber} style={{ height: "40px", paddingLeft: "10px" }}>
                                                        {option.bankName} -  {option.accountNumber}
                                                    </MenuItem>
                                                ))}





                                            </TextField>
                                            {/* <CustomSelect required inputStyle={{ height: '55px', width: '500px' }} country={"ke"} enableSearch={true} value={bankAccountKCB} onChange={(bankAccountKCB) => setBankAccountKCB(bankAccountKCB)} /> */}
                                            {/* <TextField required InputProps={{ style: { fontFamily: 'Poppins', background: '#fff' } }} InputLabelProps={{ style: { fontFamily: 'Poppins', fontSize: "13px", color: "#032541" } }} style={{ width: '500px', border: "solid 0px #e4e4e4", fontSize: "12px" }} value={'EMAIL ADDRESS'} disabled id="outlined-basic" label="Email Address" variant="outlined" /> */}
                                        </Grid>
                                        <Grid item>
                                            <div style={{ marginBottom: '10px' }}>
                                                <span style={{ fontSize: '12px', color: '#032541' }}>Amount.</span>
                                            </div>
                                            <TextField defaultValue={referenceNo} onChange={(e) => { setReferenceNo(e.target.value) }} type='text' InputProps={{ style: { fontFamily: 'Poppins', background: '#fff' } }} InputLabelProps={{ style: { fontFamily: 'Poppins', fontSize: "13px", color: "#032541" } }} style={{ width: '500px', border: "solid 0px #e4e4e4", fontSize: "12px" }} id="outlined-basic" label="Reference number" variant="outlined" required />
                                        </Grid>

                                        {/*VALIDATE  SLIP*/}
                                        <Grid item mt={10} mb={9}>
                                            <Grid container justifyContent={'center'} alignContent={'center'}>
                                                <Grid item>
                                                    <Button onClick={() => { handleBankSlip(invoiceNumber) }} style={{ width: '407px', background: "#032541", textTransform: 'none', color: '#fff', fontWeight: 'bold', fontSize: '14px' }} >Validate Slip</Button>
                                                </Grid>
                                            </Grid>

                                        </Grid>
                                    </Grid>

                                </Grid>

                            </> : activeButton === "ecitizen" ?
                                <>
                                    <Grid item style={{ background: 'rgba(23, 174, 123, 0.06)', width: isMobileUI ? "90%" : '60%', display: 'flex', flexShrink: 1, marginRight: '2.5%' }}>
                                        <Grid container direction={'column'} ml={1} spacing={3}>
                                            <Grid item mt={5} >
                                                <span style={{ fontStyle: '16px', fontWeight: '600', color: '#032541' }} >Pay invoice-Ecitizen</span>
                                            </Grid>
                                            <Grid item>

                                                <Grid container direction={isMobileUI ? "column" : 'row'} spacing={2}>
                                                    <Grid item style={{ width: isMobileUI ? "80%" : '70%' }}>
                                                        {/*PlyCard*/}
                                                        <Grid container direction={'column'} spacing={0.5} style={{ width: isMobileUI ? "280px" : "500px", borderRadius: '10px', background: 'rgba(112, 112, 112, 0.05)' }}>
                                                            <Grid item>
                                                                <span style={{ color: '#707070', fontSize: '14px' }}>Invoice No:</span>
                                                            </Grid>
                                                            <Grid item>
                                                                <span style={{ color: '#032541', fontWeight: 600, fontSize: '18px' }}>{isInvoiceBatch ? batchData?.batchNumber : invoiceNumber}</span>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item style={{ width: '30%' }}>
                                                        {/*Amount*/}
                                                        <Grid container direction={'column'} spacing={0.5} style={{ width: isMobileUI ? "280px" : "500px", borderRadius: '10px', background: 'rgba(112, 112, 112, 0.05)' }}>
                                                            <Grid item>
                                                                <span style={{ color: '#707070', fontSize: '14px' }}>Invoice Balance:</span>
                                                            </Grid>
                                                            <Grid item>
                                                                <span style={{ color: '#dc3545', fontWeight: 600, fontSize: '18px' }}>{numberFormat(Math.ceil(initialBalance))}</span>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                </Grid>

                                            </Grid>
                                            <Grid item mt={-2}>
                                                <span style={{ fontSize: '14px' }} >Kindly note above details will be used to make Payment.</span>
                                            </Grid>
                                            <Grid item>
                                                <div style={{ marginBottom: '10px' }}>
                                                    <span style={{ fontSize: '12px', color: '#032541' }}>Customer Full Name.</span>
                                                </div>
                                                <TextField disabled defaultValue={customerInfo?.firstName + " " + customerInfo?.lastName} type='text' InputProps={{ style: { fontFamily: 'Poppins', background: '#fff' } }} InputLabelProps={{ style: { fontFamily: 'Poppins', fontSize: "13px", color: "#032541" } }} style={{ width: isMobileUI ? "280px" : '500px', border: "solid 0px #e4e4e4", fontSize: "12px" }} id="outlined-basic" label="Customer FullName" variant="outlined" required />
                                            </Grid>
                                            <Grid item>
                                                <div style={{ marginBottom: '10px' }}>
                                                    <span style={{ fontSize: '12px', color: '#032541' }}>Customer Phone Number</span>
                                                </div>
                                                <TextField disabled defaultValue={customerInfo?.phone} type='text' InputProps={{ style: { fontFamily: 'Poppins', background: '#fff' } }} InputLabelProps={{ style: { fontFamily: 'Poppins', fontSize: "13px", color: "#032541" } }} style={{ width: isMobileUI ? "280px" : '500px', border: "solid 0px #e4e4e4", fontSize: "12px" }} id="outlined-basic" label="Customer PhoneNumber" variant="outlined" required />
                                            </Grid>
                                            <Grid item>
                                                <div style={{ marginBottom: '10px' }}>
                                                    <span style={{ fontSize: '12px', color: '#032541' }}>Customer Email Address</span>
                                                </div>
                                                <TextField disabled defaultValue={customerInfo?.email} type='text' InputProps={{ style: { fontFamily: 'Poppins', background: '#fff' } }} InputLabelProps={{ style: { fontFamily: 'Poppins', fontSize: "13px", color: "#032541" } }} style={{ width: isMobileUI ? "280px" : '500px', border: "solid 0px #e4e4e4", fontSize: "12px" }} id="outlined-basic" label="Customer Email Address" variant="outlined" required />
                                            </Grid>

                                            <Grid item>
                                                <div style={{ marginBottom: '10px' }}>
                                                    <span style={{ fontSize: '12px', color: '#032541' }}>Customer ID Number</span>
                                                </div>
                                                {customerInfo?.id_number ? <>
                                                    <TextField name="id_number" disabled defaultValue={customerInfo?.id_number} type='text' InputProps={{ style: { fontFamily: 'Poppins', background: '#fff' } }} InputLabelProps={{ style: { fontFamily: 'Poppins', fontSize: "13px", color: "#032541" } }} style={{ width: isMobileUI ? "280px" : '500px', border: "solid 0px #e4e4e4", fontSize: "12px" }} id="outlined-basic" label="Customer ID Number" variant="outlined" onChange={handleChangeInCustomerInfo} required />

                                                </> : <>
                                                    <TextField name="idNumber" defaultValue={idNumber} type='text' InputProps={{ style: { fontFamily: 'Poppins', background: '#fff' } }} InputLabelProps={{ style: { fontFamily: 'Poppins', fontSize: "13px", color: "#032541" } }} style={{ width: isMobileUI ? "280px" : '500px', border: "solid 0px #e4e4e4", fontSize: "12px" }} id="outlined-basic" label="Customer ID Number" variant="outlined" onChange={(e) => setIdNumber(e.target.value)} required />

                                                </>}

                                            </Grid>
                                            <Grid item>
                                                <>
                                                    <Button onClick={() => { payEcitizen(invoiceNumber) }} style={{ width: isMobileUI ? "250px" : '407px', background: "#032541", textTransform: 'none', color: '#fff', fontWeight: 'bold', fontSize: '14px' }} >Pay Now</Button>
                                                </>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </>
                                : activeButton === 'cash' ? <>
                                    <Grid item style={{ background: 'rgba(23, 174, 123, 0.06)', width: '60%', display: 'flex', flexShrink: 1, marginRight: '2.5%' }}>
                                        <Grid container direction={'column'} ml={1} spacing={3}>
                                            <Grid item mt={5} >
                                                <span style={{ fontStyle: '16px', fontWeight: '600', color: '#032541' }} >Receive via Cash</span>
                                            </Grid>
                                            <Grid item>
                                                <Grid container direction={'row'} spacing={2}>
                                                    <Grid item style={{ width: '70%' }}>
                                                        <Grid container direction={'column'} spacing={0.5} style={{ borderRadius: '10px', background: 'rgba(112, 112, 112, 0.05)' }}>
                                                            <Grid item>
                                                                <span style={{ color: '#707070', fontSize: '14px' }}>{isInvoiceBatch ? 'Batch No' : 'Invoice No'}</span>
                                                            </Grid>
                                                            <Grid item>
                                                                <span style={{ color: '#032541', fontWeight: 600, fontSize: '18px' }}>{isInvoiceBatch ? batchData?.batchNumber : batchNumber}</span>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item style={{ width: '30%' }}>
                                                        {/*Amount*/}
                                                        <Grid container direction={'column'} spacing={0.5} style={{ borderRadius: '10px' }}>
                                                            <Grid item>
                                                                <span style={{ color: '#707070', fontSize: '14px' }}>Invoice Balance:</span>
                                                            </Grid>
                                                            <Grid item>
                                                                <span style={{ color: '#dc3545', fontWeight: 600, fontSize: '18px' }}>{numberFormat(Math.ceil(amount))}</span>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                </Grid>

                                            </Grid>

                                            <Grid item>
                                                <div style={{ marginBottom: '10px' }}>
                                                    <span style={{ fontSize: '12px', color: '#032541' }}>Amount.</span>
                                                </div>
                                                <TextField defaultValue={Math.ceil(amount)} onChange={(e) => { setCashAmount(e.target.value) }} type='number' InputProps={{ style: { fontFamily: 'Poppins', background: '#fff' } }} InputLabelProps={{ style: { fontFamily: 'Poppins', fontSize: "13px", color: "#032541" } }} style={{ width: '500px', border: "solid 0px #e4e4e4", fontSize: "12px" }} id="outlined-basic" label="Amount" variant="outlined" required />
                                            </Grid>
                                            <Grid item mt={10} mb={9}>
                                                <Grid container justifyContent={'center'} alignContent={'center'}>
                                                    <Grid item>
                                                        <Button onClick={() => { payViaCash(invoiceNumber) }} style={{ width: '407px', background: "#032541", textTransform: 'none', color: '#fff', fontWeight: 'bold', fontSize: '14px' }} >Process</Button>
                                                    </Grid>
                                                </Grid>

                                            </Grid>
                                        </Grid>

                                    </Grid>

                                </> : activeButton === 'uploadeBankSlips' ?
                                    <Grid item style={{ background: '#fafafa', width: '50%' }}>
                                        <Grid container direction={'column'} spacing={3}>
                                            <Grid item mt={5} ml={10}>
                                                <span style={{ fontStyle: '16px', fontWeight: '600', color: '#032541' }} >Receive Via Bank Slip</span>
                                            </Grid>
                                            <Grid item display={'flex'} marginLeft={10} direction={'column'} width={'100%'}>
                                                <FormControl style={{ width: '500px' }}>
                                                    <InputLabel id="demo-simple-select-label">Bank <span style={{ color: '#dc3545' }}>*</span></InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        label="Age"
                                                        value={selectedBankAccount}
                                                        onChange={(e) => setSelectedBankAccount(e.target.value)}
                                                    >
                                                        {bankAccounts?.map((accountFound) => (
                                                            <MenuItem value={accountFound?.accounNumber}>{accountFound?.bankName} - Acc/No: {accountFound?.accounNumber} </MenuItem>
                                                        ))}

                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item display={'flex'} marginLeft={10} direction={'column'} width={'100%'}>
                                                <TextField value={bankslipRefNo} onChange={(e) => setBankslipRef(e.target.value)} style={{ marginTop: '20px', width: '500px' }} id="outlined-basic" label="Reference No" variant="outlined" />
                                            </Grid>
                                            {refDetailsFound?.length > 0 ? refDetailsFound?.map((refItem) => {
                                                return (
                                                    <>
                                                        <Grid item display={'flex'} mt={2} marginLeft={10} direction={'column'} width={'100%'}>
                                                            <TextField disabled value={numberFormat(refItem?.creditAmount)} style={{ marginTop: '20px', width: '500px' }} id="outlined-basic" label="Bank Slip Amount" variant="outlined" />
                                                        </Grid>

                                                        <Grid item display={'flex'} mt={2} marginLeft={10} direction={'column'} width={'100%'}>
                                                            <TextField disabled value={numberFormat(refItem?.balance === undefined ? refItem?.creditAmount : bankSlipBalance)} style={{ marginTop: '20px', width: '500px' }} id="outlined-basic" label="Bank Slip Balance" variant="outlined" />
                                                        </Grid>
                                                        <Grid item display={'flex'} mt={2} marginLeft={10} direction={'column'} width={'100%'}>
                                                            <TextField onChange={(e) => setBankSlipAccountAmount(Number(e.target.value))} defaultValue={(initialBalance)} style={{ marginTop: '20px', width: '500px' }} id="outlined-basic" label="Amount" variant="outlined" />
                                                        </Grid>
                                                        <Grid item display={'flex'} mt={2} marginLeft={10} direction={'column'} width={'100%'}>
                                                            <TextField disabled value={DateFormatter(refItem?.transactionDate)} style={{ marginTop: '20px', width: '500px' }} id="outlined-basic" label="Date" variant="outlined" />
                                                        </Grid>
                                                    </>
                                                )
                                            }) : null}

                                            <Grid ml={-14} item width={'100%'} display={'flex'} justifyContent={'flex-end'}>
                                                {isValidated === true ? <Button onClick={payViaValidatedSlip} style={{ backgroundColor: '#032541', color: '#fff', textTransform: 'inherit', width: '124px' }}> Pay Now</Button> : <Button onClick={validateUploadedTransactionRef} style={{ backgroundColor: '#032541', color: '#fff', textTransform: 'inherit', width: '124px' }}> Validate</Button>}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    : activeButton === 'settleInvoice' ?
                                        <Grid item style={{ background: '#fafafa', width: '50%' }}>
                                            <Grid container direction={'column'} spacing={1} px={3} py={3} width={'100%'}>
                                                <Grid item mt={5}>
                                                    <span style={{ fontStyle: '16px', fontWeight: '600', color: '#032541' }} >Settle Invoice</span>
                                                </Grid>
                                                <Grid item width={'100%'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                                    <Box display={'flex'} flexDirection={'column'}>
                                                        <Typography style={{ fontSize: '12px', color: '#032541' }}>Invoice No.</Typography>
                                                        <Typography style={{ fontSize: '12px', color: '#032541', fontWeight: 'bold' }}>{invoiceNumber}</Typography>
                                                    </Box>
                                                    <Box display={'flex'} flexDirection={'column'}>
                                                        <Typography style={{ fontSize: '12px', color: '#032541', }}>Invoice Balance</Typography>
                                                        <Typography style={{ fontSize: '12px', color: '#032541', fontWeight: 'bold', }}>{currencyconverter(initialBalance)}</Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item display={'flex'} mt={2} direction={'column'} width={'100%'}>
                                                    <FormControl style={{ width: '100%' }}>
                                                        <InputLabel id="demo-simple-select-label">Transaction Type</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            label="Transaction Type"
                                                            value={selectedSettleInvoiceMethod}
                                                            onChange={(e) => setSelectedSettleInvoiceMethod(e.target.value)}
                                                        >
                                                            {settleinvoiveOptions?.map((optionFound) => (
                                                                <MenuItem value={optionFound?.value}>{optionFound?.name}</MenuItem>
                                                            ))}

                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item display={'flex'} direction={'column'} width={'100%'}>
                                                    <TextField value={settleInvoiceAmount} onChange={(e) => setSettleInvoiceAmount(e.target.value)} style={{ marginTop: '20px', width: '100%' }} id="outlined-basic" label="Amount" variant="outlined" />
                                                </Grid>
                                                <Grid item display={'flex'} direction={'column'} width={'100%'}>
                                                    <TextField value={settleInvoiceRefNo} onChange={(e) => setSettleInvoiceRefNo(e.target.value)} style={{ marginTop: '20px', width: '100%' }} id="outlined-basic" label="Reference No" variant="outlined" />
                                                </Grid>
                                                <Grid item display={'flex'} mt={2} alignContent={'flex-end'} justifyContent={'flex-end'} alignItems={'flex-end'} direction={'column'} width={'100%'}>
                                                    <Button disabled={!settleInvoiceRefNo || !settleInvoiceAmount || !selectedSettleInvoiceMethod} onClick={() => payViaSettleInvoice()} style={!settleInvoiceRefNo || !settleInvoiceAmount || !selectedSettleInvoiceMethod ? { backgroundColor: "rgba(3, 37, 65, 0.4)", color: '#fff', textTransform: 'inherit', width: '124px' } : { backgroundColor: '#032541', color: '#fff', textTransform: 'inherit', width: '124px' }}> Settle Invoice</Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        : null}
            </Grid>
        </div>
    )

}

export default PayPersonalVoucher;