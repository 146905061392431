import React, { useState } from "react";
import { ErrorAlert } from "../snackBar Alerts/errorAlert";
import { SuccessAlert } from "../snackBar Alerts/successAlert";
import { Box, Breadcrumbs, Grid, IconButton, Typography, Button } from "@mui/material";
import { ArrowBackIos } from "@mui/icons-material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import CustomInputLabel from "./CustomInputLabel";
import CustomTextField from "./CustomTextField";
import HttpComponent from "./MakeRequest";

function SchoolExpel({ onClose, student }) {
    // console.log('student', student)
    const [errorShow, setErrorShow] = useState({ state: false, message: "" });
    const [successShow, setSuccessShow] = useState({ state: false, message: "" });
    const [formData, setFormData] = useState({ description: "" });

    const breadcrumbs = [
        <Typography key="X" sx={{ color: "#707070", fontSize: "0.875rem" }}>Dashboard</Typography>,
        <Typography key={"add"} sx={{ color: "#707070", fontSize: "0.875rem" }}>Students</Typography>,
        <Typography key={"add"} sx={{ color: "#dc3545", fontSize: "0.875rem" }}>Expel Student</Typography>
    ];

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };
    const isFormValid = () => {
        return formData.description
    };

    const handleExpelStudent = (e) => {
        e.preventDefault();
        HttpComponent({
            method: 'POST',
            url: `/api/expel_student?studentId=${student.studentId}`,
            body: {
                reason: formData.description,
            },
            token: localStorage.getItem('X-Authorization'),
        }).then((response) => {
            if (response.response.Status === "SUCCESS") {
                setSuccessShow({ state: true, message: "Student Expelled successfully." });
                setTimeout(() => {
                    setSuccessShow({ state: false, message: "" });
                    onClose();
                    window.location.reload();
                }, 2000);
            } else {
                setErrorShow({ state: true, message: response.message });
                setTimeout(() => {
                    setErrorShow({ state: false, message: "" });
                }, 2000);
            }
        }).catch((error) => {
            setErrorShow({ state: true, message: "An error occurred while expelling the student." });
            setTimeout(() => {
                setErrorShow({ state: false, message: "" });
            }, 2000);
        });
    };

    return (
        <div>
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <div style={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
                <div style={{ flexGrow: 1, overflow: "hidden" }}>
                    <Box sx={{ flexGrow: 1, overflowY: "auto", overflowX: "hidden", pl: 2, display: 'flex', justifyContent: 'space-between' }}>
                        <Box>
                            <IconButton style={{ position: 'relative', top: '40px', right: '10px' }} onClick={onClose}>
                                <ArrowBackIos />
                            </IconButton>
                            <Typography variant="h3" sx={{ color: "#032541", fontSize: "1.563rem", fontWeight: 700, marginLeft: '30px' }}>
                                Expel Suspend
                            </Typography>
                            <Breadcrumbs sx={{ marginTop: '20px' }} separator={<FiberManualRecordIcon sx={{ fontSize: "0.625rem", color: "#e1e5e8" }} />} aria-label="breadcrumb">
                                {breadcrumbs}
                            </Breadcrumbs>
                        </Box>
                    </Box>
                    <Grid item>
                        <Typography style={{ fontSize: "16px", color: "#032541", marginTop: "20px", fontFamily: 'Poppins', marginLeft: '20px', fontWeight: '500' }}>
                            Expel Student?
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography style={{ fontSize: "14px", color: "#707070", marginTop: "10px", fontFamily: 'Poppins', marginLeft: '20px' }}>
                            Selected Student <span style={{ fontSize: '15px', color: '#032541', fontWeight: 'medium' }}>{student?.studentName}</span> will be expelled.<br />
                            You can trace them on the expelled statements tab.
                        </Typography>
                    </Grid>
                    <Box component="div" sx={{ display: 'flex', marginLeft: '20px', marginTop: '10px' }}>
                        <Box sx={{ marginLeft: '10px' }}>
                            <CustomInputLabel required={true} label={'Description'} />
                            <CustomTextField value={formData.description} onChange={handleInputChange} name={'description'} placeholder={"Enter Description /reason"} />
                        </Box>
                    </Box>
                    <form onSubmit={handleExpelStudent}>
                        <Box style={{ display: "flex", marginTop: "15px", justifyContent: "center" }}>
                            <Button onClick={onClose} style={{ height: "45px", width: "125px", marginRight: '20px', borderRadius: "4px", border: '1px solid #1a2e4c', color: "#032541" }}>
                                Cancel
                            </Button>
                            <Button type="submit"
                                variant="contained"
                                style={{ backgroundColor: isFormValid() ? '#032541' : 'rgba(3, 37, 65, 0.4)', color: '#fff', width: '125px', height: '45px' }}
                                disabled={!isFormValid()}
                            >
                                Save
                            </Button>
                        </Box>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default SchoolExpel;