import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import { SuccessAlert } from "../../snackBar Alerts/successAlert";
import { ErrorAlert } from "../../snackBar Alerts/errorAlert";
import { useEffect, useState } from "react";
import HttpComponent from "../MakeRequest";

export default function KCBCreditAccountSetUp(props) {
    const [successShow, setSuccessShow] = useState({ state: false, message: "" });
    const [errorShow, setErrorShow] = useState({ state: false, message: "" });
    const [formData, setFormdata] = useState({ bankName: '', accountName: '', accountNumber: '', bankCode: "" })
    const [selectedBank, setSelectedBank] = useState(null)
    const [banks, setBanks] = useState([])
    const iscreditAccountSet = props?.iscreditAccountSet
    const handleFormChange = (e) => {
        setFormdata({ ...formData, [e.target.name]: e.target.value })
    }
    const getBanks =  () => {
        try {
            HttpComponent({
                method: 'GET',
                url: `/get_transfer_banks`,
                body: null,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data.status === 200) {
                    let banks = data.response.data
                    banks?.map((bank) => {
                        setBanks((old) => [...old, { label: bank.bankName, value: bank.bankName, bankCode: bank.bankCode }])
                        }
                    );

                }
            })
        } catch (error) {

        }
    }
    useEffect(() => {
        getBanks()
    }, [])

    useEffect(()=>{
        if(selectedBank){
            setFormdata({...formData,bankCode:selectedBank?.bankCode, bankName:selectedBank?.value})
        }
    },[selectedBank])

    function addCreditAccount(){
        try{
            HttpComponent({
                method:'POST',
                url:`/api/v1/set_kcb_credit_account`,
                token: localStorage.getItem('X-Authorization'),
                body:formData
            }).then((data)=>{
                if(data?.status === 201 || data?.status === 200){
                    setSuccessShow({state:true,message:data?.response?.message})
                    setTimeout(() =>{
                        props?.setShowKCBBankCreditSetup(false)
                    },1500)
                }
            })
        }catch(error){
            setErrorShow({state:true, message:error?.message})
        }
    }



    return (
        <Grid width={'100%'} flex={1} flexDirection={'column'} container>
            <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            <Box component={'div'} sx={{ px: 5 }}>
                <Typography style={{ color: "#707070", fontSize: "16px" }}>Setup you KCB Bank Credit accounts and receive payment from your suppliers, vendors and <br /> other payments!</Typography>
            </Box>
            <Box component={'div'} sx={{ px: 5, pt: 2, }}>
                <Grid item width={'100%'}>
                    <TextField name="accountName"  sx={{ width: '100%' }} value={formData.accountName} onChange={handleFormChange} label="Account Name" variant="outlined" />
                </Grid>
                <Grid item width={'100%'} my={2}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Select Bank</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedBank}
                            label="Select Bank"
                            onChange={(e) =>setSelectedBank(e.target.value)}
                        >
                            {banks?.map((bank)=> <MenuItem value={bank}>{bank?.label}</MenuItem>)}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item width={'100%'} gap={2} display={'flex'}>
                    <TextField disabled name="bankCode" sx={{ width: '49%' }} value={formData.bankCode} onChange={handleFormChange} label="Bank Code" variant="outlined" />
                    <TextField disabled name="bankName" sx={{ width: '48%' }} value={formData.bankName} onChange={handleFormChange} label="Bank Name" variant="outlined" />
                </Grid>
                <Grid item width={'100%'} my={2}>
                    <TextField name="accountNumber" sx={{ width: '100%' }} value={formData.accountNumber} onChange={handleFormChange} label="Account Number" variant="outlined" />
                </Grid>
                <Grid item display={'flex'} justifyContent={'flex-end'} gap={2} alignItems={'center'} my={2}>
                    <Button sx={{color:"#dc3545", textTransform:'inherit', border:'1px solid #dc3545', width:'120px'}}>Cancel</Button>
                    <Button onClick={addCreditAccount} style={!formData.accountName || !formData.accountNumber || !formData.bankName ||!formData.bankCode ? {backgroundColor:'#bec5d1' , textTransform:'inherit', color:'#fff' , width:'120px'}:{backgroundColor:'#032541' , textTransform:'inherit', color:'#fff', width:'120px'}} disabled={!formData.accountName || !formData.accountNumber || !formData.bankName ||!formData.bankCode}>Submit</Button>
                </Grid>
            </Box>
        </Grid>
    )
}