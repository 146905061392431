import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Box, Breadcrumbs, Grid, IconButton, styled, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, TablePagination } from "@mui/material";
import { ArrowBackIos } from "@mui/icons-material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import TotalIcon from '../../images/evoucher-icn22.webp';
import RedeemedIcon from '../../images/evoucher-icn10.webp';
import CustomerProfileIcon from '../../images/large-profile.webp';
import UnusedIcon from '../../images/evoucher-icn.webp';
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import {ErrorAlert} from "../snackBar Alerts/errorAlert";
import {SuccessAlert} from "../snackBar Alerts/successAlert";
import HttpComponent from "../School/MakeRequest";
import CustomSearchInput from "../School/CustomSearchInput";

function OpenEvoucher() {
    const navigate = useNavigate();
    const { voucherId } = useParams();
    const location = useLocation();
    const [errorShow, setErrorShow] = useState({ state: false, message: "" });
    const [successShow, setSuccessShow] = useState({ state: false, message: "" });
    const localCurrency = localStorage.getItem("localCurrency");
    const [totalAmount, setTotalAmount] = useState(0);
    const [totalVouchers, setTotalVouchers] = useState(0);
    const [amountRedeemed, setAmountRedeemed] = useState(0);
    const [redeemedVouchers, setRedeemedVouchers] = useState(0);
    const [totalUnusedAmount, setTotalUnusedAmount] = useState(0);
    const [unusedVouchers, setUnusedVouchers] = useState(0);
    const [tabValue, setTabValue] = useState("Unused");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchValue, setSearchValue] = useState("");
    const [vouchers, setVouchers] = useState([]);
    
    const handleCancelModal = () => {
        navigate('/open-evouchers');
    };
    
    const breadcrumbs = [
        <Typography key="X" sx={{ color: "#707070", fontSize: "0.875rem" }}>
            Dashboard
        </Typography>,
        <Typography key={"add"} sx={{ color: "#707070", fontSize: "0.875rem" }}>
            Open E-Vouchers
        </Typography>,
        <Typography key={"add"} sx={{ color: "#dc3545", fontSize: "0.875rem" }}>
            E-Voucher Batch
        </Typography>
    ];
    const AntTabs = styled(TabList)({
        borderBottom: "0px solid #e8e8e8",
        "& .MuiTabs-indicator": {
            backgroundColor: "#ffffff00",
        },
    });
    const handleSearchBox = (event) => {
        setSearchValue(event.target.value);
    };
    
    const batchNumber = location.state?.batchNumber;
    const customerName = location.state?.customerName;
    const customerPhone = location.state?.customerPhone;
    const voucherType = location.state?.voucherType;
    
    useEffect(() => {
        const fetchVoucherTotals = async () => {
            try {
                const url = `/api/v1/evoucher/getVouchersTotals/${voucherId}`;
                const response = await HttpComponent({
                    method: "GET",
                    url: url,
                    token: localStorage.getItem("X-Authorization"),
                });
    
                if (response.response.status === "SUCCESS") {
                    const data = response.response.data;
                    setTotalAmount(data.totalAmount);
                    setTotalVouchers(data.totalVouchers);
                    setAmountRedeemed(data.amountRedeemed);
                    setRedeemedVouchers(data.redeemedVouchers);
                    setTotalUnusedAmount(data.totalUnusedAmount);
                    setUnusedVouchers(data.unusedVouchers);
                } else {
                    setErrorShow(response.data.message);
                }
            } catch (error) {
                setErrorShow(error.response ? error.response.data.message : error.message);
            }
        };
    
        fetchVoucherTotals();
    }, [voucherId]);
    
    useEffect(() => {
        const fetchVouchers = async () => {
            try {
                const token = localStorage.getItem("X-Authorization");
                let status;
                if (tabValue === "Unused") {
                    status = "ACTIVE";
                } else if (tabValue === "Redeemed") {
                    status = "Redeemed";
                } else if (tabValue === "Expired") {
                    status = "Expired";
                }
    
                const url = `/api/v1/evoucher/getBatchVouchers/${voucherId}?pageLimit=${rowsPerPage}&pageNumber=${page + 1}&status=${status}&paymentStatus=PAID`;
                const response = await HttpComponent({
                    method: "GET",
                    url: url,
                    token: localStorage.getItem("X-Authorization"),
                });
                if (response.response.status === "SUCCESS") {
                    const data = response.response.data;
                    setVouchers(data); 
                } else {
                    setErrorShow(response.data.message);
                }
            } catch (error) {
                console.error('Fetch Error:', error);
                setErrorShow(error.response ? error.response.data.message : error.message);
            }
        };
    
        fetchVouchers();
    }, [voucherId, page, tabValue, rowsPerPage]);
    
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
        setPage(0);
    };
    const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
        minWidth: 0,
        [theme.breakpoints.up("sm")]: { minWidth: 0 },
        fontWeight: theme.typography.fontWeightRegular,
        marginRight: theme.spacing(1),
        fontSize: "13px",
        color: "#6e7074",
        fontFamily: ["Poppins"].join(","),
        "&:hover": {
            color: "#032541", opacity: 1,
        },
        "&.Mui-selected": {
            color: "#dc3545", fontWeight: 600,
        },
    }));
    
    return (
        <div>
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ flexGrow: 1, overflow: "hidden" }}>
                    <Box sx={{ flexGrow: 1, overflowY: "auto", overflowX: "hidden", p: 2, display: 'flex', justifyContent: 'space-between' }}>
                        <Box>
                            <IconButton style={{ position: 'relative', top: '40px', right: '10px' }} onClick={handleCancelModal}>
                                <ArrowBackIos />
                            </IconButton>
                            <Typography variant="h3" sx={{ color: "#032541", fontSize: "1.563rem", fontWeight: 700, marginLeft: '30px' }}>
                                {batchNumber ? `E-Voucher Batch ${batchNumber}` : "E-Voucher Batch"}
                            </Typography>
                            <Breadcrumbs sx={{ marginTop: '20px' }} separator={<FiberManualRecordIcon sx={{ fontSize: "0.625rem", color: "#e1e5e8" }} />} aria-label="breadcrumb">
                                {breadcrumbs}
                            </Breadcrumbs>
                        </Box>
                    </Box>
                    <Grid container spacing={2} style={{ width: '100%', marginLeft: '10px' }}>
                        <Grid item xs={12} sm={6} md={3}>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "15px", borderRadius: "12px", boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.1)", backgroundColor: "#fafafa" }}>
                                <Box>
                                    <Typography variant="h6" sx={{ color: "#707070", fontSize: "14px" }}>
                                        Customer:
                                    </Typography>
                                    <Typography variant="h6" sx={{ fontWeight: "bold", fontSize: "20px", color: "#000", lineHeight: '2.3' }}>
                                        {customerName}
                                    </Typography>
                                    <Typography variant="h6" sx={{ fontSize: "12px", color: "#707070", lineHeight: '1.67' }}>
                                        {customerPhone}
                                    </Typography>
                                </Box>
                                <img alt="Customer Profile Icon" style={{ width: "40px", height: "40px", objectFit: "contain" }} src={CustomerProfileIcon} />
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "15px", borderRadius: "12px", boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.1)", backgroundColor: "#f5faff" }}>
                                <Box>
                                    <Typography variant="h6" sx={{ color: "#000", fontSize: "14px" }}>
                                        Total:
                                    </Typography>
                                    <Typography variant="h6" sx={{ fontWeight: "bold", fontSize: "20px", color: "#3da2f8", lineHeight: '2.3' }}>
                                        {localCurrency} {totalAmount}
                                    </Typography>
                                    <Typography variant="h6" sx={{ fontSize: "12px", color: "#707070", lineHeight: '1.67' }}>
                                        {totalVouchers} vouchers
                                    </Typography>
                                </Box>
                                <img alt="Total Icon" style={{ width: "40px", height: "40px", objectFit: "contain" }} src={TotalIcon} />
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "15px", borderRadius: "12px", boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.1)", backgroundColor: "#f0fbf7" }}>
                                <Box>
                                    <Typography variant="h6" sx={{ color: "#000", fontSize: "14px" }}>
                                        Redeemed:
                                    </Typography>
                                    <Typography variant="h6" sx={{ fontWeight: "bold", fontSize: "20px", color: "#17ae7b", lineHeight: '2.3' }}>
                                        {localCurrency} {amountRedeemed}
                                    </Typography>
                                    <Typography variant="h6" sx={{ fontSize: "12px", color: "#707070", lineHeight: '1.67' }}>
                                        {redeemedVouchers} vouchers
                                    </Typography>
                                </Box>
                                <img alt="Redeemed Icon" style={{ width: "40px", height: "40px", objectFit: "contain" }} src={RedeemedIcon} />
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "15px", borderRadius: "12px", boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.1)", backgroundColor: "#fff9f2" }}>
                                <Box>
                                    <Typography variant="h6" sx={{ color: "#000", fontSize: "14px" }}>
                                        Unused:
                                    </Typography>
                                    <Typography variant="h6" sx={{ fontWeight: "bold", fontSize: "20px", color: "#f79009", lineHeight: '2.3' }}>
                                        {localCurrency} {totalUnusedAmount}
                                    </Typography>
                                    <Typography variant="h6" sx={{ fontSize: "12px", color: "#707070", lineHeight: '1.67' }}>
                                        {unusedVouchers} vouchers
                                    </Typography>
                                </Box>
                                <img alt="Unused Icon" style={{ width: "40px", height: "40px", objectFit: "contain" }} src={UnusedIcon} />
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: 'space-between' }}>
                                <TabContext value={tabValue}>
                                    <Box>
                                        <AntTabs onChange={handleTabChange} aria-label="Shop tabs" TabIndicatorProps={{ hidden: true }} textColor="primary">
                                            <AntTab label="Unused" value="Unused" />
                                            <AntTab label="Redeemed" value="Redeemed" />
                                            <AntTab label="Expired" value="Expired" />
                                        </AntTabs>
                                    </Box>
                                </TabContext>
                            </Box>
                        </Grid>
                    </Grid>
                    <Box sx={{ "& .super-app-theme--header": { color: "#032541", fontWeight: "600", fontSize: "10px" } }}>
                        <CustomSearchInput sx={{ marginLeft: '15px' }} name="search" value={searchValue} placeholder="Search:" onChange={handleSearchBox} />
                    </Box>
                    <TabContext value={tabValue}>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {voucherType === "Bulk Vouchers" ? (
                                            <>
                                                <TableCell style={{ color: '#032541', fontSize: '14px', fontWeight: '600', fontFamily: 'Poppins' }}>Voucher ID</TableCell>
                                                <TableCell style={{ color: '#032541', fontSize: '14px', fontWeight: '600', fontFamily: 'Poppins' }}>Assigned On</TableCell>
                                                <TableCell style={{ color: '#032541', fontSize: '14px', fontWeight: '600', fontFamily: 'Poppins' }}>Recipient Name</TableCell>
                                                <TableCell style={{ color: '#032541', fontSize: '14px', fontWeight: '600', fontFamily: 'Poppins' }}>Phone Number</TableCell>
                                                <TableCell style={{ color: '#032541', fontSize: '14px', fontWeight: '600', fontFamily: 'Poppins' }}>Amount</TableCell>
                                                <TableCell style={{ color: '#032541', fontSize: '14px', fontWeight: '600', fontFamily: 'Poppins' }}>Balance</TableCell>
                                            </>
                                        ) : (
                                            <>
                                                <TableCell style={{ color: '#032541', fontSize: '14px', fontWeight: '600', fontFamily: 'Poppins' }}>Voucher ID</TableCell>
                                                <TableCell style={{ color: '#032541', fontSize: '14px', fontWeight: '600', fontFamily: 'Poppins' }}>Created On</TableCell>
                                                <TableCell style={{ color: '#032541', fontSize: '14px', fontWeight: '600', fontFamily: 'Poppins' }}>Recipient Name</TableCell>
                                                <TableCell style={{ color: '#032541', fontSize: '14px', fontWeight: '600', fontFamily: 'Poppins' }}>Phone Number</TableCell>
                                                <TableCell style={{ color: '#032541', fontSize: '14px', fontWeight: '600', fontFamily: 'Poppins' }}>Amount</TableCell>
                                                <TableCell style={{ color: '#032541', fontSize: '14px', fontWeight: '600', fontFamily: 'Poppins' }}>Expiring Day</TableCell>
                                            </>
                                        )}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {vouchers.map((voucher) => (
                                        <TableRow key={voucher.id} onClick={() => navigate(`/open-evoucher/evoucherDetails/${voucher.voucherId}`)} style={{ cursor: 'pointer' }}>
                                            {voucherType === "Bulk Vouchers" ? (
                                                <>
                                                    <TableCell>{voucher.voucherNumber}</TableCell>
                                                    <TableCell>{new Date(voucher.createdAt).toLocaleDateString()}</TableCell>
                                                    <TableCell>{voucher.name}</TableCell>
                                                    <TableCell>{voucher.phone}</TableCell>
                                                    <TableCell>{localCurrency} {voucher.amount.toFixed(2)}</TableCell>
                                                    <TableCell>{localCurrency} {voucher.balance.toFixed(2)}</TableCell>
                                                </>
                                            ) : (
                                                <>
                                                    <TableCell>{voucher.voucherNumber}</TableCell>
                                                    <TableCell>{new Date(voucher.createdAt).toLocaleDateString()}</TableCell>
                                                    <TableCell>{voucher.name}</TableCell>
                                                    <TableCell>{voucher.phone}</TableCell>
                                                    <TableCell>{localCurrency} {voucher.amount.toFixed(2)}</TableCell>
                                                    <TableCell>{new Date(voucher.expiryDate).toLocaleString([], {year: 'numeric',month: '2-digit',day: '2-digit',hour: '2-digit',minute: '2-digit',hour12: true})}</TableCell>
                                                </>
                                            )}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </TabContext>
                </div>
            </div>
        </div>
    );
}

export default OpenEvoucher;