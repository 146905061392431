import React, { useState } from 'react';
import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import backIcon from "./Images/back-icn.svg"
import DarajaSetUpForm from "./DarajaSetUpForm";
import PortalSetUpForm from "./PortalSetUpForm";
import WeCanHelp from "./WeCanHelp";
import PaybillIcon from '../../images/paybill-icn.svg'
import TillIcon from '../../images/till-no-icn.svg'
import HttpComponent from "./MakeRequest";
import { useSelector, useDispatch } from "react-redux";
import { SuccessAlert } from "../snackBar Alerts/successAlert";
import { ErrorAlert } from "../snackBar Alerts/errorAlert";
import { CheckBox } from "@mui/icons-material";
import Checkbox from '@mui/material/Checkbox';
import { handleIsMpesaSetup } from '../../features/paymentOptionsSlice';
import TillMpesaSetUp from './tillNoSetUp';

const DarajaSetUp = ({ setShowDarajaSetup, setErrorShow, setSuccessShow, setMpesaStatus }) => {
    const [pageStatus, setPageStatus] = useState("setup")
    const [selectedMpesaOption, setSelectedMpesaOption] = useState("")
    const exSetup = localStorage?.getItem('exSetup')
    const [checked, setChecked] = React.useState(false);

    console.log(setErrorShow)

    const dispatch = useDispatch();

    const handleCheckBoxChange = (event) => {
        setChecked(event.target.checked);
    };

    const noStyle = {
        "borderRadius": "4px",
        "border": "solid 1px #032541",
        color: "#032541"
    }

    const buttonWidthHeight = {
        width: "9.063rem",
        height: "3.125rem"
    }

    const yesStyle = {
        "borderRadius": "4px",
        "backgroundColor": "#032541",
        color: "white",
        '&:hover': {
            backgroundColor: '#032541',
            color: 'white'
        }
    }

    const handleYes = () => {
        switch (selectedMpesaOption) {
            case "Paybill No": {
                setPageStatus("daraja");
                return
            }
            case "Till No": {
                setPageStatus("setuptill");
                return
            }
            default:
                setPageStatus("daraja");
                return
        }
    }

    function handleNoDaraja() {
        // if (exSetup?.includes('true')) {
        //     setPageStatus('portal')
        // }
        setPageStatus('portal')
    }

    function handleNoPortal() {
        // if (exSetup?.includes('true')) {
        //     setPageStatus('useOurPaybill')
        // }
        setPageStatus('useOurPaybill')
    }

    function handlePortalSetup() {
        // if (exSetup?.includes('true')) {
        //     setPageStatus('portalForm')
        //     localStorage.setItem('exPortalForm', true)
        // }
        setPageStatus('portalForm')
        localStorage.setItem('exPortalForm', true)
    }

    const { mpesaData } = useSelector((store) => store.payment);


    return (
        <Box component="div" sx={{ fontFamily: 'Poppins', width: "95%", minHeight: "20.938rem", "borderRadius": "6px", "border": "solid 1px #eaeaea", marginTop: "4.6%" }}>
            <Box component="div" sx={{ display: "flex", margin: 2 }}>
                <Box component="div" sx={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
                    <img onClick={() =>
                        pageStatus == "useOurPaybill" ? setPageStatus('initial') :
                            pageStatus == "portalForm" ? setPageStatus('initial') :
                                pageStatus == "portal" ? setPageStatus('initial') :
                                    pageStatus == 'daraja' ? setPageStatus('initial') :
                                        pageStatus == 'setup' ? setPageStatus('setup') :
                                            pageStatus
                    } style={{ "width": "0.856rem", "height": "1.25rem" }} src={backIcon} alt="" />
                </Box>
                <Box component="div" sx={{ display: "flex", justifyContent: "center", flexDirection: "column", marginLeft: 1 }}>
                    <Typography sx={{ color: "#032541", fontSize: "1rem", fontWeight: 700, fontFamily: 'Poppins' }}>
                        {pageStatus == "initial" || pageStatus == "none" ?
                            "Mpesa Setup"
                            : pageStatus == "daraja" ?
                                "Mpesa Daraja Setup"
                                : pageStatus == "portal" || pageStatus == "portalForm" ?
                                    "Mpesa Portal Setup"
                                    : pageStatus == "useOurPaybill" ? "Mpesa Setup"
                                        : pageStatus == "setuptill" ? "Mpesa Till No Setup"
                                            : pageStatus == "setup" ? "Mpesa Setup" : null
                        }

                    </Typography>
                </Box>
            </Box>
            {pageStatus === 'setup' ?
                <Box component="div" sx={{ display: "flex", flexDirection: "row", justifyContent: "center", marginTop: "8%", gap: 2 }}>
                    <Paper sx={{ height: '70px', cursor: 'pointer' }} onClick={() => { setPageStatus('initial'); setSelectedMpesaOption('Paybill No') }}>
                        <Box sx={{ width: '100%', height: '100%', px: 2, gap: 2 }} component={'div'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                            <Box component={'img'} src={PaybillIcon} alt='Paybill Icon' />
                            <Typography variant='body2'>Setup for <br></br> paybill Number</Typography>
                        </Box>
                    </Paper>
                    <Paper sx={{ height: '70px', cursor: 'pointer' }} onClick={() => { setPageStatus('initial'); setSelectedMpesaOption('Till No') }}>
                        <Box sx={{ width: '100%', height: '100%', px: 2, gap: 2 }} component={'div'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                            <Box component={'img'} src={TillIcon} alt='Till Icon' />
                            <Typography variant='body2'>Setup for <br></br> Till Number</Typography>
                        </Box>
                    </Paper>
                </Box>
                : pageStatus === "initial" ?
                    <Box component="div" sx={{ display: "flex", flexDirection: "column", justifyContent: "center", marginTop: "10%" }}>
                        <Box component="div" sx={{ alignSelf: "center" }}>
                            <Typography sx={{ color: "#032541", fontSize: "0.875rem", textAlign: "center" }}>
                                {selectedMpesaOption === 'Paybill No' ? (
                                    <span>Do you have <strong>Mpesa Paybill Keys</strong> <br /> for your business?</span>
                                ) : <span>Do you have <strong>Mpesa Till Keys</strong> <br /> for your business?</span>}
                            </Typography>
                        </Box>
                        <Box component="div" sx={{ alignSelf: "center", marginTop: "5%" }}>
                            <Button onClick={handleNoPortal} sx={{ ...noStyle, ...buttonWidthHeight, marginX: 1 }}>
                                No
                            </Button>

                            <Button onClick={handleYes} sx={{ ...yesStyle, ...buttonWidthHeight, marginX: 1 }}>
                                Yes
                            </Button>
                        </Box>
                    </Box>
                    : pageStatus === "daraja" ?
                        <DarajaSetUpForm setErrorShow={setErrorShow} setSuccessShow={setSuccessShow} setShowDarajaSetup={setShowDarajaSetup} setMpesaStatus={setMpesaStatus} />
                        : pageStatus == "portal" ?
                            <Box component="div" sx={{ display: "flex", flexDirection: "column", justifyContent: "center", marginTop: "10%" }}>
                                <Box component="div" sx={{ alignSelf: "center" }}>
                                    <Typography sx={{ color: "#032541", fontSize: "0.875rem", textAlign: "center" }}>
                                        Do you have Mpesa Portal set up <br /> for your business?
                                    </Typography>
                                </Box>
                                <Box component="div" sx={{ alignSelf: "center", marginTop: "5%" }}>
                                    <Button onClick={handleNoPortal} sx={{ ...noStyle, ...buttonWidthHeight, marginX: 1 }}>
                                        No
                                    </Button>

                                    <Button onClick={handlePortalSetup} sx={{ ...yesStyle, ...buttonWidthHeight, marginX: 1 }}>
                                        Yes
                                    </Button>
                                </Box>
                            </Box>
                            : pageStatus == "useOurPaybill" ?
                                <Grid container direction={'column'} justifycontent={'center'} alignContent={'center'} style={{ textAlign: 'center' }}>
                                    <Grid item mt={3}>
                                        <span style={{ color: '#17ae7b', fontSize: '24px', fontWeight: 600., textAlign: 'center', width: '50%' }} >For assistance Contact our support team at</span>
                                    </Grid>
                                    <Grid item mt={2} style={{ color: '#707070' }}>
                                        <Typography variant='p'>
                                            Email: <strong style={{ fontWeight: 'normal', color: '#032541' }}>info@zed.business</strong>
                                        </Typography>
                                    </Grid>
                                    <Grid item mt={3} style={{ color: '#707070' }}>
                                        <Typography>Phone: <strong style={{ fontWeight: 'normal', color: '#032541' }}>0709836100</strong></Typography>
                                    </Grid>
                                    <Box component="div" sx={{ alignSelf: "center", marginTop: "5%", marginBottom: '5%' }}>
                                        <Button onClick={() => { setPageStatus('initial') }} sx={{ ...noStyle, ...buttonWidthHeight, marginX: 1 }}>
                                            Cancel
                                        </Button>

                                        <Button onClick={() => {
                                            setShowDarajaSetup(false)
                                            setMpesaStatus(true);
                                            dispatch(handleIsMpesaSetup(true))
                                        }} sx={{ ...yesStyle, ...buttonWidthHeight, marginX: 1 }}>
                                            Okay
                                        </Button>
                                    </Box>
                                </Grid>
                                : pageStatus == "portalForm" ?
                                    <PortalSetUpForm />
                                    :pageStatus === 'setuptill' ? <TillMpesaSetUp setShowDarajaSetup={setShowDarajaSetup} setErrorShow={setErrorShow} setSuccessShow={setSuccessShow} setMpesaStatus={setMpesaStatus} />
                                        : pageStatus == 'none' ? <WeCanHelp />
                                        : null
            }
        </Box>
    )
}

export default DarajaSetUp;
