import { Box, Dialog, DialogActions, DialogContent, Slide } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Button, ButtonBase, Card, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import React, { useState, useEffect, useRef, forwardRef } from "react";
import PhoneInput from "react-phone-input-2";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Mpesa from "../../common/images/mpesa-icon.svg";
import CardImg from '../../common/images/card-icon.svg'
import { PaymentSent } from "../Modals/Invoice/paymentSent";
import HttpComponent from "../School/MakeRequest";
import { ErrorAlert } from "../snackBar Alerts/errorAlert";
import { SuccessAlert } from "../snackBar Alerts/successAlert";
import SendIcon from '@mui/icons-material/Send';
import BankPayBillIcon from "../School/Images/online-payment.svg";
import CARDREADY from '../School/Images/cardlinkready.svg';
import BankSlip from "../../common/images/bank-slip-icn.svg";
import CashIcon from "../School/Images/cash-icon.svg";
import SlipUpload from "../../images/slipupload.svg"
import MomoIcon from '../../images/mtn-momo-icn.svg'
import DateFormatter from "../../utils/dateFormatter";
import EcitizenPng from "./Images/ecitizen.png";
import SettleInvoice from "../../images/settle-invoice-icn.svg"
import WarningImg from "../../images/warning-icn.svg"
import FundsImg from "../../images/ft-icn.svg"
import { currencyconverter, GetDefaultCurrency, ValidateEmailAdress } from "../../common/currencyconverter";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";


const baseUrl = process.env.REACT_APP_BASE_URL;
let localCurrency = localStorage.getItem('localCurrency')

if (!localCurrency || localCurrency === 'undefined' || localCurrency === undefined) {
    localCurrency = 'KES'
}

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const numberFormat = (value) => new Intl.NumberFormat("en-US", { style: "currency", currency: localCurrency }).format(value);


export default function ParentPayMakePayment(props) {
    const navigate = useNavigate()
    const theme = useTheme();
    const isMobileUI = useMediaQuery(theme.breakpoints.down("sm"));
    const [isInvoiceBatch, setIsInvoiceBatch] = useState(false);
    let { invoiceNumber, darajaConfigId } = useParams();
    const [batchData, setBatchData] = useState({})
    const [foundbid, setfoundbid] = useState('')
    const queryParams = decodeURIComponent(window.location.search)
    const businessCat = localStorage.getItem('businessCategory')
    const state = queryParams.slice(1)
    useEffect(() => {
        if (state.startsWith('businessNumber')) {
            const businessNumber = state.split('=')[1];
            setfoundbid(businessNumber)
        }
    }, [queryParams])
    useEffect(() => {
        if (invoiceNumber?.length === 24) {
            setIsInvoiceBatch(true)
        } else {
            setIsInvoiceBatch(false)
        }
    }, [invoiceNumber])
    const [successOpen, setSuccessOpen] = useState(false);
    const group = localStorage.getItem("group");
    const [activeButton, setActiveButton] = useState('')
    const [userPhone, setUserPhone] = useState('')

    let [initialBalance, setInitialBalance] = useState('')
    const [invoiceDiscount, setInvoiceDiscount] = useState(0);
    const [invoicePayments, setInvoicePayments] = useState();
    const [invoiceBalance, setInvoiceBalance] = useState();
    const [amount, setAmount] = useState("")


    const [bankAccount, setBankAccount] = useState('')
    // const [availableBanks, setAvailableBanks] = useState([{name:"KCB", value:"KCB"}, {name:"COOP", value:"COOP"}])
    const [availableBanks, setAvailableBanks] = useState([{ name: "KCB", value: "KCB" }])
    const [kcbphone, setkcbphone] = useState('')
    const [kcbAmount, setKcbAmount] = useState('')
    const [cardAmount, setCardAmout] = useState('')

    const [invoiceData, setInvoiceData] = useState()
    const [errorFetching, setErrorFetching] = useState(false);



    function getBatchData() {
        try {
            HttpComponent({
                method: "GET",
                url: `/api/sponsorbatchdetails?batchId=${invoiceNumber}`,
                token: localStorage.getItem("X-Authorization")
            }).then((response) => {
                if (response?.status === 200 || response?.status === 201) {
                    setInitialBalance(response?.response?.data?.totalAmount)
                    setInvoiceBalance((response?.response?.data?.totalAmount) - (response?.response?.data?.amountPaid))
                    setBatchData(response.response.data);
                }
            }).catch((error) => {
                console.log(error);
            })
        } catch (error) {
            console.warn(error);
        } finally {
        }
    }

    // Success Notification
    const [successNotificationOpen, setSuccessNotificationOpen] = React.useState(false);
    const handleSuccessNotificationClick = () => { setSuccessNotificationOpen(true); };
    const handleSuccessNotificationClose = (event, reason) => { if (reason === 'clickaway') { return } setSuccessNotificationOpen(false); };


    const [successNotificationOpen2, setSuccessNotificationOpen2] = React.useState(false);
    const handleSuccessNotificationClick2 = () => { setSuccessNotificationOpen2(true); };
    const handleSuccessNotificationClose2 = (event, reason) => { if (reason === 'clickaway') { return } setSuccessNotificationOpen(false); };



    const [successNotificationOpen1, setSuccessNotificationOpen1] = React.useState(false);
    const handleSuccessNotificationClick1 = () => { setSuccessNotificationOpen1(true); };
    const handleSuccessNotificationClose1 = (event, reason) => { if (reason === 'clickaway') { return } setSuccessNotificationOpen1(false); };

    // Success Notification
    const [successNotificationReceivedPaymentOpen, setSuccessNotificationReceivedPaymentOpen] = React.useState(false);
    const handleSuccessNotificationPaymentReceivedClick = () => { setSuccessNotificationReceivedPaymentOpen(true); };
    const handleSuccessNotificationReceivedPaymentClose = (event, reason) => { if (reason === 'clickaway') { return } setSuccessNotificationReceivedPaymentOpen(false); };

    const [bussinessStkId, setBussinessStkId] = useState('')

    // Error Notification
    const [errorNotificationOpen, setErrorNotificationOpen] = React.useState(false);
    const handleErrorNotificationClick = () => { setErrorNotificationOpen(true); };
    const handleErrorNotificationClose = (event, reason) => { if (reason === 'clickaway') { return } setErrorNotificationOpen(false); };
    // Card Error
    const [errorNotificationOpen1, setErrorNotificationOpen1] = React.useState(false);
    const handleErrorNotificationClick1 = () => { setErrorNotificationOpen1(true); };
    const handleErrorNotificationClose1 = (event, reason) => { if (reason === 'clickaway') { return } setErrorNotificationOpen1(false); };

    // Error Notification
    const [errorNotificationTimeoutOpen, setErrorNotificationTimeoutOpen] = React.useState(false);
    const handleErrorNotificationTimeoutClick = () => { setErrorNotificationTimeoutOpen(true); };
    const handleErrorNotificationTimeoutClose = (event, reason) => { if (reason === 'clickaway') { return } setErrorNotificationTimeoutOpen(false); };

    // Error Notification
    const [errorNotificationNoBusinessConfigOpen, setErrorNotificationNoBusinessConfigOpen] = React.useState(false);
    const handleErrorNotificationNoBusinessConfigClick = () => { setErrorNotificationNoBusinessConfigOpen(true); };
    const handleErrorNotificationNoBusinessConfigClose = (event, reason) => { if (reason === 'clickaway') { return } setErrorNotificationNoBusinessConfigOpen(false); };



    const [mpesaLoading, setMpesaLoading] = useState(false)
    const [kcbloading, setkcbloading] = useState(false)
    const [Cardloading, setCardloading] = useState(false)

    const [intervalId, setIntervalId] = useState(null);
    const { X_Authorization } = useSelector((store) => store.user)


    const [successmessage, SetSuccessMessageCard] = useState(false)
    const [StkMessage, setSTKMessage] = useState(false)

    const [SuccessShow, setSuccessShow] = useState({ state: false, message: "" })
    const [GenerteLink, setGeneratedLink] = useState(false)
    const [GenerateLinkStatus, setGenerateLinkStatus] = useState(false)
    const [dataLINK, setDatagenerated] = useState('')

    // console.log(invoiceBalance, 'invoiceBalance >>>>>>>>>')
    // console.log(initialBalance, 'initialBalance >>>>>>>>>>')

    // console.log("GenerteLink keli checking", GenerteLink);
    const intervalIdRef = useRef(null);
    const textRef = useRef(null);

    const handleCopy = () => {
        textRef.current.select();
        document.execCommand('copy');
        // alert('LINK COPIED')
        handleSuccessNotificationClick2()
    };

    const handleClick = async (orderID) => {
        let object = { amount: amount || initialBalance, phone: userPhone, orderID: orderID };
        console.log('object', object);
        setMpesaLoading(true);
        if (object.phone == '') {
            setMpesaLoading(false);
            setErrorShow({ state: true, message: 'Please provide the phone number' })
            // console.error("Please provide the phone number");
            handleErrorNotificationClick();
        } else if (object.amount === '' || object.amount === 0) {
            setMpesaLoading(false);
            setErrorShow({ state: true, message: 'Please provide the amount ' })
            handleErrorNotificationClick();
        } else {
            try {
                HttpComponent({
                    method: "POST",
                    url: "/api/pushstk",
                    token: X_Authorization,
                    body: {
                        amount: object.amount,
                        phone: Math.ceil(object.phone),
                        orderID: isInvoiceBatch ? '' : object.orderID,
                        type: isInvoiceBatch ? 'tickets' : "",
                        batchId: isInvoiceBatch ? invoiceNumber : ""
                    }

                })
                    .then((data) => {
                        if (data.response.data.status === 200) {
                            handleSuccessNotificationClick();
                        } else {
                            setMpesaLoading(false);
                            console.error("Problem Processing StK Payment");
                            handleErrorNotificationClick();
                        }
                    })
                    .catch((e) => {
                        setMpesaLoading(false);
                        console.error(`Error Sending STK`, e.message);
                    });
            } catch (error) {
                console.error(error);
                handleErrorNotificationClick();
            }

        }

    };
    // handle momo pay

    const handleMtnMomoPay = async (orderID) => {
        let object = { amount: amount || initialBalance, phone: userPhone, orderID: orderID };
        //TODO: Currently hard coded to do stk push for sweeton
        setMpesaLoading(true);
        if (object.phone === '') {
            setMpesaLoading(false);
            setErrorShow({ state: true, message: 'Please provide the phone number' })
            // console.error("Please provide the phone number");
            handleErrorNotificationClick();
        } else if (object.amount === '' || object.amount === 0) {
            setMpesaLoading(false);
            setErrorShow({ state: true, message: 'Please provide the amount ' })
            handleErrorNotificationClick();
        } else {
            try {
                HttpComponent({
                    method: "POST",
                    url: "/api/v1/payments/momo/pay",
                    token: X_Authorization,
                    body: {
                        amount: object.amount,
                        phone: object.phone,
                        orderID: object.orderID
                    }

                })
                    .then((data) => {
                        console.log(data.response, 'lets goooooo')
                        if (data.response?.statusCode === 202) {
                            handleSuccessNotificationClick();
                        } else {
                            setMpesaLoading(false);
                            console.error("Problem Processing StK Payment");
                            handleErrorNotificationClick();
                        }
                    })
                    .catch((e) => {
                        setMpesaLoading(false);
                        console.error(`Error Sending STK`, e.message);
                    });
            } catch (error) {
                console.error(error);
                handleErrorNotificationClick();
            }

        }

    };


    const handleKcbPush = async (orderID) => {
        let object = { amount: kcbAmount || invoiceBalance, phone: kcbphone, orderID: orderID };
        // console.log('object', kcbAmount)
        const payurl = bankAccount === "KCB" ? `/api/v1/payments/initiate_kcb_stk_push` : `/api/v1/initiatecoopstkpush`
        if (kcbphone == '') {
            // alert('kindly add a phone number')
            setErrorShow({ state: true, message: 'kindly add a phone number' })
            setkcbloading(false)
        } else if (bankAccount == '') {
            setErrorShow({ state: true, message: 'kindly select  a bank account' })

            setkcbloading(false)
        } else if (invoiceBalance == 0 || object.amount == '' || object.amount == 0) {
            setErrorShow({ state: true, message: 'kindly add  a amount' })
            setkcbloading(false)
        } else {
            setkcbloading(true)
            try {
                HttpComponent({
                    method: 'POST',
                    url: payurl,
                    body: {
                        amount: Math.ceil(kcbAmount) || Math.ceil(initialBalance),
                        phone: kcbphone,
                        orderID: isInvoiceBatch ? "" : orderID,
                        type: isInvoiceBatch ? "tickets" : "invoice",
                        orderIds: [orderID],
                        batchId: isInvoiceBatch ? invoiceNumber : ""
                    },
                    token: localStorage.getItem("X-Authorization")
                }).then((data) => {
                    // console.log(data, '================+++++++++++++++++')
                    if (data.status == 200) {
                        handleSuccessNotificationClick();
                    }
                    else if (data.status === 400) {
                        // console.log('data =======')
                        setErrorShow({ state: true, message: data.response.message })
                        setkcbloading(false)

                    }

                    else {
                        setErrorShow({ state: true, message: data.response.message })
                        setkcbloading(false)
                        // console.error("Problem Processing StK Payment");

                        handleErrorNotificationClick();
                        setErrorShow({ state: true, message: data.response.message })

                    }

                })
                    .catch((e) => {
                        setkcbloading(false)
                        // console.error(`Error Sending STK`, e.message);

                    })

            } catch (error) {
                console.error(error);
                handleErrorNotificationClick();

            }

        }

    }

    const handleCardPayment = async (orderID) => {
        const obj = { email: customerInfo?.email, amount: cardAmount || invoiceBalance, orderID: orderID }

        // validat email adress;
        // let isEmailValid = ValidateEmailAdress(customerInfo?.email)

        // if(!isEmailValid){
        //     setErrorShow({ state: true, message: 'Invalid Email format' })
        //     return 
        // }
        // console.log('Email Address', email, cardAmount, orderID)
        if (obj.cardAmount === '' || obj.cardAmount === 0) {
            setErrorShow({ state: true, message: 'kindly add  a amount' })


        } else {
            try {
                let url = `/api/v1/payments/card/pay`
                if (defaultCurrency === "NGN") {
                    url = `/api/v1/payment/card/accessbank`
                }
                HttpComponent({
                    method: 'POST',
                    // url: "/api/v1/payments/card/pay",
                    url: url,
                    body: {
                        amount: cardAmount || invoiceBalance,
                        // email: email,
                        orderID: orderID,
                        type: "invoice",
                        paymentChanel: "WEB",
                        businessNumber: customerInfo?.businessId
                    },
                    token: localStorage.getItem("X-Authorization")
                }).then((data) => {
                    if (data.status === 200) {
                        if (data.response.data.status === 500) {
                            setErrorShow({ state: true, message: data.response.data.error.message })
                        } else if (data.status === 200) {
                            handleSuccessNotificationClick1();
                            setGeneratedLink(true)
                            setGenerateLinkStatus(true)
                            setCardloading(true)
                            let paymentURL = data?.response?.data?.url
                            if (defaultCurrency === "KES") {
                                setDatagenerated(data.response.data.data.invoiceInformation.paymentLink)
                            } else {
                                setDatagenerated(data?.response?.data?.url)
                            }

                        }


                    } else if (data.status === '401') {
                        setErrorShow({ state: true, message: data.response.message })
                    } else if (data.status === '400') {
                        setErrorShow({ state: true, message: data.response.error })
                    } else {
                        handleErrorNotificationClick1();
                        setErrorShow({ state: true, message: 'Erriirr  r' })
                    }

                }).catch((e) => {

                })

            } catch (error) {
                handleErrorNotificationClick1();
            }

        }




    }


    const getInvoiceDetails = async () => {
        try {
            const invoiceDetailsResponse = await fetch(baseUrl + `/api/get_invoice_by_invoice_number?invoiceNumber=${invoiceNumber}&businessNumber=${foundbid ?? foundbid}`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                        "X-Authorization": localStorage.getItem("X-Authorization"),
                    },
                }
            );

            if (invoiceDetailsResponse.status === 200) {
                const invoiceDetails = await invoiceDetailsResponse.json();
                setInvoiceData(invoiceDetails.data);
                setInitialBalance(invoiceDetails.data.invoiceBalance)
                const disc = invoiceDetails?.data?.invoiceDiscountAmount || 0
                setInvoiceDiscount(disc)
                if (invoiceDetails.data?.paymentLink !== '' && invoiceDetails.data.invoiceStatus === 'Unpaid') {
                    setGenerateLinkStatus(true)
                    setDatagenerated(invoiceDetails.data.paymentLink)

                }
            } else {
                setErrorFetching(true)
            }
        } catch (error) {
            console.error("error fetching invoice details", error.message);
        }
    };

    useEffect(() => {
        if (dataLINK === undefined) {
            // console.log("hakuna data");
            setGeneratedLink(false)
        }

        if (dataLINK) {
            setGeneratedLink(true)
        }
    }, [dataLINK])





    const getInvoicePayments = async () => {
        const invoicePaymentDetailsResponse = await fetch(baseUrl + `/api/get_invoice_payments/${invoiceNumber}`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    'X-Authorization': localStorage.getItem('X-Authorization')
                },
            }
        );
        if (invoicePaymentDetailsResponse.status === 200) {
            const paymentDetailsData = await invoicePaymentDetailsResponse.json();
            let { invoicePayments, invoiceBalance } = paymentDetailsData.data
            setInvoicePayments(invoicePayments);
            setInvoiceBalance(invoiceBalance);
        }
    }

    const [called, setCalled] = useState(false);

    const getInvoiceTwo = async () => {
        const invoicePaymentDetailsResponse = await fetch(baseUrl + `/api/get_invoice_payments/${invoiceNumber}`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    'X-Authorization': localStorage.getItem('X-Authorization')
                },
            }
        );
        if (invoicePaymentDetailsResponse.status === 200) {
            const paymentDetailsData = await invoicePaymentDetailsResponse.json();
            let { invoiceBalance } = paymentDetailsData.data

            // console.log('get initial balance senodnf check =====<><>', invoiceBalance)
            setInitialBalance(invoiceBalance);
            setCalled(true)
        }
    }



    useEffect(() => {
        if (!called && !isInvoiceBatch) {
            getInvoiceTwo()
        } else {
            getBatchData()
        }
    }, [isInvoiceBatch, called]);


    const [errorShow, setErrorShow] = useState({ state: false, message: "" })

    function handleButtonClick(name) {
        setActiveButton(name)
    }

    useEffect(() => {
        if (isInvoiceBatch) {
            getBatchData()
        } else {
            getInvoiceDetails();
            getInvoicePayments()
            setBussinessStkId(darajaConfigId)
        }
    }, [invoiceNumber, kcbphone, userPhone, isInvoiceBatch, foundbid]);

    useEffect(() => {
        handleButtonClick(activeButton)
    }, [activeButton])

    const [counter, setCounter] = useState(300);

    useEffect(() => {
        let intervalId;

        if (mpesaLoading || kcbloading) {
            setCounter(300)
            intervalId = setInterval(() => {
                setCounter((prevCounter) => prevCounter - 1);
            }, 1000);

            setTimeout(() => {
                clearInterval(intervalId);
                setMpesaLoading(false);
                setkcbloading(false)
                setCardloading(false)
                handleErrorNotificationTimeoutClick();
            }, 300000);
        }


        return () => clearInterval(intervalId);
    }, [mpesaLoading, kcbloading]);

    useEffect(() => {
        if (Cardloading) {
            let intervalId;

            setCounter(15)
            intervalId = setInterval(() => {
                setCounter((prevCounter) => prevCounter - 1);
            }, 3000);

            setTimeout(() => {

                setCardloading(false)
            }, 3000);
        }

    }, [Cardloading])


    // console.log('initialBalance  one ====<><>', initialBalance);
    // console.log('invoiceBalance one ====<><>', invoiceBalance);

    useEffect(() => {
        if (intervalIdRef.current) {
            clearInterval(intervalIdRef.current);
        }
        if (mpesaLoading || kcbloading) {
            intervalIdRef.current =
                setInterval(() => {
                    if (isInvoiceBatch === false) {
                        getInvoicePayments();
                    }
                    if (isInvoiceBatch === true) {
                        getBatchData()
                    }
                    if (initialBalance && (parseInt(initialBalance) !== parseInt(invoiceBalance))) {
                        setInvoiceBalance(initialBalance)
                        setMpesaLoading(false);
                        setkcbloading(false)
                        handleSuccessNotificationPaymentReceivedClick()

                        if (invoiceNumber && isInvoiceBatch === false) {
                            navigate(`/invoice/${invoiceNumber}`)
                        }
                        if (isInvoiceBatch === true && invoiceNumber) {
                            navigate(`/school/batch/view/${invoiceNumber}`)
                        }
                        if (darajaConfigId) {
                            navigate(`/view/invoice/${invoiceNumber}/${darajaConfigId}`)
                        }

                        clearInterval(intervalIdRef.current);
                        intervalIdRef.current = null;
                        setUserPhone('')
                        setAmount('')
                    }
                }, 1000)
            setTimeout(() => {
                clearInterval(intervalIdRef.current);
                intervalIdRef.current = null;
                handleErrorNotificationTimeoutClick()
                setMpesaLoading(false);
                setkcbloading(false)
            }, 300000);
        }
        return () => {
            clearInterval(intervalIdRef.current);
            intervalIdRef.current = null;
        };
    }, [mpesaLoading, kcbloading, initialBalance, invoiceBalance, isInvoiceBatch]);

    useEffect(() => {
        if (successmessage) {
            setTimeout(() => {
                SetSuccessMessageCard(false)

            }, 1000)

        }

    }, [successmessage])
    const [bankAccountsKCB, setBankAccountsKCB] = useState([])
    const getListOfBankAccounts = async () => {
        try {
            const response = await fetch(baseUrl + `/api/get_bank_payment_listing`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    "X-Authorization": localStorage.getItem("X-Authorization"),
                },
            })
            const data = response.json()
                .then((response) => {
                    console.log('Bank Accounts hii no hapa', response.data);
                    setBankAccountsKCB(response.data)


                    // setBankAccount(response.data[0].accountNumber) // setBankAccount(response.data[0].accountNumber)
                })

        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        getListOfBankAccounts()
    }, [])

    // console.log(bankAccountsKCB.length, 'bankAccountsKCB.length');
    const [bankAccountKCB, setBankAccountKCB] = useState('')
    const [referenceNo, setReferenceNo] = useState('')
    const handleBankSlip = async (orderID) => {
        const obj = { reference: referenceNo, creditAccount: bankAccountKCB, invoiceNumber: orderID }
        // console.log('Bank Slip', obj)
        if (obj.reference === '' || obj.creditAccount === '') {
            setErrorShow({ state: true, message: "Please fill in all fields" })
            setTimeout(() => {
                setErrorShow({ state: false, message: "" })
            }, 3000)
        } else {
            try {
                const response = await fetch(baseUrl + `/api/v1/validate_kcb_bank_slip`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                        "X-Authorization": localStorage.getItem("X-Authorization"),
                    },
                    body: JSON.stringify(obj)
                })
                const data = response.json()
                    .then((response) => {
                        // console.log('Bank Accounts hii no hapa', response.data);
                        if (response.status === 'success') {
                            navigate(`/invoice/${invoiceNumber}`)
                        } else {
                            setErrorShow({ state: true, message: response.message + `${response.data}` })
                            setTimeout(() => {
                                setErrorShow({ state: false, message: "" })
                            }, 3000)
                        }


                    })

            } catch (error) {
                console.log(error);
            }
        }

    }
    const [MpesaStatus, setMpesaStatus] = useState(false)
    const [KCBBankPaybillStatus, setKCBSBankPaybillstatus] = useState(false)
    const [KCBBankDeposit, setKCBBankDeposit] = useState(false)
    const [CardStatus, setCardStatus] = useState(false)
    const [BankStatus, setBankStatus] = useState(false)
    const [CashStatus, setCashStatus] = useState(false)
    const [numberofPaymentMethodsSet, setNumberofPaymentMethods] = useState(0)
    const [momoStatus, setMomoStatus] = useState(false)
    const [isSettleInvoiceEbaled, setISsettleInvoiceEnabled] = useState(false)
    const [defaultCurrency, setDefaultCurrency] = useState(GetDefaultCurrency())
    const [isFundTransferEnabled, setIsFundTransferEnabled] = useState(false)


    let numberOfPaymentMethods = 0;


    const checkPayemntStatus = async () => {
        try {
            const response = await fetch(baseUrl + `/api/get_payment_methods_with_status?businessNumber=${foundbid ?? foundbid}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    "X-Authorization": localStorage.getItem("X-Authorization"),
                },
            })
            const data = response.json()

                .then((response) => {
                    // console.log('Bank Accounts hapa',response.data);

                    console.log('Bank Accounts hapa', response?.data);
                    const mpesaStatus = response.data.find(method => method.name === "Mpesa")?.status ?? false
                    const cashStatus = response.data.find(method => method.name === "Cash")?.status ?? false
                    const cardStatus = response.data.find(method => method.name === "Card")?.status ?? false
                    const isfundsTrans = response?.data?.find(method => method.name === "FundsTransfer")?.status ?? false

                    let banks = response?.data?.find(method => method?.name === "Banks") ?? []
                    let kcbBanks = banks?.paymentOptions?.find(method => method?.name === "KCB") ?? []
                    const paybillStatus = kcbBanks?.kcb?.find(method => method?.name === "Mobile Money")?.status ?? false
                    const kcbdepositStatus = kcbBanks?.kcb?.find(method => method?.name === "KCB DEPOSIT")?.status ?? false
                    const bankStatus = response.data.find(method => method.name === "Banks")?.status ?? false
                    const momoPayStatus = response?.data?.find(method => method?.name === 'MTN Momo')?.status ?? false
                    const settleInvoiceStatus = response?.data?.find(method => method?.name === 'settleInvoiceStatus')?.status ?? false
                    let accessBanks = banks?.paymentOptions?.find(method => method?.name === "Accessbank") ?? []
                    setMpesaStatus(mpesaStatus)
                    setCardStatus(cardStatus)
                    if (accessBanks?.accessbank?.length > 0) {
                        setCardStatus(true)
                    }
                    setKCBSBankPaybillstatus(paybillStatus)
                    setKCBBankDeposit(kcbdepositStatus)
                    setBankStatus(bankStatus)
                    setCashStatus(cashStatus)
                    setMomoStatus(momoPayStatus)
                    setISsettleInvoiceEnabled(settleInvoiceStatus)
                    setIsFundTransferEnabled(isfundsTrans)

                    // console.log('mpesaStatus', mpesaStatus, 'cardStatus', cardStatus, 'paybillStatus', paybillStatus, 'kcbdepositStatus', kcbdepositStatus, 'bankStatus', bankStatus, 'cashStatus', cashStatus);
                    if (mpesaStatus) {
                        numberOfPaymentMethods = numberOfPaymentMethods + 1
                    } else if (cashStatus) {
                        numberOfPaymentMethods = numberOfPaymentMethods + 1

                    }
                    else if (cardStatus) {
                        numberOfPaymentMethods = numberOfPaymentMethods + 1

                    }
                    else if (paybillStatus) {
                        numberOfPaymentMethods = numberOfPaymentMethods + 1

                    }
                    else if (kcbdepositStatus) {
                        numberOfPaymentMethods = numberOfPaymentMethods + 1

                    }
                    else if (bankStatus) {
                        numberOfPaymentMethods = numberOfPaymentMethods + 1

                    } else if (momoPayStatus) {
                        numberOfPaymentMethods = numberOfPaymentMethods + 1
                    }
                    // console.log('numberOfPaymentMethods', numberOfPaymentMethods);
                    setNumberofPaymentMethods(numberOfPaymentMethods)




                })

        } catch (error) {
            console.log(error);
        }

    }
    useEffect(() => {
        checkPayemntStatus()
    }, [foundbid, state])

    const [cashAmount, setCashAmount] = useState('')
    // console.log('numberOfPaymentMethods here', numberofPaymentMethodsSet);

    const businessNumber = localStorage.getItem('businessId')
    console.log(CashStatus, 'cash tsta')

    const payViaCash = async () => {
        // referenceNo = "19/06/23 11:40 AM"
        if (initialBalance === 0) {
            setErrorShow({ state: true, message: "Please fill in cash amount received" })
            setTimeout(() => {
                setErrorShow({ state: false, message: "" })
            }, 3000)
        } else {

            const reference = new Date().toLocaleString()


            const response = await fetch(baseUrl + `/api/v1/payments/generalpayment`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    "X-Authorization": localStorage.getItem("X-Authorization"),
                },
                body: JSON.stringify({
                    amount: cashAmount || Math.ceil(initialBalance),
                    referenceNo: reference,
                    businessNumber: businessNumber,
                    batchId: isInvoiceBatch ? invoiceNumber : '',
                    invoiceNumber: invoiceNumber,
                    paymentMethod: "Cash Payment",
                    paymentChanel: 'Web',
                    type: isInvoiceBatch ? 'tickets' : ''
                })
            })
            await response.json()
                .then((response) => {
                    // console.log('Payment successfully processed', response);
                    if (response?.status === "Success" || response?.status === "SUCCESS") {
                        setSuccessNotificationReceivedPaymentOpen(true)
                        setTimeout(() => {
                            setSuccessNotificationReceivedPaymentOpen(false)
                            getInvoiceDetails()
                            if (isInvoiceBatch) {
                                navigate(`/school/batch/view/${invoiceNumber}`)
                            } else {
                                navigate(`/school/invoice/${invoiceNumber}`)
                            }
                        }, 3000)
                    } else {
                        setErrorShow({ state: true, message: response.message })
                        setTimeout(() => {
                            setErrorShow({ state: false, message: "" })
                        }, 3000)
                    }
                })
        }



    }

    // validate uploaded bankslips
    const [bankAccounts, setBankAccounts] = useState([])
    const [selectedBankAccount, setSelectedBankAccount] = useState('')
    const [selectedSettleInvoiceMethod, setSelectedSettleInvoiceMethod] = useState('')
    const [bankslipRefNo, setBankslipRef] = useState()
    const [refDetailsFound, setRefDetailsFound] = useState([])
    const [isValidated, setIsValidated] = useState(false)
    const [refId, setRefId] = useState(null)
    const [bankSlipAmount, setBankSlipAmount] = useState(0)
    const [slipAccountNumber, setSlipAccountNumber] = useState('')
    const [bankSlipBalance, setBankSlipBalance] = useState(0);
    const [bankSlipAccountAmount, setBankSlipAccountAmount] = useState(0);
    const [idNumber, setIdNumber] = useState('')

    const [settleinvoiveOptions, setSettleInvoiceOptions] = useState([])

    function getSettleInvoiceOptions() {
        try {
            HttpComponent({
                method: 'GET',
                url: `/api/v1/getpaymentmethod`,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data?.status === 200) {
                    setSettleInvoiceOptions(data?.response?.data)
                }
            })
        } catch (error) {

        }
    }

    useEffect(() => {
        getSettleInvoiceOptions()
    }, [])

    // console.log('bankSlipAccountAmount ======<><>', bankSlipAccountAmount);

    //all banks
    function fetchAllBanksAvailable() {
        try {
            HttpComponent({
                method: 'GET',
                url: `/api/v1/getAllBankStatementAccounts`,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data.status === 200) {
                    setBankAccounts(data?.response?.data)
                }

            })

        } catch (error) {

        }
    }

    useEffect(() => {
        fetchAllBanksAvailable()
    }, [])

    // validate uploaded bank statement

    function validateUploadedTransactionRef() {
        try {
            HttpComponent({
                method: 'POST',
                url: `/api/v1/validatebankslip`,
                body: {
                    accountNumber: selectedBankAccount,
                    referenceNumber: bankslipRefNo
                },
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                // console.log(data , 'data >>>>>>>>>>>>')
                if (data.status === 200) {
                    setSuccessShow({ state: true, message: data?.response?.message });
                    setRefDetailsFound(data?.response?.data)
                    setRefId(data?.response?.data[0]?._id)
                    setBankSlipAmount(data?.response?.data[0]?.creditAmount)
                    setSlipAccountNumber(data?.response?.data[0]?.accountNumber)
                    setIsValidated(true)
                    setBankSlipBalance(data?.response?.data[0]?.balance)
                } else if (data.status === 400) {
                    setErrorShow({ state: true, message: data?.response?.message })
                }
            })
        } catch (error) {
            setErrorShow({ state: true, message: error.message })

        }
    }

    // settle invoice

    const [settleInvoiceAmount, setSettleInvoiceAmount] = useState()
    const [settleInvoiceRefNo, setSettleInvoiceRefNo] = useState()

    // pay invoice using the ValidatedbankSlip
    const payViaValidatedSlip = () => {
        try {
            HttpComponent({
                method: 'POST',
                url: `/api/v1/processBankSlipPayment`,
                body: {
                    invoiceNumber: invoiceNumber,
                    invoiceAmount: bankSlipAccountAmount ? bankSlipAccountAmount : initialBalance,
                    referenceId: refId,
                    referenceNumber: bankslipRefNo,
                    accountNumber: slipAccountNumber
                },
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data?.status === 200) {
                    setSuccessShow({ state: true, message: data?.response?.message })

                    setTimeout(() => {
                        navigate(`/school/invoice/${invoiceNumber}`)
                    }, 3000);

                } else if (data?.status === 400) {
                    setErrorShow({ state: true, message: data?.response?.message })
                } else {
                    setErrorShow({ state: true, message: data?.response?.message })
                }
            })
        } catch (error) {
            setErrorShow({ state: true, message: error?.message })
        }
    }

    const payViaSettleInvoice = async () => {
        // referenceNo = "19/06/23 11:40 AM"
        if (initialBalance === 0) {
            return setErrorShow({ state: true, message: "Please fill in  amount  field" })
        } else {

            const response = await fetch(baseUrl + `/api/v1/payments/generalpayment`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    "X-Authorization": localStorage.getItem("X-Authorization"),
                },
                body: JSON.stringify({
                    amount: settleInvoiceAmount || Math.ceil(initialBalance),
                    referenceNo: settleInvoiceRefNo,
                    businessNumber: businessNumber,
                    invoiceNumber: invoiceNumber,
                    paymentMethod: selectedSettleInvoiceMethod,
                    paymentChanel: 'Web'
                })
            })
            await response.json()
                .then((response) => {
                    // console.log('Payment successfully processed', response);
                    if (response.status === "Success") {
                        setSuccessNotificationReceivedPaymentOpen(true)
                        setTimeout(() => {
                            setSuccessNotificationReceivedPaymentOpen(false)
                            getInvoiceDetails()
                            // console.log('im navigating to preview invoice', `/school/invoice/${invoiceNumber}`);
                            navigate(`/school/invoice/${invoiceNumber}`)

                        }, 3000)
                    } else {
                        setErrorShow({ state: true, message: response.message })
                        setTimeout(() => {
                            setErrorShow({ state: false, message: "" })
                        }, 3000)
                    }
                })
        }



    }


    const [ectizenStatus, setEctizenStatus] = useState(false)
    const [customerInfo, setCustomerInfo] = useState({})

    const checkEcitizenStatusMethod = async () => {
        HttpComponent({
            method: 'GET',
            url: `/api/v1/ecitizen/get_status`,
            body: null,
            token: X_Authorization
        }).then((data) => {

            if (data.status === 200) {
                let status = data.response?.ecitizenStatus
                // console.log(status, 'status---')
                setEctizenStatus(status)

            } else {
            }
        }).catch((error) => {
            console.error(error, 'error');
        })


    }
    const customerInfoMethod = async () => {
        HttpComponent({
            method: 'GET',
            url: `/api/v1/ecitizen/info_customer/${invoiceNumber}`,
            body: null,
            token: X_Authorization
        }).then((data) => {

            if (data.status === 200) {
                let info = data.response.customer
                // console.log(info, 'info---')
                setCustomerInfo(info)

            } else {
            }
        }).catch((error) => {
            console.error(error, 'error');
        })


    }

    useEffect(() => {
        checkEcitizenStatusMethod()
        customerInfoMethod()

    }, [])

    // handleChangeInCustomerInfo
    const handleChangeInCustomerInfo = (e) => {
        setCustomerInfo({ ...customerInfo, [e.target.name]: e.target.value })
    }


    const payEcitizen = async (invoiceNumber) => {
        try {
            const response = await fetch(baseUrl + `/api/v1/ecitizen/processEcitizenPayment`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    "X-Authorization": localStorage.getItem("X-Authorization"),
                },
                body: JSON.stringify({
                    invoiceNumber: invoiceNumber,
                    email: customerInfo?.email,
                    phone: customerInfo?.phone,
                    id_number: idNumber,
                    fullName: `${customerInfo?.firstName} ${customerInfo?.lastName}`,
                })
            });

            if (!response.ok) {
                const error = await response.json();
                setErrorShow({ state: true, message: error?.message || 'Error processing payment' })
                return
            }

            const html = await response.text();
            const newWindow = window.open();
            newWindow.document.write(html);
        } catch (error) {
            console.error(error);
        }
    }


    // check customer businessif ft is enabled
    const [iscustomerFtenabled, setiscutsomerftenabled] = useState(true)
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
    const handleOpenCloseDialog = () => setOpenDeleteDialog(!openDeleteDialog)

    function CheckisCustomerFt() {
        try {
            HttpComponent({
                method: 'GET',
                url: `/api/business/getfundstranferconfig`,
                token: localStorage.getItem('X-Authorization')
            }).then((response) => {
                if (response?.status === 200) {
                    setiscutsomerftenabled(response?.response?.isfundTransferSet)
                }
            })
        } catch (error) {

        }
    }

    useEffect(() => {
        CheckisCustomerFt()
    }, [])

    const handleNavigateToFt = () =>{
        navigate(`/business/ftseup`)
    }




    return (
        <Box component={'div'} sx={{width:"100%"}}>

            {/*Alerts*/}

            {/*Success Creation*/}
            <SuccessAlert message={'You will receive a prompt on your phone'} open={successNotificationOpen} onClose={handleSuccessNotificationClose} horizontal={'right'} vertical={'top'} />
            <SuccessAlert message={'Payment link generated successfully'} open={successNotificationOpen1} onClose={handleSuccessNotificationClose1} horizontal={'right'} vertical={'top'} />
            <SuccessAlert message={'Link copied successfully'} open={successNotificationOpen2} onClose={handleSuccessNotificationClose2} horizontal={'right'} vertical={'top'} />

            <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...SuccessShow, state: false })} open={SuccessShow.state} message={SuccessShow.message} />
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />


            {/*Payment Received*/}
            <SuccessAlert message={`Payment received`} open={successNotificationReceivedPaymentOpen} onClose={handleSuccessNotificationReceivedPaymentClose} horizontal={'right'} vertical={'top'} />

            {/*error sending stk*/}
            <ErrorAlert message={`Error With Stk`} open={errorNotificationOpen} onClose={handleErrorNotificationClose} horizontal={'right'} vertical={'top'} />

            <ErrorAlert message={`Error With Sending the payment`} open={errorNotificationOpen1} onClose={handleErrorNotificationClose1} horizontal={'right'} vertical={'top'} />


            {/*error TimeOut*/}
            <ErrorAlert message={`Timeout On Awaiting Payment`} open={errorNotificationTimeoutOpen} onClose={handleErrorNotificationTimeoutClose} horizontal={'right'} vertical={'top'} />

            {/*Missing Business Config ID*/}
            <ErrorAlert message={`Missing Mpesa STK Config Id!`} open={errorNotificationNoBusinessConfigOpen} onClose={handleErrorNotificationNoBusinessConfigClose} horizontal={'right'} vertical={'top'} />

            <ErrorAlert
                vertical="top"
                horizontal="right"
                onClose={() => setErrorShow({ ...errorShow, state: false })}
                open={errorShow.state}
                message={errorShow.message} />

            {/*mainContainer*/}
            <Grid container direction={isMobileUI ? "column" : 'row'} justifyContent={isMobileUI ? null : 'space-between'} sx={{ marginLeft: "5%" }}>

                {/*Labels*/}
                <Grid style={{ width: isMobileUI ? "100%" : '30%' }} item>

                    {/*Label*/}
                    <Grid container mb={7}>
                        <Grid item>
                            <span style={{ color: '#032541', fontSize: '20px', fontWeight: '600' }} >Select Payment Method</span>
                        </Grid>
                    </Grid>

                    {/*Cards*/}
                    <Grid container direction={'column'} justifyContent={'flex-start'} spacing={7}>

                        {/*Mpesa Card*/}
                        {MpesaStatus && defaultCurrency !== "NGN" ?
                            <Grid item>
                                <ButtonBase onClick={() => { handleButtonClick('mpesa') }}>
                                    <Card style={{ background: `${activeButton === 'mpesa' ? 'rgba(23, 174, 123, 0.06)' : '#fff'}`, height: '94px', width: '350px', boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.16)', borderRadius: '6px' }}>
                                        <Grid container direction={'row'} justifyContent={'flex-start'} alignContent={'center'} spacing={-2}>

                                            {/*mpesa image*/}
                                            <Grid item>
                                                <img src={Mpesa} alt="Mpesa" style={{ height: "32px", width: "100px", marginTop: "30px", marginLeft: '15px' }} />
                                            </Grid>

                                            {/*Mpesa name*/}
                                            <Grid item style={{ marginTop: "30px" }}>
                                                <span style={{ color: '#032541', marginLeft: '15px', fontSize: '18px', fontWeight: 600 }}>Mpesa</span>
                                            </Grid>

                                        </Grid>
                                    </Card>
                                </ButtonBase>
                            </Grid>
                            : null}
                        {/* bank paybill */}
                        {KCBBankPaybillStatus && defaultCurrency !== "NGN" ?
                            <Grid item>
                                <ButtonBase onClick={() => { handleButtonClick('bankpaybill') }}>
                                    <Card style={{ background: `${activeButton === 'bankpaybill' ? '#f0f2f3' : '#fff'}`, height: '94px', width: isMobileUI ? '345px' : "355px", boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.16)', borderRadius: '6px' }}>
                                        <Grid container direction={'row'} justifyContent={'flex-start'} alignContent={'center'} spacing={-2}>

                                            {/*Card image*/}
                                            <Grid item>
                                                <img src={BankPayBillIcon} alt="BankPayBillIcon" style={{ height: "52px", width: "100px", marginTop: "20px", marginLef: '0px' }} />
                                            </Grid>

                                            {/*Card name*/}
                                            <Grid item style={{ marginTop: "30px" }}>
                                                <span style={{ color: '#032541', marginLeft: '15px', fontSize: '18px', fontWeight: 600 }}>Mobile to Bank</span>
                                            </Grid>

                                        </Grid>
                                    </Card>
                                </ButtonBase>
                            </Grid>
                            : null}

                        {momoStatus && defaultCurrency !== "NGN" ?
                            <Grid item mt={2}>
                                <ButtonBase onClick={() => { handleButtonClick('MTN Momo') }}>
                                    <Card style={{ background: `${activeButton === 'MTN Momo' ? '#f0f2f3' : '#fff'}`, height: '94px', width: isMobileUI ? '345px' : "355px", boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.16)', borderRadius: '6px' }}>
                                        <Grid container direction={'row'} justifyContent={'flex-start'} alignContent={'center'} spacing={-2}>
                                            {/*Card image*/}
                                            <Grid item>
                                                <img src={MomoIcon} alt="Mpesa" style={{ height: "32px", width: "100px", marginTop: "30px", marginLef: '0px' }} />
                                            </Grid>
                                            {/*Card name*/}
                                            <Grid item style={{ marginTop: "30px" }}>
                                                <span style={{ color: '#032541', marginLeft: '15px', fontSize: '18px', fontWeight: 600 }}>MTN Momo</span>
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </ButtonBase>
                            </Grid>
                            : null}
                        {/*Card*/}
                        {CardStatus ?
                            <Grid item>
                                <ButtonBase onClick={() => { handleButtonClick('card') }}>
                                    <Card style={{ background: `${activeButton === 'card' ? '#f0f2f3' : '#fff'}`, height: '94px', width: isMobileUI ? '345px' : "355px", boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.16)', borderRadius: '6px' }}>
                                        <Grid container direction={'row'} justifyContent={'flex-start'} alignContent={'center'} spacing={-2}>

                                            {/*Card image*/}
                                            <Grid item>
                                                <img src={CardImg} alt="Mpesa" style={{ height: "32px", width: "100px", marginTop: "30px", marginLef: '0px' }} />
                                            </Grid>

                                            {/*Card name*/}
                                            <Grid item style={{ marginTop: "30px" }}>
                                                <span style={{ color: '#032541', marginLeft: '15px', fontSize: '18px', fontWeight: 600 }}>Card</span>
                                            </Grid>

                                        </Grid>
                                    </Card>
                                </ButtonBase>
                            </Grid>
                            : null}
                        {isFundTransferEnabled ?
                            <Grid item>
                                <ButtonBase onClick={() => { iscustomerFtenabled ? '' : handleOpenCloseDialog() }}>
                                    <Card style={{ background: `${activeButton === 'card' ? '#f0f2f3' : '#fff'}`, height: '94px', width: isMobileUI ? '345px' : "355px", boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.16)', borderRadius: '6px' }}>
                                        <Grid container direction={'row'} justifyContent={'flex-start'} alignContent={'center'} spacing={-2}>

                                            {/*Card image*/}
                                            <Grid item>
                                                <img src={FundsImg} alt="Mpesa" style={{ height: "32px", width: "100px", marginTop: "30px", marginLef: '0px' }} />
                                            </Grid>

                                            {/*Card name*/}
                                            <Grid item style={{ marginTop: "30px" }}>
                                                <span style={{ color: '#032541', marginLeft: '15px', fontSize: '18px', fontWeight: 600 }}>Fund Transfer (FT)</span>
                                            </Grid>

                                        </Grid>
                                    </Card>
                                </ButtonBase>
                            </Grid>
                            : null}
                        {/* RECEIVE PAYMENT */}
                        {KCBBankDeposit && KCBBankPaybillStatus && MpesaStatus && CardStatus && group === "Merchant" || group === "Owner" ?

                            <Grid item>
                                <span>Request Payment</span>
                            </Grid>
                            :
                            !KCBBankDeposit && !KCBBankPaybillStatus && !momoStatus && !MpesaStatus && !CardStatus && group === 'Customer' ?
                                <Grid item>
                                    <span style={{ textTransform: "uppercase" }}>NO PAYMENT ACTIVATED KINDLY CONTACT THE  {localStorage.getItem("businessName")}</span>
                                </Grid>
                                : null}
                        {KCBBankDeposit ?
                            <>
                                <Grid item>
                                    <ButtonBase onClick={() => { handleButtonClick('bankSlip') }}>
                                        <Card style={{ background: `${activeButton === 'bankSlip' ? '#f0f2f3' : '#fff'}`, height: '94px', width: isMobileUI ? '345px' : "355px", boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.16)', borderRadius: '6px' }}>
                                            <Grid container direction={'row'} justifyContent={'flex-start'} alignContent={'center'} spacing={-2}>
                                                {/*Card image*/}
                                                <Grid item>
                                                    <img src={BankSlip} alt="Mpesa" style={{ height: "32px", width: "100px", marginTop: "30px", marginLef: '0px' }} />
                                                </Grid>
                                                {/*Card name*/}
                                                <Grid item style={{ marginTop: "30px" }}>
                                                    <span style={{ color: '#032541', marginLeft: '15px', fontSize: '18px', fontWeight: 600 }}>Bank Slip</span>
                                                </Grid>
                                            </Grid>
                                        </Card>
                                    </ButtonBase>
                                </Grid>
                            </>
                            : ectizenStatus ? <Grid item>
                                <ButtonBase onClick={() => { handleButtonClick('ecitizen') }}>
                                    <Card style={{ background: `${activeButton === 'ecitizen' ? '#f0f2f3' : '#fff'}`, height: '94px', width: isMobileUI ? '345px' : "355px", boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.16)', borderRadius: '6px' }}>
                                        <Grid container direction={'row'} justifyContent={'flex-start'} alignContent={'center'} spacing={-2}>
                                            {/*Card image*/}
                                            <Grid item>
                                                <img src={EcitizenPng} alt="EcitizenPng" style={{ height: "32px", width: "100px", marginTop: "30px", marginLef: '0px', objectFit: "contain" }} />
                                            </Grid>
                                            {/*Card name*/}
                                            <Grid item style={{ marginTop: "30px" }}>
                                                <span style={{ color: '#032541', marginLeft: '15px', fontSize: '18px', fontWeight: 600 }}>Ecitizen</span>
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </ButtonBase>
                            </Grid>
                                : null}

                    </Grid>
                    <Grid container mt={3} direction={'column'}>
                        {
                            (localCurrency !== "NGN") && <Grid item mb={2}>
                                <Typography variant="h6" style={{ color: '#032541', fontSize: '20px', fontWeight: '600' }}>Receive Payment</Typography>
                            </Grid>
                        }

                        {(group === 'Customer') || (localCurrency === "NGN") || (businessCat === 'Distributor' && foundbid) ? null :
                            <Grid item mb={2}>
                                <ButtonBase onClick={() => { handleButtonClick('uploadeBankSlips') }}>
                                    <Card style={{ background: `${activeButton === 'cash' ? '#f0f2f3' : '#fff'}`, height: '94px', width: isMobileUI ? '345px' : "355px", boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.16)', borderRadius: '6px' }}>
                                        <Grid container direction={'row'} justifyContent={'flex-start'} alignContent={'center'} spacing={-2}>
                                            {/*Card image*/}
                                            <Grid item>
                                                <img src={SlipUpload} alt="Mpesa" style={{ height: "32px", width: "100px", marginTop: "30px", marginLef: '0px' }} />
                                            </Grid>
                                            {/*Card name*/}
                                            <Grid item style={{ marginTop: "30px" }}>
                                                <span style={{ color: '#032541', marginLeft: '15px', fontSize: '16px', fontWeight: 600 }}>Uploaded Bank Statements</span>
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </ButtonBase>
                            </Grid>}
                        {isSettleInvoiceEbaled && group !== 'Customer' ?
                            <Grid item mb={2}>
                                <ButtonBase onClick={() => { handleButtonClick('settleInvoice') }}>
                                    <Card style={{ background: `${activeButton === 'cash' ? '#f0f2f3' : '#fff'}`, height: '94px', width: isMobileUI ? '345px' : "355px", boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.16)', borderRadius: '6px' }}>
                                        <Grid container direction={'row'} justifyContent={'flex-start'} alignContent={'center'} alignItems={'center'} spacing={-2}>
                                            {/*Card image*/}
                                            <Grid item>
                                                <img src={SettleInvoice} alt="Mpesa" style={{ height: "32px", width: "100px", marginTop: "30px", marginLef: '0px' }} />
                                            </Grid>
                                            {/*Card name*/}
                                            <Grid item style={{ marginTop: "30px" }}>
                                                <span style={{ color: '#032541', marginLeft: '15px', fontSize: '16px', fontWeight: 600 }}>Settle Invoice</span>
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </ButtonBase>
                            </Grid> : null}
                        {(CashStatus && (localCurrency !== "NGN") && group !== "Customer") || (CashStatus && group !== "Customer") ?
                            <Grid item>
                                <ButtonBase onClick={() => { handleButtonClick('cash') }}>
                                    <Card style={{ background: `${activeButton === 'cash' ? '#f0f2f3' : '#fff'}`, height: '94px', width: isMobileUI ? '345px' : "355px", boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.16)', borderRadius: '6px' }}>
                                        <Grid container direction={'row'} justifyContent={'flex-start'} alignContent={'center'} spacing={-2}>
                                            {/*Card image*/}
                                            <Grid item>
                                                <img src={CashIcon} alt="Mpesa" style={{ height: "32px", width: "100px", marginTop: "30px", marginLef: '0px' }} />
                                            </Grid>
                                            {/*Card name*/}
                                            <Grid item style={{ marginTop: "30px" }}>
                                                <span style={{ color: '#032541', marginLeft: '15px', fontSize: '18px', fontWeight: 600 }}>Cash</span>
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </ButtonBase>
                            </Grid>
                            : null}

                    </Grid>
                </Grid>

                {/*Display Arena*/}
                {activeButton === 'mpesa' ?
                    <Grid item style={{ background: 'rgba(23, 174, 123, 0.06)', width: '60%', display: 'flex', flexShrink: 1, marginRight: '2.5%' }}>

                        <Grid container direction={'column'} ml={1} spacing={3}>

                            {/*Header lable*/}
                            <Grid item mt={5} >
                                <span style={{ fontStyle: '16px', fontWeight: '600', color: '#032541' }} >Pay invoice-Mpesa</span>
                            </Grid>

                            {/*Invoice card Information*/}
                            <Grid item>

                                <Grid container direction={'row'} spacing={2}>
                                    <Grid item style={{ width: '70%' }}>
                                        {/*PlyCard*/}
                                        <Grid container direction={'column'} spacing={0.5} style={{ borderRadius: '10px', background: 'rgba(112, 112, 112, 0.05)' }}>
                                            <Grid item>
                                                <span style={{ color: '#707070', fontSize: '14px' }}>Invoice No:</span>
                                            </Grid>
                                            <Grid item>
                                                <span style={{ color: '#032541', fontWeight: 600, fontSize: '18px' }}>{isInvoiceBatch ? batchData?.batchNumber : invoiceNumber}</span>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item style={{ width: '30%' }}>
                                        {/*Amount*/}
                                        <Grid container direction={'column'} spacing={0.5} style={{ borderRadius: '10px' }}>
                                            <Grid item>
                                                <span style={{ color: '#707070', fontSize: '14px' }}>Invoice Balance:</span>
                                            </Grid>
                                            <Grid item>
                                                <span style={{ color: '#dc3545', fontWeight: 600, fontSize: '18px' }}>{numberFormat(Math.ceil(initialBalance))}</span>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                </Grid>

                            </Grid>

                            {/*Mpesa Instruction*/}
                            <Grid item mt={-2}>
                                <span style={{ fontSize: '14px' }} >Kindly enter your mobile number to receive the payment prompt and enter you M-Pesa Pin.</span>
                            </Grid>

                            {/*Mobile input*/}
                            <Grid item>
                                <div style={{ marginBottom: '10px' }}>
                                    <span style={{ fontSize: '12px', color: '#032541' }}>Mobile no.</span>
                                </div>

                                <PhoneInput required inputStyle={{ height: '55px', width: '500px' }} country={"ke"} enableSearch={true} value={userPhone} onChange={(phone) => setUserPhone(phone)} />
                            </Grid>

                            {/*Amount Input*/}
                            <Grid item>
                                <div style={{ marginBottom: '10px' }}>
                                    <span style={{ fontSize: '12px', color: '#032541' }}>Amount.</span>
                                </div>
                                <TextField defaultValue={Math.ceil(initialBalance)} onChange={(e) => { setAmount(e.target.value) }} type='number' InputProps={{ style: { fontFamily: 'Poppins', background: '#fff' } }} InputLabelProps={{ style: { fontFamily: 'Poppins', fontSize: "13px", color: "#032541" } }} style={{ width: '500px', border: "solid 0px #e4e4e4", fontSize: "12px" }} id="outlined-basic" label="Amount" variant="outlined" required />
                            </Grid>

                            {/*Send prompt button*/}
                            <Grid item mt={10} mb={9}>
                                <Grid container justifyContent={'center'} alignContent={'center'}>
                                    <Grid item>
                                        {mpesaLoading ?
                                            <>
                                                <LoadingButton sx={{ width: "407px", backgroundColor: "#34A353", color: "white", '&:hover': { backgroundColor: '#34A353', color: 'white' } }}
                                                    endIcon={<SendIcon />}
                                                    loading={true}
                                                    loadingPosition="end"
                                                    variant="contained">
                                                    <span>Awaiting Payment</span>
                                                </LoadingButton>
                                                <Box sx={{ display: "flex", textAlign: "center", justifyContent: "center", width: "100%" }}>
                                                    <Typography sx={{ alignSelf: "center", marginLeft: "auto", marginRight: "auto" }}>
                                                        Resend Prompt: {counter}
                                                    </Typography>
                                                </Box>
                                            </>

                                            :
                                            <>
                                                <Button onClick={() => { handleClick(invoiceNumber) }} style={{ width: '407px', background: "#032541", textTransform: 'none', color: '#fff', fontWeight: 'bold', fontSize: '14px' }} >Pay Now</Button>
                                            </>
                                        }
                                    </Grid>
                                </Grid>

                            </Grid>

                        </Grid>

                    </Grid> : activeButton === 'card' ?

                        <Grid item style={{ background: 'rgba(23, 174, 123, 0.06)', width: isMobileUI ? "95%" : '60%', display: 'flex', flexShrink: 1, marginRight: '2.5%' }}>

                            <Grid container direction={'column'} ml={1} spacing={3}>
                                {GenerteLink ? <>
                                    {/* {console.log('GenerteLink inside div',GenerteLink)} */}
                                    <div className="pt-5 mt-5">
                                        <div className="div-card-ready" style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                                            <img src={CARDREADY} alt="" />

                                            <div className="pt-3">
                                                <h5 style={{ fontSize: "20px", color: "#032541", textAlign: "center", fontWeight: "bold" }}>Your Payment Link is Ready</h5>
                                                <h4 style={{ fontSize: "14px", color: "#707070", textAlign: "center" }}>Open or Copy your payment link for </h4>
                                                <h4 style={{ fontSize: "14px", color: "#032541", textAlign: "center" }}>Invoice NO: {invoiceNumber}</h4>
                                                <h4 style={{ fontSize: "16px", color: "#dc3545", textAlign: "center" }}>Balance  {numberFormat(invoiceBalance)} </h4>
                                                <input style={{ display: "", opacity: "0" }} ref={textRef} type="text" value={dataLINK} readOnly />


                                                <div className="button-group" style={{ display: "flex", justifyContent: "center", alignContent: "center" }}>

                                                    <Button style={{ height: "40px", marginTop: "19px", padding: "0px 20px", fontSize: "16px", fontWeight: "500", color: "#032541", border: "2px solid #032541", }} onClick={handleCopy} >Copy Link</Button>
                                                    {/* <a href={`${dataLINK}`}> */}
                                                    <Button style={{ height: "40px", marginTop: "19px", padding: "0px 20px", fontSize: "16px", fontWeight: "500", color: "#fff", backgroundColor: "#032541", border: "2px solid #032541" }} className="ml-3" onClick={() => {
                                                        window.location.assign(dataLINK)
                                                    }} >Open Link</Button>

                                                    {/* </a> */}


                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </> : <>
                                    <>
                                        {/*Header lable*/}
                                        <Grid item mt={5} >
                                            <span style={{ fontStyle: '16px', fontWeight: '600', color: '#032541' }} >Pay invoice-Card</span>
                                        </Grid>

                                        {/*Invoice card Information*/}
                                        <Grid item>

                                            <Grid container direction={isMobileUI ? "column" : 'row'} spacing={2}>
                                                <Grid item style={{ width: isMobileUI ? '90%' : '70%' }}>
                                                    {/*PlyCard*/}
                                                    <Grid container direction={'column'} spacing={0.5} style={{ borderRadius: '10px', background: 'rgba(112, 112, 112, 0.05)' }}>
                                                        <Grid item>
                                                            <span style={{ color: '#707070', fontSize: '14px' }}>Invoice No:</span>
                                                        </Grid>
                                                        <Grid item>
                                                            <span style={{ color: '#032541', fontWeight: 600, fontSize: '18px' }}>{invoiceNumber}</span>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item style={{ width: isMobileUI ? "90%" : '30%' }}>
                                                    {/*Amount*/}
                                                    <Grid container direction={'column'} spacing={0.5} style={{ borderRadius: '10px' }}>
                                                        <Grid item>
                                                            <span style={{ color: '#707070', fontSize: '14px' }}>Invoice Balance:</span>
                                                        </Grid>
                                                        <Grid item>
                                                            <span style={{ color: '#dc3545', fontWeight: 600, fontSize: '18px' }}>{numberFormat(invoiceBalance)}</span>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                            </Grid>

                                        </Grid>

                                        {/*Mpesa Instruction*/}
                                        <Grid item mt={-2}>
                                            {/* <span style={{ fontSize: '14px' }} >Kindly enter your email address to receive the payment prompt </span> */}
                                        </Grid>

                                        {/*Mobile input*/}
                                        <Grid item>
                                            <div style={{ marginBottom: '20px', }}>
                                                <span style={{ fontSize: '12px', color: '#032541' }}>EMAIL ADDRESS.</span>
                                            </div>
                                            <TextField required InputProps={{ style: { fontFamily: 'Poppins', background: '#fff' } }} InputLabelProps={{ style: { fontFamily: 'Poppins', fontSize: "13px", color: "#032541" } }} style={{ width: isMobileUI ? "300px" : '500px', border: "solid 0px #e4e4e4", fontSize: "12px" }} value={customerInfo?.email} disabled id="outlined-basic" label="Email Address" variant="outlined" />


                                            {/* <TextField required InputProps={{ style: { fontFamily: 'Poppins', background: '#fff' } }} InputLabelProps={{ style: { fontFamily: 'Poppins', fontSize: "13px", color: "#032541" } }} style={{ width: '500px', border: "solid 0px #e4e4e4", fontSize: "12px" }} value={email} onChange={(e) => setEmailAddress(e.target.value)} id="outlined-basic" label="Email Address" variant="outlined" /> */}
                                        </Grid>

                                        {/*Amount Input*/}
                                        <Grid item>
                                            <div style={{ marginBottom: '10px' }}>
                                                <span style={{ fontSize: '12px', color: '#032541' }}>Amount.</span>
                                            </div>
                                            <TextField defaultValue={invoiceBalance} onChange={(e) => { setCardAmout(e.target.value) }} type='number' InputProps={{ style: { fontFamily: 'Poppins', background: '#fff' } }} InputLabelProps={{ style: { fontFamily: 'Poppins', fontSize: "13px", color: "#032541" } }} style={{ width: isMobileUI ? "300px" : '500px', border: "solid 0px #e4e4e4", fontSize: "12px" }} id="outlined-basic" label="Amount" variant="outlined" required />
                                        </Grid>

                                        {/*Send prompt button*/}
                                        <Grid item mt={10} mb={9}>
                                            <Grid container justifyContent={'center'} alignContent={'center'}>
                                                <Grid item>
                                                    {/* 
                                                {GenerteLink ? <>
                                                    <Button style={{ width: '407px', background: "#032541", textTransform: 'none', color: '#fff', fontWeight: 'bold', fontSize: '14px' }} >CLICK HERE TO PAY</Button>

                                                </> : <>
                                                <Button onClick={() => { handleCardPayment(invoiceNumber) }} style={{ width: '407px', background: "#032541", textTransform: 'none', color: '#fff', fontWeight: 'bold', fontSize: '14px' }} >Generate  Link</Button>

                                                </>} */}

                                                    {Cardloading ?
                                                        <>
                                                            <LoadingButton sx={{ width: "407px", backgroundColor: "#34A353", color: "white", '&:hover': { backgroundColor: '#34A353', color: 'white' } }}
                                                                endIcon={<SendIcon />}
                                                                loading={true}
                                                                loadingPosition="end"
                                                                variant="contained">
                                                                <span>Awaiting </span>
                                                            </LoadingButton>
                                                            <Box sx={{ display: "flex", textAlign: "center", justifyContent: "center", width: "100%" }}>
                                                                <Typography sx={{ alignSelf: "center", marginLeft: "auto", marginRight: "auto" }}>
                                                                    Generating Link: {counter}
                                                                </Typography>
                                                            </Box>
                                                        </>

                                                        :
                                                        <>
                                                            {/* {GenerateLinkStatus  ?  <div className="d-flex">
                                                        <div className="d-flex justify-content-space-between">
                                                            <Link to={`${dataLINK}`}>
                                                        
                                                        <Button  style={{ width: '300px', background: "#032541", textTransform: 'none', color: '#fff', fontWeight: 'bold', fontSize: '14px' }} >Open LINK</Button>
                                                        </Link>
                                                        <Button onClick={() => { handleCardPayment(invoiceNumber) }} className="ml-2" style={{ width: '300px', background: "#032541", textTransform: 'none', color: '#fff', fontWeight: 'bold', fontSize: '14px' }} >Generate Link</Button>

                                                        </div>
                                                    </div> :
                                                <>
                                                <Button onClick={() => { handleCardPayment(invoiceNumber) }} style={{ width: '407px', background: "#032541", textTransform: 'none', color: '#fff', fontWeight: 'bold', fontSize: '14px' }} >Generate Link</Button> </> */}
                                                            {/* } */}

                                                            <Button onClick={() => { handleCardPayment(invoiceNumber) }} style={{ width: isMobileUI ? "250px" : '407px', background: "#032541", textTransform: 'none', color: '#fff', fontWeight: 'bold', fontSize: '14px' }} >Generate Link</Button>

                                                        </>
                                                    }

                                                </Grid>
                                            </Grid>

                                        </Grid>
                                    </>
                                </>}
                            </Grid>

                        </Grid> : activeButton === 'MTN Momo' ? <>
                            <Grid item style={{ background: 'rgba(23, 174, 123, 0.06)', width: '60%', display: 'flex', flexShrink: 1, marginRight: '2.5%' }}>

                                <Grid container direction={'column'} ml={1} spacing={3}>

                                    {/*Header lable*/}
                                    <Grid item mt={5} >
                                        <span style={{ fontStyle: '16px', fontWeight: '600', color: '#032541' }} >Pay invoice-MTN Momo</span>
                                    </Grid>

                                    {/*Invoice card Information*/}
                                    <Grid item>

                                        <Grid container direction={'row'} spacing={2}>
                                            <Grid item style={{ width: '70%' }}>
                                                {/*PlyCard*/}
                                                <Grid container direction={'column'} spacing={0.5} style={{ borderRadius: '10px', background: 'rgba(112, 112, 112, 0.05)' }}>
                                                    <Grid item>
                                                        <span style={{ color: '#707070', fontSize: '14px' }}>Invoice No:</span>
                                                    </Grid>
                                                    <Grid item>
                                                        <span style={{ color: '#032541', fontWeight: 600, fontSize: '18px' }}>{invoiceNumber}</span>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item style={{ width: '30%' }}>
                                                {/*Amount*/}
                                                <Grid container direction={'column'} spacing={0.5} style={{ borderRadius: '10px' }}>
                                                    <Grid item>
                                                        <span style={{ color: '#707070', fontSize: '14px' }}>Invoice Balance:</span>
                                                    </Grid>
                                                    <Grid item>
                                                        <span style={{ color: '#dc3545', fontWeight: 600, fontSize: '18px' }}>{numberFormat(Math.ceil(initialBalance))}</span>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                        </Grid>

                                    </Grid>

                                    {/*Mpesa Instruction*/}
                                    <Grid item mt={-2}>
                                        <span style={{ fontSize: '14px' }} >Kindly enter your mobile number to receive the payment prompt and enter you  Pin.</span>
                                    </Grid>

                                    {/*Mobile input*/}
                                    <Grid item>
                                        <div style={{ marginBottom: '10px' }}>
                                            <span style={{ fontSize: '12px', color: '#032541' }}>Mobile no.</span>
                                        </div>

                                        <PhoneInput required inputStyle={{ height: '55px', width: '500px' }} country={"ug"} enableSearch={true} value={userPhone} onChange={(phone) => setUserPhone(phone)} />
                                    </Grid>

                                    {/*Amount Input*/}
                                    <Grid item>
                                        <div style={{ marginBottom: '10px' }}>
                                            <span style={{ fontSize: '12px', color: '#032541' }}>Amount.</span>
                                        </div>
                                        <TextField defaultValue={Math.ceil(initialBalance)} onChange={(e) => { setAmount(e.target.value) }} type='number' InputProps={{ style: { fontFamily: 'Poppins', background: '#fff' } }} InputLabelProps={{ style: { fontFamily: 'Poppins', fontSize: "13px", color: "#032541" } }} style={{ width: '500px', border: "solid 0px #e4e4e4", fontSize: "12px" }} id="outlined-basic" label="Amount" variant="outlined" required />
                                    </Grid>

                                    {/*Send prompt button*/}
                                    <Grid item mt={10} mb={9}>
                                        <Grid container justifyContent={'center'} alignContent={'center'}>
                                            <Grid item>
                                                {mpesaLoading ?
                                                    <>
                                                        <LoadingButton sx={{ width: "407px", backgroundColor: "#34A353", color: "white", '&:hover': { backgroundColor: '#34A353', color: 'white' } }}
                                                            endIcon={<SendIcon />}
                                                            loading={true}
                                                            loadingPosition="end"
                                                            variant="contained">
                                                            <span>Awaiting Payment</span>
                                                        </LoadingButton>
                                                        <Box sx={{ display: "flex", textAlign: "center", justifyContent: "center", width: "100%" }}>
                                                            <Typography sx={{ alignSelf: "center", marginLeft: "auto", marginRight: "auto" }}>
                                                                Resend Prompt: {counter}
                                                            </Typography>
                                                        </Box>
                                                    </>

                                                    :
                                                    <>
                                                        <Button onClick={() => { handleMtnMomoPay(invoiceNumber) }} style={{ width: '407px', background: "#032541", textTransform: 'none', color: '#fff', fontWeight: 'bold', fontSize: '14px' }} >Pay Now</Button>
                                                    </>
                                                }
                                            </Grid>
                                        </Grid>

                                    </Grid>

                                </Grid>

                            </Grid>
                        </> : activeButton === 'bankpaybill' ?
                            <>
                                <Grid item style={{ background: 'rgba(23, 174, 123, 0.06)', width: isMobileUI ? "90%" : '60%', display: 'flex', flexShrink: 1, marginRight: '2.5%' }}>

                                    <Grid container direction={'column'} ml={1} spacing={3}>

                                        {/*Header lable*/}
                                        <Grid item mt={5} >
                                            <span style={{ fontStyle: '16px', fontWeight: '600', color: '#032541' }} >{isInvoiceBatch ? "Pay Invoice Batch via Bank Paybill" : "Pay invoice via Bank Paybill"}</span>
                                        </Grid>

                                        {/*Invoice card Information*/}
                                        <Grid item>

                                            <Grid container direction={isMobileUI ? "column" : 'row'} spacing={2}>
                                                <Grid item style={{ width: isMobileUI ? "80%" : '70%' }}>
                                                    {/*PlyCard*/}
                                                    <Grid container direction={'column'} spacing={isMobileUI ? 0 : 0.5} style={{ width: isMobileUI ? "280px" : "500px", borderRadius: '10px', background: 'rgba(112, 112, 112, 0.05)' }}>
                                                        <Grid item>
                                                            <span style={{ color: '#707070', fontSize: '14px' }}>Invoice No:</span>
                                                        </Grid>
                                                        <Grid item>
                                                            <span style={{ color: '#032541', fontWeight: 600, fontSize: '18px' }}>{isInvoiceBatch ? batchData.batchNumber : invoiceNumber}</span>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item style={{ width: isMobileUI ? "100%" : '30%' }}>
                                                    {/*Amount*/}
                                                    <Grid container direction={'column'} spacing={0.5} style={{ borderRadius: '10px' }}>
                                                        <Grid item>
                                                            <span style={{ color: '#707070', fontSize: '14px' }}>Invoice Balance:</span>
                                                        </Grid>
                                                        <Grid item>
                                                            <span style={{ color: '#dc3545', fontWeight: 600, fontSize: '18px' }}>{numberFormat(Math.ceil(invoiceBalance))}</span>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                            </Grid>

                                        </Grid>
                                        {/*Mobile input*/}
                                        <Grid item>
                                            <div style={{ marginBottom: '10px' }}>
                                                <span style={{ fontSize: '12px', color: '#032541' }}>Bank </span>
                                            </div>

                                            {/* <SelectInput required inputStyle={{height : '55px', width : '500px'}} /> */}
                                            <TextField select label="Select Bank Name" id="demo-select-small" style={{ height: "55px", width: isMobileUI ? "280px" : "500px", borderRadius: "1px", fontFamily: "Poppins", }}
                                                defaultValue={bankAccount}
                                                onChange={(e) => setBankAccount(e.target.value)}
                                                required>
                                                {availableBanks?.map((bank, index) => {
                                                    return <MenuItem key={index} value={bank?.name}>{bank?.name}</MenuItem>
                                                })}

                                            </TextField>
                                        </Grid>



                                        {/*Mobile input*/}
                                        <Grid item>
                                            <div style={{ marginBottom: '10px' }}>
                                                <span style={{ fontSize: '12px', color: '#032541' }}>Mobile no.</span>
                                            </div>

                                            <PhoneInput required inputStyle={{ height: '55px', width: isMobileUI ? "280px" : '500px' }} country={"ke"} enableSearch={true} value={kcbphone} onChange={(kcbphone) => setkcbphone(kcbphone)} />
                                        </Grid>

                                        {/*Amount Input*/}
                                        <Grid item>
                                            <div style={{ marginBottom: '10px' }}>
                                                <span style={{ fontSize: '12px', color: '#032541' }}>Amount.</span>
                                            </div>
                                            <TextField defaultValue={Math.ceil(invoiceBalance)} onChange={(e) => { setKcbAmount(e.target.value) }} type='number' InputProps={{ style: { fontFamily: 'Poppins', background: '#fff' } }} InputLabelProps={{ style: { fontFamily: 'Poppins', fontSize: "13px", color: "#032541" } }} style={{ width: isMobileUI ? "280px" : '500px', border: "solid 0px #e4e4e4", fontSize: "12px" }} id="outlined-basic" label="Amount" variant="outlined" required />
                                        </Grid>

                                        {/*Send prompt button*/}
                                        <Grid item mt={10} mb={9}>
                                            <Grid container justifyContent={'center'} alignContent={'center'}>
                                                <Grid item>
                                                    {kcbloading ?
                                                        <>
                                                            <LoadingButton sx={{ width: isMobileUI ? "200px" : '407px', backgroundColor: "#34A353", color: "white", '&:hover': { backgroundColor: '#34A353', color: 'white' } }}
                                                                endIcon={<SendIcon />}
                                                                loading={true}
                                                                loadingPosition="end"
                                                                variant="contained">
                                                                <span>Awaiting Payment</span>
                                                            </LoadingButton>
                                                            <Box sx={{ display: "flex", textAlign: "center", justifyContent: "center", width: "100%" }}>
                                                                <Typography sx={{ alignSelf: "center", marginLeft: "auto", marginRight: "auto" }}>
                                                                    Resend Prompt: {counter}
                                                                </Typography>
                                                            </Box>
                                                        </>

                                                        :
                                                        <>
                                                            <Button onClick={() => { handleKcbPush(invoiceNumber) }} style={{ width: isMobileUI ? "200px" : '407px', background: "#032541", textTransform: 'none', color: '#fff', fontWeight: 'bold', fontSize: '14px' }} >Send Prompt</Button>
                                                        </>
                                                    }
                                                </Grid>
                                            </Grid>

                                        </Grid>

                                    </Grid>

                                </Grid>
                            </> : activeButton === 'bankSlip' ? <>
                                <Grid item style={{ background: 'rgba(23, 174, 123, 0.06)', width: '60%', display: 'flex', flexShrink: 1, marginRight: '2.5%' }}>

                                    <Grid container direction={'column'} ml={1} spacing={3}>

                                        {/*Header lable*/}
                                        <Grid item mt={5} >
                                            <span style={{ fontStyle: '16px', fontWeight: '600', color: '#032541' }} >Bank Slip Validation</span>
                                        </Grid>

                                        {/*Invoice card Information*/}
                                        <Grid item>

                                            <Grid container direction={'row'} spacing={2}>
                                                <Grid item style={{ width: '70%' }}>
                                                    {/*PlyCard*/}
                                                    <Grid container direction={'column'} spacing={0.5} style={{ borderRadius: '10px', background: 'rgba(112, 112, 112, 0.05)' }}>
                                                        <Grid item>
                                                            <span style={{ color: '#707070', fontSize: '14px' }}>Invoice No:</span>
                                                        </Grid>
                                                        <Grid item>
                                                            <span style={{ color: '#032541', fontWeight: 600, fontSize: '18px' }}>{invoiceNumber}</span>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item style={{ width: '30%' }}>
                                                    {/*Amount*/}
                                                    <Grid container direction={'column'} spacing={0.5} style={{ borderRadius: '10px' }}>
                                                        <Grid item>
                                                            <span style={{ color: '#707070', fontSize: '14px' }}>Invoice Balance:</span>
                                                        </Grid>
                                                        <Grid item>
                                                            <span style={{ color: '#dc3545', fontWeight: 600, fontSize: '18px' }}>{numberFormat(Math.ceil(invoiceBalance))}</span>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                            </Grid>

                                        </Grid>
                                        <Grid item>
                                            <div style={{ marginBottom: '20px', }}>
                                                <span style={{ fontSize: '12px', color: '#032541' }}>Select    Banks </span>
                                            </div>
                                            {console.log(bankAccountsKCB, "bankAccountsKCB")}
                                            <TextField select label="Select Bank Name" id="demo-select-small" style={{ height: "55px", width: "500px", borderRadius: "1px", fontFamily: "Poppins", }}

                                                defaultValue={bankAccountKCB}
                                                onChange={(e) => setBankAccountKCB(e.target.value)}
                                                required>
                                                {bankAccountsKCB.map((option) => (
                                                    <MenuItem key={option.id} value={option.accountNumber} style={{ height: "40px", paddingLeft: "10px" }}>
                                                        {option.bankName} -  {option.accountNumber}
                                                    </MenuItem>
                                                ))}





                                            </TextField>
                                            {/* <CustomSelect required inputStyle={{ height: '55px', width: '500px' }} country={"ke"} enableSearch={true} value={bankAccountKCB} onChange={(bankAccountKCB) => setBankAccountKCB(bankAccountKCB)} /> */}
                                            {/* <TextField required InputProps={{ style: { fontFamily: 'Poppins', background: '#fff' } }} InputLabelProps={{ style: { fontFamily: 'Poppins', fontSize: "13px", color: "#032541" } }} style={{ width: '500px', border: "solid 0px #e4e4e4", fontSize: "12px" }} value={'EMAIL ADDRESS'} disabled id="outlined-basic" label="Email Address" variant="outlined" /> */}
                                        </Grid>
                                        <Grid item>
                                            <div style={{ marginBottom: '10px' }}>
                                                <span style={{ fontSize: '12px', color: '#032541' }}>Amount.</span>
                                            </div>
                                            <TextField defaultValue={referenceNo} onChange={(e) => { setReferenceNo(e.target.value) }} type='text' InputProps={{ style: { fontFamily: 'Poppins', background: '#fff' } }} InputLabelProps={{ style: { fontFamily: 'Poppins', fontSize: "13px", color: "#032541" } }} style={{ width: '500px', border: "solid 0px #e4e4e4", fontSize: "12px" }} id="outlined-basic" label="Reference number" variant="outlined" required />
                                        </Grid>

                                        {/*VALIDATE  SLIP*/}
                                        <Grid item mt={10} mb={9}>
                                            <Grid container justifyContent={'center'} alignContent={'center'}>
                                                <Grid item>
                                                    <Button onClick={() => { handleBankSlip(invoiceNumber) }} style={{ width: '407px', background: "#032541", textTransform: 'none', color: '#fff', fontWeight: 'bold', fontSize: '14px' }} >Validate Slip</Button>
                                                </Grid>
                                            </Grid>

                                        </Grid>
                                    </Grid>

                                </Grid>

                            </> : activeButton === "ecitizen" ?
                                <>
                                    <Grid item style={{ background: 'rgba(23, 174, 123, 0.06)', width: isMobileUI ? "90%" : '60%', display: 'flex', flexShrink: 1, marginRight: '2.5%' }}>

                                        <Grid container direction={'column'} ml={1} spacing={3}>

                                            {/*Header lable*/}
                                            <Grid item mt={5} >
                                                <span style={{ fontStyle: '16px', fontWeight: '600', color: '#032541' }} >Pay invoice-Ecitizen</span>
                                            </Grid>

                                            {/*Invoice card Information*/}
                                            <Grid item>

                                                <Grid container direction={isMobileUI ? "column" : 'row'} spacing={2}>
                                                    <Grid item style={{ width: isMobileUI ? "80%" : '70%' }}>
                                                        {/*PlyCard*/}
                                                        <Grid container direction={'column'} spacing={0.5} style={{ width: isMobileUI ? "280px" : "500px", borderRadius: '10px', background: 'rgba(112, 112, 112, 0.05)' }}>
                                                            <Grid item>
                                                                <span style={{ color: '#707070', fontSize: '14px' }}>Invoice No:</span>
                                                            </Grid>
                                                            <Grid item>
                                                                <span style={{ color: '#032541', fontWeight: 600, fontSize: '18px' }}>{isInvoiceBatch ? batchData?.batchNumber : invoiceNumber}</span>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item style={{ width: '30%' }}>
                                                        {/*Amount*/}
                                                        <Grid container direction={'column'} spacing={0.5} style={{ width: isMobileUI ? "280px" : "500px", borderRadius: '10px', background: 'rgba(112, 112, 112, 0.05)' }}>
                                                            <Grid item>
                                                                <span style={{ color: '#707070', fontSize: '14px' }}>Invoice Balance:</span>
                                                            </Grid>
                                                            <Grid item>
                                                                <span style={{ color: '#dc3545', fontWeight: 600, fontSize: '18px' }}>{numberFormat(Math.ceil(initialBalance))}</span>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                </Grid>

                                            </Grid>

                                            {/*Ecitizen Instruction*/}
                                            <Grid item mt={-2}>
                                                <span style={{ fontSize: '14px' }} >Kindly note above details will be used to make Payment.</span>
                                            </Grid>


                                            <Grid item>
                                                <div style={{ marginBottom: '10px' }}>
                                                    <span style={{ fontSize: '12px', color: '#032541' }}>Customer Full Name.</span>
                                                </div>
                                                <TextField disabled defaultValue={customerInfo?.firstName + " " + customerInfo?.lastName} type='text' InputProps={{ style: { fontFamily: 'Poppins', background: '#fff' } }} InputLabelProps={{ style: { fontFamily: 'Poppins', fontSize: "13px", color: "#032541" } }} style={{ width: isMobileUI ? "280px" : '500px', border: "solid 0px #e4e4e4", fontSize: "12px" }} id="outlined-basic" label="Customer FullName" variant="outlined" required />


                                            </Grid>

                                            <Grid item>
                                                <div style={{ marginBottom: '10px' }}>
                                                    <span style={{ fontSize: '12px', color: '#032541' }}>Customer Phone Number</span>
                                                </div>
                                                <TextField disabled defaultValue={customerInfo?.phone} type='text' InputProps={{ style: { fontFamily: 'Poppins', background: '#fff' } }} InputLabelProps={{ style: { fontFamily: 'Poppins', fontSize: "13px", color: "#032541" } }} style={{ width: isMobileUI ? "280px" : '500px', border: "solid 0px #e4e4e4", fontSize: "12px" }} id="outlined-basic" label="Customer PhoneNumber" variant="outlined" required />


                                            </Grid>
                                            <Grid item>
                                                <div style={{ marginBottom: '10px' }}>
                                                    <span style={{ fontSize: '12px', color: '#032541' }}>Customer Email Address</span>
                                                </div>
                                                <TextField disabled defaultValue={customerInfo?.email} type='text' InputProps={{ style: { fontFamily: 'Poppins', background: '#fff' } }} InputLabelProps={{ style: { fontFamily: 'Poppins', fontSize: "13px", color: "#032541" } }} style={{ width: isMobileUI ? "280px" : '500px', border: "solid 0px #e4e4e4", fontSize: "12px" }} id="outlined-basic" label="Customer Email Address" variant="outlined" required />


                                            </Grid>

                                            <Grid item>
                                                <div style={{ marginBottom: '10px' }}>
                                                    <span style={{ fontSize: '12px', color: '#032541' }}>Customer ID Number</span>
                                                </div>
                                                {customerInfo?.id_number ? <>
                                                    <TextField name="id_number" disabled defaultValue={customerInfo?.id_number} type='text' InputProps={{ style: { fontFamily: 'Poppins', background: '#fff' } }} InputLabelProps={{ style: { fontFamily: 'Poppins', fontSize: "13px", color: "#032541" } }} style={{ width: isMobileUI ? "280px" : '500px', border: "solid 0px #e4e4e4", fontSize: "12px" }} id="outlined-basic" label="Customer ID Number" variant="outlined" onChange={handleChangeInCustomerInfo} required />

                                                </> : <>
                                                    <TextField name="idNumber" defaultValue={idNumber} type='text' InputProps={{ style: { fontFamily: 'Poppins', background: '#fff' } }} InputLabelProps={{ style: { fontFamily: 'Poppins', fontSize: "13px", color: "#032541" } }} style={{ width: isMobileUI ? "280px" : '500px', border: "solid 0px #e4e4e4", fontSize: "12px" }} id="outlined-basic" label="Customer ID Number" variant="outlined" onChange={(e) => setIdNumber(e.target.value)} required />

                                                </>}


                                            </Grid>
                                            <Grid item>
                                                <>
                                                    <Button onClick={() => { payEcitizen(invoiceNumber) }} style={{ width: isMobileUI ? "250px" : '407px', background: "#032541", textTransform: 'none', color: '#fff', fontWeight: 'bold', fontSize: '14px' }} >Pay Now</Button>
                                                </>
                                            </Grid>

                                        </Grid>

                                    </Grid>
                                </>
                                : activeButton === 'cash' ? <>
                                    <Grid item style={{ background: 'rgba(23, 174, 123, 0.06)', width: '60%', display: 'flex', flexShrink: 1, marginRight: '2.5%' }}>

                                        <Grid container direction={'column'} ml={1} spacing={3}>

                                            {/*Header lable*/}
                                            <Grid item mt={5} >
                                                <span style={{ fontStyle: '16px', fontWeight: '600', color: '#032541' }} >Receive via Cash</span>
                                            </Grid>

                                            {/*Invoice card Information*/}
                                            <Grid item>

                                                <Grid container direction={'row'} spacing={2}>
                                                    <Grid item style={{ width: '70%' }}>
                                                        {/*PlyCard*/}
                                                        <Grid container direction={'column'} spacing={0.5} style={{ borderRadius: '10px', background: 'rgba(112, 112, 112, 0.05)' }}>
                                                            <Grid item>
                                                                <span style={{ color: '#707070', fontSize: '14px' }}>{isInvoiceBatch ? 'Batch No' : 'Invoice No'}</span>
                                                            </Grid>
                                                            <Grid item>
                                                                <span style={{ color: '#032541', fontWeight: 600, fontSize: '18px' }}>{isInvoiceBatch ? batchData?.batchNumber : invoiceNumber}</span>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item style={{ width: '30%' }}>
                                                        {/*Amount*/}
                                                        <Grid container direction={'column'} spacing={0.5} style={{ borderRadius: '10px' }}>
                                                            <Grid item>
                                                                <span style={{ color: '#707070', fontSize: '14px' }}>Invoice Balance:</span>
                                                            </Grid>
                                                            <Grid item>
                                                                <span style={{ color: '#dc3545', fontWeight: 600, fontSize: '18px' }}>{numberFormat(Math.ceil(initialBalance))}</span>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                </Grid>

                                            </Grid>

                                            <Grid item>
                                                <div style={{ marginBottom: '10px' }}>
                                                    <span style={{ fontSize: '12px', color: '#032541' }}>Amount.</span>
                                                </div>
                                                <TextField defaultValue={Math.ceil(initialBalance)} onChange={(e) => { setCashAmount(e.target.value) }} type='number' InputProps={{ style: { fontFamily: 'Poppins', background: '#fff' } }} InputLabelProps={{ style: { fontFamily: 'Poppins', fontSize: "13px", color: "#032541" } }} style={{ width: '500px', border: "solid 0px #e4e4e4", fontSize: "12px" }} id="outlined-basic" label="Amount" variant="outlined" required />
                                            </Grid>

                                            {/*VALIDATE  SLIP*/}
                                            <Grid item mt={10} mb={9}>
                                                <Grid container justifyContent={'center'} alignContent={'center'}>
                                                    <Grid item>
                                                        <Button onClick={() => { payViaCash(invoiceNumber) }} style={{ width: '407px', background: "#032541", textTransform: 'none', color: '#fff', fontWeight: 'bold', fontSize: '14px' }} >Process</Button>
                                                    </Grid>
                                                </Grid>

                                            </Grid>
                                        </Grid>

                                    </Grid>

                                </> : activeButton === 'uploadeBankSlips' ?
                                    <Grid item style={{ background: '#fafafa', width: '50%' }}>
                                        <Grid container direction={'column'} spacing={3}>
                                            <Grid item mt={5} ml={10}>
                                                <span style={{ fontStyle: '16px', fontWeight: '600', color: '#032541' }} >Receive Via Bank Slip</span>
                                            </Grid>
                                            <Grid item display={'flex'} marginLeft={10} direction={'column'} width={'100%'}>
                                                <FormControl style={{ width: '500px' }}>
                                                    <InputLabel id="demo-simple-select-label">Bank <span style={{ color: '#dc3545' }}>*</span></InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        label="Age"
                                                        value={selectedBankAccount}
                                                        onChange={(e) => setSelectedBankAccount(e.target.value)}
                                                    >
                                                        {bankAccounts?.map((accountFound) => (
                                                            <MenuItem value={accountFound?.accounNumber}>{accountFound?.bankName} - Acc/No: {accountFound?.accounNumber} </MenuItem>
                                                        ))}

                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item display={'flex'} marginLeft={10} direction={'column'} width={'100%'}>
                                                <TextField value={bankslipRefNo} onChange={(e) => setBankslipRef(e.target.value)} style={{ marginTop: '20px', width: '500px' }} id="outlined-basic" label="Reference No" variant="outlined" />
                                            </Grid>
                                            {refDetailsFound?.length > 0 ? refDetailsFound?.map((refItem) => {
                                                // console.log('refItem =======<><>', refItem);
                                                return (
                                                    <>
                                                        <Grid item display={'flex'} mt={2} marginLeft={10} direction={'column'} width={'100%'}>
                                                            <TextField disabled value={numberFormat(refItem?.creditAmount)} style={{ marginTop: '20px', width: '500px' }} id="outlined-basic" label="Bank Slip Amount" variant="outlined" />
                                                        </Grid>

                                                        <Grid item display={'flex'} mt={2} marginLeft={10} direction={'column'} width={'100%'}>
                                                            <TextField disabled value={numberFormat(refItem?.balance === undefined ? refItem?.creditAmount : bankSlipBalance)} style={{ marginTop: '20px', width: '500px' }} id="outlined-basic" label="Bank Slip Balance" variant="outlined" />
                                                        </Grid>
                                                        <Grid item display={'flex'} mt={2} marginLeft={10} direction={'column'} width={'100%'}>
                                                            <TextField onChange={(e) => setBankSlipAccountAmount(Number(e.target.value))} defaultValue={(initialBalance)} style={{ marginTop: '20px', width: '500px' }} id="outlined-basic" label="Amount" variant="outlined" />
                                                        </Grid>
                                                        <Grid item display={'flex'} mt={2} marginLeft={10} direction={'column'} width={'100%'}>
                                                            <TextField disabled value={DateFormatter(refItem?.transactionDate)} style={{ marginTop: '20px', width: '500px' }} id="outlined-basic" label="Date" variant="outlined" />
                                                        </Grid>
                                                    </>
                                                )
                                            }) : null}

                                            <Grid ml={-14} item width={'100%'} display={'flex'} justifyContent={'flex-end'}>
                                                {isValidated === true ? <Button onClick={payViaValidatedSlip} style={{ backgroundColor: '#032541', color: '#fff', textTransform: 'inherit', width: '124px' }}> Pay Now</Button> : <Button onClick={validateUploadedTransactionRef} style={{ backgroundColor: '#032541', color: '#fff', textTransform: 'inherit', width: '124px' }}> Validate</Button>}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    : activeButton === 'settleInvoice' ?
                                        <Grid item style={{ background: '#fafafa', width: '50%' }}>
                                            <Grid container direction={'column'} spacing={1} px={3} py={3} width={'100%'}>
                                                <Grid item mt={5}>
                                                    <span style={{ fontStyle: '16px', fontWeight: '600', color: '#032541' }} >Settle Invoice</span>
                                                </Grid>
                                                <Grid item width={'100%'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                                    <Box display={'flex'} flexDirection={'column'}>
                                                        <Typography style={{ fontSize: '12px', color: '#032541' }}>Invoice No.</Typography>
                                                        <Typography style={{ fontSize: '12px', color: '#032541', fontWeight: 'bold' }}>{invoiceNumber}</Typography>
                                                    </Box>
                                                    <Box display={'flex'} flexDirection={'column'}>
                                                        <Typography style={{ fontSize: '12px', color: '#032541', }}>Invoice Balance</Typography>
                                                        <Typography style={{ fontSize: '12px', color: '#032541', fontWeight: 'bold', }}>{currencyconverter(initialBalance)}</Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item display={'flex'} mt={2} direction={'column'} width={'100%'}>
                                                    <FormControl style={{ width: '100%' }}>
                                                        <InputLabel id="demo-simple-select-label">Transaction Type</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            label="Transaction Type"
                                                            value={selectedSettleInvoiceMethod}
                                                            onChange={(e) => setSelectedSettleInvoiceMethod(e.target.value)}
                                                        >
                                                            {settleinvoiveOptions?.map((optionFound) => (
                                                                <MenuItem value={optionFound?.value}>{optionFound?.name}</MenuItem>
                                                            ))}

                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item display={'flex'} direction={'column'} width={'100%'}>
                                                    <TextField value={settleInvoiceAmount} onChange={(e) => setSettleInvoiceAmount(e.target.value)} style={{ marginTop: '20px', width: '100%' }} id="outlined-basic" label="Amount" variant="outlined" />
                                                </Grid>
                                                <Grid item display={'flex'} direction={'column'} width={'100%'}>
                                                    <TextField value={settleInvoiceRefNo} onChange={(e) => setSettleInvoiceRefNo(e.target.value)} style={{ marginTop: '20px', width: '100%' }} id="outlined-basic" label="Reference No" variant="outlined" />
                                                </Grid>
                                                <Grid item display={'flex'} mt={2} alignContent={'flex-end'} justifyContent={'flex-end'} alignItems={'flex-end'} direction={'column'} width={'100%'}>
                                                    <Button disabled={!settleInvoiceRefNo || !settleInvoiceAmount || !selectedSettleInvoiceMethod} onClick={() => payViaSettleInvoice()} style={!settleInvoiceRefNo || !settleInvoiceAmount || !selectedSettleInvoiceMethod ? { backgroundColor: "rgba(3, 37, 65, 0.4)", color: '#fff', textTransform: 'inherit', width: '124px' } : { backgroundColor: '#032541', color: '#fff', textTransform: 'inherit', width: '124px' }}> Settle Invoice</Button>
                                                </Grid>
                                            </Grid>
                                        </Grid> : null}
                {/*Mpesa payment*/}
                <PaymentSent successOpen={successOpen} onClose={() => setSuccessOpen(false)} />
              
            </Grid>
            <Dialog
                    open={openDeleteDialog}
                    TransitionComponent={Transition}
                    sx={{width:'100%'}}
                    keepMounted
                    PaperProps={{
                        style: {
                            height: "300px",
                            backgroundPosition:'center',
                            width:'800px',
                            boxShadow: "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)"
                        },
                    }}
                    BackdropProps={{ style: { backgroundColor: "rgba(0, 0, 0, 0.1)", backdropFilter: "blur(10px)", } }}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogContent sx={{ display: "flex", justifyContent: 'space-evenly', alignContent: "center", alignItems: "center" }}>
                        <img src={WarningImg} alt='approve' />
                        <div style={{ marginLeft: '20px' }}>
                            <Typography variant="h6" fontWeight={600}>Fund Transfer Setup is required</Typography>
                            <Typography variant="body2" mt={1}>To proceed with this payment mode, you will need to first setup a KCB Bank debit account then setup approvals</Typography><br></br>
                            <span>Proceed to setup?</span>
                        </div>
                    </DialogContent>
                    <DialogActions sx={{justifyContent:'center', display:'flex'}}>
                        <Button onClick={handleOpenCloseDialog} style={{ border: "1px solid #dc3545", color: "#dc3545", backgroundColor: "transparent", textTransform: 'inherit', cursor: 'pointer', width: "100px", marginRight: '10px' }}>Cancel</Button>
                        <Button onClick={handleNavigateToFt} style={{ backgroundColor: "#032541", border: 'none', color: "#fff", textTransform: 'inherit', cursor: 'pointer', width: "100px", paddingLeft: "10px", }}>Continue</Button>
                    </DialogActions>
                </Dialog>
        </Box>
    )
}
