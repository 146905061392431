import React, { useState, useEffect, useMemo, useCallback } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Grid, Modal, Box, Button, Checkbox, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import dissaprovetick from "../../../common/images/dissaprovetick.svg";
import { MoreVert } from "@mui/icons-material";
import { Menudropdown, SuspendCustomerModal } from "./modals";
import { SuccessAlertRightAligned } from "../../bills/startorderModals/modals";
import { customerstyles } from "./styles";
import DateFormatter from "../../../utils/dateFormatter";
import CurrencyFormatter from "../../../utils/currencyFormatter";
import HttpComponent from "../../School/MakeRequest";
import powericon from "../../../common/images/warning.svg";
import ReusableModal from "../../School/ReusableModal";


const baseUrl = process.env.REACT_APP_BASE_URL;
const X_Authorization = localStorage.getItem("X-Authorization");

// API END POINTS;
const getPendingCustomers = baseUrl + "/api/customers_list?status=Awaiting&page=1&limit=10&paymentType=&customerType=";

const approveCustomerUrl = baseUrl + "/api/activateCustomer";
const dissapproveCustomerUrl = baseUrl + "/api/deleteCustomer";

const CustomerAproval = (props) => {
  const { businessCat } = useSelector((store) => store.businessCategory);

  const customerSearchValue = props?.customerSearchValue;

  const setExportData = props.setExportData;
  const setCsvExport = props.setCsvExport;
  // APPROVE CUSTOMER STATES;
  // ACTIONS BUTTON STATE;

  const [customerApproval, setCustomerApproval] = useState(false)
  const [approvingProcess, setApprovingProcess] = useState(false)


  // DataGrid Pagination
  const [dataGridPageSize, setPageSize] = React.useState(5);
  const [pageState, setPageState] = useState({ isLoading: true, data: [], count: 0, page: 1, pageSize: dataGridPageSize });


  useEffect(() => {
    // pdf exports;
    const pdfData = pageState?.data?.map?.((customer) => [customer?.customerName, customer?.customerType, customer?.paymentType, DateFormatter(customer?.createdOn), CurrencyFormatter(customer?.limit, "KES")]);
    const pdfHeaders = [["Customer Name", "Customer Type", "Payment Type", "Date Created", "Amount/Limit (KES)"]]
    setExportData({ headers: pdfHeaders, data: pdfData });

    const csvData = pageState?.data?.map((customer) => {
      return {
        "Customer Name": customer?.customerName,
        "Customer Type": customer?.customerType,
        "Payment Type": customer?.paymentType,
        "Date Created": customer?.createdOn,
        "Amount": customer?.limit
      }
    })

    const csvHeaders = [
      { label: "Customer Name", key: "Customer Name" },
      { label: "Customer Type", key: "Customer Type" },
      { label: "Payment Type", key: "Payment Type" },
      { label: "Date Created", key: "Date Created" },
      { label: "Amount", key: "Amount" },
    ]

    setCsvExport({ headers: csvHeaders, data: csvData })
  }, [pageState.data, pageState.pageSize, dataGridPageSize]);


  // CONST GET CUSTOMER PENDING APPROVAL DATA;
  const getCustomersPendingApproval = async () => {
    // if (getApprovedCustomersDb.length) return;
    try {
      const pendingApprovalresponse = await fetch(baseUrl + `/api/customers_list?status=Awaiting&page=${pageState.page}&limit=${dataGridPageSize}&paymentType=&customerType=`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
      });

      if (pendingApprovalresponse.status === 200) {
        const pendingApprovalResponseData = await pendingApprovalresponse.json();
        setPageState({ ...pageState, isLoading: false, data: pendingApprovalResponseData.data, count: pendingApprovalResponseData.count });
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  useEffect(() => {
    getCustomersPendingApproval();
  }, [pageState.page, dataGridPageSize]);

  // APPROVE CUSTOMER STATES;
  const [sucess, setSucess] = useState({ state: false, message: "" })
  const [openSuspendModal, setSuspendModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null)

  const [dissapproveId, setDissaprovedId] = useState("");
  const [dissaproveName, setDissaprovedName] = useState("");
  const [dissapprovingStarts, setDissapproving] = useState(false)
 
  const [selectedIds, setSelectedIds] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [updateWarningOpen, setUpdateWarningOpen] = useState(false);


  const handleSelectAllChange = async (event) => {
    if (event.target.checked) {
        await getAllCustomerIds();
        setIsAllSelected(true);
    } else {
        setSelectedIds([]);
        setIsAllSelected(false);
    }
  };
  const closeUpdateModal = () => {
    setUpdateWarningOpen(false)
  }

  const handleCheckboxChange = (event, row) => {
    if (event.target.checked) {
      if (!selectedIds.includes(row.id)) {
        setSelectedIds((prevSelected) => [...prevSelected, row.id]);
      }
    } else {
      setSelectedIds((prevSelected) =>
        prevSelected.filter((id) => id !== row.id)
      );
    }
  };
  const launchUpdateModal = () => {
    setUpdateWarningOpen(true)

  }
  const cancelChecked = () => {
    setSelectedIds([]);
    setIsAllSelected(false);
  }
  const bulkApprove = () => {
    console.log(selectedIds,'selectedIdsselectedIds')

    let data = {
      customerIds:selectedIds,
    };
    HttpComponent({
      method: "POST",
      url: `/api/activateCustomerBulk`,
      body: data,
      token: X_Authorization,
    })
      .then((data) => {
        if (data.status === 200) {
          closeUpdateModal()
          getCustomersPendingApproval();
          setSucess({ state: true, message: "Customers approved successfully" });
        } else {
          console.error("Error setting info");
        }
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  const getUserDetailsAndDeleteAcc = (params) => {
    console.log(params);
    if (params) {
      setDissaprovedName(params.customerName);
      setDissaprovedId(params.id);
      setAnchorEl(null);
      setSuspendModal(true)
      setDissapproving(true)
    }
  }

  const getAllCustomerIds = async () => {
      try {
          const response = await fetch(baseUrl + `/api/customers_list?status=Awaiting&limit=10000`, { 
              method: "GET",
              headers: {
                  "Content-Type": "application/json",
                  Accept: "application/json",
                  "X-Authorization": localStorage.getItem("X-Authorization"),
              },
          });

          if (response.status === 200) {
              const data = await response.json();
              const allIds = data.data.map(customer => customer._id);
              setSelectedIds(allIds);
          }
      } catch (e) {
          console.log("Error fetching customer IDs:", e.message);
      }
  };


  const dissApproveCustomer = async () => {
    try {
      const dissapproveCustomerResponse = await fetch(dissapproveCustomerUrl + `?customerId=${dissapproveId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
      });

      if (dissapproveCustomerResponse.status === 202) {
        setSucess({ state: true, message: "User has been deleted  sucessfully!" });
        getCustomersPendingApproval()
        setSuspendModal(false)
        setApprovingProcess(false)
      }

      setTimeout(() => {
        setSucess({ state: false });
      }, 2000);
    } catch (error) {
      console.log(error.message);
    }
  };


  const [approveId, setApproveId] = useState("");
  const [approveName, setApprovedName] = useState("");
  // console.log(approveId);
  const getUserInfoAndApprove = (params) => {

    if (params) {
      setAnchorEl(null);
      setSuspendModal(true)
      setApprovingProcess(true)
      setApprovedName(params.row.customerName)
      setApproveId(params.row.id)
      setDissapproving(false)
    }
  }

  const approveCustomer = async (data) => {
    try {
      const approveCustomerResponse = await fetch(approveCustomerUrl + `?customerId=${approveId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
      });

      if (approveCustomerResponse.status === 202) {
        setSucess({ state: true, message: "User has been approved sucessfully!" });
        getCustomersPendingApproval()
        setSuspendModal(false)
        setApprovingProcess(false)
      }

      setTimeout(() => {
        setSucess({ state: false });
      }, 2000);
    } catch (error) {
      console.log(error.message);
    }
  };

  const searchCustomer = () => {
    HttpComponent({
      method: "GET",
      url: `/api/searchCustomer?state=Awaiting&search=${customerSearchValue}`,
      body: null,
      token: localStorage.getItem('X-Authorization')
    }).then((data) => {
      setPageState({ ...pageState, isLoading: false, data: data.response.data, count: data.response.count });
    }).catch((e) => {
      console.log(e.message);
    })
  }

  useEffect(() => {
    searchCustomer()
  }, [customerSearchValue])

  return (
    <>
      <DataGrid
        style={customerstyles.dataGridStyling}
        columns={[
          {
            field: "checked",
            headerName: "",
            width: 100,
            align: 'left',
            headerAlign: 'left',
            renderCell: (params) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Checkbox
                        style={{ color: '#dc3545' }}
                        checked={selectedIds.includes(params.row.id)}
                        onChange={(event) => handleCheckboxChange(event, params.row)}
                    />
                    <span style={{ color: '#707070', fontSize: '14px' }}>{params.value}</span>
                </div>
            ),
            renderHeader: () => (
                <Checkbox
                    checked={isAllSelected}
                    onChange={handleSelectAllChange}
                />
            ),
        },
          {
            field: "customerName",
            headerName: "Full Name",
            renderHeader: () => (
              <strong
                style={{
                  fontFamily: "Poppins",
                  fontSize: "10px",
                  fontWeight: "600",
                  color: "#032541",
                }}
              >
                {"Full Name"}
              </strong>
            ),
            flex: 1,
          },
          {
            field: "customerType",
            headerName: "Type",
            renderHeader: () => (
              <strong
                style={{
                  fontFamily: "Poppins",
                  fontSize: "10px",
                  fontWeight: "600",
                  color: "#032541",
                }}
              >
                {"Type"}
              </strong>
            ),
            flex: 1,
          },
          { field: "limit", hide: businessCat === "Rental", headerName: "Amount/Limit", renderHeader: () => <strong style={{ fontFamily: "Poppins", fontSize: "10px", fontWeight: "600", color: "#032541" }}>{"Amount/Limit"}</strong>, flex: 1 },
          //{field: "extraServices", hide : businessCat !== 'Rental',  renderHeader: () => (<strong style={{fontFamily: "Poppins", fontSize: "10px", fontWeight: "600", color: "#032541",}}>{"Charges"}</strong>), flex: 1,},
          { field: "paymentType", hide: businessCat === "Rental", headerName: "Payment", renderHeader: () => <strong style={{ fontFamily: "Poppins", fontSize: "10px", fontWeight: "600", color: "#032541" }}>{"Payment"}</strong>, flex: 1 },
          {
            field: "Actions",
            headerName: "Actions",
            renderCell: (params) => {

              const [anchorEl, setAnchorEl] = React.useState(null);
              const MenuoptionOpen = Boolean(anchorEl);
              const handleClick = (event) => {
                setAnchorEl(event.currentTarget);
                setCustomerApproval(true)
              };
              const handleActionClose = () => {
                setAnchorEl(null);
              };

              return (
                <div>

                  <MoreVert aria-controls={MenuoptionOpen ? "demo-customized-menu" : undefined} aria-haspopup="true" aria-expanded={MenuoptionOpen ? "true" : undefined} variant="outlined" disableElevation color="primary" onClick={handleClick} id="demo-customized-button" />

                  <Menudropdown MenuoptionOpen={MenuoptionOpen} handleActionClose={handleActionClose} anchorEl={anchorEl} params={params.row.id} getUserInfoAndApprove={getUserInfoAndApprove} approveParams={params} customerApproval={customerApproval} getUserDetailsAndDeleteAcc={getUserDetailsAndDeleteAcc} />

                  <SuspendCustomerModal approvingProcess={approvingProcess} openSuspendModal={openSuspendModal} setSuspendModal={setSuspendModal} approveCustomer={approveCustomer} setDissapproving={setDissapproving} dissapprovingStarts={dissapprovingStarts} dissApproveCustomer={dissApproveCustomer} />
                  {/* <Grid item xs="6" style={{ width: "100%" }}>
                      <img src={approveTick} alt="" />
                    </Grid>

                    <Grid item xs="6" style={{ width: "100%" }}>
                      <img src={dissaprovetick} alt="" />
                    </Grid> */}

                </div>
              );
            },
          },
        ]}
        rows={pageState?.data?.map((pendApp) => ({
          id: pendApp._id,
          customerName: pendApp.customerName,
          customerType: pendApp.customerType,
          paymentType: pendApp.paymentType,
          limit: pendApp.limit,
      }))}
      rowCount={pageState.count}
      loading={pageState.isLoading}
      pagination
      page={pageState.page - 1}
      pageSize={dataGridPageSize}
      paginationMode="server"
      onPageChange={(newPage) => {
          setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize }));
      }}
      onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
      // checkboxSelection
      // selectionModel={selectedIds}  // Use selected IDs
      // onSelectionModelChange={(newSelection) => {
      //     setSelectedIds(newSelection);  // Update IDs when selecting individually
      // }}
      
      />

      {sucess && <SuccessAlertRightAligned sucess={sucess.state} message={sucess.message} />}
      <div>
         <Grid container justifyContent={'flex-end'}>
            <Grid item>
              <Button onClick={cancelChecked} style={{textDecoration : "none", marginTop : '5px',background : '#transparent', color : '#002543', fontSize : `14px`, fontWeight : 600, height : '35px', marginRight: '10px',border: "1px solid #002543","&:hover": {
                    backgroundColor: "transparent",
                    color: "#002543",
                  },}} >Cancel</Button>
                <Button onClick={launchUpdateModal} style={{textDecoration : "none", marginTop : '5px',background : '#17ae7b', color : '#fff', fontSize : `14px`, fontWeight : 600, height : '35px'}} >Approve</Button>
            </Grid>
        </Grid>
       
        <ReusableModal open={updateWarningOpen} onClose={closeUpdateModal} width="34.688rem">
        <Box sx={{ display: "flex", justifyContent: "center", padding: "3.125rem" }}>
          <Box sx={{ alignSelf: "center", display: "flex", flexDirection: "column", justifyContent: "center" }}>
            <Box sx={{ display: "flex" }}>
              <img style={{ paddingRight: "2.5rem" }} src={powericon} alt="Warning" />
              <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                <Typography sx={{ color: "#032541", fontWeight: 600 }}>Approve Customers ?</Typography>
                <Typography sx={{ color: "#323d52", fontSize: "0.875rem" }}>
                  Are you sure you want to approve this customers?
                </Typography>
              </Box>
            </Box>

            <Box sx={{ display: "flex", justifyContent: "center", gap: 2, marginTop: 5 }}>
              <Button
                sx={{
                  backgroundColor: "transparent",
                  border: "1px solid #002543",
                  color: "#002543",
                  width: "7.813rem",
                  height: "2.813rem",
                  "&:hover": {
                    backgroundColor: "transparent",
                    color: "#002543",
                  },
                }}
                onClick={closeUpdateModal}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  backgroundColor: "rgb(23, 174, 123)",
                  color: "#ffffff",
                  width: "7.813rem",
                  height: "2.813rem",
                  "&:hover": {
                    backgroundColor: "rgb(23, 174, 123)",
                    color: "#ffffff",
                  },
                }}
                onClick={bulkApprove}
              >
                Approve
              </Button>
            </Box>
          </Box>
        </Box>
      </ReusableModal>
      </div>
    </>
  );
};

export default CustomerAproval;
