import React, { useEffect, useState } from 'react';
import { SuccessAlert } from "../snackBar Alerts/successAlert";
import { ErrorAlert } from "../snackBar Alerts/errorAlert";
import { Box, Grid, InputAdornment, List, ListItem, Switch, TextField } from '@mui/material';
import SearchIcon from "@mui/icons-material/Search";
import HttpComponent from '../School/MakeRequest';
import { useNavigate } from 'react-router-dom';

function EvoucherBooking() {
    const [evoucherStatus, setEvoucherStatus] = useState(false);
    const [message, setMessage] = useState('')
    const [bookingEvoucherStatus, setBookingEvoucherStatus] = useState(false);
    const [bookingStatus, setBookingStatus] = useState(false);


    const navigate = useNavigate();
    const checkVoucherStatus = async () => {
        try {
            HttpComponent({
                method: "GET",
                url: '/api/v1/evoucher/getEvoucherConfigStatus',
                token: localStorage.getItem('X-Authorization'),
            }).then((data) => {
                console.log('evoucherStatus', data)
                if (data.status === 200) {
                    setEvoucherStatus(data.response.status);
                    // setOpenVoucherModal(false);
                } else {
                    console.log('Failed to fetch voucher status');
                }
            });
        } catch (e) {
            console.log('error', e);
        }
    };

    // function getEvoucherStatus() {
    //     try {
    //         HttpComponent({
    //             method: "GET",
    //             url: '/api/v1/evoucher/getEvoucherConfigStatus',
    //             token: localStorage.getItem('X-Authorization'),
    //         }).then((data) => {
    //             if (data.status === 200) {
    //                 let statusEvoucher = data?.response?.status
    //                 setEvoucherStatus(JSON.parse(statusEvoucher));
    //                 console.log(statusEvoucher)
    //             } else {
    //                 setMessage('Failed to enable evoucher');
    //             }
    //         })
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }

    const checkBookingStatus = async () => {
        try {
            HttpComponent({
                method: "GET",
                url: '/api/v1/booking/booking_status',
                token: localStorage.getItem('X-Authorization'),
            }).then((data) => {
                if (data.status === 200) {
                    setBookingStatus(data.response.bookingConfig);
                } else {
                    console.log('Failed to fetch booking status');
                }
            });
        } catch (e) {
            console.log('error', e);
        }
    };

    useEffect(() => {
        if (evoucherStatus && bookingStatus) {
            setBookingEvoucherStatus(true);
        } else {
            setBookingEvoucherStatus(false);
        }
    }, [evoucherStatus, bookingStatus]);

    useEffect(() => {
        checkVoucherStatus()
        checkBookingStatus();
    }, [])

    const switchEvoucher = () => {
        if (evoucherStatus) {
            navigate('/evoucher');
        } else {
            navigate('/evoucher');
        }
    }

    const switchEvoucherBooking = () => {
        if (evoucherStatus) {
            navigate('/evoucher_booking_config');
        } else {
            navigate('/evoucher_booking_config');
        }
    }

    return (
        <div>
            <Grid container item className={"container-fluid"}>
                <Grid container item justifyContent="flex-start" alignItems="left" style={{ width: "86%" }}>
                    <label style={{ height: "35px", margin: "24px 718px 24px 0", fontFamily: "Poppins", fontSize: "25px", fontWeight: "600", fontStretch: "normal", fontStyle: "normal", lineHeight: "1.32", letterSpacing: "normal", textAlign: "left", color: "#dc3545" }}>Configuration</label>
                </Grid>
                <Grid container item justifyContent="space-between" alignItems="left" style={{ width: "86%" }}>
                    <Grid item>
                        <div style={{ fontFamily: "Poppins", fontSize: "20px", fontWeight: "600" }}>
                            <span style={{ color: "#6e7074" }}>Dashboard \ </span>
                            <span style={{ color: "#032541" }}>Booking & Evoucher Config</span>
                        </div>
                    </Grid>
                    <Grid item>
                        <span style={{ marginRight: "10px", fontFamily: "Poppins", fontSize: "15px", fontWeight: "bold", color: "#032541" }}>Search :</span>
                        <TextField size="small" placeholder={"search"} style={{ height: "1px" }} InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon style={{ width: "15px", height: "15px" }} />
                                </InputAdornment>
                            ),
                        }}
                        ></TextField>
                    </Grid>
                </Grid>
                <Grid container item justifyContent={"space-between"} alignContent={"center"} style={{ width: "86%", marginTop: "30px" }}>
                    <Grid item style={{ fontFamily: "Poppins", fontSize: "18px", fontWeight: "600", color: "#032541" }}>
                        Configuration
                    </Grid>
                    <Grid item style={{ fontFamily: "Poppins", fontSize: "18px", fontWeight: "600", color: "#032541" }}>
                        Action
                    </Grid>
                </Grid>
                <Grid container style={{ width: "86%", marginTop: "30px" }}>
                    <Grid item style={{ width: "100%" }}>
                        <List>
                            <ListItem style={{ marginBottom: "10px", fontFamily: "Poppins", fontSize: "14px", borderRadius: "6px", border: "solid 1px rgba(193,193,193, .3)" }}>
                                <Box style={{ display: "flex", alignItems: "center", width: "100%", justifyContent: "space-between" }}>
                                    <Box style={{ color: "#032541", fontSize: "14px", fontWeight: "600" }}>Enable Booking</Box>
                                    <Box>
                                        <Switch checked={bookingEvoucherStatus} onClick={switchEvoucherBooking} />
                                    </Box>
                                </Box>
                            </ListItem>
                        </List>
                        <List>
                            <ListItem style={{ marginBottom: "10px", fontFamily: "Poppins", fontSize: "14px", borderRadius: "6px", border: "solid 1px rgba(193,193,193, .3)" }}>
                                <Box style={{ display: "flex", alignItems: "center", width: "100%", justifyContent: "space-between" }}>
                                    <Box style={{ color: "#032541", fontSize: "14px", fontWeight: "600" }}>Enable Evoucher</Box>
                                    <Box>
                                        <Switch checked={evoucherStatus} onClick={switchEvoucher} />
                                    </Box>
                                </Box>
                            </ListItem>
                        </List>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default EvoucherBooking;
