import {
  Box,
  ButtonBase,
  Checkbox,
  Collapse, FormControl,
  InputLabel,
  LinearProgress, MenuItem,
  Pagination, Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import startOrderFunction from "./posmethods";
import { Add, KeyboardArrowDownRounded } from "@mui/icons-material";
import DateFormatter from "../../../utils/dateFormatter";
import { currencyconverter } from "../../../common/currencyconverter";
import CustomTableSkeleton from "./customTableSkeleton";
import mpesatrans from "../../../common/images/payit_trans_icn.svg";
import banktrans from "../../../common/images/bank-icn.svg";

const Reports = ({dateFrom ,dateTo, searchValue}) => {
  const startOrderFunctions = new startOrderFunction();
  // get all unpaidBills:

  const [page, setPage] = useState(1);
  const [noOfPages, setPageNumbers] = useState(0);
  // handle pagination;
  const handlePage = (event, value) => {
    setPage(value);
  };

  const [dataGridPageSize, setPageSize] = React.useState(10);
  const rowsPerPageOptions = [5, 10, 25, 50, 100];
  const [pageState, setPageState] = useState({ isLoading: true, data: [], count: 0, page: 1, pageSize: dataGridPageSize, transactionsType: [] });

  //   collapse controls;
  const [collapse, setOpenCollapse] = useState(null);

  useEffect(() => {
    setPageState({ ...pageState, isLoading: true });

    startOrderFunctions.getAllTransactions(page, dataGridPageSize, dateFrom ,dateTo, searchValue).then((data) => {
      setPageState({ ...pageState, data: data?.response?.data, count: data?.response?.count, isLoading: false, transactionsType: data?.response?.TransactionTotals });
      setPageNumbers(Math.ceil(data?.response?.count / dataGridPageSize));
    });
  }, [page, dataGridPageSize, dateFrom ,dateTo, searchValue]);
  const handleRowsPerPageChange = (event) => {
    setPageSize(event.target.value);
    setPage(1);
  };

  const listTransactionsByType = () => {
    return pageState.transactionsType?.map((transaction) => {
      return (
        <Box sx={{ display: "flex", gap: "10px", boxShadow: "0 1px 1px 0 rgba(0, 0, 0, 0.16)", padding: "10px", border: "1px solid #d8dee2", height: "10%", width: "200px" }}>
          <ButtonBase>
            {" "}
            <img style={{ width: "30px", height: "30px" }} src={mpesatrans} alt={""} />{" "}
          </ButtonBase>

          <Box>
            <Typography component={"div"}>{transaction?.transactionType}</Typography>
            <Typography component={"div"}>{transaction?.totals}</Typography>
          </Box>
        </Box>
      );
    });
  };

  return (
    <Box>
      {pageState.isLoading && <LinearProgress color="inherit" />}
      <Box sx={{ display: "flex", gap: "10px" }}>{listTransactionsByType()}</Box>

      <TableContainer sx={{ marginBottom: 2, height: '550px' }}>
        <Table>
          <TableHead sx={{ color: "#032541", fontSize: "14px" }}>
            <TableRow>
              <TableCell style={{fontWeight: 'bold'}}>Transaction ID</TableCell>
              <TableCell style={{fontWeight: 'bold'}}>TXN Name</TableCell>
              <TableCell style={{fontWeight: 'bold'}}>Date & Time</TableCell>
              <TableCell style={{fontWeight: 'bold'}}>Amount</TableCell>
            </TableRow>
          </TableHead>

          {pageState.isLoading && <CustomTableSkeleton length={dataGridPageSize} numberOfRows={6} />}
          <TableBody sx={{ color: "#707070" }}>
            {!pageState.isLoading && pageState.data.map((report, index) => {
              return (
                <TableRow
                  style={{ marginBottom: "100px !important" }}
                  key={index}
                  //  sx={{ border: "1px solid #e1d6d6", color: "#707070", '&.MuiTableRow':{marginBottom:'10px', border:'1px solid red'}  }}
                  sx={{ "& > *": { mb: " !important" } }}
                >
                  <TableCell>{report.transactionID}</TableCell>
                  <TableCell>{report.cashier}</TableCell>
                  <TableCell>{DateFormatter(report.transtime)}</TableCell>
                  <TableCell>{currencyconverter(report.transamount)}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <FormControl variant="outlined" sx={{ marginBottom: 2, minWidth: 60, height: 40 }}>
          <InputLabel>Rows per page</InputLabel>
          <Select
              value={dataGridPageSize}
              onChange={handleRowsPerPageChange}
              label="Rows per page"
          >
            {rowsPerPageOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Pagination count={noOfPages} page={page} defaultPage={1} onChange={handlePage} variant="outlined" shape="rounded" />
      </Box>
    </Box>
  );
};

export default Reports;
