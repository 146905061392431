import { Box, Button, FormControl, Grid, InputLabel, MenuItem, CircularProgress, Select, Typography } from "@mui/material";
import NotDoneImg from '../../../images/notdone-ft.svg';
import DoneImg from '../../../images/done-ft.svg';
import { ErrorAlert } from "../../snackBar Alerts/errorAlert";
import { SuccessAlert } from "../../snackBar Alerts/successAlert";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import Divider from '@mui/material/Divider';
import { useEffect, useState } from "react";
import HttpComponent from "../MakeRequest";

export default function AddInputersApprovals(props) {
    const [errorShow, setErrorShow] = useState({ state: false, message: '' });
    const [successShow, setSuccessShow] = useState({ state: false, message: '' });
    const [isApprovalsSet, setIsApprovalsSet] = useState(false)
    const [approvaleRanges, setApprovalRanges] = useState([])
    const [leveslOptions, setLevelsRangeOptions] = useState([])
    // const [selectedUsersToApprove, setSelectedUserToApprover] = useState([])
    const showInputersAdd = props?.showInputersAdd
    const showApproversAdd = props?.showApproversAdd
    const setShowInputersAdd = props?.setShowInputersAdd
    const setShowApproversAdd = props?.setShowApproversAdd
    const selectedInputers = props?.selectedInputers ?? []
    const setSelectedInputers = props?.setSelectedInputers
    const selectedNoofApprovers  =props?.selectedNoofApprovers
    const setSelectedNoOfApprover = props?.setSelectedNoOfApprover
    const setSelectedRange = props?.setSelectedRange
    const selectedRange = props?.selectedRange
    const LevelAssignLevel = props?.LevelAssignLevel
    const setLevelAssignLevel = props?.setLevelAssignLevel
    const selectedApprovers = props?.selectedApprovers
    const setSelectedApprovers = props?.setSelectedApprovers

    function getApprovalsSet() {
        try {
            HttpComponent({
                url: `/api/business/getapproversinbusiness`,
                method: 'GET',
                token: localStorage.getItem('X-Authorization'),
            }).then((data) => {
                if (data?.status === 200) {
                    setIsApprovalsSet(data?.response?.isApprovalSet);
                }
            });
        } catch (error) { }
    }

    const getAmountRange = async () => {
        try {
            HttpComponent({
                method: "GET",
                url: '/api/v1/funds_transfer/amount_range',
                token: localStorage.getItem('X-Authorization'),
            }).then((data) => {
                if (data?.status === 200) {
                    let amountRange = data.response.data
                    amountRange = amountRange?.filter((item) => { return item?.status === 'Active' })
                    amountRange = amountRange?.map((item) => {
                        return { value: item?._id, label: item?.amountRangeDescription }
                    })
                    setApprovalRanges(amountRange)
                }
            })
        } catch (error) {
            console.log(error)

        }

    }
    const getLevelsRange = async () => {
        try {
            HttpComponent({
                method: "GET",
                url: '/api/v1/funds_transfer/levels',
                token: localStorage.getItem('X-Authorization'),
            }).then((data) => {
                if (data?.status === 200) {
                    let levelsRange = data?.response?.data
                    let levelsRangeOptions = levelsRange?.map((item) => {
                        return { value: item?.approveLevelNumber, label: item?.approveLevelName, levelId: item?._id }
                    })
                    setLevelsRangeOptions(levelsRangeOptions)
                }
            })
        }
        catch (error) {
            console.log(error)

        }
    }
    useEffect(() => {
        getApprovalsSet();
        getAmountRange();
        getLevelsRange();
    }, []);

    const handleAddApprover = (index) =>{
        setLevelAssignLevel(index)
        setShowApproversAdd(!showApproversAdd)
    }

    const deleteUserApprover = (user) => {
        let approvers = selectedApprovers?.filter((approver) => approver !== user)
        setSelectedApprovers(approvers)
        setSuccessShow({ state: true, message: "Successfully deleted approver" });

    }


    function CreateApproversInputers() {
        return Array.from({ length: selectedNoofApprovers }, (_, index) => (
            <Grid container width={'100%'}>
                <Grid item width={'100%'}>
                    <Box component={'div'} py={1} display={'flex'} flexDirection={'column'} width={'100%'}>
                        <Box component={'div'} width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                            <Typography sx={{ color: "#032541", fontWeight: 900 }} variant="p">Level {index + 1} Approvers</Typography>
                            <Button onClick={() =>handleAddApprover(index)} style={{ textTransform: "inherit", display: 'flex', alignItems: 'center', gap: 2 }}>
                                <AddCircleIcon style={{ color: "#032541" }} />
                                <Typography sx={{ color: "#032541", fontWeight: 400 }} variant="p">Assign Approver</Typography>
                            </Button>
                        </Box>
                        {selectedApprovers?.length > 0 ? selectedApprovers?.filter((user) => user.levelNumber === index)?.map((user) => (
                            <Box component={'div'} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Typography>{user.userName}</Typography>
                                <Typography>{user.userPhone}</Typography>
                                <Typography>{user.approvalStatus}</Typography>
                                <DeleteIcon onClick={() => deleteUserApprover(user)}  sx={{ color: "#DC3545", cursor: "pointer" }} />
                            </Box>
                        )) :
                            <Box component={'div'} py={3}>
                                <Typography variant="p" sx={{ color: "#707070" }}>No Approvers Added</Typography>
                            </Box>
                        }
                        <Divider sx={{ color: "#707070", py: 1 }} />
                    </Box>
                </Grid>
            </Grid>
        ))

    }

    const [isLoading , setisLoading] = useState(false)
    const disabled = !selectedRange || selectedInputers?.length === 0 ||  !selectedNoofApprovers || selectedApprovers?.length === 0 

    

    const deleteInputerData = (id) => {
        let inputers = selectedInputers?.filter((inputer) => inputer._id !== id)
        setSelectedInputers(inputers)
        setSuccessShow({ state: true, message: "Successfully deleted inputer" });
    }

    function submitAssignApproversInputer(){
        let levelsData = []
        let inputers = []
        let amountRangeId = selectedRange
        let levelNumber = selectedNoofApprovers
        inputers = selectedInputers?.map((inputer) => {
            return { userId: inputer._id }
        });

        for (let i = 0; i < levelNumber; i++) {
            let approves = selectedApprovers?.filter((user) => user?.levelNumber === i)
            approves = approves?.map((user) => {
                return { approvalStatus: user?.approvalStatus, userId: user?._id }
            })
            let levelId = leveslOptions[i].levelId
            let levelData = { approves, levelId }
            levelsData.push(levelData)
        }
        let obj = { amountRangeId, inputers, levelNumber, levelsData }

        if(inputers?.length === 0){
            return setErrorShow({state:true, message:"Inputers are missing"})
        }

        if(levelsData?.length === 0){
            return setErrorShow({state:true, message:"Approvers are missing"})
        }
         if(amountRangeId === null || amountRangeId === undefined){
            return setErrorShow({state:true, message:"Amount range id is missing"})
         }
         if(levelNumber === null || levelNumber === undefined){
            return setErrorShow({state:true, message:"Approver level number is missing"})
         }
         setisLoading(true)
        try{
            HttpComponent({
                method: "POST",
                url: '/api/v1/funds_transfer/add_approver_inputer',
                body: obj,
                token: localStorage.getItem('X-Authorization'),
            }).then((data)=>{
                 if (data.status === 200 || data.status === 201) {
                    setisLoading(false)
                    setSuccessShow({ state: true, message: "Successfully assigned approvers" });

                 }
                
            })
        }catch(error){
            setErrorShow({state:true, message:error?.message})
            setisLoading(false)
        }
    }


    return (
        <Grid container direction={'column'} display={'flex'} width={'100%'}>
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <Grid item width={'50%'} sx={{ border: isApprovalsSet ? '1px solid #17ae7b' : '1px solid #bec5d1', paddingX: 2 , paddingY:2 }}>
                <Box component={'div'} display={'flex'} flexDirection={'column'}>
                    <Box component={'div'} width={'100%'} display={'flex'} py={2} justifyContent={'space-between'} alignItems={'center'}>
                        <Typography variant="h6" sx={{ fontWeight: 700 }}>
                            Step 2: Set Up Approvals
                        </Typography>
                        <Box component={'img'} src={isApprovalsSet ? DoneImg : NotDoneImg} />
                    </Box>
                    <Typography variant="body2">Assign approvers who will allow payments made from your business to suppliers, vendors or petty cash.</Typography>
                </Box>
                <Box component={'div'} flexDirection={'column'} display={'flex'}>
                    <Box component={'div'} my={2}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label-range">Select Approval Range</InputLabel>
                            <Select
                                labelId="demo-simple-select-label-range"
                                id="demo-simple-select"
                                value={selectedRange}
                                label="Select Approval Range"
                                onChange={(e) => setSelectedRange(e.target.value)}
                            >
                                {approvaleRanges?.map((range) => <MenuItem key={range.value} value={range.value}>{range.label}</MenuItem>)}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box component={'div'}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label-levels">Select Approval Levels</InputLabel>
                            <Select
                                labelId="demo-simple-select-label-levels"
                                id="demo-simple-select"
                                value={selectedNoofApprovers}
                                label="Select Approval Levels"
                                onChange={(e) => setSelectedNoOfApprover(e.target.value)}
                            >
                                {leveslOptions?.map((level) => <MenuItem key={level.value} value={level.value}>{level.label}</MenuItem>)}
                            </Select>
                        </FormControl>
                    </Box>
                    {selectedRange && selectedNoofApprovers &&
                        <>
                            <Box component={'div'} py={2}>
                                <Box component={'div'} width={'100%'} display={'flex'} justifyContent={'space-between'}>
                                    <Typography sx={{ color: "#032541", fontWeight: 900 }} variant="h6">Inputers</Typography>
                                    <Button style={{ textTransform: "inherit" }} onClick={() => setShowInputersAdd(!showInputersAdd)}>
                                        <AddCircleIcon style={{ color: "#032541" }} />
                                        <Typography sx={{ color: "#032541", fontWeight: 400 }} variant="subtitle2">Assign Inputer</Typography>
                                    </Button>
                                </Box>
                                <Box component={'div'} sx={{gap:2}}>
                                    {selectedInputers?.map((inputer) => (
                                        <Box component={'div'} key={inputer?._id} sx={{ display: 'flex',justifyContent: 'space-between', alignItems: 'center', }}>
                                            <Typography variant="p">{inputer?.userName}</Typography>
                                            <Typography variant="p">{inputer?.userPhone}</Typography>
                                            <DeleteIcon onClick={() => deleteInputerData(inputer?._id)} sx={{ color: "#DC3545", cursor: "pointer" }} />
                                        </Box>
                                    ))}
                                    {selectedInputers.length === 0 && <Box component={'div'} py={3}>
                                        <Typography variant="p" sx={{ color: "#707070" }}>No Inputers Added</Typography>
                                    </Box>}
                                </Box>
                            </Box>
                            <Box component={'div'}>
                                {CreateApproversInputers()}
                            </Box>
                            
                            <Box component={'div'} justifyContent={'flex-end'} display={'flex'}>
                                <Button disabled={disabled || isLoading} style={ disabled || isLoading ? {backgroundColor: '#03254166', color: '#fff', width: "180px", textTransform: 'inherit'}:{backgroundColor: '#032541', color: '#fff', width: "140px", textTransform: 'inherit'}}> {isLoading ? (
                                     <Box component={'div'} sx={{ display: 'flex', alignItems: 'center' }} gap={4}>
                                     Submitting <CircularProgress size={24} sx={{ color: '#fff' }} />
                                 </Box>
                                ):('Save & Continue')}</Button>
                            </Box>
                        </>
                    }
                </Box>
            </Grid>
        </Grid>
    )
}