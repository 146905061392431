import {
  TextField,
  Stack,
  Grid,
  InputAdornment,
  Button,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ActiveTable from "./ActiveTable";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import { removeClicked, removeModel } from "../../../../features/actionSlice";
import ErrorIcon from "@mui/icons-material/Error";
import CircleIcon from "@mui/icons-material/Circle";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  handleDeactivated,
  handleDeleted,
  handleReleased,
} from "../../../../features/AlertSlice";
import { setSearch } from "../../../../features/searchSlice";
import CancelIcon from "@mui/icons-material/Cancel";
import Papa from "papaparse";
import TerminalsDropdown2 from "./TerminalsDropdown2";
import { makeStyles } from "@mui/styles";
import TerminalsDropdown3 from "./TerminalsDropdown3";

const useStyles = makeStyles((theme) => ({
  focusedTextField: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#032541",
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "#032541",
    },
  },
}));

function exportArrayToCSV(data, filename) {
  const csv = Papa.unparse(data);
  const blob = new Blob([csv], { type: "text/csv" });
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
}

const baseUrl = process.env.REACT_APP_BASE_URL;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Active() {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(removeModel());
  }, []);

  const [open, setOpen] = React.useState(false);
  const [releaseOpen, setReleaseOpen] = React.useState(false);

  const clickedItem = useSelector((state) => state.dropdownAction.clickedItem);

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenDialog3 = () => {
    setReleaseOpen(true);
  };

  const handleClose3 = () => {
    setReleaseOpen(false);
  };

  const serialNo = useSelector((state) => state.terminalDetails.serialNo);
  const terminalId = useSelector((state) => state.terminalDetails.terminalId);

  const handleDeactivate = () => {
    try {
      axios
        .post(baseUrl + "/api/deactivateTerminal", {
          serialNo: serialNo,
        })
        .then((response) => {
          if (response) {
            console.log("deactivated successfully");
            dispatch(handleDeactivated(true));
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  const handleRelease = () => {
    try {
      axios
        .post(
          baseUrl + "/api/releaseTerminalFromBusiness",
          {
            terminalId,
          },
          {
            headers: {
              "X-Authorization": localStorage.getItem("X-Authorization"),
            },
          }
        )
        .then((response) => {
          if (response) {
            console.log("deleted successfully");
            dispatch(handleReleased(true));
            window.location.reload();
          }
        });
    } catch (e) {
      console.log(e);
    }
    // console.log("Releaase function called");
  };

  useEffect(() => {
    if (clickedItem === "Deactivate") {
      handleOpenDialog();
    } else if (clickedItem === "Release") {
      handleOpenDialog3();
    }
    dispatch(removeClicked());
  }, [clickedItem]);

  const [searchValue, setSearchValue] = useState("");

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };

  const reduxSearchBy = useSelector((state) => state.dropdownAction.searchBy4);
  const [searchBy, setSearchBy] = useState(reduxSearchBy);

  useEffect(() => {
    setSearchBy(reduxSearchBy);
  }, [reduxSearchBy]);

  //seeting search value in redux when user types in textfield
  useEffect(() => {
    if (searchValue) {
      dispatch(setSearch(searchValue));
    } else {
      dispatch(setSearch(""));
    }
  }, [searchValue]);

  const exportData = useSelector((state) => state.exportDetails.terminals);

  const handleExport = () => {
    exportArrayToCSV(exportData, "Assigned Terminals.csv");
  };

  return (
    <Stack>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Stack direction="row" spacing={2}>
              <TerminalsDropdown3 />
              <TextField
                id="input-with-icon-textfield"
                // label="Search by serialNo"
              label={`Search By ${searchBy}`}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
                size="small"
                value={searchValue}
                onChange={handleSearch}
                className={classes.focusedTextField}
              />
            </Stack>

            <TerminalsDropdown2 />

            <Button
              variant="outlined"
              sx={{
                bgcolor: "#f5f6f7",
                borderColor: "#f5f6f7",
                color: "#032541",
                ":hover": {
                  borderColor: "#032541",
                },
                textTransform: "none",
              }}
              onClick={handleExport}
            >
              Export
              <MoreVertIcon
                sx={{
                  color: "#032541",
                }}
              />
            </Button>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <ActiveTable />
        </Grid>
      </Grid>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        {/* <DialogTitle>{"Use Google's location service?"}</DialogTitle> */}
        <DialogContent>
          <Stack direction="row">
            <Stack
              width={"30%"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <ErrorIcon
                sx={{
                  fontSize: "100px",
                  color: "#dc3545",
                }}
              />
            </Stack>
            <Stack width={"70%"}>
              <Typography
                fontFamily="Poppins"
                fontSize={16}
                fontWeight="600"
                fontStyle="normal"
                textAlign="left"
                color="#032541"
              >
                Deactivate Terminal?
              </Typography>
              <Typography
                fontFamily="Poppins"
                fontSize={14}
                fontWeight="normal"
                fontStyle="normal"
                textAlign="left"
                color="#707070"
              >
                Selected Terminal will be deactivated...
              </Typography>
              <Typography
                fontFamily="Poppins"
                fontSize={14}
                fontWeight="normal"
                fontStyle="normal"
                textAlign="left"
                color="#032541"
              >
                <CircleIcon sx={{ fontSize: "10px", mr: 1 }} />
                {serialNo}
              </Typography>
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            variant="outlined"
            sx={{
              borderRadius: 4,
              backgroundColor: "#ffffff",
              borderStyle: "solid",
              borderWidth: 1,
              borderColor: "#032541",
              color: "#032541",
              ":hover": {
                backgroundColor: "#032541",
                color: "white",
              },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleClose();
              handleDeactivate();
            }}
            variant="outlined"
            sx={{
              borderRadius: 4,
              backgroundColor: "#dc3545",
              borderStyle: "solid",
              borderWidth: 1,
              borderColor: "#dc3545",
              color: "white",
              ":hover": {
                backgroundColor: "#032541",
                color: "white",
              },
            }}
          >
            Deactivate
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={releaseOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose3}
        aria-describedby="alert-dialog-slide-description"
      >
        {/* <DialogTitle>{"Use Google's location service?"}</DialogTitle> */}
        <DialogContent>
          <Stack direction="row">
            <Stack
              width={"30%"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <CancelIcon
                sx={{
                  fontSize: "100px",
                  color: "#dc3545",
                }}
              />
            </Stack>
            <Stack width={"70%"}>
              <Typography
                fontFamily="Poppins"
                fontSize={16}
                fontWeight="600"
                fontStyle="normal"
                textAlign="left"
                color="#032541"
              >
                Release Terminal
              </Typography>
              <Typography
                fontFamily="Poppins"
                fontSize={14}
                fontWeight="normal"
                fontStyle="normal"
                textAlign="left"
                color="#707070"
              >
                Selected Terminal will be released from business..
              </Typography>
              <Typography
                fontFamily="Poppins"
                fontSize={14}
                fontWeight="normal"
                fontStyle="normal"
                textAlign="left"
                color="#032541"
              >
                <CircleIcon sx={{ fontSize: "10px", mr: 1 }} />
                {serialNo}
              </Typography>
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose3}
            variant="outlined"
            sx={{
              borderRadius: 4,
              backgroundColor: "#ffffff",
              borderStyle: "solid",
              borderWidth: 1,
              borderColor: "#032541",
              color: "#032541",
              ":hover": {
                backgroundColor: "#032541",
                color: "white",
              },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              console.log("release clicked");
              handleClose3();
              handleRelease();
            }}
            variant="outlined"
            sx={{
              borderRadius: 4,
              backgroundColor: "#dc3545",
              borderStyle: "solid",
              borderWidth: 1,
              borderColor: "#dc3545",
              color: "white",
              ":hover": {
                backgroundColor: "#032541",
                color: "white",
              },
            }}
          >
            Release
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
}

export default Active;
