import { Box, Button, Select, TextField, Typography, MenuItem, FormControl } from "@mui/material";
import React, { useEffect, useState } from 'react';
import happy from "./Images/happy-icn.svg"
import { useDispatch, useSelector } from "react-redux";
import { addService, clearServicesToAdd, setCategories } from "../../features/servicesSlice";
import HttpComponent from "./MakeRequest";
import { ErrorAlert } from "../snackBar Alerts/errorAlert";
import { SuccessAlert } from "../snackBar Alerts/successAlert";
import CustomInputLabel from "./CustomInputLabel";
import CustomTextField from "./CustomTextField";
import CustomSelectField from "./CustomSelectField";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Checkbox from '@mui/material/Checkbox';
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import InputLabel from "@mui/material/InputLabel";

let baseUrl = process.env.REACT_APP_BASE_URL;
// import Checkbox from '@mui/material/Checkbox';

// const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const SchoolAddService = () => {
    const buttonWidthHeight = {
        width: "9.063rem",
        height: "3.125rem",
        '&:hover': {
            backgroundColor: '#032541',
            color: 'white'
        }
    }
    const [gradeOptions, setGradeOptions] = useState([])

    const [streams, setStreames] = useState([]);
    const [termOptions, setTermOptions] = useState([]);
    const [productTypes, setProductTypes] = useState([])


    const { schoolTypeName, schoolTypeId } = useSelector((store) => store.schoolType.schoolTypeDetail)

    console.log('School Type Id', schoolTypeId);
    const GetGrades = async () => {
        const response = await fetch(`${baseUrl}/api/get_school_grades?schoolType_id=${schoolTypeId}`, {
            headers: {
                "Content-Type": "application/json",
                "X-Authorization": X_Authorization,
            },
        });
        const data = await response.json();
        console.log(data, "Grades");

        if (response.status === 201) {
            setGradeOptions(data.data.map((itemGrade) => {
                return { value: itemGrade.schoolGrades, label: itemGrade.schoolGrades }
            }
            ))
        }
    };
    const [coursesOptions, setCoursesOptions] = useState([]);

    const getCourses = async () => {
        const response = await fetch(`${baseUrl}/api/v1/get_school_courses`, {
            headers: {
                "Content-Type": "application/json",
                "X-Authorization": X_Authorization,
            },
            method: "GET",
        });
        const data = await response.json();
        console.log(data, "Get Courses");
        if (response.status === 200) {
            // Assuming data.data is an array of course objects
            const uniqueCourses = Array.from(new Set(data.data.map(course => course.courseName)));

            const coursesOptions = uniqueCourses.map(courseName => ({
                value: courseName,
                label: courseName
            }));
            console.log("Courses set to unique", coursesOptions);
            setCoursesOptions(coursesOptions);
        }

    };

    useEffect(() => {
        getCourses();
    }, []);

    function getTypeOPtions() {
        try {
          HttpComponent({
            method: "GET",
            url: `/api/getVariablePriceStatus`,
            token: localStorage.getItem('X-Authorization')
          }).then((data) => {
            if (data.status === 200) {
              setProductTypes(data?.response?.data)
            }
    
          })
    
        } catch (error) {
    
        }
      }
    
      useEffect(() => {
        getTypeOPtions()
      }, [])


    const GetStreamsTerms = async () => {
        const response = await fetch(`${baseUrl}/api/get_school_terms?schoolType_id=${schoolTypeId}`, {
            headers: {
                "Content-Type": "application/json",
                "X-Authorization": X_Authorization,
            },
        });
        const data = await response.json();
        console.log(data, "Streams");
        if (response.status === 201) {
            setTermOptions(data.data.map((terms) => {
                return { value: terms.schoolGrades, label: terms.schoolGrades }
            }
            ))
        }
    };
    useEffect(() => {
        if (schoolTypeId) {
            GetGrades();
            GetStreamsTerms();
        }

    }, [schoolTypeId]);



    const cancelButtonStyles =
    {
        "width": "7.813rem",
        "height": "2.813rem",
        "padding": "12.5px 36.7px 12.5px 38.3px",
        "borderRadius": "4px",
        "border": "solid 1px #dc3545",
        "color": "#dc3545"
    }

    const buttonStyles =
    {
        "width": "7.813rem",
        "height": "2.813rem",
        "borderRadius": "4px",
    }
    const [checkRequest, setCheckRequest] = useState(false);

    // const checkButtonChange = (e) => {
    //     setCheckRequest(e.target.checked);
    // };
    const checkButtonChange = (e) => {
        const isChecked = e.target.checked;
        setCheckRequest(isChecked);
        
        setFormData({
            ...formData,
            serviceType: isChecked ? "Request" : "Normal", 
        });
    };

    console.log(checkRequest, "checkRequest");

    const [serviceExpiry, SetServiceExpiry] = useState(new Date());

    const obj = {
        serviceName: "",
        priceType: "",
        categoryId: "",
        amount: "",
        description: "",
        grade: "",
        term: "",
        serviceType: "Normal",
        serviceExpiry: serviceExpiry,
        ectizenitem_ref: "",
        ectizenAccount_Id: "",
        accountId: "",
    
    };

    // Initialize formData based on the checkbox state
    const initialFormData = obj;

    const [formData, setFormData] = useState(initialFormData);



    // console.log(formData, "formData ,hapa");

    const [successShow, setSuccessShow] = useState({ state: false, message: "service  created successfully" });

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };
    const handleServiceExpiry = (newValue) => {
        SetServiceExpiry(newValue);
        setFormData({
            ...formData,
            serviceExpiry: newValue
        });
    };

    const dispatch = useDispatch();


    // const addToList = () =>{
    //     dispatch(addService(formData))
    //     setFormData({
    //         serviceName: "",
    //         categoryId:"",
    //         amount:"",
    //         description:"",
    //         priceType:"Fixed",
    //     })
    //     setSuccessShow({state:true, message:"Service Added"})
    // }

    const navigate = useNavigate()
    const [ectizenStatus, setEctizenStatus] = useState(false)



    const checkEcitizenStatusMethod = async () => {
        HttpComponent({
            method: 'GET',
            url: `/api/v1/ecitizen/get_status`,
            body: null,
            token: X_Authorization
        }).then((data) => {

            if (data.status === 200) {
                let status = data.response?.ecitizenStatus
                console.log(status, 'status---')
                setEctizenStatus(status)

            } else {
            }
        }).catch((error) => {
            console.error(error, 'error');
        })


    }

    useEffect(() => {
        checkEcitizenStatusMethod()
    }, [])


    const mapOptions = productTypes?.map((option) => {
        const isVariablePriceItem = option?.priceStatusName === "Variable Price Item";
        return {
          value: isVariablePriceItem ? "Variable Price Item" : option?.priceStatusName,
          label: isVariablePriceItem ? "Variable Price Service" : option?.priceStatusName,
        };
      })

    const submit = () => {
        //  check if formData.amount is a number
        if (isNaN(formData.amount)) {
            setErrorShow({ state: true, message: "Amount must be a number" })
            return;
        }

        // Add the serviceType field conditionally
        if (checkRequest) {
            formData.serviceType = "Request";
        } else if (ectizenStatus) {
            if (!formData?.ectizenAccount_Id) {
                return setErrorShow({ state: true, message: "Service ID is required" })
            }

            if (!formData?.ectizenitem_ref) {
                return setErrorShow({ state: true, message: "Service ID is required" })

            }

        }
        else {
            // Remove serviceType and serviceExpiry when checkRequest is false
            delete formData.serviceType;
            delete formData.serviceExpiry;
        }

        console.log("here store is", formData);
        HttpComponent({
            method: 'POST',
            url: '/api/add_services',
            body: {

                services: [
                    formData
                ]
            },
            token: X_Authorization
        }).then((data) => {
            console.log("here store is data", data);
            if (data.status === 201) {
                navigate("/products")
            } else {
                console.error("Error setting info")
                setErrorShow({ state: true, message: data.response.message })
            }
        }).catch((error) => {
            console.error(error.message);
        })
    }


    const { userId, X_Authorization } = useSelector((store) => store.user);

    const [errorShow, setErrorShow] = useState({ state: false, message: "" })

    const [options, setOptions] = useState([]);
    const [expenseAccounts, setExpenseAccount] = useState([])
    const [accountStatus, setAccountStatus] = useState({})

    // function getAccountingStatus() {
    //     try {
    //         HttpComponent({
    //             method: "POST",
    //             url: '/api/v1/check_accounting_status',
    //             body: {
    //                 businessID: localStorage.getItem('businessId')
    //             },
    //             token: localStorage.getItem('X-Authorization'),
    //         }).then((data) => {
    //             if (data.status === 200) {
    //                 setAccountStatus(data.response.data)
    //             }
    //         })
    //     } catch (error) {
    //     }
    // }




    //categoryName 

    const fetchCategories = () => {
        HttpComponent({
            method: 'GET',
            url: '/api/listCategories/Active?productService=Service',
            body: null,
            token: X_Authorization
        }).then((data) => {
            console.log("here store is data", data);
            if (data.status === 200) {
                //console.log(data?.response?.data , 'jj')
                const mappedData = data?.response?.data?.filter((product) => product?.categoryName !== 'Convenience Fee')?.map(({ categoryName, _id }) => ({ label: categoryName, value: _id }));
                console.log("mapped complete", mappedData)
                setOptions(mappedData)
                dispatch(setCategories(mappedData));
            } else {
                console.error("Error setting info")
                setErrorShow({ state: true, message: data.response.message })
            }
        }).catch((error) => {
            console.error(error.message);
        })
    }
    // const getExpenseAccount = () => {
    //     try {
    //         HttpComponent({
    //             method: 'POST',
    //             url: `/api/v1/business/get_general_ledger_expense_accounts`,
    //             body: {
    //                 businessID: localStorage.getItem('businessId')
    //             },
    //             token: localStorage.getItem('X-Authorization')
    //         }).then((data) => {
    //             console.log(data, 'expense account')
    //             if (data.status === 200) {
    //                 console.log(data?.response?.data, 'expense account')
    //                let newData = data?.response?.data
    //                newData = newData.map((item) => {
    //                       return {label: item.name, value: item.accountId}
    //                 })
    //                 setExpenseAccount(newData)
    //             }

    //         })

    //     } catch (error) {

    //     }
    // }



    useEffect(() => {
        // getAccountingStatus()
        fetchCategories()
        // getExpenseAccount()

    }, []);




    return (
        <Box component="div" sx={{ display: "flex", flexDirection: "column", marginX: 1 }}>
            <ErrorAlert
                vertical="top"
                horizontal="right"
                onClose={() => setErrorShow({ ...errorShow, state: false })}
                open={errorShow.state}
                message={errorShow.message} />
            <SuccessAlert
                vertical="top"
                horizontal="right"
                onClose={() => setSuccessShow({ ...successShow, state: false })}
                open={successShow.state}
                message={successShow.message}
            />
            <Box sx={{ marginBottom: 4, display: "flex" }}>
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <ArrowBackIosIcon sx={{ cursor: "pointer" }} onClick={() => navigate("/products")} />
                </Box>

                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Typography sx={{ color: "#032541", fontSize: "1.563rem", fontWeight: 700 }}>
                        Services
                    </Typography>
                </Box>

            </Box>


            <Box component="div" sx={{ display: "flex" }}>
                <Box>
                    <CustomInputLabel required={true} label={"Service Name"} />
                    <CustomTextField value={formData.serviceName} onChange={handleInputChange} name={"serviceName"} placeholder={"Service Name"} />

                    <CustomInputLabel required={true} label={"Amount Type "} />
                    {/* <CustomTextField value={formData.priceType} name={"priceType"} /> */}
                   
                    <CustomSelectField
                    value={formData.priceType}
                    onChange={handleInputChange}
                    name={"priceType"}
                    options={[
                        { value: "", label: "Select Amount Type" },
                        ...mapOptions,
                    ]}
                    medium
                    />
                </Box>


                <Box component="div" sx={{ marginLeft: 1 }}>
                    <CustomInputLabel required={true} label={"Service Category"} />
                    {/*this now will have to be a select*/}
                    <CustomSelectField value={formData.categoryId} onChange={handleInputChange} name={"categoryId"} placeholder={"Select Service Category"} options={options} medium />
                    {/*<CustomTextField value={formData.serviceCategory} onChange={handleInputChange} name={"serviceCategory"} placeholder={"Service Category"} />*/}

                    <CustomInputLabel required={true} label={"Amount"} />
                    <CustomTextField value={formData.amount} onChange={handleInputChange} name={"amount"} placeholder={"Amount"} />
                </Box>
            </Box>

            <Box sx={{ display: "flex" }}>
                <Box component="div" sx={{}}>
                    <CustomInputLabel required={true} label={schoolTypeName?.includes("University") ? "Course" : "Grade"} />
                    {schoolTypeName?.includes("University") ? (
                        <CustomSelectField medium value={formData.grade} onChange={handleInputChange} name={"grade"} placeholder={"Select Course"} options={coursesOptions} />
                    ) :
                        <CustomSelectField value={formData.grade} onChange={handleInputChange} name={"grade"} placeholder={"Select Grade"} options={gradeOptions} />
                    }

                </Box>
                <Box component="div" sx={{ marginLeft: 1 }}>
                    <CustomInputLabel required={true} label={schoolTypeName?.includes("University") ? "Semester" : "Term"} />
                    <CustomSelectField medium value={formData.term} onChange={handleInputChange} name={"term"} placeholder={schoolTypeName?.includes("University") ? "Select Semester" : "Select Term"} options={termOptions} />
                </Box>
            </Box>
            {ectizenStatus ? <Box sx={{ display: "flex" }}>
                <Box component="div" sx={{}}>
                    <CustomInputLabel required={true} label={"Ecitizen Service Id"} />
                    <CustomTextField value={formData.ectizenAccount_Id} onChange={handleInputChange} name={"ectizenAccount_Id"} placeholder={"Ectizen Service Id"} />

                </Box>
                <Box component="div" sx={{ marginLeft: 1 }}>
                    <CustomInputLabel required={true} label={"Ecitizen Item Reference"} />
                    <CustomTextField value={formData.ectizenitem_ref} onChange={handleInputChange} name={"ectizenitem_ref"} placeholder={"Ecitizen Item Reference"} />
                </Box>
            </Box> : null}

            {/* {accountStatus ? <>
                <Box component="div" sx={{}}>
                    <CustomInputLabel required={true} label={"Ecitizen Service Id"} />
                    <CustomSelectField value={formData.accountId} onChange={handleInputChange} name={"accountId"} placeholder={"GL Account"} options={expenseAccounts} />

                </Box>
            </> : null} */}


            <Box>
                <CustomInputLabel required={false} label={"Service Description"} />
                <TextField
                    name={"description"}
                    value={formData.description}
                    onChange={handleInputChange}
                    placeholder={"Service Description"}
                    variant="outlined"
                    margin="dense"
                    sx={{ marginBottom: 2 }}
                    multiline
                    minRows={2}
                    InputProps={{
                        sx: {
                            width: '51rem',
                            fontSize: "0.875rem",
                            "& fieldset": {
                                borderColor: "#bec5d1"
                            }
                        }
                    }}
                />
            </Box>
            <Box component="div" sx={{ display: "flex", }}>
                <Checkbox checked={checkRequest} onChange={checkButtonChange} name={"Request"} label={"Request"} />
                <div style={{ marginTop: "10px", fontSize: "16px" }}>
                    <span>This service can be requested.</span>

                </div>

            </Box>
            {checkRequest && <Box component="div" sx={{ display: "flex", flexDirection: "column", marginTop: "10px" }}>
                <CustomInputLabel required={true} label={"Valid Until"} />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                        inputFormat="DD/MM/YYYY"
                        value={serviceExpiry}
                        required
                        onChange={handleServiceExpiry}
                        InputProps={{
                            sx: {
                                width: '51rem',
                                fontSize: "0.875rem",
                                "& fieldset": {
                                    borderColor: "#bec5d1"
                                }
                            }
                        }}
                        renderInput={(params) => <TextField {...params} />}
                    />
                </LocalizationProvider>

            </Box>}



            <Box component="div" sx={{ width: "70%", marginLeft: "-9center0px", display: "flex", justifyContent: "space-evenly", marginTop: "10px" }}>
                <Box component="div">

                    <Button sx={{
                        ...buttonStyles,
                        border: " solid 1px #002543",
                        color: "#002543",
                        '&:hover': {
                            backgroundColor: "transparent",
                            color: '#002543'
                        }
                    }}
                        onClick={submit}
                        disabled={!formData.serviceName.trim() || !formData.categoryId.trim() || !formData.amount.trim() || !formData.priceType}
                    >
                        Save
                    </Button>

                </Box>

            </Box>
        </Box>
    );
};

export default SchoolAddService;
