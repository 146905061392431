// converts the first letter of input string to uppercase
export const toCamelCase = (inputString) => {
  const inputValues = inputString.split(" ");
  return inputValues
    .map((inputValue, index) => {
      if (index === 0) {
        return (
          inputValue.charAt(0).toUpperCase() + inputValue.slice(1).toLowerCase()
        );
      } else {
        return inputValue.toLowerCase();
      }
    })
    .join(" ");
};

// convert all the first letters of the input string to uppercase
export const toTitleCase = (inputString) => {
    return inputString
    .split(' ') 
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' '); 
};

