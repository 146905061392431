import LoadingButton from "@mui/lab/LoadingButton";
import { Button, ButtonBase, Card, Grid, Box, MenuItem, TextField, Typography } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import PhoneInput from "react-phone-input-2";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Mpesa from "../../../common/images/mpesa-icon.svg";
import CardImg from '../../../common/images/card-icon.svg'
import { PaymentSent } from "../../Modals/Invoice/paymentSent";
import HttpComponent from "../../School/MakeRequest";
import { ErrorAlert } from "../../snackBar Alerts/errorAlert";
import { SuccessAlert } from "../../snackBar Alerts/successAlert";
import SendIcon from '@mui/icons-material/Send';
import BankPayBillIcon from "../../School/Images/bank-paybill.svg";
import CARDREADY from '../../School/Images/cardlinkready.svg';
import BankSlip from "../../../common/images/bank-slip-icn.svg";
import CashIcon from "../../School/Images/cash-icon.svg"
import MomoIcon from '../../../images/mtn-momo-icn.svg'
import { sumBy } from "lodash";
import { PrintPromptAfterSale, PrinterPopUp } from "../../bills/startorderModals/modals";
import startOrderFunction from "../../bills/billscomponents/startOrderEndpoints";
import { currencyconverter, GetDefaultCurrency } from "../../../common/currencyconverter";
import VoucherIcon from '../../School/Images/uploaded-sts-icn.svg'
import SelectInput from "@mui/material/Select/SelectInput";
import { QRcodeScanner } from "../../Dashboard/customerScanner";
import RedeemedIcon from '../../../images/evoucher-icn10.webp'
import UnusedIcon from '../../../images/evoucher-icn.webp'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ExpiredVoucherIcon from '../../../images/evoucher-red.png'
import PinInput from "react-pin-input";

const baseUrl = process.env.REACT_APP_BASE_URL;

export default function RequestPayFromCustomer({ orderIDS }) {
    //get the order ids
    const startOrderFunctions = new startOrderFunction();
    const orderids = orderIDS?.map((order) => order?.orderId)

    // open print Modals
    const [openPrintModal, setOpenPrintModal] = useState(false)
    // toggle printer and pos;
    const [showPrinter, setShowPrinter] = useState(false);
    const [printerPopUp, setPrinterPopUp] = useState(false)
    // GET PRINTERS;
    const [printers, setPrinters] = useState([]);
    const [pos, setPos] = useState([]);
    const [posSerialNo, setPosSerialNo] = useState("")

    // get startOrderData;
    const queryParams = decodeURIComponent(window.location.search);
    const startOrderData = queryParams.slice(1);
    // console.log(startOrderData , 'startOrderData')
    const [startOrderTag, newStartOrderId, carriedAmount, startOrderArrayItems, doRedirect, discAmount] = startOrderData?.split("?")

    // console.log("carriedAmount", carriedAmount)
    const [startOrderAmount, setStartOrderAmount] = useState(carriedAmount)
    const [startOrderId, setStartOrderId] = useState([])
    console.log('startOrderId', startOrderId)
    const [startOrderItems, setStartOrderItems] = useState([])
    const [discount, setDiscountAmount] = useState(0)
    //return products

    const productData = orderIDS?.flatMap((item) => item?.products?.map((product) => product))
    //billTotal
    let totalBills = sumBy(orderIDS, v => { return v?.billTotal })
    //get pushyTransaction ids 
    const checkedpushTransactionIds = orderIDS.map((checkord) => checkord?.orderId)

    //show balance
    const [balance, setBalance] = useState(totalBills);
    const [paybillBalance, setPaybillbalance] = useState(0)
    const [cashAmount, setCashAmount] = useState(0)
    let group = localStorage.getItem('group')

    useEffect(() => {
        if (startOrderTag === "startOrderTag") {
            setStartOrderId([newStartOrderId])
        } else if (startOrderTag === "unPaidstartOrderTag") {

            setStartOrderId(JSON.parse(newStartOrderId))
            if (startOrderArrayItems) {
                setStartOrderItems(JSON.parse(startOrderArrayItems))
            }

        }

        if (startOrderArrayItems) {
            setStartOrderItems(JSON.parse(startOrderArrayItems))
        }

        setCashAmount(startOrderAmount)
        // setNewTotalBill(startOrderAmount)
        if (discAmount) { setDiscountAmount(discAmount) }


    }, [startOrderTag, carriedAmount])


    useEffect(() => {
        if (totalBills !== 0 && cashAmount > totalBills) {
            setPaybillbalance((Number(cashAmount) - Number(totalBills)))
        } else if (cashAmount > startOrderAmount) {
            setPaybillbalance((Number(cashAmount) - Number(startOrderAmount)))
        } else {
            setPaybillbalance(0)
        }

    }, [cashAmount])


    // get printers;
    // getPos
    useEffect(() => {
        startOrderFunctions.getPos().then((data) => {
            if (data?.status === 200 || data?.status === 201) {
                setPos(data?.response?.data)
            }
        })
    }, [])

    useEffect(() => {
        startOrderFunctions.getPrinters().then((data) => {
            if (data?.status === 200 || data?.status === 201) {
                setPrinters(data?.response?.data)
            }
        })
    }, [])

    useEffect(() => {
        if (startOrderTag === "unPaidstartOrderTag") {
            setNewTotalBill(parseInt(startOrderAmount))
        }
        else {
            // setNewTotalBill(totalBills)
            setNewTotalBill(Number(carriedAmount))
        }
    }, [totalBills, startOrderAmount])

    const [orderDetails, setOrder] = useState({ transamount: 0, discountAmount: 0 })
    useEffect(() => {
        if (startOrderTag && startOrderTag === "startOrderTag") {

            let orderId = startOrderId[0] ? startOrderId[0] : newStartOrderId
            startOrderFunctions.getDetailedBill(orderId).then((data) => {
                let order = data?.response?.data?.deficit
                // console.log("new startordertagstartOrderTag", startOrderTag, orderId, data?.response?.data?.deficit)
                setStartOrderAmount(data?.response?.data?.deficit)
                setCashAmount(data?.response?.data?.deficit)
                setOrder({ ...orderDetails, transamount: data?.response?.data?.deficit, discountAmount: data?.response?.data?.deficit })
            })
        }

    }, [startOrderTag])

    const sendToPrinter = async (SerialNo) => {

        if (startOrderId?.length > 1) {
            await Promise.all(
                startOrderId?.map((startOrderId) => {
                    startOrderFunctions.sendToPrinter(startOrderId, SerialNo).then((data) => {
                        if (data?.status === 409 || data?.status === 200) {
                            setSuccessNotificationReceivedPaymentOpen(true)
                            setTimeout(() => {
                                setSuccessNotificationReceivedPaymentOpen(false)
                                navigate(-1)
                            }, 2000)
                        }
                    })
                })
            ).then(() => {
                setSuccessNotificationReceivedPaymentOpen(true)
                setTimeout(() => {
                    setSuccessNotificationReceivedPaymentOpen(false)
                    navigate(-1)
                }, 2000)
            })
        } else {
            startOrderFunctions.sendToPrinter(startOrderId[0], SerialNo).then((data) => {
                if (data?.status === 409 || data?.status === 200) {
                    setSuccessNotificationReceivedPaymentOpen(true)
                    setTimeout(() => {
                        setSuccessNotificationReceivedPaymentOpen(false)
                        if (doRedirect && doRedirect === "fromSaveBill") {
                            navigate(`/pos/startorder`)
                        } else {
                            navigate(-1);
                        }
                    }, 2000)
                }
            })
        }

    }

    function getRandomPushTransactionIdFromArray(numbers) {
        const randomIndex = Math.floor(Math.random() * numbers.length);
        return numbers[randomIndex];
    }
    const pushIdArray = checkedpushTransactionIds;
    const randomNum = getRandomPushTransactionIdFromArray(pushIdArray);

    const navigate = useNavigate()
    const [successOpen, setSuccessOpen] = useState(false);
    const [activeButton, setActiveButton] = useState('')
    const [userPhone, setUserPhone] = useState('')
    const [invoiceBalance, setInvoiceBalance] = useState();
    const [amount, setAmount] = useState("")


    const [bankAccount, setBankAccount] = useState('')
    const [availableBanks, setAvailableBanks] = useState([])

    useEffect(() => {
        const bankIds = []
        startOrderFunctions.getBusinessInfo().then((data) => {
            let coopDarajaId = data?.response?.data?.coopDarajaId
            let kcbDarajaId = data?.response?.data?.kcbDarajaConfigIds
            if (coopDarajaId) {
                bankIds.push({ name: "COOP", value: "COOP" })
            }
            if (kcbDarajaId?.length > 0) {
                bankIds.push({ name: "KCB", value: "KCB" })
            }

            if (bankIds?.length === 1) {
                setAvailableBanks(bankIds)
                setBankAccount(bankIds[0]?.value)
            } else {
                setAvailableBanks(bankIds)
            }

        })
    }, [])

    useEffect(() => {

    }, [])
    // get businessConfigs;
    const [kcbphone, setkcbphone] = useState('')
    const [kcbAmount, setKcbAmount] = useState('')

    const [email, setEmailAddress] = useState('')
    const [cardAmount, setCardAmout] = useState('')

    const { invoiceNumber, darajaConfigId } = useParams();

    // Success Notification
    const [successNotificationOpen, setSuccessNotificationOpen] = React.useState(false);
    const handleSuccessNotificationClick = () => { setSuccessNotificationOpen(true); };
    const handleSuccessNotificationClose = (event, reason) => { if (reason === 'clickaway') { return } setSuccessNotificationOpen(false); };


    const [successNotificationOpen2, setSuccessNotificationOpen2] = React.useState(false);
    const handleSuccessNotificationClick2 = () => { setSuccessNotificationOpen2(true); };
    const handleSuccessNotificationClose2 = (event, reason) => { if (reason === 'clickaway') { return } setSuccessNotificationOpen(false); };



    const [successNotificationOpen1, setSuccessNotificationOpen1] = React.useState(false);
    const handleSuccessNotificationClick1 = () => { setSuccessNotificationOpen1(true); };
    const handleSuccessNotificationClose1 = (event, reason) => { if (reason === 'clickaway') { return } setSuccessNotificationOpen1(false); };

    // Success Notification
    const [successNotificationReceivedPaymentOpen, setSuccessNotificationReceivedPaymentOpen] = React.useState(false);
    const handleSuccessNotificationPaymentReceivedClick = () => { setSuccessNotificationReceivedPaymentOpen(true); };
    const handleSuccessNotificationReceivedPaymentClose = (event, reason) => { if (reason === 'clickaway') { return } setSuccessNotificationReceivedPaymentOpen(false); };

    const [bussinessStkId, setBussinessStkId] = useState('')

    // Error Notification
    const [errorNotificationOpen, setErrorNotificationOpen] = React.useState(false);
    const handleErrorNotificationClick = () => { setErrorNotificationOpen(true); };
    const handleErrorNotificationClose = (event, reason) => { if (reason === 'clickaway') { return } setErrorNotificationOpen(false); };
    // Card Error
    const [errorNotificationOpen1, setErrorNotificationOpen1] = React.useState(false);
    const handleErrorNotificationClick1 = () => { setErrorNotificationOpen1(true); };
    const handleErrorNotificationClose1 = (event, reason) => { if (reason === 'clickaway') { return } setErrorNotificationOpen1(false); };

    // Error Notification
    const [errorNotificationTimeoutOpen, setErrorNotificationTimeoutOpen] = React.useState(false);
    const handleErrorNotificationTimeoutClick = () => { setErrorNotificationTimeoutOpen(true); };
    const handleErrorNotificationTimeoutClose = (event, reason) => { if (reason === 'clickaway') { return } setErrorNotificationTimeoutOpen(false); };

    // Error Notification
    const [errorNotificationNoBusinessConfigOpen, setErrorNotificationNoBusinessConfigOpen] = React.useState(false);
    const handleErrorNotificationNoBusinessConfigClick = () => { setErrorNotificationNoBusinessConfigOpen(true); };
    const handleErrorNotificationNoBusinessConfigClose = (event, reason) => { if (reason === 'clickaway') { return } setErrorNotificationNoBusinessConfigOpen(false); };



    const [mpesaLoading, setMpesaLoading] = useState(false)
    const [kcbloading, setkcbloading] = useState(false)
    const [Cardloading, setCardloading] = useState(false)

    const [intervalId, setIntervalId] = useState(null);
    const { X_Authorization } = useSelector((store) => store.user)


    const [successmessage, SetSuccessMessageCard] = useState(false)
    const [StkMessage, setSTKMessage] = useState(false)

    const [SuccessShow, setSuccessShow] = useState({ state: false, message: "" })
    const [GenerteLink, setGeneratedLink] = useState(false)
    const [GenerateLinkStatus, setGenerateLinkStatus] = useState(false)
    const [dataLINK, setDatagenerated] = useState('')
    const [voucherDetails, setVoucherDetails] = useState(null);
    const [otp, setOtp] = useState('');
    const [isOtpVisible, setIsOtpVisible] = useState(false);
    const [isVoucherDetailsVisible, setIsVoucherDetailsVisible] = useState(false);

    const intervalIdRef = useRef(null);
    const textRef = useRef(null);

    const handleCopy = () => {
        textRef.current.select();
        document.execCommand('copy');
        handleSuccessNotificationClick2()
    };

    //check if the push transactions is payed
    const bsNo = localStorage.getItem('businessId')
    const XAuthorization = localStorage.getItem('X-Authorization')
    const [orderStatus, setOrderStatus] = useState()
    const [newTotalBill, setNewTotalBill] = useState(carriedAmount)
    // console.log("orderStatus ======<><>>", orderStatus);
    // console.log("newTotalBill ======<><>>", newTotalBill);

    const listenWebsocket = async (randomNum) => {
        let ws = ""
        if (process.env.NODE_ENV === 'development') {
            ws = new WebSocket(`wss://${baseUrl}/notification/${invoiceNumber}`);
        } else if (process.env.NODE_ENV === 'production') {
            ws = new WebSocket(`wss://${baseUrl}/notification/${invoiceNumber}`);
        } else if (process.env.NODE_ENV === 'test') {
            //zed.swerri.io
            ws = new WebSocket(`wss://${baseUrl}/notification/${invoiceNumber}`);
        }
        console.log(ws, 'ws');

        // const ws = new WebSocket(`wss://dev.zed.business/notification/${invoiceNumber}}`);
        ws.onopen = () => {
            console.log('connected')
        }
        ws.onmessage = function (event) {
            const data = JSON.parse(event.data); // Parse the JSON string into an object


            if (data.status === "OK") {
                const message = data.message;
                console.log('Received message:', data);
                setSTKMessage(message)
                console.log(message, 'message')
                handleSuccessNotificationClick();

            } else if (data.status === "FAILED") {
                setSuccessNotificationOpen(false)
                const message = data.statusMessage;
                console.log('Error message:', data);
                setSTKMessage(message)
                console.log(message, 'message')
                setErrorShow({ state: true, message: message });
                setMpesaLoading(false);


            }

            // Handle incoming messages from the WebSocket server here.
        };
        ws.onclose = function (event) {
            console.log('WebSocket connection closed with code:', event.code);
            // Handle WebSocket connection close event here.
        };

        ws.onerror = function (error) {
            console.error('WebSocket error:', error);
            // Handle any WebSocket errors here.
        };

    }

    const handleQrScanSuccess = async (scannedData) => {
        console.log('QR Code Scanned Data:', scannedData);
        if (scannedData && typeof scannedData === 'object' && scannedData.data) {
            const voucherId = scannedData.data;
            setSerialNumber(voucherId);
            toggleQrScanner();

            try {
                const response = await fetch(`${baseUrl}/api/v1/evoucher/getEvoucherDetails?voucherId=${voucherId}`, {
                    method: 'GET',
                    headers: {
                        "Content-Type": "application/json",
                        "X-Authorization": localStorage.getItem("X-Authorization"),
                    },
                });

                const data = await response.json();
                console.log('Voucher Details:', data);

                if (data.status === 'SUCCESS') {
                    setVoucherDetails(data.data);
                    setSuccessShow({ state: true, message: data.message });
                    setShowVoucherDetails(true);
                } else {
                    setErrorShow({ state: true, message: data.message });
                }
            } catch (error) {
                console.error('Error fetching voucher details:', error);
                setErrorShow({ state: true, message: 'Failed to fetch voucher details.' });
            }
        } else {
            console.error('Invalid scanned data:', scannedData);
        }
    };
    const handleSerialNumberChange = async (e) => {
        const value = e.target.value;
        setSerialNumber(value);

        if (value.trim() !== '') {
            try {
                const response = await fetch(`${baseUrl}/api/v1/evoucher/getEvoucherDetails?voucherNumber=${value}`, {
                    method: 'GET',
                    headers: {
                        "Content-Type": "application/json",
                        "X-Authorization": localStorage.getItem("X-Authorization"),
                    },
                });

                const data = await response.json();
                console.log('Voucher Details:', data);

                if (data.status === 'SUCCESS') {
                    setVoucherDetails(data.details);
                    setErrorShow({ state: false, message: "" });
                } else {
                    setErrorShow({ state: true, message: data.message });
                    setVoucherDetails(null);
                }
            } catch (error) {
                console.error('Error fetching voucher details:', error);
                setErrorShow({ state: true, message: 'Failed to fetch voucher details.' });
                setVoucherDetails(null);
            }
        } else {
            setVoucherDetails(null);
        }
    };
    const handleNextClick = async () => {
        if (serialNumber.trim() !== '') {
            try {
                const response = await fetch(`${baseUrl}/api/v1/evoucher/getEvoucherDetails?voucherNumber=${serialNumber}`, {
                    method: 'GET',
                    headers: {
                        "Content-Type": "application/json",
                        "X-Authorization": localStorage.getItem("X-Authorization"),
                    },
                });

                const data = await response.json();
                console.log('Voucher Details:', data);

                if (data.status === 'SUCCESS') {
                    setVoucherDetails(data.data);
                    setSuccessShow({ state: true, message: "" });
                    // setShowVoucherDetails(true);
                    setIsOtpVisible(data.data.type !== "batch");
                } else {
                    setErrorShow({ state: false, message: data.message });
                    setVoucherDetails(null);
                }
            } catch (error) {
                console.error('Error fetching voucher details:', error);
                setErrorShow({ state: true, message: 'Failed to fetch voucher details.' });
            }
        } else {
            setErrorShow({ state: true, message: 'Please enter a serial number.' });
        }
    };
    const handleOtpChange = (index, value) => {
        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);
    };

    function checkOrderPaymentStatus() {
        try {
            HttpComponent({
                method: "POST",
                url: `/api/order_payment_status`,
                body: {
                    pushyTransactionId: startOrderTag === "startOrderTag" ? startOrderId[0] : startOrderTag === "unPaidstartOrderTag" ? startOrderId[0] : randomNum,
                    businessNo: bsNo || localStorage.getItem("businessId")
                },
                token: XAuthorization
            }).then((data) => {
                console.log(data, 'order status')

                if (data.status === 200) {
                    setOrderStatus(data?.response?.data?.deficit)
                }
            })
        } catch (error) {

        }
    }

    useEffect(() => {
        checkOrderPaymentStatus()
    }, [])

    let [object, setMpesaObject] = useState({})

    useEffect(() => {
        if (startOrderTag === "startOrderTag") {
            let object = { amount: amount || totalBills || startOrderAmount, phone: userPhone, orderID: startOrderId };
            setMpesaObject(object)
        } else if (startOrderTag === "unPaidstartOrderTag") {
            let object = { amount: amount || totalBills || startOrderAmount, phone: userPhone, orderID: startOrderId };
            setMpesaObject(object)
        }
        else {
            let object = { amount: amount || totalBills || startOrderAmount, phone: userPhone, orderID: orderids };
            setMpesaObject(object)
        }

    }, [userPhone, amount, totalBills])

    console.log(object, 'OBJECT FOUND >>>>>')


    const handleMtnMomoPay = async () => {
        setMpesaLoading(true);
        if (object.phone === '') {
            setMpesaLoading(false);
            setErrorShow({ state: true, message: 'Please provide the phone number' })
            handleErrorNotificationClick();
        } else if (object.amount === '' || object.amount === 0) {
            setMpesaLoading(false);
            setErrorShow({ state: true, message: 'Please provide the amount ' })
            handleErrorNotificationClick();
        } else {
            try {
                HttpComponent({
                    method: "POST",
                    url: "/api/v1/payments/momo/pay",
                    token: localStorage.getItem('X-Authorization'),
                    body: {
                        amount: object.amount,
                        phone: object.phone,
                        orderIds: object.orderID,
                        paymentChanel: "Web",
                        type: "order"
                    }

                })
                    .then((data) => {
                        console.log(data, 'pay via mpesaa')
                        if (data.response?.statusCode === 202) {
                            // listenWebsocket(randomNum)


                            handleSuccessNotificationClick()



                        } else {
                            setMpesaLoading(false);
                            handleErrorNotificationClick();
                        }
                    })
                    .catch((e) => {
                        setMpesaLoading(false);
                        console.error(`Error Sending STK`, e.message);
                    });
            } catch (error) {
                console.error(error);
                handleErrorNotificationClick();
            }

        }
    };


    const handleClick = async () => {
        setMpesaLoading(true);
        if (object.phone === '') {
            setMpesaLoading(false);
            setErrorShow({ state: true, message: 'Please provide the phone number' })
            handleErrorNotificationClick();
        } else if (object.amount === '' || object.amount === 0) {
            setMpesaLoading(false);
            setErrorShow({ state: true, message: 'Please provide the amount ' })
            handleErrorNotificationClick();
        } else {
            try {
                HttpComponent({
                    method: "POST",
                    url: "/api/pushstk",
                    token: X_Authorization,
                    body: {
                        amount: object.amount,
                        phone: object.phone,
                        businessId: localStorage.getItem("businessID"),
                        orderIds: object.orderID,
                        type: "order",
                        paymentChanel: "Web",
                        discount: discount,
                    }

                })
                    .then((data) => {
                        console.log(data, 'pay via mpesaa')
                        if (data.response.data.status === 200) {
                            // listenWebsocket(randomNum)


                            handleSuccessNotificationClick()



                        } else {
                            setMpesaLoading(false);
                            handleErrorNotificationClick();
                        }
                    })
                    .catch((e) => {
                        setMpesaLoading(false);
                        console.error(`Error Sending STK`, e.message);
                    });
            } catch (error) {
                console.error(error);
                handleErrorNotificationClick();
            }

        }
    };

    const handleKcbPush = async () => {

        let object = { amount: kcbAmount || newTotalBill, phone: kcbphone, orderID: startOrderTag === "startOrderTag" ? startOrderId : orderids };

        const payurl = bankAccount === "KCB" ? `/api/v1/payments/initiate_kcb_stk_push` : `/api/v1/initiatecoopstkpush`

        if (kcbphone === '') {
            // alert('kindly add a phone number')
            setErrorShow({ state: true, message: 'kindly add a phone number' })
            setkcbloading(false)
        } else if (bankAccount === '') {
            setErrorShow({ state: true, message: 'kindly select bank' })

            setkcbloading(false)
        } else if (object.amount === '' || object.amount === 0) {
            setErrorShow({ state: true, message: 'kindly add  a amount' })
            setkcbloading(false)
        } else {
            setkcbloading(true)

            try {
                HttpComponent({
                    method: 'POST',
                    url: payurl,
                    body: {
                        amount: kcbAmount || newTotalBill,
                        phone: kcbphone,
                        orderIds: startOrderTag === "startOrderTag" ? startOrderId : startOrderId,
                        type: "order",
                        paymentChanel: "Web",
                        discount: discount
                    },
                    token: localStorage.getItem("X-Authorization")
                }).then((data) => {
                    if (data.status === 200) {

                        handleSuccessNotificationClick();


                    }
                    else if (data.status === 400) {

                        setErrorShow({ state: true, message: data.response.message })
                        setkcbloading(false)

                    }

                    else {
                        setErrorShow({ state: true, message: `Error in sending stk push!` })
                        setkcbloading(false)

                    }

                })
                    .catch((e) => {
                        setErrorShow({ state: true, message: `Error in sending stk push!` })
                        setkcbloading(false)


                    })

            } catch (error) {
                console.error(error);
                handleErrorNotificationClick();

            }

        }

    }


    const handleCardPayment = async () => {
        const obj = { email: email, amount: cardAmount || totalBills, orderID: orderids }

        if (obj.cardAmount === '' || obj.cardAmount === 0) {
            setErrorShow({ state: true, message: 'kindly add  a amount' })
        } else {
            try {
                HttpComponent({
                    method: 'POST',
                    url: "/api/v1/payments/card/pay",
                    body: {
                        amount: cardAmount || totalBills,
                        orderIds: checkedpushTransactionIds,
                        paymentChanel: "Web",
                        type: "order"
                    }
                }).then((data) => {
                    // console.log(data.response.data.data.data.invoiceInformation.paymentLink, 'Data');
                    console.log(data, 'Response data hapa');

                    if (data.status === 200) {

                        if (data.response.data.status === 500) {
                            console.log(data.response.data.error.message, 'Response data hapa');
                            console.log(' mesage arrr', data.response.data.error.message)
                            setErrorShow({ state: true, message: data.response.data.error.message })

                        } else if (data.response.data.status === 200) {
                            console.log("link data =>>>>>>>>>>>>", data.response.data.data);

                            handleSuccessNotificationClick1();
                            setGeneratedLink(true)
                            setGenerateLinkStatus(true)
                            setCardloading(true)

                            setDatagenerated(data.response.data.data.invoiceInformation.paymentLink)

                        }


                    } else if (data.status == '401') {
                        console.log(' mesage arrr', data.response.message)
                        setErrorShow({ state: true, message: data.response.message })
                    } else if (data.status == '400') {
                        console.log(' mesage arrr', 'Error here')
                        setErrorShow({ state: true, message: data.response.error })
                    } else {
                        handleErrorNotificationClick1();
                        console.log(' mesage arrr', 'Error here')
                        setErrorShow({ state: true, message: 'Erriirr  r' })
                    }

                })
                    .catch((e) => {
                        console.error(`Error Sending payment`, e.message);

                    })

            } catch (error) {
                console.error(error);
                handleErrorNotificationClick1();
                // console.log(' mesage arrr', 'Error here')
            }

        }
    }

    useEffect(() => {
        if (dataLINK === undefined) {
            console.log("hakuna data");
            setGeneratedLink(false)
        }

        if (dataLINK) {
            setGeneratedLink(true)
        }
    }, [dataLINK])

    const [errorShow, setErrorShow] = useState({ state: false, message: "" })

    function handleButtonClick(name) {
        setActiveButton(name)
    }

    useEffect(() => {
        setBussinessStkId(darajaConfigId)
    }, []);

    useEffect(() => {
        handleButtonClick(activeButton)
    }, [activeButton])

    const [counter, setCounter] = useState(300);

    useEffect(() => {
        let intervalId;

        if (mpesaLoading || kcbloading) {
            setCounter(300)
            intervalId = setInterval(() => {
                setCounter((prevCounter) => prevCounter - 1);
            }, 1000);

            setTimeout(() => {
                clearInterval(intervalId);
                setMpesaLoading(false);
                setkcbloading(false)
                setCardloading(false)
                handleErrorNotificationTimeoutClick();
            }, 300000);
        }


        return () => clearInterval(intervalId);
    }, [mpesaLoading, kcbloading]);

    useEffect(() => {
        if (Cardloading) {
            let intervalId;

            setCounter(15)
            intervalId = setInterval(() => {
                setCounter((prevCounter) => prevCounter - 1);
            }, 3000);

            setTimeout(() => {

                setCardloading(false)
            }, 3000);
        }

    }, [Cardloading])


    useEffect(() => {
        if (successmessage) {
            setTimeout(() => {
                SetSuccessMessageCard(false)

            }, 1000)

        }

    }, [successmessage])
    useEffect(() => {

        if (intervalIdRef.current) {
            clearInterval(intervalIdRef.current);
        }


        if (mpesaLoading || kcbloading) {

            intervalIdRef.current =
                setInterval(() => {
                    checkOrderPaymentStatus();
                    console.log("orderStatus =====<><>", orderStatus);
                    console.log("newTotalBill =====<><>", newTotalBill);
                    if (orderStatus < newTotalBill) {

                        // console.log("amount to be ppaud ========<><>", orderStatus,totalBills );
                        // setBalance(orderStatus)
                        setMpesaLoading(false);
                        setkcbloading(false)
                        setCounter(0)
                        if (newStartOrderId) {
                            clearInterval(intervalIdRef.current);
                            intervalIdRef.current = null;
                            setUserPhone('')
                            setAmount('')
                            if (startOrderId?.length > 0) {
                                const orderIdToCarry = startOrderId[0]
                                handleSuccessNotificationPaymentReceivedClick()
                                setTimeout(() => {
                                    navigate(`/detailedbill/${orderIdToCarry}`)
                                }, 3000);

                            }
                        } else {
                            handleSuccessNotificationPaymentReceivedClick()
                            setTimeout(() => {
                                navigate(-1)
                            }, 3000);
                            clearInterval(intervalIdRef.current);
                            intervalIdRef.current = null;
                            setUserPhone('')
                            setAmount('')
                        }

                    }
                }, 1000)
            setTimeout(() => {
                clearInterval(intervalIdRef.current);
                intervalIdRef.current = null;
                handleErrorNotificationTimeoutClick()
                setMpesaLoading(false);
                setkcbloading(false)
            }, 300000);
        }
        return () => {
            clearInterval(intervalIdRef.current);
            intervalIdRef.current = null;
        };
    }, [mpesaLoading, kcbloading, totalBills, orderStatus, startOrderAmount]);


    const [bankAccountsKCB, setBankAccountsKCB] = useState([])
    const getListOfBankAccounts = async () => {
        try {
            const response = await fetch(baseUrl + `/api/get_bank_payment_listing`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    "X-Authorization": localStorage.getItem("X-Authorization"),
                },
            })
            const data = response.json()
                .then((response) => {
                    console.log('Bank Accounts hii no hapa', response.data);
                    setBankAccountsKCB(response.data)


                    // setBankAccount(response.data[0].accountNumber) // setBankAccount(response.data[0].accountNumber)
                })

        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        getListOfBankAccounts()
    }, [])

    const [bankAccountKCB, setBankAccountKCB] = useState('')
    const [referenceNo, setReferenceNo] = useState('')

    const obj = { reference: referenceNo, creditAccount: bankAccountKCB, invoiceNumber: orderids }

    const handleBankSlip = async () => {

        console.log('Bank Slip', obj)
        if (obj.reference === '' || obj.creditAccount === '') {
            setErrorShow({ state: true, message: "Please fill in all fields" })
            setTimeout(() => {
                setErrorShow({ state: false, message: "" })
            }, 3000)
        } else {
            try {
                const response = await fetch(baseUrl + `/api/v1/validate_kcb_bank_slip`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                        "X-Authorization": localStorage.getItem("X-Authorization"),
                    },
                    body: JSON.stringify(obj)
                })
                const data = response.json()
                    .then((response) => {
                        console.log('Bank Accounts hii no hapa', response.data);
                        if (response.status === 'success') {
                            navigate(-1)
                        } else {
                            setErrorShow({ state: true, message: response.message + `${response.data}` })
                            setTimeout(() => {
                                setErrorShow({ state: false, message: "" })
                            }, 3000)
                        }


                    })

            } catch (error) {
                console.log(error);
            }
        }

    }
    const [defaultCurrency, setDefaultCurrency] = useState(GetDefaultCurrency())
    const [MpesaStatus, setMpesaStatus] = useState(false)
    const [KCBBankPaybillStatus, setKCBSBankPaybillstatus] = useState(false)
    const [CardStatus, setCardStatus] = useState(false)
    const [BankStatus, setBankStatus] = useState(false)
    const [momoStatus, setMomoStatus] = useState(false)
    const [CashStatus, setCashStatus] = useState(false)
    const [voucherStatus, setVoucherStatus] = useState(false)
    const [openQrScanner, setOpenQrScanner] = useState(false);
    const [serialNumber, setSerialNumber] = useState('');
    const [qrScanned, setQrScanned] = useState(false);
    const [showVoucherDetails, setShowVoucherDetails] = useState(false);
    const [KCBBankDeposit, setKCBBankDeposit] = useState(false)
    const [pin, setPin] = useState('')

    const toggleQrScanner = () => {
        setOpenQrScanner((prev) => !prev);
    };
    const handleVerifyOtp = async () => {
        if (pin.length !== 4) {
            setErrorShow({ state: true, message: 'Please enter a valid 4-digit OTP' });
            return;
        }

        try {
            const response = await fetch(`${baseUrl}/api/v1/user/verify/otp`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Authorization': localStorage.getItem('X-Authorization'),
                },
                body: JSON.stringify({ otp: pin }),
            });

            const data = await response.json();

            if (response.ok) {
                console.log('OTP verified successfully:', data);
                setSuccessShow({ state: true, message: 'OTP verified successfully!' });
                setPin('');
                setShowVoucherDetails(true);
            } else {
                // Handle error
                console.error('Error verifying OTP:', data.message);
                setErrorShow({ state: true, message: data.message });
            }
        } catch (error) {
            console.error('Error during OTP verification:', error);
            setErrorShow({ state: true, message: 'An error occurred while verifying OTP.' });
        }
    };

    const isNextButtonActive = qrScanned || serialNumber.trim() !== '';

    console.log(defaultCurrency, 'defaultCurrency >>>>>>>>>>>>>>')


    const checkPayemntStatus = async () => {
        try {
            const response = await fetch(baseUrl + `/api/get_payment_methods_with_status`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    "X-Authorization": localStorage.getItem("X-Authorization"),
                },
            })
            const data = response.json()

                .then((response) => {
                    console.log('Bank Accounts hapa', response.data);

                    // console.log('Bank Accounts hapa', );
                    const mpesaStatus = response.data.find(method => method.name === "Mpesa")?.status ?? false;
                    const cardStatus = response.data.find(method => method.name === "Card")?.status ?? false;
                    const cashStatus = response.data.find(method => method.name === "Cash")?.status ?? false;
                    const voucherStatus = response.data.find(method => method.name === "Evoucher")?.status ?? false;
                    // banks 
                    let banks = response?.data?.find(method => method?.name === "Banks") ?? []
                    let kcbBanks = banks?.paymentOptions?.find(method => method?.name === "KCB") ?? []

                    const paybillStatus = kcbBanks?.kcb?.find(method => method?.name === "Mobile Money")?.status ?? false
                    const kcbdepositStatus = kcbBanks?.kcb?.find(method => method?.name === "KCB DEPOSIT")?.status ?? false
                    const bankStatus = response.data.find(method => method.name === "Banks")?.status ?? false
                    const momoPayStatus = response?.data?.find(method => method?.name === 'MTN Momo')?.status ?? false
                    const settleInvoiceStatus = response?.data?.find(method => method?.name === 'settleInvoiceStatus')?.status ?? false
                    let accessBanks = banks?.paymentOptions?.find(method => method?.name === "Accessbank") ?? []

                    setMpesaStatus(mpesaStatus)
                    setCardStatus(cardStatus)
                    if (accessBanks?.accessbank?.length > 0) {
                        setCardStatus(true)
                    }
                    setKCBSBankPaybillstatus(paybillStatus)
                    console.log(mpesaStatus, 'paybillStatus heyyyy')
                    setKCBBankDeposit(kcbdepositStatus)
                    setBankStatus(bankStatus)
                    setMomoStatus(momoPayStatus)
                    setCashStatus(cashStatus)
                    setVoucherStatus(voucherStatus);
                })

        } catch (error) {
            console.log(error);
        }

    }
    useEffect(() => {
        checkPayemntStatus()
    }, [])

    let payAmount = cashAmount
    const payViaCash = async () => {

        if (cashAmount === 0) {
            setErrorShow({ state: true, message: "Please fill in cash amount received" })
            setTimeout(() => {
                setErrorShow({ state: false, message: "" })
            }, 3000)
        }
        try {
            const response = await fetch(baseUrl + `/api/transactions`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    "X-Authorization": localStorage.getItem("X-Authorization"),
                },
                body: JSON.stringify({
                    appBuildTime: "N/A",
                    billRefNo: Date.now(),
                    businessName: localStorage.getItem("businessName"),
                    businessNo: localStorage.getItem("businessId"),
                    businessShortCode: "0000",
                    cashier: localStorage.getItem("username"),
                    customerFirstName: 'N/A',
                    customerMiddleName: "N/A",
                    customerPhone: "N/A",
                    customerSecondName: "N/A",
                    items: productData?.length === 0 ? startOrderItems : productData,
                    paybillBalance: paybillBalance,
                    discountAmount: Number(discount),
                    paymentChanel: "Web",
                    productCategory: "--",
                    productName: "--",
                    pushyTransactionId: checkedpushTransactionIds?.length === 0 ? startOrderId : checkedpushTransactionIds,
                    receiptNumber: Date.now(),
                    requestType: "N/A",
                    serialNo: " N/A",
                    transactionID: Date.now(),
                    transactionType: 'Cash Payment',
                    // transamount: cashAmount > startOrderAmount ? startOrderAmount : cashAmount,
                    transamount: payAmount,
                    transtime: new Date(Date.now()),
                    uploadTime: new Date(Date.now()),
                    userId: localStorage.getItem("userId"),
                    versionCode: "webv1",
                    versionName: "webv1",
                })
            })
            const data = await response.json();
            if (data.Status === 'SUCCESS') {

                if (startOrderId?.length > 0) {
                    const orderIdToCarry = startOrderId[0]
                    handleSuccessNotificationPaymentReceivedClick()
                    setTimeout(() => {
                        navigate(`/detailedbill/${orderIdToCarry}`)
                    }, 3000);

                } else {
                    setSuccessNotificationReceivedPaymentOpen(true)
                    setTimeout(() => {
                        setSuccessNotificationReceivedPaymentOpen(false)
                        navigate(-1)
                    }, 2000)
                }



            } else {
                setErrorShow({ state: true, message: data?.message })
                setTimeout(() => {
                    navigate(-1)
                    setErrorShow({ state: false, message: "" })
                }, 3000)
            }
        } catch (error) {
            console.log(error)

        }
    }

    console.log(cashAmount, 'onchnage cahs')

    return (
        <div style={{ width: "100%", padding: "0px 0px 0px 50px" }}>

            {/* printer Pop Up */}
            {printerPopUp && <PrinterPopUp setPrinterPopUp={setPrinterPopUp} setOpenPrintModal={setOpenPrintModal} printerPopUp={printerPopUp} setShowPrinter={setShowPrinter} showPrinter={showPrinter} pos={pos} printers={printers} sendToPrinter={sendToPrinter} posSerialNo={posSerialNo} setPosSerialNo={setPosSerialNo} doRedirect={doRedirect} />}

            <PrintPromptAfterSale openPrintModal={openPrintModal} setOpenPrintModal={setOpenPrintModal} setPrinterPopUp={setPrinterPopUp} pos={pos} sendToPrinter={sendToPrinter} setPosSerialNo={setPosSerialNo} setErrorShow={setErrorShow} doRedirect={doRedirect} />

            {/*Alerts*/}
            {/*Success Creation*/}
            <SuccessAlert message={'You will receive a prompt on your phone'} open={successNotificationOpen} onClose={handleSuccessNotificationClose} horizontal={'right'} vertical={'top'} />
            <SuccessAlert message={'We have sent you an email kindly complete the transcation'} open={successNotificationOpen1} onClose={handleSuccessNotificationClose1} horizontal={'right'} vertical={'top'} />
            <SuccessAlert message={'Link copied successfully'} open={successNotificationOpen2} onClose={handleSuccessNotificationClose2} horizontal={'right'} vertical={'top'} />



            {/*Payment Received*/}
            <SuccessAlert message={`Payment received`} open={successNotificationReceivedPaymentOpen} onClose={handleSuccessNotificationReceivedPaymentClose} horizontal={'right'} vertical={'top'} />

            {/*error sending stk*/}
            <ErrorAlert message={`Error With Stk`} open={errorNotificationOpen} onClose={handleErrorNotificationClose} horizontal={'right'} vertical={'top'} />

            <ErrorAlert message={`Error With Sending the payment`} open={errorNotificationOpen1} onClose={handleErrorNotificationClose1} horizontal={'right'} vertical={'top'} />


            {/*error TimeOut*/}
            <ErrorAlert message={StkMessage ? `${StkMessage}` : `Timeout On Awaiting Payment `} open={errorNotificationTimeoutOpen} onClose={handleErrorNotificationTimeoutClose} horizontal={'right'} vertical={'top'} />

            {/*Missing Business Config ID*/}
            <ErrorAlert message={`Missing Mpesa STK Config Id!`} open={errorNotificationNoBusinessConfigOpen} onClose={handleErrorNotificationNoBusinessConfigClose} horizontal={'right'} vertical={'top'} />

            <ErrorAlert
                vertical="top"
                horizontal="right"
                onClose={() => setErrorShow({ ...errorShow, state: false })}
                open={errorShow.state}
                message={errorShow.message} />

            {/*mainContainer*/}
            <Grid container direction={'row'} justifyContent={'space-between'} >

                {/*Labels*/}
                <Grid xs={12} sm={12} md={12} lg={4} item margin={'0px 0px 5% 0px'}>

                    {/*Label*/}
                    <Grid container mb={7}>
                        <Grid item>
                            <span style={{ color: '#032541', fontSize: '20px', fontWeight: '600' }} >Select Payment Method</span>
                        </Grid>
                    </Grid>

                    {/*Cards*/}
                    <Grid container direction={'column'} justifyContent={'flex-start'} spacing={7}>

                        {/*Mpesa Card*/}
                        {MpesaStatus && (defaultCurrency === "KES") ?
                            <Grid item width={'100%'}>
                                <ButtonBase onClick={() => { handleButtonClick('mpesa') }}>
                                    <Card style={{ background: `${activeButton === 'mpesa' ? 'rgba(23, 174, 123, 0.06)' : '#fff'}`, height: '94px', width: '365px', boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.16)', borderRadius: '6px' }}>
                                        <Grid container direction={'row'} justifyContent={'flex-start'} alignContent={'center'} spacing={-2}>

                                            {/*mpesa image*/}
                                            <Grid item>
                                                <img src={Mpesa} alt="Mpesa" style={{ height: "32px", width: "100px", marginTop: "30px", marginLeft: '15px' }} />
                                            </Grid>

                                            {/*Mpesa name*/}
                                            <Grid item style={{ marginTop: "30px" }}>
                                                <span style={{ color: '#032541', marginLeft: '15px', fontSize: '18px', fontWeight: 600 }}>Mpesa</span>
                                            </Grid>

                                        </Grid>
                                    </Card>
                                </ButtonBase>
                            </Grid>
                            : null}
                        {/* bank paybill */}
                        {KCBBankPaybillStatus && (defaultCurrency === "KES") ?
                            <Grid item width={"100%"}>
                                <ButtonBase onClick={() => { handleButtonClick('bankpaybill') }} >
                                    <Card style={{ background: `${activeButton === 'bankpaybill' ? '#f0f2f3' : '#fff'}`, height: '94px', width: '365px', boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.16)', borderRadius: '6px' }}>
                                        <Grid container direction={'row'} justifyContent={'flex-start'} alignItems={'center'} spacing={-2}>

                                            {/*Card image*/}
                                            <Grid item>
                                                <img src={BankPayBillIcon} alt="BankPayBillIcon" style={{ height: "32px", width: "100px", marginTop: "30px", marginLef: '0px' }} />
                                            </Grid>

                                            {/*Card name*/}
                                            <Grid item style={{ marginTop: "30px" }}>
                                                <span style={{ color: '#032541', marginLeft: '15px', fontSize: '18px', fontWeight: 600 }}>Mobile Payment To Bank</span>
                                            </Grid>

                                        </Grid>
                                    </Card>
                                </ButtonBase>
                            </Grid>
                            : null}

                        {momoStatus && (defaultCurrency === "UGX") ?
                            <Grid item mt={2}>
                                <ButtonBase onClick={() => { handleButtonClick('MTN Momo') }}>
                                    <Card style={{ background: `${activeButton === 'MTN Momo' ? '#f0f2f3' : '#fff'}`, height: '94px', width: '365px', boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.16)', borderRadius: '6px' }}>
                                        <Grid container direction={'row'} justifyContent={'flex-start'} alignContent={'center'} spacing={-2}>
                                            {/*Card image*/}
                                            <Grid item>
                                                <img src={MomoIcon} alt="Mpesa" style={{ height: "32px", width: "100px", marginTop: "30px", marginLef: '0px' }} />
                                            </Grid>
                                            {/*Card name*/}
                                            <Grid item style={{ marginTop: "30px" }}>
                                                <span style={{ color: '#032541', marginLeft: '15px', fontSize: '18px', fontWeight: 600 }}>MTN Momo</span>
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </ButtonBase>
                            </Grid>
                            : null}

                        {/*Card*/}
                        {/* {CardStatus ?
                            <Grid item>
                                <ButtonBase onClick={() => { handleButtonClick('card') }}>
                                    <Card style={{ background: `${activeButton === 'card' ? '#f0f2f3' : '#fff'}`, height: '94px', width: '365px', boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.16)', borderRadius: '6px' }}>
                                        <Grid container direction={'row'} justifyContent={'flex-start'} alignContent={'center'} spacing={-2}>
                                            <Grid item>
                                                <img src={CardImg} alt="Mpesa" style={{ height: "32px", width: "100px", marginTop: "30px", marginLef: '0px' }} />
                                            </Grid>
                                            <Grid item style={{ marginTop: "30px" }}>
                                                <span style={{ color: '#032541', marginLeft: '15px', fontSize: '18px', fontWeight: 600 }}>Card</span>
                                            </Grid>

                                        </Grid>
                                    </Card>
                                </ButtonBase>
                            </Grid>
                            : null} */}
                        {/* RECEIVE PAYMENT */}

                        {/* <Grid item>
                            <span>Receive Payment</span>
                        </Grid> */}
                        {BankStatus && (defaultCurrency === "KSH") ?
                            <>
                                <Grid item>
                                    <ButtonBase onClick={() => { handleButtonClick('bankSlip') }}>
                                        <Card style={{ background: `${activeButton === 'bankSlip' ? '#f0f2f3' : '#fff'}`, height: '94px', width: '365px', boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.16)', borderRadius: '6px' }}>
                                            <Grid container direction={'row'} justifyContent={'flex-start'} alignContent={'center'} spacing={-2}>
                                                {/*Card image*/}
                                                <Grid item>
                                                    <img src={BankSlip} alt="Mpesa" style={{ height: "32px", width: "100px", marginTop: "30px", marginLef: '0px' }} />
                                                </Grid>
                                                {/*Card name*/}
                                                <Grid item style={{ marginTop: "30px" }}>
                                                    <span style={{ color: '#032541', marginLeft: '15px', fontSize: '18px', fontWeight: 600 }}>Bank Slip</span>
                                                </Grid>
                                            </Grid>
                                        </Card>
                                    </ButtonBase>
                                </Grid>
                            </>
                            : null}
                        {CashStatus && group !== "Customer" ?
                            <Grid item>
                                <ButtonBase onClick={() => { handleButtonClick('cash') }}>
                                    <Card style={{ background: `${activeButton === 'cash' ? '#f0f2f3' : '#fff'}`, height: '94px', width: '365px', boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.16)', borderRadius: '6px' }}>
                                        <Grid container direction={'row'} justifyContent={'flex-start'} alignContent={'center'} spacing={-2}>
                                            {/*Card image*/}
                                            <Grid item>
                                                <img src={CashIcon} alt="Mpesa" style={{ height: "32px", width: "100px", marginTop: "30px", marginLef: '0px' }} />
                                            </Grid>
                                            {/*Card name*/}
                                            <Grid item style={{ marginTop: "30px" }}>
                                                <span style={{ color: '#032541', marginLeft: '15px', fontSize: '18px', fontWeight: 600 }}>Cash</span>
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </ButtonBase>
                            </Grid> : null
                        }
                        {voucherStatus && group !== "Customer" ?
                            <Grid item>
                                <ButtonBase onClick={() => { handleButtonClick('voucher') }}>
                                    <Card style={{ background: `${activeButton === 'cash' ? '#f0f2f3' : '#fff'}`, height: '94px', width: '365px', boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.16)', borderRadius: '6px' }}>
                                        <Grid container direction={'row'} justifyContent={'flex-start'} alignContent={'center'} spacing={-2}>
                                            <Grid item>
                                                <img src={VoucherIcon} alt="Voucher" style={{ height: "32px", width: "100px", marginTop: "30px", marginLef: '0px' }} />
                                            </Grid>
                                            <Grid item style={{ marginTop: "30px" }}>
                                                <span style={{ color: '#032541', marginLeft: '15px', fontSize: '18px', fontWeight: 600 }}>Voucher</span>
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </ButtonBase>
                            </Grid>
                            : null}


                    </Grid>

                </Grid>

                {/*Display Arena*/}
                {activeButton === 'mpesa' ?
                    <Grid item style={{ background: 'rgba(23, 174, 123, 0.06)', width: '60%', display: 'flex', flexShrink: 1, marginRight: '2.5%' }}>

                        <Grid container direction={'column'} ml={1} spacing={3}>

                            {/*Header lable*/}
                            <Grid item mt={5} >
                                <span style={{ fontStyle: '16px', fontWeight: '600', color: '#032541' }} >Pay Order With Mpesa</span>
                            </Grid>

                            {/*Invoice card Information*/}
                            <Grid item>

                                <Grid container direction={'row'} spacing={2}>
                                    <Grid item style={{ width: '70%' }}>
                                        {/*PlyCard*/}
                                        <Grid container direction={'column'} spacing={0.5} style={{ borderRadius: '10px', background: 'rgba(112, 112, 112, 0.05)' }}>
                                            <Grid item>
                                                <span style={{ color: '#707070', fontSize: '14px' }}>BillTotal</span>
                                            </Grid>
                                            {/* <Grid item>
                                                <span style={{ color: '#032541', fontWeight: 600, fontSize: '18px' }}>{invoiceNumber}</span>
                                            </Grid> */}
                                        </Grid>
                                    </Grid>

                                    <Grid item style={{ width: '30%' }}>
                                        {/*Amount*/}
                                        <Grid container direction={'column'} spacing={0.5} style={{ borderRadius: '10px' }}>
                                            <Grid item>
                                                <span style={{ color: '#dc3545', fontSize: '14px' }}>{currencyconverter(startOrderTag === "startOrderTag" ? startOrderAmount : startOrderTag === "unPaidstartOrderTag" ? startOrderAmount : totalBills)}</span>
                                            </Grid>
                                            {/* <Grid item>
                                                <span style={{ color: '#dc3545', fontWeight: 600, fontSize: '18px' }}>{currencyconverter(invoiceBalance)}</span>
                                            </Grid> */}
                                        </Grid>
                                    </Grid>

                                </Grid>

                            </Grid>

                            {/*Mpesa Instruction*/}
                            <Grid item mt={4}>
                                <span style={{ fontSize: '14px' }} >Kindly enter your mobile number to receive the payment prompt and enter you M-Pesa Pin.</span>
                            </Grid>

                            {/*Mobile input*/}
                            <Grid item>
                                <div style={{ marginBottom: '10px' }}>
                                    <span style={{ fontSize: '12px', color: '#032541' }}>Mobile no.</span>
                                </div>

                                <PhoneInput required inputStyle={{ height: '55px', width: '500px' }} country={"ke"} enableSearch={true} value={userPhone} onChange={(phone) => setUserPhone(phone)} />
                            </Grid>

                            {/*Amount Input*/}
                            <Grid item>
                                <div style={{ marginBottom: '10px' }}>
                                    <span style={{ fontSize: '12px', color: '#032541' }}>Amount.</span>
                                </div>
                                <TextField defaultValue={totalBills || startOrderAmount} onChange={(e) => { setAmount(e.target.value) }} type='number' InputProps={{ style: { fontFamily: 'Poppins', background: '#fff' } }} InputLabelProps={{ style: { fontFamily: 'Poppins', fontSize: "13px", color: "#032541" } }} style={{ width: '500px', border: "solid 0px #e4e4e4", fontSize: "12px" }} id="outlined-basic" label="Amount" variant="outlined" required />
                            </Grid>

                            {/*Send prompt button*/}
                            <Grid item mt={10} mb={9}>
                                <Grid container justifyContent={'center'} alignContent={'center'}>
                                    <Grid item>
                                        {mpesaLoading ?
                                            <>
                                                <LoadingButton sx={{ width: "407px", backgroundColor: "#34A353", color: "white", '&:hover': { backgroundColor: '#34A353', color: 'white' } }}
                                                    endIcon={<SendIcon />}
                                                    loading={true}
                                                    loadingPosition="end"
                                                    variant="contained">
                                                    <span>Awaiting Payment</span>
                                                </LoadingButton>
                                                <Box sx={{ display: "flex", textAlign: "center", justifyContent: "center", width: "100%" }}>
                                                    <Typography sx={{ alignSelf: "center", marginLeft: "auto", marginRight: "auto" }}>
                                                        Resend Prompt: {counter}
                                                    </Typography>
                                                </Box>
                                            </>
                                            :
                                            <>
                                                <Button onClick={handleClick} style={{ width: '407px', background: "#032541", textTransform: 'none', color: '#fff', fontWeight: 'bold', fontSize: '14px' }} >Pay Now</Button>
                                            </>
                                        }
                                    </Grid>
                                </Grid>

                            </Grid>

                        </Grid>

                    </Grid> : activeButton === 'card' ?

                        <Grid item xs={12} sm={12} md={12} lg={8} style={{ background: 'rgba(23, 174, 123, 0.06)' }}>

                            <Grid container direction={'column'} ml={1} spacing={3}>
                                {GenerteLink ? <>
                                    {/* {console.log('GenerteLink inside div',GenerteLink)} */}
                                    <div className="pt-5 mt-5">
                                        <div className="div-card-ready" style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                                            <img src={CARDREADY} alt="" />

                                            <div className="pt-3">
                                                <h5 style={{ fontSize: "20px", color: "#032541", textAlign: "center", fontWeight: "bold" }}>Your Payment Link is Ready</h5>
                                                <h4 style={{ fontSize: "14px", color: "#707070", textAlign: "center" }}>Open or Copy your payment link for </h4>
                                                <h4 style={{ fontSize: "14px", color: "#032541", textAlign: "center" }}>BillTotal</h4>
                                                <h4 style={{ fontSize: "16px", color: "#dc3545", textAlign: "center" }}>Balance  {currencyconverter(totalBills)} </h4>
                                                <input style={{ display: "", opacity: "0" }} ref={textRef} type="text" value={dataLINK} readOnly />


                                                <div className="button-group" style={{ display: "flex", justifyContent: "center", alignContent: "center" }}>

                                                    <Button style={{ height: "40px", marginTop: "19px", padding: "0px 20px", fontSize: "16px", fontWeight: "500", color: "#032541", border: "2px solid #032541", }} onClick={handleCopy} >Copy Link</Button>
                                                    <a href={`${dataLINK}`}>
                                                        <Button style={{ height: "40px", marginTop: "19px", padding: "0px 20px", fontSize: "16px", fontWeight: "500", color: "#fff", backgroundColor: "#032541", border: "2px solid #032541" }} className="ml-3" onClick={() => {
                                                            window.href(`${dataLINK}`)
                                                        }} >Open LInk</Button>

                                                    </a>


                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </> : <>
                                    <>
                                        {/*Header lable*/}
                                        <Grid item mt={5} >
                                            <span style={{ fontStyle: '16px', fontWeight: '600', color: '#032541' }} >Pay Order</span>
                                        </Grid>

                                        {/*Invoice card Information*/}
                                        <Grid item>

                                            <Grid container direction={'row'} spacing={2}>
                                                <Grid item style={{ width: '70%' }}>
                                                    {/*PlyCard*/}
                                                    <Grid container direction={'column'} spacing={0.5} style={{ borderRadius: '10px', background: 'rgba(112, 112, 112, 0.05)' }}>
                                                        <Grid item>
                                                            <span style={{ color: '#707070', fontSize: '14px' }}>Bill Total:</span>
                                                        </Grid>
                                                        {/* <Grid item>
                                                            <span style={{ color: '#032541', fontWeight: 600, fontSize: '18px' }}>{invoiceNumber}</span>
                                                        </Grid> */}
                                                    </Grid>
                                                </Grid>

                                                <Grid item style={{ width: '30%' }}>
                                                    {/*Amount*/}
                                                    <Grid container direction={'column'} spacing={0.5} style={{ borderRadius: '10px' }}>
                                                        {/* <Grid item>
                                                            <span style={{ color: '#707070', fontSize: '14px' }}>Invoice Balance:</span>
                                                        </Grid> */}
                                                        <Grid item>
                                                            <span style={{ color: '#dc3545', fontWeight: 600, fontSize: '18px' }}>{currencyconverter(totalBills)}</span>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                            </Grid>

                                        </Grid>

                                        {/*Mpesa Instruction*/}
                                        <Grid item mt={-2}>
                                            {/* <span style={{ fontSize: '14px' }} >Kindly enter your email address to receive the payment prompt </span> */}
                                        </Grid>

                                        {/*Mobile input*/}
                                        <Grid item>
                                            <div style={{ marginBottom: '20px', }}>
                                                <span style={{ fontSize: '12px', color: '#032541' }}>EMAIL ADDRESS.</span>
                                            </div>
                                            <TextField required InputProps={{ style: { fontFamily: 'Poppins', background: '#fff' } }} InputLabelProps={{ style: { fontFamily: 'Poppins', fontSize: "13px", color: "#032541" } }} style={{ width: '500px', border: "solid 0px #e4e4e4", fontSize: "12px" }} value={'EMAIL ADDRESS'} disabled id="outlined-basic" label="Email Address" variant="outlined" />


                                            {/* <TextField required InputProps={{ style: { fontFamily: 'Poppins', background: '#fff' } }} InputLabelProps={{ style: { fontFamily: 'Poppins', fontSize: "13px", color: "#032541" } }} style={{ width: '500px', border: "solid 0px #e4e4e4", fontSize: "12px" }} value={email} onChange={(e) => setEmailAddress(e.target.value)} id="outlined-basic" label="Email Address" variant="outlined" /> */}
                                        </Grid>

                                        {/*Amount Input*/}
                                        <Grid item>
                                            <div style={{ marginBottom: '10px' }}>
                                                <span style={{ fontSize: '12px', color: '#032541' }}>Amount.</span>
                                            </div>
                                            <TextField defaultValue={invoiceBalance} onChange={(e) => { setCardAmout(e.target.value) }} type='number' InputProps={{ style: { fontFamily: 'Poppins', background: '#fff' } }} InputLabelProps={{ style: { fontFamily: 'Poppins', fontSize: "13px", color: "#032541" } }} style={{ width: '500px', border: "solid 0px #e4e4e4", fontSize: "12px" }} id="outlined-basic" label="Amount" variant="outlined" required />
                                        </Grid>

                                        {/*Send prompt button*/}
                                        <Grid item mt={10} mb={9}>
                                            <Grid container justifyContent={'center'} alignContent={'center'}>
                                                <Grid item>
                                                    {/* 
                                                {GenerteLink ? <>
                                                    <Button style={{ width: '407px', background: "#032541", textTransform: 'none', color: '#fff', fontWeight: 'bold', fontSize: '14px' }} >CLICK HERE TO PAY</Button>

                                                </> : <>
                                                <Button onClick={() => { handleCardPayment(invoiceNumber) }} style={{ width: '407px', background: "#032541", textTransform: 'none', color: '#fff', fontWeight: 'bold', fontSize: '14px' }} >Generate  Link</Button>

                                                </>} */}

                                                    {Cardloading ?
                                                        <>
                                                            <LoadingButton sx={{ width: "407px", backgroundColor: "#34A353", color: "white", '&:hover': { backgroundColor: '#34A353', color: 'white' } }}
                                                                endIcon={<SendIcon />}
                                                                loading={true}
                                                                loadingPosition="end"
                                                                variant="contained">
                                                                <span>Awaiting </span>
                                                            </LoadingButton>
                                                            <Box sx={{ display: "flex", textAlign: "center", justifyContent: "center", width: "100%" }}>
                                                                <Typography sx={{ alignSelf: "center", marginLeft: "auto", marginRight: "auto" }}>
                                                                    Generating Link: {counter}
                                                                </Typography>
                                                            </Box>
                                                        </>

                                                        :
                                                        <>
                                                            {/* {GenerateLinkStatus  ?  <div className="d-flex">
                                                        <div className="d-flex justify-content-space-between">
                                                            <Link to={`${dataLINK}`}>
                                                        
                                                        <Button  style={{ width: '300px', background: "#032541", textTransform: 'none', color: '#fff', fontWeight: 'bold', fontSize: '14px' }} >Open LINK</Button>
                                                        </Link>
                                                        <Button onClick={() => { handleCardPayment(invoiceNumber) }} className="ml-2" style={{ width: '300px', background: "#032541", textTransform: 'none', color: '#fff', fontWeight: 'bold', fontSize: '14px' }} >Generate Link</Button>

                                                        </div>
                                                    </div> :
                                                <>
                                                <Button onClick={() => { handleCardPayment(invoiceNumber) }} style={{ width: '407px', background: "#032541", textTransform: 'none', color: '#fff', fontWeight: 'bold', fontSize: '14px' }} >Generate Link</Button> </> */}
                                                            {/* } */}

                                                            <Button onClick={handleCardPayment} style={{ width: '407px', background: "#032541", textTransform: 'none', color: '#fff', fontWeight: 'bold', fontSize: '14px' }} >Generate Link</Button>

                                                        </>
                                                    }

                                                </Grid>
                                            </Grid>

                                        </Grid>
                                    </>
                                </>}



                            </Grid>

                        </Grid> : activeButton === 'MTN Momo' ? <>
                            <Grid item style={{ background: 'rgba(23, 174, 123, 0.06)', width: '60%', display: 'flex', flexShrink: 1, marginRight: '2.5%' }}>

                                <Grid container direction={'column'} ml={1} spacing={3}>

                                    {/*Header lable*/}
                                    <Grid item mt={5} >
                                        <span style={{ fontStyle: '16px', fontWeight: '600', color: '#032541' }} >Pay invoice-MTN Momo</span>
                                    </Grid>

                                    {/*Invoice card Information*/}
                                    <Grid item>

                                        <Grid container direction={'row'} spacing={2}>
                                            <Grid item style={{ width: '70%' }}>
                                                {/*PlyCard*/}
                                                <Grid container direction={'column'} spacing={0.5} style={{ borderRadius: '10px', background: 'rgba(112, 112, 112, 0.05)' }}>
                                                    <Grid item>
                                                        <span style={{ color: '#707070', fontSize: '14px' }}>BillTotal:</span>
                                                    </Grid>
                                                    {/* <Grid item>
                                                        <span style={{ color: '#032541', fontWeight: 600, fontSize: '18px' }}>{invoiceNumber}</span>
                                                    </Grid> */}
                                                </Grid>
                                            </Grid>

                                            <Grid item style={{ width: '30%' }}>
                                                {/*Amount*/}
                                                <Grid container direction={'column'} spacing={0.5} style={{ borderRadius: '10px' }}>
                                                    {/* <Grid item>
                                                        <span style={{ color: '#707070', fontSize: '14px' }}>Invoice Balance:</span>
                                                    </Grid> */}
                                                    <Grid item>
                                                        <span style={{ color: '#dc3545', fontWeight: 600, fontSize: '18px' }}>{currencyconverter(totalBills || startOrderAmount)}</span>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                        </Grid>

                                    </Grid>

                                    {/*Mpesa Instruction*/}
                                    <Grid item mt={-2}>
                                        <span style={{ fontSize: '14px' }} >Kindly enter your mobile number to receive the payment prompt and enter you  Pin.</span>
                                    </Grid>

                                    {/*Mobile input*/}
                                    <Grid item>
                                        <div style={{ marginBottom: '10px' }}>
                                            <span style={{ fontSize: '12px', color: '#032541' }}>Mobile no.</span>
                                        </div>

                                        <PhoneInput required inputStyle={{ height: '55px', width: '500px' }} country={"ug"} enableSearch={true} value={userPhone} onChange={(phone) => setUserPhone(phone)} />
                                    </Grid>

                                    {/*Amount Input*/}
                                    <Grid item>
                                        <div style={{ marginBottom: '10px' }}>
                                            <span style={{ fontSize: '12px', color: '#032541' }}>Amount.</span>
                                        </div>
                                        <TextField defaultValue={totalBills} onChange={(e) => { setAmount(e.target.value) }} type='number' InputProps={{ style: { fontFamily: 'Poppins', background: '#fff' } }} InputLabelProps={{ style: { fontFamily: 'Poppins', fontSize: "13px", color: "#032541" } }} style={{ width: '500px', border: "solid 0px #e4e4e4", fontSize: "12px" }} id="outlined-basic" label="Amount" variant="outlined" required />
                                    </Grid>

                                    {/*Send prompt button*/}
                                    <Grid item mt={10} mb={9}>
                                        <Grid container justifyContent={'center'} alignContent={'center'}>
                                            <Grid item>
                                                {mpesaLoading ?
                                                    <>
                                                        <LoadingButton sx={{ width: "407px", backgroundColor: "#34A353", color: "white", '&:hover': { backgroundColor: '#34A353', color: 'white' } }}
                                                            endIcon={<SendIcon />}
                                                            loading={true}
                                                            loadingPosition="end"
                                                            variant="contained">
                                                            <span>Awaiting Payment</span>
                                                        </LoadingButton>
                                                        <Box sx={{ display: "flex", textAlign: "center", justifyContent: "center", width: "100%" }}>
                                                            <Typography sx={{ alignSelf: "center", marginLeft: "auto", marginRight: "auto" }}>
                                                                Resend Prompt: {counter}
                                                            </Typography>
                                                        </Box>
                                                    </>

                                                    :
                                                    <>
                                                        <Button onClick={() => handleMtnMomoPay()} style={{ width: '407px', background: "#032541", textTransform: 'none', color: '#fff', fontWeight: 'bold', fontSize: '14px' }} >Pay Now</Button>
                                                    </>
                                                }
                                            </Grid>
                                        </Grid>

                                    </Grid>

                                </Grid>

                            </Grid>
                        </> : activeButton === 'bankpaybill' ?
                            <>
                                <Grid item xs={12} sm={12} md={12} lg={8} style={{ background: 'rgba(23, 174, 123, 0.06)' }}>

                                    <Grid container direction={'column'} ml={1} spacing={3}>

                                        {/*Header lable*/}
                                        <Grid item mt={5} >
                                            <span style={{ fontStyle: '16px', fontWeight: '600', color: '#032541' }} >Pay Order via Bank Paybill</span>
                                        </Grid>

                                        {/*Invoice card Information*/}
                                        <Grid item>

                                            <Grid container direction={'row'} spacing={2}>
                                                <Grid item style={{ width: '70%' }}>
                                                    {/*PlyCard*/}
                                                    <Grid container direction={'column'} spacing={0.5} style={{ borderRadius: '10px', background: 'rgba(112, 112, 112, 0.05)' }}>
                                                        <Grid item>
                                                            <span style={{ color: '#707070', fontSize: '14px' }}>BillTotal:</span>
                                                        </Grid>
                                                        {/* <Grid item>
                                                            <span style={{ color: '#032541', fontWeight: 600, fontSize: '18px' }}>{invoiceNumber}</span>
                                                        </Grid> */}
                                                    </Grid>
                                                </Grid>

                                                <Grid item style={{ width: '30%' }}>
                                                    {/*Amount*/}
                                                    <Grid container direction={'column'} spacing={0.5} style={{ borderRadius: '10px' }}>
                                                        {/* <Grid item>
                                                            <span style={{ color: '#707070', fontSize: '14px' }}>Invoice Balance:</span>
                                                        </Grid> */}
                                                        <Grid item>
                                                            <span style={{ color: '#dc3545', fontWeight: 600, fontSize: '18px' }}>{currencyconverter(startOrderTag === "startOrderTag" ? startOrderAmount : totalBills || cashAmount)}</span>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                            </Grid>

                                        </Grid>
                                        {/*Mobile input*/}
                                        <Grid item>
                                            <div style={{ marginBottom: '10px' }}>
                                                <span style={{ fontSize: '12px', color: '#032541' }}>Bank </span>
                                            </div>

                                            <SelectInput required inputStyle={{ height: '55px', width: '500px' }} />
                                            <TextField select label="Select Bank Name" id="demo-select-small" style={{ height: "55px", width: "500px", borderRadius: "1px", fontFamily: "Poppins", }}
                                                // defaultValue={bankAccount}
                                                value={bankAccount}
                                                onChange={(e) => setBankAccount(e.target.value)}
                                                required>
                                                {availableBanks?.map((bank, index) => {
                                                    return <MenuItem key={index} value={bank?.name}>{bank?.name}</MenuItem>
                                                })}
                                            </TextField>
                                        </Grid>



                                        {/*Mobile input*/}
                                        <Grid item>
                                            <div style={{ marginBottom: '10px' }}>
                                                <span style={{ fontSize: '12px', color: '#032541' }}>Mobile no.</span>
                                            </div>

                                            <PhoneInput required inputStyle={{ height: '55px', width: '500px' }} country={"ke"} enableSearch={true} value={kcbphone} onChange={(kcbphone) => setkcbphone(kcbphone)} />
                                        </Grid>

                                        {/*Amount Input*/}
                                        <Grid item>
                                            <div style={{ marginBottom: '10px' }}>
                                                <span style={{ fontSize: '12px', color: '#032541' }}>Amount.</span>
                                            </div>
                                            <TextField defaultValue={startOrderTag === "startOrderTag" ? startOrderAmount : cashAmount} onChange={(e) => { setKcbAmount(e.target.value) }} type='number' InputProps={{ style: { fontFamily: 'Poppins', background: '#fff' } }} InputLabelProps={{ style: { fontFamily: 'Poppins', fontSize: "13px", color: "#032541" } }} style={{ width: '500px', border: "solid 0px #e4e4e4", fontSize: "12px" }} id="outlined-basic" label="Amount" variant="outlined" required />
                                        </Grid>

                                        {/*Send prompt button*/}
                                        <Grid item mt={10} mb={9}>
                                            <Grid container justifyContent={'center'} alignContent={'center'}>
                                                <Grid item>
                                                    {kcbloading ?
                                                        <>
                                                            <LoadingButton sx={{ width: "407px", backgroundColor: "#34A353", color: "white", '&:hover': { backgroundColor: '#34A353', color: 'white' } }}
                                                                endIcon={<SendIcon />}
                                                                loading={true}
                                                                loadingPosition="end"
                                                                variant="contained">
                                                                <span>Awaiting Payment</span>
                                                            </LoadingButton>
                                                            <Box sx={{ display: "flex", textAlign: "center", justifyContent: "center", width: "100%" }}>
                                                                <Typography sx={{ alignSelf: "center", marginLeft: "auto", marginRight: "auto" }}>
                                                                    Resend Prompt: {counter}
                                                                </Typography>
                                                            </Box>
                                                        </>

                                                        :
                                                        <>
                                                            <Button onClick={() => handleKcbPush()} style={{ width: '407px', background: "#032541", textTransform: 'none', color: '#fff', fontWeight: 'bold', fontSize: '14px' }} >Send Prompt</Button>
                                                        </>
                                                    }
                                                </Grid>
                                            </Grid>

                                        </Grid>

                                    </Grid>

                                </Grid>
                            </> : activeButton === 'bankSlip' ? <>
                                <Grid item xs={12} sm={12} md={12} lg={8} style={{ background: 'rgba(23, 174, 123, 0.06)' }}>

                                    <Grid container direction={'column'} ml={1} spacing={3}>

                                        {/*Header lable*/}
                                        <Grid item mt={5} >
                                            <span style={{ fontStyle: '16px', fontWeight: '600', color: '#032541' }} >Bank Slip Validation</span>
                                        </Grid>

                                        {/*Invoice card Information*/}
                                        <Grid item>

                                            <Grid container direction={'row'} spacing={2}>
                                                <Grid item style={{ width: '70%' }}>
                                                    {/*PlyCard*/}
                                                    <Grid container direction={'column'} spacing={0.5} style={{ borderRadius: '10px', background: 'rgba(112, 112, 112, 0.05)' }}>
                                                        <Grid item>
                                                            <span style={{ color: '#707070', fontSize: '14px' }}>BillTotal:</span>
                                                        </Grid>
                                                        {/* <Grid item>
                                                            <span style={{ color: '#032541', fontWeight: 600, fontSize: '18px' }}>{invoiceNumber}</span>
                                                        </Grid> */}
                                                    </Grid>
                                                </Grid>

                                                <Grid item style={{ width: '30%' }}>
                                                    {/*Amount*/}
                                                    <Grid container direction={'column'} spacing={0.5} style={{ borderRadius: '10px' }}>
                                                        {/* <Grid item>
                                                            <span style={{ color: '#707070', fontSize: '14px' }}>Invoice Balance:</span>
                                                        </Grid> */}
                                                        <Grid item>
                                                            <span style={{ color: '#dc3545', fontWeight: 600, fontSize: '18px' }}>{currencyconverter(totalBills)}</span>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                            </Grid>

                                        </Grid>
                                        <Grid item>
                                            <div style={{ marginBottom: '20px', }}>
                                                <span style={{ fontSize: '12px', color: '#032541' }}>Select    Banks </span>
                                            </div>
                                            {console.log(bankAccountsKCB, "bankAccountsKCB")}
                                            <TextField select label="Select Bank Name" id="demo-select-small" style={{ height: "55px", width: "500px", borderRadius: "1px", fontFamily: "Poppins", }}

                                                defaultValue={bankAccountKCB}
                                                onChange={(e) => setBankAccountKCB(e.target.value)}
                                                required>
                                                {bankAccountsKCB.map((option) => (
                                                    <MenuItem key={option.id} value={option.accountNumber} style={{ height: "40px", paddingLeft: "10px" }}>
                                                        {option.bankName} -  {option.accountNumber}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                            {/* <CustomSelect required inputStyle={{ height: '55px', width: '500px' }} country={"ke"} enableSearch={true} value={bankAccountKCB} onChange={(bankAccountKCB) => setBankAccountKCB(bankAccountKCB)} /> */}
                                            {/* <TextField required InputProps={{ style: { fontFamily: 'Poppins', background: '#fff' } }} InputLabelProps={{ style: { fontFamily: 'Poppins', fontSize: "13px", color: "#032541" } }} style={{ width: '500px', border: "solid 0px #e4e4e4", fontSize: "12px" }} value={'EMAIL ADDRESS'} disabled id="outlined-basic" label="Email Address" variant="outlined" /> */}
                                        </Grid>
                                        <Grid item>
                                            <div style={{ marginBottom: '10px' }}>
                                                <span style={{ fontSize: '12px', color: '#032541' }}>Amount.</span>
                                            </div>
                                            <TextField defaultValue={referenceNo} onChange={(e) => { setReferenceNo(e.target.value) }} type='text' InputProps={{ style: { fontFamily: 'Poppins', background: '#fff' } }} InputLabelProps={{ style: { fontFamily: 'Poppins', fontSize: "13px", color: "#032541" } }} style={{ width: '500px', border: "solid 0px #e4e4e4", fontSize: "12px" }} id="outlined-basic" label="Reference number" variant="outlined" required />
                                        </Grid>

                                        {/*VALIDATE  SLIP*/}
                                        <Grid item mt={10} mb={9}>
                                            <Grid container justifyContent={'center'} alignContent={'center'}>
                                                <Grid item>
                                                    <Button onClick={handleBankSlip} style={{ width: '407px', background: "#032541", textTransform: 'none', color: '#fff', fontWeight: 'bold', fontSize: '14px' }} >Validate Slip</Button>
                                                </Grid>
                                            </Grid>

                                        </Grid>
                                    </Grid>

                                </Grid>

                            </> : activeButton === 'cash' ? <>
                                <Grid item xs={12} sm={12} md={12} lg={8} style={{ background: 'rgba(23, 174, 123, 0.06)' }}>

                                    <Grid container direction={'column'} ml={1} spacing={3}>

                                        {/*Header lable*/}
                                        <Grid item mt={5} >
                                            <span style={{ fontStyle: '16px', fontWeight: '600', color: '#032541' }} >Receive via Cash</span>
                                        </Grid>

                                        {/*Invoice card Information*/}
                                        <Grid item>

                                            <Grid container direction={'row'} spacing={2}>
                                                <Grid item style={{ width: '70%' }}>
                                                    {/*PlyCard*/}
                                                    <Grid container direction={'column'} spacing={0.5} style={{ borderRadius: '10px', background: 'rgba(112, 112, 112, 0.05)' }}>
                                                        <Grid item>
                                                            <span style={{ color: '#707070', fontSize: '14px' }}>BillTotal:</span>
                                                        </Grid>
                                                        {/* <Grid item>
                                                            <span style={{ color: '#032541', fontWeight: 600, fontSize: '18px' }}>{invoiceNumber}</span>
                                                        </Grid> */}
                                                    </Grid>
                                                </Grid>

                                                <Grid item style={{ width: '30%' }}>
                                                    {/*Amount*/}
                                                    <Grid container direction={'column'} spacing={0.5} style={{ borderRadius: '10px' }}>
                                                        {/* <Grid item>
                                                            <span style={{ color: '#707070', fontSize: '14px' }}>Invoice Balance:</span>
                                                        </Grid> */}
                                                        <Grid item>
                                                            <span style={{ color: '#dc3545', fontWeight: 600, fontSize: '18px' }}>{currencyconverter(totalBills || startOrderAmount)}</span>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                            </Grid>

                                        </Grid>

                                        <Grid item>
                                            <div style={{ marginBottom: '10px' }}>
                                                <span style={{ fontSize: '12px', color: '#032541' }}>Amount.</span>
                                            </div>
                                            <TextField defaultValue={cashAmount} onChange={(e) => { setCashAmount(Number(e.target.value)) }} type='number' InputProps={{ style: { fontFamily: 'Poppins', background: '#fff' }, inputProps: { min: 1 } }} InputLabelProps={{ style: { fontFamily: 'Poppins', fontSize: "13px", color: "#032541" } }} style={{ width: '500px', border: "solid 0px #e4e4e4", fontSize: "12px" }} id="outlined-basic" label="Amount" variant="outlined" required />
                                        </Grid>
                                        <Grid item mt={10} mb={9}>
                                            <Grid container justifyContent={'center'} alignContent={'center'}>
                                                <Grid item>
                                                    <Button onClick={payViaCash} style={{ width: '407px', background: "#032541", textTransform: 'none', color: '#fff', fontWeight: 'bold', fontSize: '14px' }} >Process</Button>
                                                </Grid>
                                            </Grid>

                                        </Grid>
                                    </Grid>

                                </Grid>

                            </> : activeButton === 'voucher' ? <>
                                {showVoucherDetails ? (
                                    <Grid item xs={12} sm={12} md={12} lg={8} style={{ background: 'rgba(23, 174, 123, 0.06)', }}>
                                        <Box style={{ display: 'flex' }}>
                                            <Typography onClick={() => setShowVoucherDetails(false)} style={{ fontWeight: 600, fontSize: "18px", color: "#032541", marginTop: "10px", fontFamily: 'Poppins', marginLeft: '20px', cursor: 'pointer' }}>
                                                <ArrowBackIcon style={{ marginRight: '8px' }} />
                                            </Typography>
                                            <Typography style={{ fontWeight: 600, fontSize: "18px", color: "#032541", marginTop: "10px", fontFamily: 'Poppins', marginLeft: '20px' }}>
                                                E-Voucher Details
                                            </Typography>
                                        </Box>
                                        <Grid container spacing={2} style={{ width: '100%', marginLeft: '10px', marginTop: '20px' }}>
                                            <Grid item xs={12} sm={6} md={3}>
                                                <div style={{ width: '350px', height: '50px', display: 'flex', marginBottom: '10px', justifyContent: 'space-between', alignItems: 'center', borderRadius: "12px", boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.1)", backgroundColor: voucherDetails?.status === "Redeemed" ? "#f3fbf8" : voucherDetails?.status === "EXPIRED" ? "#fef4f5" : "#fff9f2" }}>
                                                    <div style={{ marginLeft: '5px', color: '#0e294a', fontSize: '15px', fontWeight: '600' }}>Status:</div>
                                                    <div style={{ marginRight: '20px', color: voucherDetails?.status === "Redeemed" ? "#17ae7b" : voucherDetails?.status === "EXPIRED" ? "#FFA7B5" : "#E67300" }}>{voucherDetails?.status}</div>
                                                </div>
                                                <div style={{ display: "flex", width: '350px', alignItems: "center", justifyContent: "space-between", padding: "15px", borderRadius: "12px", boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.1)", backgroundColor: 'white' }}>
                                                    <Box style={{}}>
                                                        <Typography variant="h6" sx={{ color: "#000", fontSize: "16px" }}>
                                                            Balance:
                                                        </Typography>
                                                        <Typography variant="h6" sx={{
                                                            fontWeight: 600, fontSize: "21px", color:
                                                                voucherDetails?.status === "Redeemed" ? "#17ae7b" :
                                                                    voucherDetails?.status === "EXPIRED" ? "#dc3545" : "#f79009"
                                                        }}>
                                                            {voucherDetails.balance.toFixed(2)}
                                                        </Typography>
                                                        <Typography variant="h6" sx={{ fontSize: "12px", color: "#707070", lineHeight: '1.67' }}>
                                                            {voucherDetails?.status === "EXPIRED" ? (
                                                                <>
                                                                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                        ID: {voucherDetails.voucherNumber}.
                                                                        <Typography variant="h6" sx={{ color: "#dc3545", fontSize: "14px", marginLeft: '10px' }}>
                                                                            Expired on: {new Date(voucherDetails.expiryDate).toLocaleDateString()}
                                                                        </Typography>
                                                                    </Box>
                                                                    <Typography variant="h6" sx={{ color: "#000", fontSize: "14px", fontWeight: 500 }}>
                                                                        Amount: {voucherDetails.balance.toFixed(2)}
                                                                    </Typography>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    ID: {voucherDetails.voucherNumber}. Expiring in {Math.ceil((new Date(voucherDetails.expiryDate) - new Date()) / (1000 * 60 * 60 * 24))} days
                                                                    <br />
                                                                    <span style={{ color: "#111927", fontWeight: '600', fontSize: '12px' }}>Amount: {localStorage.getItem('localCurrency')} {voucherDetails.amount.toFixed(2)}</span>
                                                                </>
                                                            )}
                                                        </Typography>
                                                    </Box>
                                                    <img alt="Voucher Status Icon" style={{ width: "40px", height: "40px", objectFit: "contain" }} src={voucherDetails?.status === "Redeemed" ? RedeemedIcon : voucherDetails?.status === "EXPIRED" ? ExpiredVoucherIcon : UnusedIcon} />
                                                </div>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2} style={{ width: '100%', marginLeft: '10px', }}>
                                            <Grid item xs={12} sm={6} md={3}>
                                                <Typography style={{ fontWeight: 600, fontSize: "14px", color: "#000", marginTop: "20px", fontFamily: "Poppins", }}>
                                                    Recipient Details
                                                </Typography>
                                                <Grid container justifyContent="space-between" alignItems="center" xs={12}>
                                                    <Grid item>
                                                        <Typography variant="h6" sx={{ color: "#707070", fontSize: "14px" }}>
                                                            {voucherDetails.name}
                                                        </Typography>
                                                        <Typography variant="h6" sx={{ fontWeight: 600, fontSize: "14px", color: "#707070" }}>
                                                            {voucherDetails.phone}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography variant="h6" sx={{ fontSize: "12px", color: "#707070", lineHeight: "1.67" }}>
                                                            {voucherDetails.email}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid container ml={3} mt={5}>
                                            <div style={{ width: '350px', height: '50px', display: 'flex', marginBottom: '10px', justifyContent: 'space-between', alignItems: 'center', borderRadius: "12px", boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.1)", backgroundColor: "white" }}>
                                                <div style={{ marginLeft: '5px', color: '#0e294a', fontSize: '15px', fontWeight: '600' }}>New Balance</div>
                                                <div style={{ marginRight: '20px', color: '0e294a0', fontSize: '15px', fontWeight: '600' }}>{localStorage.getItem('localCurrency')} {voucherDetails.balance.toFixed(2)}</div>
                                            </div>
                                        </Grid>
                                        {/* Redeem Button */}
                                        {(voucherDetails?.status === "UNUSED" || voucherDetails?.status === "Partially Redeemed") && (
                                            <Grid container item mt={2} ml={40} >
                                                <Button
                                                    variant="contained"
                                                    sx={{ backgroundColor: '#032541', '&:hover': { backgroundColor: '#032541' } }}
                                                    onClick={async () => {
                                                        console.log("Redeem button clicked"); 
                                                        try {
                                                            // const orderIds = orderIDS?.map((order) => order?.orderId);
                                                            const response = await fetch(`${baseUrl}/api/v1/evoucher/redeemEvoucher`, {
                                                                method: 'POST',
                                                                headers: {
                                                                    'Content-Type': 'application/json',
                                                                    'X-Authorization': localStorage.getItem('X-Authorization'),
                                                                },
                                                                body: JSON.stringify({
                                                                    voucherNumber: voucherDetails.voucherNumber,
                                                                    discount: 0.0,
                                                                    orderIds: startOrderId,
                                                                    paymentChanel: "Mobile"
                                                                }),
                                                            });

                                                            if (!response.ok) {
                                                                throw new Error(`HTTP error! Status: ${response.status}`);
                                                            }

                                                            const data = await response.json();
                                                            // console.log('API Response:', data); 

                                                            if (data.Status === 'SUCCESS') { 
                                                                // console.log('Voucher redeemed successfully:', data);
                                                                const paidOrderId = data.data.results.paidOrderId;
                                                                console.log('paidOrderId:', paidOrderId);
                                                                setSuccessShow({ state: true, message: "" });
                                                                setShowVoucherDetails(false);
                                                                navigate(`/detailedbill/${paidOrderId}`);
                                                            } else {
                                                                console.error('Error redeeming voucher:', data.message);
                                                            }
                                                        } catch (error) {
                                                            console.error('Fetch error:', error);
                                                        }
                                                    }}
                                                >
                                                    Redeem
                                                </Button>
                                            </Grid>
                                        )}
                                    </Grid>
                                ) : isOtpVisible ? (
                                    <Grid item xs={12} sm={12} md={12} lg={8} style={{ background: 'rgba(23, 174, 123, 0.06)', }}>
                                        <Box style={{ display: 'flex' }}>
                                            <Typography
                                                onClick={() => setShowVoucherDetails(false)}
                                                style={{ fontWeight: 600, fontSize: "18px", color: "#032541", marginTop: "10px", fontFamily: 'Poppins', marginLeft: '20px', cursor: 'pointer' }}
                                            >
                                                <ArrowBackIcon style={{ marginRight: '8px' }} />
                                            </Typography>
                                            <Typography
                                                style={{ fontWeight: 600, fontSize: "18px", color: "#032541", marginTop: "10px", fontFamily: 'Poppins', marginLeft: '20px' }}
                                            >
                                                Verify OTP
                                            </Typography>
                                        </Box>

                                        <Box textAlign="center" marginTop="20px">
                                            <Typography sx={{ fontSize: '20px', fontWeight: 600, color: '#032541' }}>
                                                One Time Pin
                                            </Typography>
                                        </Box>
                                        <Box textAlign="center" marginTop="5px">
                                            <Typography sx={{ fontSize: '14px', color: '#707070' }}>
                                                Enter the 4 digit code sent to {voucherDetails.email}
                                            </Typography>
                                        </Box>

                                        <Box display="flex" justifyContent="center" marginTop="20px">
                                            <PinInput
                                                length={4}
                                                initialValue=""
                                                secret={true} // Mask the input
                                                secretDelay={100} // Delay for revealing the secret
                                                onChange={(pin) => setPin(pin)}
                                                type="password"
                                                inputMode="numeric"
                                                style={{ padding: '10px' }}
                                                inputStyle={{ borderColor: '#bec5d1', width: "92px", height: "108px", fontSize: "50px" }} // Style for each input box
                                                inputFocusStyle={{ borderColor: 'blue' }}
                                                autoSelect={true}
                                                regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                                            />
                                        </Box>

                                        <Box textAlign="center" marginTop="20px">
                                            <Button
                                                variant="contained"
                                                sx={{ backgroundColor: '#032541', '&:hover': { backgroundColor: '#032541' } }}
                                                onClick={handleVerifyOtp}
                                            >
                                                Verify OTP
                                            </Button>
                                        </Box>
                                    </Grid>

                                ) : (

                                    <Grid item xs={12} sm={12} md={12} lg={8} style={{ background: 'rgba(23, 174, 123, 0.06)', height: '880px' }}>
                                        <Grid container direction={'column'} ml={1} spacing={3}>
                                            <Grid item mt={5} >
                                                <span style={{ fontStyle: '16px', fontWeight: '600', color: '#032541' }} >Receive via Voucher</span>
                                            </Grid>
                                            <Grid item>

                                                <Grid container direction={'row'} spacing={2}>
                                                    <Grid item style={{ width: '70%' }}>
                                                        {/*PlyCard*/}
                                                        <Grid container direction={'column'} spacing={0.5} style={{ borderRadius: '10px', background: 'rgba(112, 112, 112, 0.05)' }}>
                                                            <Grid item>
                                                                <span style={{ color: '#707070', fontSize: '14px' }}>BillTotal:</span>
                                                            </Grid>
                                                            {/* <Grid item>
                                                            <span style={{ color: '#032541', fontWeight: 600, fontSize: '18px' }}>{invoiceNumber}</span>
                                                        </Grid> */}
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item style={{ width: '30%' }}>
                                                        <Grid container direction={'column'} spacing={0.5} style={{ borderRadius: '10px' }}>
                                                            {/* <Grid item>
                                                            <span style={{ color: '#707070', fontSize: '14px' }}>Invoice Balance:</span>
                                                        </Grid> */}
                                                            <Grid item>
                                                                <span style={{ color: '#dc3545', fontWeight: 600, fontSize: '18px' }}>{currencyconverter(totalBills || startOrderAmount)}</span>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                </Grid>
                                                {/* <Grid item mt={2}>
                                                <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" style={{ height: '320px' }}  >
                                                    <div style={{ marginBottom: "10px", textAlign: "center" }}>
                                                        <span style={{ fontSize: "16px", color: "#032541", fontWeight: "bold" }}>Scan QR Code</span>
                                                        <p style={{ fontSize: "14px", color: "#707070" }}>
                                                            Point your camera at the QR Code
                                                        </p>
                                                    </div>
                                                    <QRcodeScanner width="500px" height="320px" />
                                                </Box>
                                            </Grid> */}
                                                <Grid item style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                                    <Button variant="contained" onClick={toggleQrScanner} style={{ marginBottom: '20px', backgroundColor: '#032541', marginTop: '10px' }}>
                                                        {openQrScanner ? "Hide Scanner" : "Scan QR Code"}
                                                    </Button>
                                                    {openQrScanner && (
                                                        <Box
                                                            display="flex"
                                                            flexDirection="column"
                                                            alignItems="center"
                                                            justifyContent="center"
                                                        // style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 1000, background: 'white', padding: '20px', borderRadius: '8px', boxShadow: '0 0 10px rgba(0,0,0,0.5)' }}
                                                        >
                                                            <div style={{ marginBottom: "10px", textAlign: "center" }}>
                                                                <span style={{ fontSize: "16px", color: "#032541", fontWeight: "bold" }}>Scan QR Code</span>
                                                                <p style={{ fontSize: "14px", color: "#707070" }}>
                                                                    Point your camera at the QR Code
                                                                </p>
                                                            </div>
                                                            <QRcodeScanner width="500px" height="320px" closeQr={toggleQrScanner} onScanSuccess={handleQrScanSuccess} />
                                                        </Box>
                                                    )}
                                                </Grid>
                                                <Grid item >
                                                    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" style={{ height: '100px' }}  >
                                                        <div style={{ marginBottom: "10px", textAlign: "center" }}>
                                                            <span style={{ fontSize: "14px", color: "#707070", fontWeight: "normal" }}>Or Enter Serial Number Manually</span>
                                                        </div>
                                                    </Box>
                                                </Grid>
                                                <Grid item>
                                                    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" style={{ height: '80px' }}>
                                                        <div style={{ marginBottom: "10px", textAlign: "center" }}>
                                                            <span style={{ fontSize: "14px", color: "#707070", fontWeight: "normal" }}>Serial Number<span style={{ color: "red" }}>*</span></span>
                                                        </div>
                                                        <TextField label="Serial Number" placeholder="Enter Serial Number" variant="outlined" value={serialNumber} onChange={handleSerialNumberChange} style={{ width: '500px' }} />
                                                    </Box>
                                                </Grid>
                                                {isVoucherDetailsVisible && voucherDetails && voucherDetails.type === "batch" ? (
                                                    <Grid item>
                                                        <Typography style={{ fontWeight: 600, fontSize: "18px", color: "#032541", marginTop: "10px", fontFamily: 'Poppins', marginLeft: '20px' }}>
                                                            E-Voucher Details
                                                        </Typography>
                                                        <Grid container spacing={2} style={{ width: '100%', marginLeft: '10px', marginTop: '20px' }}>
                                                            <Grid item xs={12} sm={6} md={3}>
                                                                <div style={{
                                                                    display: "flex", alignItems: "center", justifyContent: "space-between", padding: "15px", borderRadius: "12px", boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.1)", backgroundColor:
                                                                        voucherDetails?.status === "Redeemed" ? "#f3fbf8" :
                                                                            voucherDetails?.status === "EXPIRED" ? "#fef4f5" : "#fff9f2"
                                                                }}>
                                                                    <Box>
                                                                        <Typography variant="h6" sx={{ color: "#000", fontSize: "16px" }}>
                                                                            Amount:
                                                                        </Typography>
                                                                        <Typography variant="h6" sx={{
                                                                            fontWeight: 600, fontSize: "21px", color:
                                                                                voucherDetails?.status === "Redeemed" ? "#17ae7b" :
                                                                                    voucherDetails?.status === "EXPIRED" ? "#dc3545" : "#f79009"
                                                                        }}>
                                                                            {voucherDetails.amount.toFixed(2)}
                                                                        </Typography>
                                                                        <Typography variant="h6" sx={{ fontSize: "12px", color: "#707070", lineHeight: '1.67' }}>
                                                                            {voucherDetails?.status === "EXPIRED" ? (
                                                                                <>
                                                                                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                                        ID: {voucherDetails.voucherNumber}.
                                                                                        <Typography variant="h6" sx={{ color: "#dc3545", fontSize: "14px", marginLeft: '10px' }}>
                                                                                            Expired on: {new Date(voucherDetails.expiryDate).toLocaleDateString()}
                                                                                        </Typography>
                                                                                    </Box>
                                                                                    <Typography variant="h6" sx={{ color: "#000", fontSize: "14px", fontWeight: 500 }}>
                                                                                        Amount: {voucherDetails.balance.toFixed(2)}
                                                                                    </Typography>
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    ID: {voucherDetails.voucherNumber}. Expiring in {Math.ceil((new Date(voucherDetails.expiryDate) - new Date()) / (1000 * 60 * 60 * 24))} days
                                                                                </>
                                                                            )}
                                                                        </Typography>
                                                                    </Box>
                                                                    <img alt="Voucher Status Icon" style={{ width: "40px", height: "40px", objectFit: "contain" }}
                                                                        src={voucherDetails?.status === "Redeemed" ? "RedeemedIcon" :
                                                                            voucherDetails?.status === "EXPIRED" ? "ExpiredVoucherIcon" : "UnusedIcon"} />
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography style={{ fontWeight: 600, fontSize: "14px", color: "#000", marginTop: "20px", fontFamily: 'Poppins', marginLeft: '20px' }}>
                                                                Recipient Details
                                                            </Typography>
                                                        </Grid>
                                                        <Grid container spacing={2} style={{ width: '100%', marginLeft: '10px', marginTop: '5px' }}>
                                                            <Grid item xs={12} sm={6} md={3}>
                                                                <Box>
                                                                    <Typography variant="h6" sx={{ color: "#707070", fontSize: "14px" }}>
                                                                        {voucherDetails.name}
                                                                    </Typography>
                                                                    <Typography variant="h6" sx={{ fontWeight: 600, fontSize: "14px", color: "#707070" }}>
                                                                        {voucherDetails.phone}
                                                                    </Typography>
                                                                    <Typography variant="h6" sx={{ fontSize: "12px", color: "#707070", lineHeight: '1.67' }}>
                                                                        {voucherDetails.email}
                                                                    </Typography>
                                                                </Box>
                                                                <hr style={{ marginTop: '10px' }} />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                ) : null}
                                                <Grid item mt={10} mb={9}>
                                                    <Grid container justifyContent={'center'} alignContent={'center'}>
                                                        <Grid item>
                                                            <Button onClick={handleNextClick} style={{ width: '407px', background: "#032541", textTransform: 'none', color: '#fff', fontWeight: 'bold', fontSize: '14px' }} disabled={!isNextButtonActive} >Next</Button>
                                                        </Grid>
                                                    </Grid>

                                                </Grid>
                                            </Grid>

                                            {/* <Grid item>
                                            <div style={{ marginBottom: '10px' }}>
                                                <span style={{ fontSize: '12px', color: '#032541' }}>Amount.</span>
                                            </div>
                                            <TextField defaultValue={cashAmount} onChange={(e) => { setCashAmount(Number(e.target.value)) }} type='number' InputProps={{ style: { fontFamily: 'Poppins', background: '#fff' }, inputProps: { min: 1 } }} InputLabelProps={{ style: { fontFamily: 'Poppins', fontSize: "13px", color: "#032541" } }} style={{ width: '500px', border: "solid 0px #e4e4e4", fontSize: "12px" }} id="outlined-basic" label="Amount" variant="outlined" required />
                                        </Grid>
                                        <Grid item mt={10} mb={9}>
                                            <Grid container justifyContent={'center'} alignContent={'center'}>
                                                <Grid item>
                                                    <Button onClick={payViaCash} style={{ width: '407px', background: "#032541", textTransform: 'none', color: '#fff', fontWeight: 'bold', fontSize: '14px' }} >Process</Button>
                                                </Grid>
                                            </Grid>

                                        </Grid> */}
                                        </Grid>
                                    </Grid>
                                )}

                            </> : null
                }
                {/*Mpesa payment*/}
                <PaymentSent successOpen={successOpen} onClose={() => setSuccessOpen(false)} />
            </Grid>

        </div>
    )
}
