import React, { useState, useEffect } from "react";
import { Grid, Breadcrumbs, TextField, MenuItem, Button, FormControl, InputLabel, Select } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { ArrowBackIos, NavigateNext } from "@mui/icons-material";
import { customerstyles } from "./styles";
import PhoneInput from "react-phone-input-2";
import HttpComponent from "../../School/MakeRequest";
import { SuccessAlertRightAligned } from "../../bills/startorderModals/modals";
import { ErrorAlertRightAligned } from "../../bills/startorderModals/modals";
import GeoLocation from "../../../hooks/useLocation";

const Createcustomer = () => {
  const navigate = useNavigate();
  const { id } = useParams();


  const [customerType, setCustomerType] = useState(id === 'fmcg' ? ['Company']:["Company", "Individual"]);
  const [selectCustomerType, setSelectedCustomerType] = useState("");
  const [paymentType, setPaymentType] = useState([]);
  const [customerPhone, setCustomerPhone] = useState("");
  const [inputsField, setInputField] = useState(false);
  const [customerDetails, setCustomerDetails] = useState({ customerType: "", physicalAdress: "", emailAdress: "", firstName: "", lastName: "", paymentType: "", companyName: "", amountRecieved:0 });
  const [sucess, setSucess] = useState({ state: false, message: "" });
  const [error, setError] = useState({ state: false, message: "" });
  const [selectedValue ,setSelectedValue] = useState('')
  const [allCoutries, setAllCountries] = useState([])
  const [selectedCurrency, setSelectedCurrency] = useState(null)
  const [selectedCountry, setSelectedCountry] = useState(null)
  const [counrty, setCount] = useState(null)

  const getInputFields = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setCustomerDetails({ ...customerDetails, [name]: value });
  };

  useEffect(() => {
    if ((id === 'new') && (customerDetails.customerType === "" && customerDetails.emailAdress === "" && customerPhone === "" && customerDetails.amountRecieved)) {
      setInputField(false);
    } else if ((id === 'new') && (customerDetails.customerType && customerDetails.emailAdress && customerDetails.paymentType && customerPhone || customerDetails.amountRecieved)) {
      setInputField(true);
    }else if ((id === 'fmcg') && (customerDetails.customerType === "" && customerDetails.emailAdress === "" && customerPhone === "" && customerDetails.companyName =='' && selectedCountry === null && selectedCurrency === null && customerDetails.paymentType === '')){
      setInputField(false);
    }else if ((id === 'fmcg') && (customerDetails.customerType  && customerDetails.emailAdress && customerPhone && customerDetails.companyName  && selectedCountry  && selectedCurrency && customerDetails.paymentType )){
      setInputField(true);
    }
  }, [customerDetails.customerType, customerDetails.emailAdress, customerDetails.paymentType, customerPhone, customerDetails.companyName, customerDetails.amountRecieved]);
  //selectedValue?.description 
 
  const getCurrencyandCountry = async () => {
      try {
          const response = await axios.get('https://restcountries.com/v3.1/all?fields=name,currencies', { headers: { "Content-Type": "application/json", "Accept": "application/json" } })

          if (response.status === 200) {
              setAllCountries(response.data)
          }

      } catch (error) {

      }
  }

  useEffect(() => {
      getCurrencyandCountry()
  }, [])

  const allCountry = [{
      "name": {
          "common": "Kenya",
      },
      "currencies": {
          "KES": {
              "name": "Kenyan shilling",
              "symbol": "Sh"
          }
      }
  },
  {
      "name": {
          "common": "Uganda",
      },
      "currencies": {
          "UGX": {
              "name": "Ugandan shilling",
              "symbol": "Sh"
          }
      }
  },
  {
      "name": {
          "common": "Nigeria",
      },
      "currencies": {
          "NGN": {
              "name": "Nigerian naira",
              "symbol": "₦"
          }
      }
  }
]


  const filteredCountries = allCountry?.filter(country => {
      const commonName = country?.name?.common;
      return commonName === "Kenya" || commonName === "Uganda" || commonName === "Nigeria";
  });

  const [currencyCode, setCurrencyCode] = useState(null)
  const [phoneCurrencyCode, setPhoneCurrency] = useState("ke")


  const currencies = filteredCountries?.map(country => {
      const commonName = country?.name?.common;
      const currencyData = country?.currencies[Object.keys(country.currencies)[0]];
      return {
          country: commonName,
          currency: currencyData.name,
          currencyCode: Object.keys(country.currencies)[0],
          currencySymbol: currencyData.symbol
      };
  });


  useEffect(() => {
    if (!currencyCode) {
      setPhoneCurrency("ke")
    }
    if (currencyCode === "UGX") {
      setPhoneCurrency("ug")
    }
    if (currencyCode === "NGN") {
      setPhoneCurrency("ng")
    }
    if (currencyCode === "USD") {
      setPhoneCurrency("us")
    }
    if (currencyCode === "KES") {
      setPhoneCurrency("ke")
    }
  }, [counrty, currencyCode])

  // get payment types

  const getpaymentTypes = async() =>{
    try{
      HttpComponent({
        method:'GET',
        url:`/api/listPaymentType`,
        token: localStorage.getItem("X-Authorization"),
      }).then((data)=>{
        if(data?.status === 200 || data?.status === 201){
          setPaymentType(data?.response?.data)
        }
      })
    }catch(error){

    }
  }
  useEffect(() =>{
    getpaymentTypes()
  },[])

  const handleCountryChange = (event) => {
    const selectedValue = event.target.value;
    const returnCurreny =  currencies?.find((currency) => currency.country === selectedValue )
    setCount(selectedValue)
    setSelectedCountry(returnCurreny?.country);
    setSelectedCurrency(returnCurreny.currencyCode);
    setCurrencyCode(returnCurreny?.currencyCode)
};

  // get user details
  const getUserDetails = () => {
    if(id === 'fmcg' || 'new'){
      return
    }
    HttpComponent({
      method: "GET",
      url: `/api/get_customer_by_number?customerId=${id}`,
      body: null,
      token: localStorage.getItem("X-Authorization"),
    })
      .then((data) => {

        if (data.status) {
          let allData = data.response.data;
          const filteredData = allData.filter(item => item._id === id);
          setCustomerDetails({
            customerType: filteredData[0]?.customerType,
            physicalAdress: filteredData[0]?.customerAddress,
            emailAdress: filteredData[0]?.email,
            firstName: filteredData[0]?.firstName,
            lastName: filteredData[0]?.lastName,
            paymentType : filteredData[0]?.paymentType,
            companyName: filteredData[0]?.firstName
          })
          setCustomerPhone(filteredData[0]?.phone)
          setSelectedValue(customerDetails.physicalAdress)
          // const { customerType, email, firstName, paymentType, limit, phone, customerAddress, lastName } = data.response.data[0];
          // setCustomerDetails({ customerType: customerType, physicalAdress: customerAddress, emailAdress: email, firstName: firstName, lastName: lastName, paymentType: paymentType, companyName: firstName });
          // setCustomerPhone(phone);
        }
      })
      .catch((e) => {
        console.log(e.message);
      });
  };

  useEffect(() => {
    getUserDetails();
  }, [id]);

  let fmcgCustomerPaylaod = {
    "businessPhoneNumber":`+${customerPhone}`,
    "businessLocation":selectedValue?.description,
    "businessEmail":customerDetails.emailAdress,
    "country":selectedCountry,
    "businessType":customerDetails.paymentType,
    "customerTypeId":customerDetails.paymentType,
    "currency":selectedCurrency,
    "businessOwnerName":customerDetails.companyName,
    "businessOwnerPhone":`+${customerPhone}`,
    "businessOwnerEmail":customerDetails.emailAdress,
    "businessName":customerDetails.companyName
  }


  const addUser = () => {
    HttpComponent({
      method: "POST",
      url: id === 'fmcg' ? '/api/v1/customers/fmcg/add':`/api/addCustomer`,
      body: id === 'fmcg' ? fmcgCustomerPaylaod: {
        businessId: localStorage.getItem("businessId"),
        customerType: customerDetails.customerType,
        // customerAddress: customerDetails.physicalAdress,
        customerAddress:selectedValue?.description,
        email: customerDetails.emailAdress,
        firstName: customerDetails.customerType === "Individual" ? customerDetails.firstName : customerDetails.companyName,
        lastName: customerDetails.customerType === "Individual" ? customerDetails.lastName : "  ",
        phone: `+${customerPhone}`,
        paymentType: customerDetails.paymentType,
        limit: customerDetails.paymentType === "Normal" ? 0 :customerDetails.amountRecieved,
        amountReceived: customerDetails.paymentType === "Normal" ? 0 : customerDetails.amountRecieved,
        limitInstrument: customerDetails.paymentType === "Normal" ? 0 : 0,
        vehicles: [],
      },
      token: localStorage.getItem("X-Authorization"),
    })
      .then((data) => {
        // console.log(data);
        if (data.status === 201) {
          setSucess({ state: true, message: "Customer has been added succesfully!" });
          setCustomerDetails({ customerType: "", physicalAdress: "", emailAdress: "", firstName: "", lastName: "", paymentType: "", companyName: "", amountRecieved:0 });
          setTimeout(() => {
            setSucess({ state: false });
            setError({ state: false });
            navigate(`/customers`)
          }, 2000);
          
        } else if (data.status > 399 && data.status < 501) {
          setError({ state: true, message: data.response.message });
        }

   
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const updateCustomer = () => {
    if(id === 'new' || id === 'fmcg'){
      return
    }
    HttpComponent({
      method: "PUT",
      url: `/api/updateCustomer?customerId=${id}`,
      body: {
        businessId: localStorage.getItem("businessId"),
        customerType: customerDetails.customerType,
       // customerAddress: customerDetails.physicalAdress,
        customerAddress:selectedValue?.description,
        email: customerDetails.emailAdress,
        firstName: customerDetails.customerType === "Individual" ? customerDetails.firstName : customerDetails.companyName,
        lastName: customerDetails.customerType === "Individual" ? customerDetails.lastName : "  ",
        phone: `+${customerPhone}`,
        paymentType: customerDetails.paymentType,
        limit: customerDetails.paymentType === "Normal" ? 0 : 0,
        amountReceived: customerDetails.paymentType === "Normal" ? 0 : 0,
        limitInstrument: customerDetails.paymentType === "Normal" ? 0 : 0,
        vehicles: [],
      },
      token: localStorage.getItem("X-Authorization"),
    })
      .then((data) => {
        // console.log(data);
        if (data.status === 202) {
          setSucess({ state: true, message: "Customer has been updated succesfully!" });

          setTimeout(() => {
            setSucess({ state: false });
            setError({ state: false });
            navigate("/customers");
          }, 2000);
        } else if (data.status > 399 && data.status < 501) {
          setError({ state: true, message: data.response.message });
          setTimeout(() => {
            setError({ state: false });
          }, 1000);
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  return (
    <div style={customerstyles.universalStyle}>
      <Grid container direction={"row"} width={"100%"} style={customerstyles.gridMargin}>
        <Grid item width={"100%"} margin={'0px 0px 2% 0px'}>
          <Breadcrumbs separator={<NavigateNext fontSize="small" />} aria-label="breadcrumb">
            <span>Dashboard</span>
            {id === "createcustomer" ? <span style={{ color: "red" }}>Customers</span> :  <span style={{ color: "red" }}>{id === "new" ? "Add Customer" : id === 'fmcg' ? 'Add Fmcg Customer': "Edit Customer"}</span>}
          </Breadcrumbs>
        </Grid>

        <Grid item width={"100%"} margin={'0px 0px 2% 0px'}>
          <span style={{display:'flex', alignItems:'center'}}> <ArrowBackIos onClick={() => navigate(`/customers`)} /> {id === "new" ? "Add Customer" :id === 'fmcg' ? 'Add Fmcg Customer': "Edit Customer"} </span>
        </Grid>
      </Grid>

      <Grid container width={"100%"} direction={"column"}>
        <Grid item width={"70%"} style={customerstyles.marginGridItems}>
          <TextField style={{ width: "50%" }} id="outlined-select-meals" value={customerDetails.customerType} select label="Customer Type" helperText="" onChange={getInputFields} name="customerType">
            {customerType?.map((customer, index) => {
              return (
                <MenuItem key={index} value={customer}>
                  {customer}
                </MenuItem>
              );
            })}
          </TextField>
        </Grid>

        {customerDetails.customerType === "Company" && (
          <Grid item width={"70%"} style={customerstyles.marginGridItems}>
            <TextField label="Company Name" value={customerDetails.companyName} name="companyName" style={{ width: "50%" }} onChange={getInputFields} />
          </Grid>
        )}
        {id === 'fmcg' &&
        <Grid item width={'35%'}>
          <FormControl sx={{ marginBottom: "10px", display: "flex" }} width={'100%'}>
            <InputLabel id="demo-simple-select-label">Select Country</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={counrty}
              label="Select Country"
              onChange={handleCountryChange}
            >
              {currencies?.map((item) => (
                <MenuItem key={item?.currencyCode} value={item?.country}>{item?.country}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>}

        {customerDetails.customerType === "Individual" && (
          <Grid item width={"70%"} style={customerstyles.marginGridItems}>
            <div style={customerstyles.individualInputsholder}>
              <TextField label="First Name" value={customerDetails.firstName} name="firstName" onChange={getInputFields} />
              <TextField label="Last Name" name="lastName" value={customerDetails.lastName} onChange={getInputFields} />
            </div>
          </Grid>
        )}

        <Grid item width={"70%"} style={customerstyles.marginGridItems}>
          <div style={customerstyles.individualInputsholder}>
            {/* <TextField label="Physical Adress" value={customerDetails.physicalAdress} name="physicalAdress" style={{ width: "50%" }} onChange={getInputFields} /> */}
            <GeoLocation sx={{width:"300px" ,  marginRight:"10px"}} onValueChange={(location)=>setSelectedValue(location)} selectedValue={customerDetails.physicalAdress} />
            <PhoneInput name="phone" value={customerPhone} onChange={(phone) => setCustomerPhone(phone)} required country={"ke"} enableSearch={true} label={"Mobile Number"} multiline inputStyle={{ height: "55px" }} />
          </div>
        </Grid>

        <Grid item width={"70%"} style={customerstyles.marginGridItems}>
          <TextField label="Email Adress" value={customerDetails.emailAdress} name="emailAdress" style={{ width: "50%" }} onChange={getInputFields} />
        </Grid>

        <Grid item width={"70%"} style={customerstyles.marginGridItems}>
          <TextField style={{ width: "50%" }} value={customerDetails.paymentType} id="outlined-select-meals" select label="Payment Type" helperText="" name="paymentType" onChange={getInputFields}>
            {paymentType?.map((payment, index) => {
              return (
                <MenuItem key={index} value={payment?.paymentName}>
                  {payment?.paymentName}
                </MenuItem>
              );
            })}
          </TextField>
        </Grid>


        {
          customerDetails.paymentType === 'Prepaid' || customerDetails.paymentType === 'Credit'  ?    <Grid item width={"70%"} style={customerstyles.marginGridItems}>
          <TextField label="Amount Recieved" value={customerDetails.amountRecieved} name="amountRecieved" style={{ width: "50%" }} onChange={getInputFields} />
        </Grid> : null
        }
     

        <Grid item width={"70%"}>
          <div style={customerstyles.buttonsHoldingDiv}>
            <Button style={inputsField ? customerstyles.cancelButton : customerstyles.cancelButtonLowOpacity} onClick={() => navigate("/customers")} >Cancel</Button>
            <Button style={inputsField ? customerstyles.approvalButton : customerstyles.approvalButtonLowOpacity} onClick={() => (inputsField && (id === "new" || 'fmcg')  ? addUser() : inputsField && (id !== "new" || id !== 'fmcg') ? updateCustomer() : null)}>
              {" "}
              {id === "new" ? "Add" : id === 'fmcg' ? 'Add Fmcg Customer': "Update"}
            </Button>
          </div>
        </Grid>
      </Grid>

      {/* modals */}
      {sucess && <SuccessAlertRightAligned message={sucess.message} sucess={sucess.state} />}
      {error && <ErrorAlertRightAligned message={error.message} error={error.state} />}
    </div>
  );
};

export default Createcustomer;
