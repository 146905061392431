import React, { useEffect, useRef, useState } from 'react';
import QrScanner from 'qr-scanner';
import { Box } from '@mui/material';

const QRcodeScanner = ({ width, height, closeQr, onScanSuccess }) => {
    const scanner = useRef();
    const qrBoxEl = useRef(null);
    const videoEl = useRef(null);
    const [qrOn, setQrOn] = useState(true);

    const handleScanSuccess = (result) => {
        console.log('Scanned result:', result);
        if (onScanSuccess) {
            onScanSuccess(result);
        }
    };

    const onScanFail = (err) => {
        console.log(err);
    };

    const handleCloseCamera = () => {
        scanner?.current?.stop();
        closeQr();
    };

    useEffect(() => {
        if (videoEl?.current && !scanner.current) {
            scanner.current = new QrScanner(videoEl?.current, handleScanSuccess, {
                onDecodeError: onScanFail,
                preferredCamera: "environment",
                highlightScanRegion: true,
                highlightCodeOutline: true,
                overlay: qrBoxEl?.current || undefined,
            });

            scanner?.current
                ?.start()
                .then(() => setQrOn(true))
                .catch((err) => {
                    if (err) setQrOn(false);
                });
        }
        return () => {
            if (!videoEl?.current) {
                scanner?.current?.stop();
            }
        };
    }, []);

    useEffect(() => {
        if (!qrOn)
            alert(
                "Camera is blocked or not accessible. Please allow camera in your browser permissions and Reload."
            );
    }, [qrOn]);

    return (
        <Box
            component="video"
            ref={videoEl}
            style={{ width: width, height: height, border: '2px solid #032541' }}
        />
    );
};

export { QRcodeScanner };