import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Modal, Select, Stack, styled, Tab, TablePagination, Typography } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { CheckCircle, Circle, Close } from "@mui/icons-material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { makeStyles } from "@mui/styles";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import { DataGrid } from "@mui/x-data-grid";
import { Link, useNavigate, useParams } from "react-router-dom";
import profilePicture from "../../../common/images/profile_picture.svg";
import deactivateUser from "../../../common/images/deactivate-user.svg";
import { CreateSchoolInvoiceModal } from "../../Modals/Invoice/createSchoolInvoice";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CustomTable from "../../School/CustomTable";
let baseUrl = process.env.REACT_APP_BASE_URL;

const AntTabs = styled(TabList)({
  borderBottom: "3px solid #e8e8e8",
  "& .MuiTabs-indicator": {
    backgroundColor: "#dc3545",
  },
});

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: "none",
  minWidth: 0,
  [theme.breakpoints.up("sm")]: {
    minWidth: 0,
  },
  fontWeight: theme.typography.fontWeightRegular,
  marginRight: theme.spacing(1),
  fontSize: "13px",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "2.75",
  letterSpacing: "normal",
  textAlign: "left",
  color: "#6e7074",
  fontFamily: ["Poppins"].join(","),
  "&:hover": {
    color: "#032541",
    opacity: 1,
  },
  "&.Mui-selected": {
    color: "#dc3545",
    fontWeight: 600,
  },
  "&.Mui-focusVisible": {
    backgroundColor: "blue",
  },
}));

const useStyles = makeStyles({
  table: {
    minWidth: 800,
  },
});

const ParentProfile = (props) => {
  const { customerId } = useParams();
  const [tabValue, setTabValue] = useState("invoices");
  const [students, setStudents] = useState([]);
  const [parentData, setParentData] = useState();
  const [customerActivity, setCustomerActivity] = useState([]);
  const [customerTransRows, setCustomerTransRows] = useState([]);
  const [customerTransPageSize, setCustomerTransPageSize] = useState(10);
  const [dataGridPageSize, setPageSize] = React.useState(5);
  const [pageState, setPageState] = useState({ isLoading: true, data: [], count: 0, page: 1, pageSize: dataGridPageSize });
  const [createInvoiceModal, setCreateInvoiceModal] = useState(false);
  const [invoiceData, setInvoiceData] = useState([]);

  console.log("parent data",parentData)

  let localCurrency = localStorage.getItem('localCurrency')

  if (!localCurrency || localCurrency === 'undefined' || localCurrency === undefined ) {
    localCurrency = 'KES'
  }
  
  const numberFormat = (value) => new Intl.NumberFormat("en-US", { style: "currency", currency: localCurrency === 'undefined' ? 'KES':localCurrency}).format(value);

  const classes = useStyles();
  const theColumns = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  };

  function convertDate(dateString) {
    let date = new Date(dateString);
    let options = { month: "short" };
    let day = date.getDate();
    let suffix = "th";

    if (day === 1 || day === 21 || day === 31) {
      suffix = "st";
    } else if (day === 2 || day === 22) {
      suffix = "nd";
    } else if (day === 3 || day === 23) {
      suffix = "rd";
    }

    return day + suffix + " " + date.toLocaleString("default", options) + " " + date.getFullYear();
  }

  const commonTextSize = {
    fontSize: "15px",
  };
  const setNewValue = (event, newValue) => {
    setTabValue(newValue);
  };

  const getTransactions = async () => {
    console.log("test working");
    const customerDetailsResponse = await fetch(baseUrl + `/api/customerTransactions/?customerId=${customerId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-Authorization": localStorage.getItem("X-Authorization"),
      },
    });
    if (customerDetailsResponse.status === 201) {
      await customerDetailsResponse.json().then((data) => {
        console.log("transa", data.transaction);
        let counter = 1;
        const rowsWithIds = data.transaction.map((row) => {
          let transactionTimeDate = new Date(row.transactionTime).toLocaleDateString("en-US", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          });
          let transactionTimeTime = new Date(row.transactionTime).toLocaleTimeString("en-US");
          let transactionTime = `${transactionTimeDate} ${transactionTimeTime}`;
          return { ...row, id: counter++, transactionTime };
        });
        console.log("new rows", rowsWithIds);
        setCustomerTransRows(rowsWithIds);
      });
    }
  };

  const fetchActivity = async () => {
    const customerDetailsResponse = await fetch(baseUrl + `/api/CustomersActivities/?customerId=${customerId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-Authorization": localStorage.getItem("X-Authorization"),
      },
    });
    if (customerDetailsResponse.status === 201) {
      const customerDetailsData = await customerDetailsResponse.json();
      console.log("CUSTOMERS ......", customerDetailsData.data);
      setCustomerActivity(customerDetailsData.data);
    }
  };

  const fetchInvoices = async () => {
    // /api/get_invoice_by_customerId/:customerId
    const customerDetailsResponse = await fetch(baseUrl + `/api/get_invoice_by_customerId/${customerId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-Authorization": localStorage.getItem("X-Authorization"),
      },
    });
    if (customerDetailsResponse.status === 200) {
      const customerDetailsData = await customerDetailsResponse.json();
      console.log("invoices", customerDetailsData.data);
      setInvoiceData(customerDetailsData.data);
    }
  };

  const formatDate = (date) => {
    let transactionTimeDate = new Date(date).toLocaleDateString("en-US", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
    let transactionTimeTime = new Date(date).toLocaleTimeString("en-US");
    return `${transactionTimeDate} ${transactionTimeTime}`;
  };

  const fetchStudentsInfo = async () => {
    let url = baseUrl + `/api/getBillableItems?page=${pageState.page}&limit=${dataGridPageSize}`;
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-Authorization": localStorage.getItem("X-Authorization"),
      },
      body: JSON.stringify({
        customerId: customerId,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("student info", data);
        setStudents(data?.data);
        setPageState({ ...pageState, isLoading: false, data: data?.data, count: data?.count });

      })
      .catch((error) => {
        console.error(error);
      });
  };
  const columns = [
    { field: "id", headerName: "Admission Number", flex: 1 },
    {
      field: "Student Name", headerName: "Student Name", flex: 1, renderCell: (params) => (
        <strong>
          {params.row.firstName} {params.row.lastName}
        </strong>
      )
    },

    { field: "grade", headerName: "Grade", flex: 1 },
    { field: "term", headerName: "Term", flex: 1 },
    { field: "stream", headerName: "Stream", flex: 1 },
    { field: "boardingStatus", headerName: "Boarding Status", flex: 1 },
    // { field: "status", headerName: "Student Status", flex: 1},
    // {field: 'servedBy', headerName: 'Served By',minWidth:130},
    {
      field: "additionalInfo", headerName: "AdditionalInfo", flex: 1, renderCell: (params) => (
        <strong>
          {params.row.additionalInfo === "" ? "N/A" : "Yes"}
        </strong>
      )
    },
    { field: "pendingInvoices", headerName: "Pending Invoices", flex: 1 },
    { field: "pendingBalance", headerName: "Pending Balance", flex: 1 },
    {
      field: "status",
      headerName: "Student Status",
      flex: 1,
      renderCell: (params) => (
        <Typography
          sx={{
            color: params.row.status === "Deactivated" ? "#dc3545" : params.row.status === "ACTIVE" ? "#17ae7b" : "#000", // Default color if neither
          }}
        >
          {params.row.status}
        </Typography>
      ),
    },
    {
      field: "action", headerName: "Action", flex: 1, renderCell: (params) => (
        <strong>
          <MoreVertIcon />
        </strong>
      )
    },

    // {field: 'approvedBy', headerName: 'Approved By',minWidth:130}
  ]
  const studentsRows = students.map((student) => {
    return {
      id: student.itemNumber,
      firstName: student.firstName,
      lastName: student.lastName,
      grade: student.grade,
      term: student.term,
      stream: student.stream,
      boardingStatus: student.boardingStatus,
      amount: student.amount,
      additionalInfo: student.additionalInfo,
      pendingInvoices: student.pendingInvoices,
      pendingBalance: student.pendingBalance,
      status: student.status,
    };
  });
  const fetchCustomerInfo = async () => {
    const productResponse = await fetch(baseUrl + `/api/get_customer_by_customer_id?customerId=${customerId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-Authorization": localStorage.getItem("X-Authorization"),
      },
    });

    if (productResponse.status === 200) {
      const customerData = await productResponse.json();
      console.log("customer data", customerData);
      setParentData(customerData?.data);
    }
  };

  const fetchReceivedReceipts = async () => {
    try {
      const options = {
        method: "GET",
        headers: {
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
      };
      const response = await fetch(baseUrl + `/api/get_customer_receipts?page=1&limit=1000000000&customerId=${customerId}`, options);
      if (response.status === 201) {
        await response.json().then((data) => {
          console.log("received receipts", data);
          const rowsWithIds = data.data.map((row) => {
            const issuedDate = new Date(row.issuedDate).toLocaleDateString("de-DE", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            });
            const amount = numberFormat(row.amount);
            return { ...row, id: row.transactionId, issuedDate, amount };
          });
          setReceivedReceipts(rowsWithIds);
        });
      }
    } catch (e) {
      console.error("error fetching recived invoices", e.message);
    }
  };

  useEffect(() => {
    fetchReceivedReceipts();
    fetchStudentsInfo();
    fetchCustomerInfo();
    fetchActivity();
    getTransactions();
    fetchInvoices();
  }, [pageState.page, dataGridPageSize]);

  const [receivedReceipts, setReceivedReceipts] = useState([]);
  const [secondaryParentModal, setSecondaryParentModal] = useState(false);
  const [secondaryParentData, setSecondaryParentData] = useState([]);

  const changeSecondaryParent = () => {
    if (secondaryParentModal) {
      setSecondaryParentModal(false);
    }else if(!secondaryParentModal){
      setSecondaryParentModal(true);
      getDetailsOfParents();
    }
    
  }
  const getDetailsOfParents = async () => {

    const customerDetailsResponse = await fetch(baseUrl + `/api/v1/customers/get_secondary_parent`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-Authorization": localStorage.getItem("X-Authorization"),
      },
      
      body:JSON.stringify({
        primaryParentId:customerId
      })
    });
    // if (customerDetailsResponse.status === 200) {
      const customerDetailsData = await customerDetailsResponse.json();
      console.log("init", customerDetailsData);
    setSecondaryParentData(customerDetailsData.data);
    
    console.log("parent details", customerDetailsData.data)
     
    // }
  }


  useEffect(() => {
    getDetailsOfParents()
  }, [])
  const navigate = useNavigate();

  console.log(parentData, 'shhhhhhhhhhhhhhhhhhh')
  
  return (
    <>
      <Grid container marginBottom="10px">
        <Grid item style={{ width: "100%" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "20px",
            }}
          >
            <div
              style={{
                fontSize: "25px",
                fontWeight: "600",
                color: "#dc3545",
              }}
            >
              Parent/Guardian Profile
            </div>
            <div>
              <Button
                style={{
                  width: "190px",
                  height: "45px",
                  fontSize: "14px",
                  fontWeight: "500",
                  backgroundColor: "#032541",
                  borderRadius: "5px",
                  color: "white",
                }}
                onClick={() => navigate(`/school/parent/edit/${customerId}`)}
              >
                Edit Parent
              </Button>
              <Link to={`/school/student/add/${customerId}`} className="ml-3" >
                <Button style={{
                  width: "190px",
                  height: "45px",
                  fontSize: "14px",
                  fontWeight: "500",
                  backgroundColor: "#032541",
                  borderRadius: "5px",
                  marginLeft:'10px',
                  color: "white",
                }}>Add Student</Button>
              </Link>
            </div>
          </div>
          {secondaryParentModal ?
            <>
             <div style={{ display: "flex", justifyContent: "center" }}>
                <div style={{ display: "flex", justifyContent: "space-between", height: "150px", width: "95%", boxShadow: "0 4px 9px 0 rgba(0, 0, 0, 0.16)", borderRadius: "15px", marginTop: "3%", padding: "20px", }} >
                  <div style={theColumns}>
                    <img src={profilePicture} alt="Profile Picture Placeholder" style={{ height: "110px", width: "110px", }} />
                  </div>
                  <div style={theColumns}>
                    <div style={{ color: "#032541", fontSize: "25px", fontWeight: "bold", paddingTop: "0.125rem", paddingBottom: "0.125rem" }}>
                      {secondaryParentData?.firstName} {secondaryParentData?.lastName}
                    </div>
                    <div style={{ fontSize: "15px", color: "#666f76", paddingTop: "0.125rem", paddingBottom: "0.125rem" }}>Create At {convertDate(secondaryParentData?.createdAt)}</div>
                    <div style={{ commonTextSize, paddingTop: "0.25rem", paddingBottom: "0.25rem" }}>
                      <span style={{ fontWeight: "bold", color: "#032541" }}>Address: </span>
                      <span style={{ color: "#666f76" }}>{secondaryParentData?.country}</span>
                    </div>
                  </div>
                  <div style={theColumns}>
                    <div style={{ commonTextSize, paddingTop: "0.25rem", paddingBottom: "0.25rem" }}>
                      <span style={{ fontWeight: "bold", color: "#032541" }}>Email: </span>
                      <span style={{ color: "#666f76" }}>{secondaryParentData?.email}</span>
                    </div>
                    <div style={{ commonTextSize, paddingTop: "0.25rem", paddingBottom: "0.25rem" }}>
                      <span style={{ fontWeight: "bold", color: "#032541" }}>No. Of Students: </span>
                      <span style={{ color: "#666f76" }}>{pageState.count}</span>
                    </div>
                  </div>
                  <div style={theColumns}>

                    <div style={{ commonTextSize, paddingTop: "0.25rem", paddingBottom: "0.25rem" }}>
                      <span style={{ fontWeight: "bold", color: "#032541" }}>Mobile Number: </span>
                      <span style={{ color: "#666f76" }}>{secondaryParentData?.phone}</span>
                    </div>
                    <div style={{ commonTextSize, paddingTop: "0.25rem", paddingBottom: "0.25rem" }}>
                      <span style={{ fontWeight: "bold", color: "#032541" }}>Pending Invoices: </span>
                      <span style={{ color: "#666f76" }}>{secondaryParentData?.pendingInvoiceCount}</span>
                    </div>
                  </div>
                  <div style={theColumns}>
                    <div style={{ commonTextSize, paddingTop: "0.25rem", paddingBottom: "0.25rem", }}>
                      <Button style={{ backgroundColor: "#f5f6f7", padding: "13px 22px 12px", }} onClick={changeSecondaryParent} >Primary Parent</Button>
                    </div>

                    <div style={{ commonTextSize, paddingTop: "0.25rem", paddingBottom: "0.25rem" }}>
                      <span style={{ fontWeight: "bold", color: "#032541" }}>Pending Balance: </span>
                      <span style={{ color: "#666f76" }}>{localCurrency} {secondaryParentData?.pendingAmount}</span>
                    </div>
                  </div>
                </div>
              </div>
            </> :

            <>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div style={{ display: "flex", justifyContent: "space-between", height: "150px", width: "95%", boxShadow: "0 4px 9px 0 rgba(0, 0, 0, 0.16)", borderRadius: "15px", marginTop: "3%", padding: "20px", }} >
                  <div style={theColumns}>
                    <img src={profilePicture} alt="Profile Picture Placeholder" style={{ height: "110px", width: "110px", }} />
                  </div>
                  <div style={theColumns}>
                    <div style={{ color: "#032541", fontSize: "25px", fontWeight: "bold", paddingTop: "0.125rem", paddingBottom: "0.125rem" }}>
                      {parentData?.firstName} {parentData?.lastName}
                    </div>
                    <div style={{ fontSize: "15px", color: "#666f76", paddingTop: "0.125rem", paddingBottom: "0.125rem" }}>Create At {convertDate(parentData?.createdAt)}</div>
                    <div style={{ commonTextSize, paddingTop: "0.25rem", paddingBottom: "0.25rem" }}>
                      <span style={{ fontWeight: "bold", color: "#032541" }}>Address: </span>
                      <span style={{ color: "#666f76" }}>{parentData?.country}</span>
                    </div>
                  </div>
                  <div style={theColumns}>
                    <div style={{ commonTextSize, paddingTop: "0.25rem", paddingBottom: "0.25rem" }}>
                      <span style={{ fontWeight: "bold", color: "#032541" }}>Email: </span>
                      <span style={{ color: "#666f76" }}>{parentData?.email}</span>
                    </div>
                    <div style={{ commonTextSize, paddingTop: "0.25rem", paddingBottom: "0.25rem" }}>
                      <span style={{ fontWeight: "bold", color: "#032541" }}>No. Of Students: </span>
                      <span style={{ color: "#666f76" }}>{pageState.count}</span>
                    </div>
                  </div>
                  <div style={theColumns}>

                    <div style={{ commonTextSize, paddingTop: "0.25rem", paddingBottom: "0.25rem" }}>
                      <span style={{ fontWeight: "bold", color: "#032541" }}>Mobile Number: </span>
                      <span style={{ color: "#666f76" }}>{parentData?.phone}</span>
                    </div>
                    <div style={{ commonTextSize, paddingTop: "0.25rem", paddingBottom: "0.25rem" }}>
                      <span style={{ fontWeight: "bold", color: "#032541" }}>Pending Invoices: </span>
                      <span style={{ color: "#666f76" }}>{parentData?.pendingInvoices}</span>
                    </div>
                  </div>
                  <div style={theColumns}>
                    <div style={{ commonTextSize, paddingTop: "0.25rem", paddingBottom: "0.25rem", }}>
                      {console.log( parentData?.hasSecondaryParent , 'hasSecondaryParent')}
                      <Button style={{ backgroundColor: "#f5f6f7", padding: "13px 22px 12px"}} onClick={changeSecondaryParent}  disabled={parentData?.hasSecondaryParent === false || parentData?.hasSecondaryParent=== undefined }>Sec Parent</Button>
                    </div>

                    <div style={{ commonTextSize, paddingTop: "0.25rem", paddingBottom: "0.25rem" }}>
                      <span style={{ fontWeight: "bold", color: "#032541" }}>Pending Balance: </span>
                      <span style={{ color: "#666f76" }}>{localCurrency} {parentData?.pendingAmount}</span>
                    </div>

                    <div style={{ commonTextSize, paddingTop: "0.25rem", paddingBottom: "0.25rem" }}>
                      <span style={{ fontWeight: "bold", color: "#032541" }}>Overpayment: </span>
                      <span style={{ color: "#666f76" }}>{localCurrency} {parentData?.overPayment}</span>
                    </div>

                  </div>
                </div>
              </div>
            </>}

          <TabContext value={tabValue}>
            <Box>

              <AntTabs onChange={setNewValue}>
                <AntTab value="invoices" label="Invoices" />
                <AntTab value="transactions" label="Transactions" />
                <AntTab label="Receipts" value="Receipts" />
                <AntTab value="students" label="Students" />
                {/*<AntTab value="activity" label="Activity" />*/}
              </AntTabs>
            </Box>

            <TabPanel value="students">
              {/* <div
                style={{
                  height: "350px",
                  minWidth: "1000px",
                }}
              >
                <Table className={classes.table} aria-label="students data grid">
                  <TableHead>
                    <TableRow>
                      <TableCell>Student ID</TableCell>
                      <TableCell>Student Name</TableCell>
                      <TableCell>Grade</TableCell>
                      <TableCell>Term</TableCell>
                      <TableCell>Additional Info</TableCell>
                      <TableCell>Pending Invoices</TableCell>
                      <TableCell>Amount Due</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {students?.map((student) => (
                      <TableRow key={student._id}>
                        <TableCell>
                          <Link to={`/school/student/${customerId}/${student.itemNumber}`}>{student.itemNumber}</Link>
                        </TableCell>
                        <TableCell>
                          {student.firstName} {student.lastName}
                        </TableCell>
                      
                        <TableCell>{student.grade}</TableCell>
                        <TableCell>{student.term}</TableCell>
                        <TableCell>{student.additionalInfo === "" ? "N/A" : "Yes"}</TableCell>
                        <TableCell>{student.pendingInvoices}</TableCell>
                        <TableCell>KES {student.pendingBalance}</TableCell>
                        <TableCell>
                          <MoreVertIcon />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div> */}

              <div style={{ height: "600px", minWidth: "1000px", }} >

                <DataGrid

                  columns={columns}
                  rows={studentsRows}
                  width="100%"
                  rowsPerPageOptions={[5, 10, 20, 50, 100]}
                  rowCount={pageState.count}
                  loading={pageState.isLoading}
                  pagination
                  page={pageState.page - 1}
                  pageSize={dataGridPageSize}
                  paginationMode="server"
                  onPageChange={(newPage) => {
                    setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize }));
                  }}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}



                />
              </div>
            </TabPanel>

            <TabPanel value="transactions">
              <div
                style={{
                  height: "350px",
                  minWidth: "1000px",
                }}
              >
                <DataGrid
                  pagination
                  rows={customerTransRows}
                  width="100%"
                  getRowId={(row) => row.id}
                  pageSize={customerTransPageSize}
                  onPageSizeChange={(newPageSize) => setCustomerTransPageSize(newPageSize)}
                  rowsPerPageOptions={[5, 10, 20, 50, 100]}
                  columns={[
                    { field: "transactionNo", headerName: "Transaction No", flex: 1 },
                    { field: "transactionTime", headerName: "Date/Time", flex: 1 },
                    // {field: 'servedBy', headerName: 'Served By',minWidth:130},
                    { field: "noOfItems", headerName: "No. Of Items", flex: 1 },
                    { field: "business", headerName: "Business", flex: 1 },
                    { field: "branchName", headerName: "Branch", flex: 1 },
                    { field: "amount", headerName: "Amount" },
                    // {field: 'approvedBy', headerName: 'Approved By',minWidth:130}
                  ]}
                />
              </div>
            </TabPanel>

            {/*<TabPanel value="activity">*/}
            {/*    <div style={{*/}
            {/*        height:"350px",*/}
            {/*        minWidth:"1000px"*/}
            {/*    }}>*/}
            {/*        {customerActivity.length < 1 ?(*/}
            {/*            <div style={{*/}
            {/*                display:"flex",*/}
            {/*                justifyContent:"center"*/}
            {/*            }}>*/}
            {/*                <div>*/}
            {/*                    No Customer Activity*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        ):(*/}
            {/*            <>*/}
            {/*                {customerActivity?.map((activity, index) => (*/}
            {/*                    <div key={index}>*/}
            {/*                        {numberFormat(activity.amount)} {activity.customerPaymentType === "credit" ? "credited" : "debited"} {activity.customerPaymentType === "credit" ? "to" : "from"} customer account by {activity.createdBy}*/}
            {/*                    </div>*/}
            {/*                ))}*/}
            {/*            </>*/}
            {/*        )}*/}

            {/*    </div>*/}
            {/*</TabPanel>*/}

            <TabPanel value="Receipts">
              <div
                style={{
                  height: "350px",
                  minWidth: "1000px",
                }}
              >
                <CustomTable
                  data={receivedReceipts}
                  columns={[
                    {
                      field: "receiptNo",
                      headerName: "Receipt Number",
                      flex: 1,
                      renderCell: (params) => {
                        return <Link style={{color:'#032541'}} to={`/receipt/${params.value}/${params.row.invoiceNumber}`}>{params.value}</Link>;
                      },
                    },
                    { field: "invoiceNumber", headerName: "Invoice Number", flex: 1 },
                    { field: "from", headerName: "From", flex: 1 },
                    { field: "amount", headerName: "Amount Paid", flex: 1 },
                    { field: "issuedDate", headerName: "Date Issued", flex: 1 },
                  ]}
                  rowsPerPage={5}
                />
              </div>
            </TabPanel>

            <TabPanel value="invoices">
              <div
                style={{
                  height: "350px",
                  minWidth: "1000px",
                }}
              >
                <Table className={classes.table} aria-label="students data grid">
                  <TableHead>
                    <TableRow>
                      <TableCell>Invoice No</TableCell>
                      {/*<TableCell>From</TableCell>*/}
                      <TableCell>Date/Time Issued</TableCell>
                      <TableCell>Amount</TableCell>
                      <TableCell>Amount Paid</TableCell>
                      <TableCell>Type</TableCell>
                      <TableCell>Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {invoiceData?.map((invoice) => (
                      <TableRow key={invoice._id}>
                        <TableCell>
                          <Link style={{color:'#032541'}} to={`/invoice/${invoice.invoiceNumber}`}>{invoice.invoiceNumber}</Link>
                        </TableCell>
                        {/*<TableCell>{student.firstName} {student.lastName}</TableCell>*/}
                        <TableCell>{formatDate(invoice.createdAt)}</TableCell>
                        <TableCell>{invoice?.invoiceAmount}</TableCell>
                        <TableCell>{invoice?.amountPaid}</TableCell>
                        <TableCell>{invoice.invoiceType}</TableCell>
                        <TableCell>
                          <Typography
                            sx={{
                              color: invoice.invoiceStatus == "Paid" ? "#17ae7b" : invoice.invoiceStatus == "Unpaid" ? "#dc3545" : invoice.invoiceStatus == "Partially Paid" ? "#ff8503" : "#032541",
                            }}
                          >
                            {invoice.invoiceStatus}
                          </Typography>
                        </TableCell>
                        {/*<TableCell>{student.additionalInfo === "" ? "N/A" : "Yes"}</TableCell>*/}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </TabPanel>
          </TabContext>
        </Grid>
      </Grid>
      <CreateSchoolInvoiceModal modalOpen={createInvoiceModal} onClose={() => setCreateInvoiceModal(false)} />
    </>
  );
};

export { ParentProfile };
