import { escape } from "lodash";
import HttpComponent from "../../School/MakeRequest";

export default class startOrderFunction {
  constructor() {}

  filterTransactionByDateAndType(startDate, endDate, status) {
    return HttpComponent({
      method: `POST`,
      url: `/api/fetchBillsByDate/?status=${status}`,
      body: { dateList: [startDate, endDate] },
      token: localStorage.getItem("X-Authorization"),
    });
  }

  getDetailedBill(id) {
    return HttpComponent({
      method: "POST",
      url: `/api/order_payment_status`,
      body: {
        pushyTransactionId: id,
        businessNo: localStorage.getItem("businessId"),
      },
      token: localStorage.getItem("X-Authorization"),
    });
  }

  requestToVoidBill(transId, comments) {
    return HttpComponent({
      method: "POST",
      url: `/api/v1/payments/void`,
      body: {
        transactionId: transId,
        action: "request",
        comments: comments,
      },
      token: localStorage.getItem("X-Authorization"),
    });
  }

  voidTransactions(transId) {
    return HttpComponent({
      method: "POST",
      url: `/api/v1/payments/void`,
      body: {
        transactionId: transId,
        action: "approve",
      },
      token: localStorage.getItem("X-Authorization"),
    });
  }

  cancelVoidTransactions(transId){
    return HttpComponent({
      method: "POST",
      url: `/api/v1/payments/void`,
      body: {
        transactionId: transId,
        action: "decline",
      },
      token: localStorage.getItem("X-Authorization"),
    });
  }

  getBillByTransactionID(transId){
    return HttpComponent({
      method: "GET",
      url: `/api/v1/payments/by-transaction-id?transactionId=${transId}`,
      body: null,
      token: localStorage.getItem("X-Authorization"),
    });
  }

  getBillsByStatus(status, page, limit, dateFrom , dateTo, searchValue){
    return HttpComponent({
      method:'GET',
      url:`/api/fetchByStatus/?status=${status}&page=${page}&limit=${limit}&startDate=${dateFrom}&endDate=${dateTo}&searchValue=${searchValue}`,
      token:localStorage.getItem("X-Authorization")
    })
  }

  getBillsByDateAndStatus(page, pagesize, startDate, endDate, status){
    console.log(`Bills by Date ${page , pagesize , startDate , endDate , status } `)
    return HttpComponent({
      method:"POST",
      url:`/api/fetchBillsByDate/?status=${status}&page=${page}&limit=${pagesize}`,
      body:{
        dateList: [startDate, endDate],
      },
      token:localStorage.getItem("X-Authorization")
    })
  }

  getPos() {
   return HttpComponent({
      method:'GET',
      url:`/api/allTerminals/Active`,
      token:localStorage.getItem("X-Authorization")
    })
  }

  getPrinters(){
    return HttpComponent({
      method:'GET',
      url:``,
      token:localStorage.getItem('X-Authorization')
    })
  }

  sendToPrinter(pushTransactionId, serialNo){
    console.log("pushTransactionId" , pushTransactionId)
    return HttpComponent({
      method:'POST',
      url:`/api/sendPushy`,
      body:{
        pushTransactionId,
        serialNo
      },
      token:localStorage.getItem('X-Authorization')
    } 
    )
  }

  getProductCategories(page, limit, search){
    return HttpComponent({
      method:"GET",
      url:`/api/listByCategories?page=${page}&limit=${limit}&search=${search}&consumable=}`,
      token:localStorage.getItem("X-Authorization")
    })
  }

  getProducts(categoryName,page, pageSize, searchValue){

    console.log("page ===<><>", page)
    console.log("pageSize ===<><>", pageSize)
    let newPage = !page ? 1 : page 
    let newPageSize = !pageSize ? 10 : pageSize

    return HttpComponent({
      method:'GET',
      url:`/api/listByProducts?categoryId=${categoryName}&page=${page}&limit=${pageSize}&searchValue=${searchValue}`,
      token:localStorage.getItem("X-Authorization")
    })
  }

  searchCategories(categoryName){
    return HttpComponent({
      method:"GET",
      url:`/api/searchByCategories?search=${categoryName}`,
      token:localStorage.getItem("X-Authorization")
    })
  }

  searchProducts(queryProducts){
    return HttpComponent({
      method:"GET",
      url:`/api/searchProducts?c_search=&p_search=&page=1&limit=10&searchValue=${escape(queryProducts)}`,
      token:localStorage.getItem("X-Authorization")
    })
  }

  saveOrder(data){

    let { customerBalance,customerId, customerName, customerPaymentType, customerType, items, orderTable, parentOrderId , paymentMethod ,transamount , discountAmount, orderType } = data
    return HttpComponent({
      method:`POST`,
      url:`/api/savePushy`,
      body:{
        beneficiary: "N/A",
        businessId: localStorage.getItem("businessId"),
        cashier: localStorage.getItem("username"),
        customerBalance,
        customerId,
        customerName,
        customerPaymentType,
        customerType,
        items,
        mileage: "0",
        orderTable,
        orderType,
        parentOrderId,
        paymentMethod,
        pushTransactionId: Date.now(),
        serialNo: "",
        status: "unpaid",
        transamount: Number(transamount),
        transtime: new Date(Date.now()),
        userId: localStorage.getItem("userId"),
        // discountAmount:0
      },
      token:localStorage.getItem('X-Authorization')
    })
  }

  processStartOrderPayment(data){
    const  { items, customerFirstName, customerMiddleName, customerPhone , discountAmount,  startOrderIds, transamount, transactionType } = data
   return HttpComponent({
      method:'POST',
      url:`/api/transactions`,
      body:{
        appBuildTime: "N/A",
        billRefNo: Date.now(),
        businessName: localStorage.getItem("businessName"),
        businessNo: localStorage.getItem("businessId"),
        businessShortCode: "0000",
        cashier: localStorage.getItem("username"),
        customerFirstName: customerFirstName ? customerFirstName:  'N/A',
        customerMiddleName: customerMiddleName ? customerMiddleName : "N/A",
        customerPhone: customerPhone ? customerPhone : "N/A",
        customerSecondName: "N/A",
        items: items ,
        paybillBalance: 0,
        discountAmount:  discountAmount ,
        paymentChanel: "Web",
        productCategory: "--",
        productName: "--",
        pushyTransactionId: startOrderIds ,
        receiptNumber: Date.now(),
        requestType: "N/A",
        serialNo: " N/A",
        transactionID: Date.now(),
        transactionType: transactionType,
        transamount: Number(transamount)  ,
        transtime: new Date(Date.now()),
        uploadTime: new Date(Date.now()),
        userId: localStorage.getItem("userId"),
        versionCode: "webv1",
        versionName: "webv1",
      },
      token:localStorage.getItem("X-Authorization"),
    })
  }

  getAllCustomers(searchValue){
    return HttpComponent({
      method:'GET',
      url:`/api/customers_list?searchValue=${searchValue}`,
      token:localStorage.getItem('X-Authorization')
    })
  }

  searchCustomer(searchValue){
    return HttpComponent({
      method:"GET",
      url:`/api/searchCustomer?state=Active&search=${searchValue}`,
      token:localStorage.getItem("X-Authorization")
    })
  }

  getAllTransactions(page = 1, limit = 10, dateFrom ,dateTo, searchValue){
    return HttpComponent({
      url:`/viewAllTransactions?page=${page}&limit=${limit}&startDate=${dateFrom}&endDate=${dateTo}&search=${searchValue}`,
      method:`GET`,
      token:localStorage.getItem("X-Authorization")
    })
  }
}
