import { Button, Grid, Typography,Breadcrumbs } from "@mui/material";
import { useState } from "react";
import PinInput from 'react-pin-input';

import { useNavigate } from "react-router-dom";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import HttpComponent from "../School/MakeRequest";
import { SuccessAlert } from "../snackBar Alerts/successAlert";
import { ErrorAlert } from "../snackBar Alerts/errorAlert";



export default function PaymentVerifyOtp(props) {
    
    console.log(props, 'props OtpVerficationFT');
    const previoursPin = props.pin
    const email = props.email
    const phone = props.phone
    console.log(previoursPin, 'previoursPin OtpVerficationFT');
    const [successShow, setSuccessShow] = useState({ state: false, message: "" });
    const [errorShow, setErrorShow] = useState({ state: false, message: "" });
    const [pin, setPin] = useState('')
    const [showPin, setShowPin] = useState(false)
    const navigate = useNavigate()
    const ResendOtp = () => {
        if (!previoursPin) {
            setErrorShow({ state: true, message: 'Your will have to provide a pin first' });
            return navigate('/payables/transcations?verifyPayable')
        }

        HttpComponent({
            method: 'POST',
            url: `/api/v1/zed/funds_transfer/otp`,
            token: localStorage.getItem("X-Authorization"),
            body: {
                "pin": previoursPin

            }

        }).then((data) => {
            if (data.status === 200) {
                console.log(data?.response?.message, 'data')
                let message = data?.response?.message
                // console.log(message, 'message')
                setSuccessShow({ state: true, message: message });


            }

            else {
                console.log(data?.response?.message, 'data')

                let message = data?.response?.message
                // console.log(message, 'message')
                setErrorShow({ state: true, message: message });
            }
        }).catch((error) => {
            console.error(error);
        })


    }

    const VerifyOtp = () => {
       
        HttpComponent({
            method: 'POST',
            url: `/api/v1/user/verify/otp`,
            token: localStorage.getItem("X-Authorization"),
            body:{
                "otp": pin
            }
        }).then((data) => {
            if (data.status === 200 || data.status === 201) {
                console.log(data?.response?.message, 'data')
                setSuccessShow({ state: true, message: data?.response?.message });
                let dataGive = {
                    state: true,
                }
                console.log(dataGive, 'dataGive')
                console.log(props, 'props')
                props.parentCallBackAuthChild(dataGive)
                

            }

            else {
                console.log(data?.response?.message, 'data')

                let message = data?.response?.message
                // console.log(message, 'message')
                setErrorShow({ state: true, message: message });
                setPin('')
            }
        }).catch((error) => {
            console.error(error);
        })

    }
    if (pin.length === 4) {
            VerifyOtp()
    }

    return (
        <>
        
            <Grid container direction={'column'} mt={10}>
                <Grid item display={'flex'} direction={'column'} justifyContent={'center'} textAlign={'center'}>
                    <Typography style={{ fontSize: '25px', fontWeight: 600, color: '#032541' }}>We've sent a 4-digit One-Time Pin to</Typography>
                    <Typography style={{ fontSize: '16px', marginTop: "10px", color: '#707070' }}>Your email  <span style={{ color: "black", fontWeight: "900" }}> {email}</span> & WhatsApp <span style={{ color: "black", fontWeight: "900" }}>{phone}</span>  </Typography>
                </Grid>
                <Grid item mt={1} display={'flex'} justifyContent={'center'}>
                    <PinInput
                        length={4}
                        initialValue=""

                        secret={true}
                        secretDelay={100}
                        onChange={(pin) => setPin(pin)}
                        // type="numeric"
                        type={showPin ? 'text' : 'password'}
                        inputMode="numeric"
                        style={{ padding: '10px' }}
                        inputStyle={{ borderColor: '#bec5d1' , width:"92px", height:"108px", fontSize:"50px"}}
                        inputFocusStyle={{ borderColor: 'blue' }}
                        //onComplete={(value, index) => { }}
                        autoSelect={true}
                        regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                    />
                </Grid>
                <Grid item mt={2} display={'flex'} direction={'column'} justifyContent={'center'} textAlign={'center'}>
                    <span style={{ fontSize: '16px', cursor: 'pointer', fontWeight: 600, color: '#9AA9B2' }}>Didn't get the code ?  <span style={{ cursor: "pointer", color: "#032541" }} onClick={() => ResendOtp()} >Resend</span> </span>
                </Grid>
               
                <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
                <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            </Grid>
        </>

    )
}