// import React, { useEffect, useState } from "react";
// import Autocomplete from "@mui/material/Autocomplete";
// import TextField from "@mui/material/TextField";
// import { useDispatch, useSelector } from "react-redux";
// import { handleBranchId } from "../../../../features/selectedSlice";


// // const options = ["Option 1", "Option 2", "Option 3", "Option 4"];

// function SearchableSelect2() {
//   const dispatch = useDispatch()
//   const [options, setOptions] = useState([]);
//   const [selectedOption, setSelectedOption] = useState(null);

//   let branches = useSelector((state) => state.terminalSelect.branches);

//   let stringArr = [];

//   const createArray2 = () => {
//     if (branches) {
//       const uniqueStrings = new Set(); // Use a Set to store unique strings
//       branches?.forEach((obj) => {
//         // let string = `${obj.branchName} - ${obj.businessName} - ${obj.branchId}`;
//         let string = `${obj.branchName}`;
//         uniqueStrings.add(string); // Add each string to the Set
//       });
//       setOptions(Array.from(uniqueStrings)); // Convert Set back to an array and set options
//     }
//   };

//   useEffect(() => {
//     if (branches.length > 0) createArray2();
//   }, [branches]);

//   useEffect(() => {
//     if (selectedOption) {
//         const regex = /([a-fA-F0-9]{24})$/;
//         const match = selectedOption.match(regex);
//         if (match) {
//             const extractedString = match[1];
//             console.log(`extractedString : ${extractedString}`);
//             dispatch(handleBranchId(extractedString))
//         }
//     }
//   }, [selectedOption]);

//   return (
//     <Autocomplete
//       label="Business Id"
//       variant="outlined"
//       fullWidth
//       size="small"
//       value={selectedOption}
//       onChange={(event, newValue) => {
//         setSelectedOption(newValue);
//       }}
//       options={options}
//       renderInput={(params) => (
//         <TextField {...params} label="branch id" variant="outlined" />
//       )}
//     />
//   );
// }

// export default SearchableSelect2;

import React, { useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useDispatch, useSelector } from "react-redux";
import { handleBranchId } from "../../../../features/selectedSlice";

function SearchableSelect2() {
  const dispatch = useDispatch();
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);

  let branches = useSelector((state) => state.terminalSelect.branches);

  const createArray2 = () => {
    if (branches) {
      const uniqueOptions = branches.map((obj) => ({
        branchName: obj.branchName,
        branchId: obj.branchId,
        businessName: obj.businessName, // If you need it for any reason
      }));
      setOptions(uniqueOptions);
    }
  };

  useEffect(() => {
    if (branches.length > 0) createArray2();
  }, [branches]);

  useEffect(() => {
    if (selectedOption) {
      // Dispatch the branchId directly from the selected option
      dispatch(handleBranchId(selectedOption.branchId));
    }
  }, [selectedOption]);

  return (
    <Autocomplete
      value={selectedOption}
      onChange={(event, newValue) => {
        setSelectedOption(newValue);
      }}
      options={options}
      getOptionLabel={(option) => option.branchName} // Display only branchName
      renderInput={(params) => (
        <TextField {...params} label="Select Branch" variant="outlined" />
      )}
      // Add this to ensure the selected option is valid
      isOptionEqualToValue={(option, value) => option.branchId === value?.branchId}
    />
  );
}

export default SearchableSelect2;