import { Box, Button, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

export default function ViewInvoiceFt() {
    const navigate = useNavigate()
    const handleNavigateToInvoice = () => {
        navigate(`/`)
    }
    return (
        <Grid container width={'100%'} display={'flex'} flexDirection={'column'} py={2}>
            <Grid item width={'50%'} sx={{ border: '1px solid #bec5d1', paddingX: 2 }}>
                <Box component={'div'} display={'flex'} flexDirection={'column'} gap={1}>
                    <Typography variant="h6" sx={{ fontWeight: 700, color: '#000000', py: 1 }}>Voila! You have completed all the steps.</Typography>
                    <Box component={'div'}>
                        <Typography variant="p" sx={{ color: '#707070', fontSize: '14px' }}>You can add more debit accounts from the <Link style={{ fontWeight: "700", color: '#000000', textDecoration: 'none' }} to={'/paymentsettingup'}>Payment Setup</Link> and approvers from the <Link style={{ fontWeight: "700", color: '#000000', textDecoration: 'none' }} to={'/payables/approvers'}>Payables</Link> menu.</Typography>
                    </Box>
                    <Typography sx={{ color: '#707070', fontSize: '14px' }} variant="p">You can now proceed to pay for the invoice.</Typography>
                </Box>
                <Box component={'div'} display={'flex'} justifyContent={'flex-end'} alignItems={'center'} py={2}>
                    <Button style={{ backgroundColor: '#032541', color: '#fff', width: "140px", textTransform: 'inherit' }}>View Invoice</Button>
                </Box>
            </Grid>
        </Grid>
    )
}