import { Box, Breadcrumbs, Button, Dialog, DialogActions, DialogContent, Grid, Slide, Stack, Tab, Typography } from "@mui/material"
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { forwardRef, useEffect, useState } from "react";
import ReconciliationPage from "./reconcileTransactions";
import { NoRowsOverlay } from "../../No Rows/noRowsOverlay";
import { DataGrid } from "@mui/x-data-grid";
import { SuccessAlert } from "../../snackBar Alerts/successAlert";
import { ErrorAlert } from "../../snackBar Alerts/errorAlert";
import ApprovImg from "../../../images/Appro.svg"
import WarningImg from "../../../images/warning.png"
import HttpComponent from "../MakeRequest";
import { currencyconverter } from "../../../common/currencyconverter";
import DateFormatter from "../../../utils/dateFormatter";



const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Dashboard</Typography>,
    <Typography key={2} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>ZPM Reconcilliation Report</Typography>
]

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

// custom tab styling
const AntTabs = styled(TabList)({
    borderBottom: "0px solid #e8e8e8",
    "& .MuiTabs-indicator": { backgroundColor: "" },
});

const AntTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
        textTransform: "none",
        minWidth: 0,
        [theme.breakpoints.up("sm")]: { minWidth: 0 },
        fontWeight: theme.typography.fontWeightRegular,
        marginRight: theme.spacing(1),
        fontSize: "18px",
        background: "",
        borderRadius: "5px",
        marginLeft: "-10px",
        fontStretch: "normal",
        fontStyle: "normal",
        letterSpacing: "normal",
        textAlign: "left",
        color: "#6e7074",
        fontFamily: ["Poppins"].join(","),
        "&:hover": { color: "#032541", opacity: 1 },
        "&.Mui-selected": {
            color: "#dc3545",
            // backgroundColor: "#fef7f8",
            fontWeight: 600,
        },
        "&.Mui-focusVisible": { backgroundColor: "blue" },
    })
);


const ZedReconciliationReport = () => {
    const navigate = useNavigate();
    const [tabValue, setTabValue] = useState('All')
    const handleTabChange = (event, newTabValue) => setTabValue(newTabValue)
    const [dataGridPageSize, setPageSize] = useState(10);
    const [pageState, setPageState] = useState({ isLoading: false, data: [], total: 0, page: 1, pageSize: dataGridPageSize, })
    const queryparams = decodeURIComponent(window.location.search)
    const state = queryparams.slice(1)
    const [queryParamValue] = state.split("?")
    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })
    const [searchValue, setSearchValue] = useState('')
    const [refNo, setRefNo] = useState()
    const [customerName, setCustomerName] = useState()
    const RenderCell = (params) => {

        const [openDeclineModal, setDeclineModalRequest] = useState(false)
        const [openApproveModal, setApproveModalRequest] = useState(false)

        const handleOpenDeclineModal = () => {
            setRefNo(params?.row?.referenceNumber)
            setCustomerName(params?.row?.createdBy)
            setDeclineModalRequest(true)
        }
        const handleCloseDeclineModal = () => {
            setRefNo('')
            setCustomerName('')
            setDeclineModalRequest(false)
        }

        const handleOpenApproveModal = () => {
            setRefNo(params?.row?.referenceNumber)
            setCustomerName(params?.row?.createdBy)
            setApproveModalRequest(true)
        }
        const handleCloseApproveModal = () => {
            setRefNo('')
            setCustomerName('')
            setApproveModalRequest(false)
        }



        function handleDeactivateTransaction() {
            if (!refNo) {
                return setErrorShow({ state: true, message: 'Transaction Reference is missing' })
            }
            try {
                HttpComponent({
                    method: 'POST',
                    token: localStorage.getItem('X-Authorization'),
                    body: { transactionReference: refNo , status:'Decline' },
                    url: `/api/v1/reconcilerequest`
                }).then((data) => {
                    if (data?.status === 200) {
                        setSuccessShow({ message: data?.response?.message, state: true })
                        setTimeout(() => {
                            handleCloseDeclineModal();
                            fetchTransactionstoReconcile();
                        }, 1500)
                    } else {
                        setErrorShow({ state: true, message: data?.response?.message })
                    }
                })
            } catch (error) {
                setErrorShow({ state: true, message: error?.message })
            }
        }
        function handleApproveTransaction() {
            if (!refNo) {
                return setErrorShow({ state: true, message: 'Transaction Reference is missing' })
            }
            try {
                HttpComponent({
                    method: 'POST',
                    token: localStorage.getItem('X-Authorization'),
                    body: { transactionReference: refNo,status:'Approved'},
                    url: `/api/v1/reconcilerequest`
                }).then((data) => {
                    if (data?.status === 200) {
                        setSuccessShow({ message: data?.response?.message, state: true })
                        setTimeout(() => {
                            handleCloseDeclineModal();
                            fetchTransactionstoReconcile();
                        }, 1500)
                    } else {
                        setErrorShow({ state: true, message: data?.response?.message })
                    }
                })
            } catch (error) {
                setErrorShow({ state: true, message: error?.message })
            }
        }
        return (
            <Grid container>
                <Stack direction={'row'} alignItems={'center'} sx={{ cursor: 'pointer', marginLeft: 3 }} gap={2}>
                    <CheckIcon onClick={handleOpenApproveModal} sx={{ color: '#17ae7b', borderRadius: '50%', border: "1px solid #17ae7b", fontSize: '22px', paddingY: 0.5, paddingX: 0.5 }} />
                    <CloseIcon onClick={() => handleOpenDeclineModal} sx={{ color: '#dc3545', fontSize: '22px', paddingY: 0.5, paddingX: 0.5, borderRadius: '50%', border: "1px solid #dc3545" }} />
                </Stack>
                <Dialog
                    open={openDeclineModal}
                    TransitionComponent={Transition}
                    keepMounted
                    PaperProps={{
                        style: {
                            height: "300px",
                            width: "600px",
                            boxShadow: "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)"
                        },
                    }}
                    onClose={handleCloseDeclineModal}
                    BackdropProps={{ style: { backgroundColor: "rgba(0, 0, 0, 0.5)", backdropFilter: "blur(10px)", } }}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogContent sx={{ display: "flex", justifyContent: 'space-evenly', alignContent: "center", alignItems: "center", gap: 4 }}>
                        <img src={WarningImg} alt='decline' />
                        <Box component={'div'} style={{}}>
                            <Typography variant="h6" paddingBottom={1}>Decline Reconciliation Request</Typography>
                            <span><Typography fontWeight={700}>{customerName}</Typography> reconciliation request will be declined . This action cannot be undone </span><br></br>
                            <span>Do you wish to continue</span>
                        </Box>
                    </DialogContent>
                    <DialogActions sx={{ display: 'flex', justifyContent: 'center', paddingBottom: 4 }}>
                        <Button onClick={handleCloseDeclineModal} style={{ border: "1px solid #032541", color: "#032541", backgroundColor: "transparent", textTransform: 'inherit', cursor: 'pointer', width: "100px", marginRight: '10px' }}>Cancel</Button>
                        <Button onClick={() => handleDeactivateTransaction()} style={{ backgroundColor: "#dc3545", border: 'none', color: "#fff", textTransform: 'inherit', cursor: 'pointer', width: "100px", paddingLeft: "10px" }}>Decline</Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={openApproveModal}
                    TransitionComponent={Transition}
                    keepMounted
                    PaperProps={{
                        style: {
                            height: "300px",
                            width: "600px",
                            boxShadow: "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)"
                        },
                    }}
                    onClose={handleCloseApproveModal}
                    BackdropProps={{ style: { backgroundColor: "rgba(0, 0, 0, 0.5)", backdropFilter: "blur(10px)", } }}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogContent sx={{ display: "flex", justifyContent: 'space-evenly', alignContent: "center", alignItems: "center", gap: 10 }}>
                        <img src={ApprovImg} alt='decline' />
                        <Box component={'div'} sx={{}}>
                            <Typography variant="h6" paddingBottom={1}>Approve Reconciliation Request</Typography>
                            <span> <Typography fontWeight={700}>{customerName}</Typography>reconciliation request will be approved . This action cannot be undone </span><br></br>
                            <span>Do you wish to continue</span>
                        </Box>
                    </DialogContent>
                    <DialogActions sx={{ display: 'flex', justifyContent: 'center', paddingBottom: 4 }}>
                        <Button onClick={handleCloseApproveModal} style={{ border: "1px solid #032541", color: "#032541", backgroundColor: "transparent", textTransform: 'inherit', cursor: 'pointer', width: "100px", marginRight: '10px' }}>Cancel</Button>
                        <Button onClick={() => handleApproveTransaction()} style={{ backgroundColor: "#dc3545", border: 'none', color: "#fff", textTransform: 'inherit', cursor: 'pointer', width: "100px", paddingLeft: "10px" }}>Approve</Button>
                    </DialogActions>
                </Dialog>
            </Grid>
        )
    }

    const ReconciliationColumns = [
        { headerName: "referenceNumber", field: 'Transaction ID', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Transaction ID</strong>) }, renderCell: (params) => { return (<Typography fontWeight={700}>{params?.row?.referenceNumber}</Typography>) } },
        { headerName: "itemNumber", field: 'admissionNumber', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Student ID</strong>) }, renderCell: (params) => { return (<><span style={{ marginLeft: "10px" }}>{params?.row?.itemNumber}</span></>) } },
        { headerName: "studentName", field: 'Student Name', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Student Name</strong>) }, renderCell: (params) => { return (<Typography>{params?.row?.firstName + ' ' + params?.row?.lastName}</Typography>) } },
        { headerName: "amount", field: 'Amount Credited', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Amount Credited</strong>) }, renderCell: (params) => { return (<Typography>{currencyconverter(params?.row?.amount)}</Typography>) } },
        { headerName: "raisedBy", field: 'Raised By', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Raised by</strong>) }, renderCell: (params) => { return (<Typography>{params?.row?.createdBy}</Typography>) } },
        { headerName: "date", field: 'date', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Date</strong>) }, renderCell: (params) => { return (<Typography>{DateFormatter(params?.row?.createdAt)}</Typography>) } }

    ]

    const status = { headerName: "status", field: 'Status', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Status</strong>) }, renderCell: (params) => { return (<Typography sx={{ color: color(params?.row?.status), fontWeight: 600 }}>{params?.row?.status}</Typography>) } }
    const pendingAction = { field: 'Action', headerName: 'Action', flex: 1, renderCell: RenderCell }
    const ApprovedBy = { headerName: "approvedBy", field: 'Approved By', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Approved By</strong>) }, renderCell: (params) => { return (<Typography>{params?.row?.approvedBy}</Typography>) } }
    const declineddBy = { headerName: "declinedBy", field: 'Declined By', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Declined By</strong>) }, renderCell: (params) => { return (<Typography>{params?.row?.declinedBy}</Typography>) } }

    const color = (status) => {
        switch (status) {
            case 'Pending': {
                return "#f87f05"
            }
            case 'Approved': {
                return "#17ae7b"
            }
            case 'Decline': {
                return "#dc3545"
            }
            default:
                "#000000"
        }
    }


    switch (tabValue) {
        case 'All':
            ReconciliationColumns.splice(7, 0, status);
            break;
        case 'Pending':
            ReconciliationColumns.splice(6, 0, pendingAction);
            break;
        case 'Approved':
            ReconciliationColumns.splice(5, 0, ApprovedBy);
            break;
        case 'Decline':
            ReconciliationColumns.splice(5, 0, declineddBy);
            break;
        default:
            break;
    }

    function fetchTransactionstoReconcile() {
        setPageState((old) => ({ ...old, isLoading: true }));
        try {
            HttpComponent({
                method: 'GET',
                url: `/api/v1/listreconcilliationRequest?page=${pageState.page}&limit=${dataGridPageSize}&status=${tabValue}&searchValue=${searchValue}`,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data?.status === 200) {
                    setPageState({ ...pageState, isLoading: false, total: data?.response?.count, data: data.response.data })
                }else{
                    setPageState({...pageState , isLoading:false,total:0,data:[]})

                }
            })
        } catch (error) {
            setPageState({...pageState , isLoading:false,total:0,data:[]})

        }
    }
    useEffect(() => {
        fetchTransactionstoReconcile()
    }, [pageState.page, dataGridPageSize, tabValue, searchValue, queryParamValue])


    return (
        <Grid container width={'100%'} flexDirection={'column'} flex={1}>
            <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            {queryParamValue === 'Reconcile' ? <ReconciliationPage /> : <>
                <Grid item display={'flex'} alignContent={'center'} alignItems={'center'}>
                    <ArrowBackIosIcon style={{ cursor: "pointer" }} onClick={() => navigate(-1)} />
                    <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }}>ZPM Reconcilliation Report</Typography>
                </Grid>
                <Grid item mt={2} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                    <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                        {breadcrumbs}
                    </Breadcrumbs>
                    <Button onClick={() => navigate(`/school/zpmreconciliationreports?Reconcile`)} style={{ width: '140px', background: '#032541', color: '#fff', textTransform: 'inherit' }}>Reconcile</Button>
                </Grid>
                <Grid item width={'100%'} mt={2}>
                    <TabContext value={tabValue}>
                        <AntTabs onChange={handleTabChange} textColor="primary" TabIndicatorProps={{ hidden: true }}>
                            <AntTab label="All" value="All" />
                            <AntTab label="Pending" value="Pending" style={{ marginLeft: "20px" }} />
                            <AntTab label="Approved" value="Approved" style={{ marginLeft: "20px" }} />
                            <AntTab label="Decline" value="Decline" style={{ marginLeft: "20px" }} />
                        </AntTabs>
                        <TabPanel value="All">
                            <DataGrid
                                components={{ NoRowsOverlay: NoRowsOverlay }}
                                sx={{ height: '400px', width: 'auto', fontFamily: 'Poppins', fontSize: "14px", color: '#272d3d', boxShadow: 0, border: 0 }}
                                rowsPerPageOptions={[1, 5, 10, 20, 50, 100]}
                                rowCount={pageState?.total}
                                loading={pageState?.isLoading}
                                pagination
                                page={pageState?.page - 1}
                                pageSize={dataGridPageSize}
                                paginationMode="server"
                                onPageChange={(newPage) => { setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize })); }}
                                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                columns={ReconciliationColumns}
                                rows={pageState?.data}
                                getRowId={row => row?.referenceNumber} />
                        </TabPanel>
                        <TabPanel value="Pending">
                            <DataGrid
                                components={{ NoRowsOverlay: NoRowsOverlay }}
                                sx={{ height: '400px', width: 'auto', fontFamily: 'Poppins', fontSize: "14px", color: '#272d3d', boxShadow: 0, border: 0 }}
                                rowsPerPageOptions={[1, 5, 10, 20, 50, 100]}
                                rowCount={pageState?.total}
                                loading={pageState?.isLoading}
                                pagination
                                page={pageState?.page - 1}
                                pageSize={dataGridPageSize}
                                paginationMode="server"
                                onPageChange={(newPage) => { setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize })); }}
                                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                columns={ReconciliationColumns}
                                rows={pageState?.data}
                                getRowId={row => row?.referenceNumber} />
                        </TabPanel>
                        <TabPanel value="Approved">
                            <DataGrid
                                components={{ NoRowsOverlay: NoRowsOverlay }}
                                sx={{ height: '400px', width: 'auto', fontFamily: 'Poppins', fontSize: "14px", color: '#272d3d', boxShadow: 0, border: 0 }}
                                rowsPerPageOptions={[1, 5, 10, 20, 50, 100]}
                                rowCount={pageState?.total}
                                loading={pageState?.isLoading}
                                pagination
                                page={pageState?.page - 1}
                                pageSize={dataGridPageSize}
                                paginationMode="server"
                                onPageChange={(newPage) => { setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize })); }}
                                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                columns={ReconciliationColumns}
                                rows={pageState?.data}
                                getRowId={row => row?.referenceNumber} />
                        </TabPanel>
                        <TabPanel value="Decline">
                            <DataGrid
                                components={{ NoRowsOverlay: NoRowsOverlay }}
                                sx={{ height: '400px', width: 'auto', fontFamily: 'Poppins', fontSize: "14px", color: '#272d3d', boxShadow: 0, border: 0 }}
                                rowsPerPageOptions={[1, 5, 10, 20, 50, 100]}
                                rowCount={pageState?.total}
                                loading={pageState?.isLoading}
                                pagination
                                page={pageState?.page - 1}
                                pageSize={dataGridPageSize}
                                paginationMode="server"
                                onPageChange={(newPage) => { setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize })); }}
                                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                columns={ReconciliationColumns}
                                rows={pageState?.data}
                                getRowId={row => row?.referenceNumber} />
                        </TabPanel>
                    </TabContext>
                </Grid>
            </>}

        </Grid>
    )
}

export default ZedReconciliationReport