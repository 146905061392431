import { Box, Breadcrumbs, Button, Grid, Menu, MenuItem, Modal, Tab, TextField, Typography } from "@mui/material";
import React, { useState, useEffect } from 'react';
import CustomSearchInput from "./CustomSearchInput";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { alpha, styled } from "@mui/material/styles";
import { useNavigate, useParams } from "react-router-dom";
import HttpComponent from "./MakeRequest";
import { useSelector } from "react-redux";
import { ErrorAlert } from "../snackBar Alerts/errorAlert";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import CustomTable from "./CustomTable";
import ExportMenu from "./ExportMenu";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import WarningImg from "../../images/warning.png"
import DoneIcon from '@mui/icons-material/Done';
import { SuccessAlert } from "../snackBar Alerts/successAlert";
import { NoRowsOverlay } from "../No Rows/noRowsOverlay";
import { DataGrid } from "@mui/x-data-grid";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import jsondecode from "jwt-decode";

const AntTabs = styled(TabList)({ borderBottom: "0px solid #e8e8e8", "& .MuiTabs-indicator": { backgroundColor: "#dc3545" } });

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: "none", minWidth: 0, [theme.breakpoints.up("sm")]: { minWidth: 0 },
    fontWeight: theme.typography.fontWeightRegular, marginRight: theme.spacing(1),
    fontSize: "18px", fontStretch: "normal", fontStyle: "normal", lineHeight: "2.75",
    letterSpacing: "normal", textAlign: "left", color: "#6e7074", fontFamily: ["Poppins"].join(","),
    "&:hover": { color: "#032541", opacity: 1 }, "&.Mui-selected": { color: "#dc3545", fontWeight: 600 },
    "&.Mui-focusVisible": { backgroundColor: "blue" },
}));

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{
            vertical: "top",
            horizontal: "right",
            frontFamily: "Poppins",
        }}
        {...props}
    />
))(({ theme }) => ({ "& .MuiPaper-root": { borderRadius: 6, marginTop: theme.spacing(1), minWidth: 18, frontFamily: "Poppins", color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300], boxShadow: "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px", "& .MuiMenu-list": { padding: "4px 0" }, "& .MuiMenuItem-root": { "& .MuiSvgIcon-root": { frontFamily: "Poppins", fontSize: 18, color: theme.palette.text.secondary, marginRight: theme.spacing(1.5), }, "&:active": { backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity), }, }, }, }));

const style = { borderRadius: "10px", backgroundColor: "#fff", display: "flex", position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: "600px", height: "298px", p: 4, };



const InvoicesInBatch = () => {
    const { batchId } = useParams();
    const navigate = useNavigate();
    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })
    const [tableData, setTableData] = useState([]);
    const [dataToShow, setDataToShow] = useState([]);
    const [titleData, setTitleData] = useState({})
    const userGroup = localStorage.getItem('group')
    const userId = localStorage.getItem('userId')
    const [selectedUserId, setSelectedUserId] = useState(null)
    const [gridLoading, setGridLoading] = useState(false)

    let localCurrency = localStorage.getItem('localCurrency')
    const localToken = localStorage.getItem("X-Authorization");
    const decoded = jsondecode(localToken)
    if (!localCurrency) {
        localCurrency = decoded?.localCurrency
    }
    if (!localCurrency || localCurrency === 'undefined' || localCurrency === undefined) {
        localCurrency = 'KES'
    }

  // Number Format for Currency
  const numberFormat = (value) => new Intl.NumberFormat("en-US", { style: "currency", currency: localCurrency }).format(value);



    const approve_delete_invoice = userGroup ? 'Director' || 'Accountant' & userId !== selectedUserId : null

    //breadcrumbs

    const breadcrumbs = [
        <Typography key="X" sx={{ color: "#707070", fontSize: "0.875rem" }}>
            Dashboard
        </Typography>,
        <Typography onClick={() => navigate("/school/invoices/batch")} key={"Invoices"} sx={{ color: "#707070", fontSize: "0.875rem", cursor: "pointer" }}>
            Invoices
        </Typography>,
        <Typography key={"InvoiceB"} sx={{ color: "#dc3545", fontSize: "0.875rem" }}>
            Invoice Batch
        </Typography>
    ];


    //modals

    const columns = [
        {
            headerName: "Invoice No", field: "invoiceNumber", flex: 1,
            renderCell: (params) => (
                <Typography onClick={() => navigate(`/school/invoice/${params.row.invoiceNumber}`)} sx={{ color: "#032541", textDecoration: "underline" }}>
                    {params.row.invoiceNumber}
                </Typography>
            ),
        },
        { headerName: "Admission No", field: "studentNumber", flex: 1, },
        { headerName: "Student Name", field: "studentName", flex: 1 },
        { headerName: "Grade", field: "grade", flex: 1 },
        { headerName: "Term", field: "term", flex: 1 },
        {
            headerName: "Invoice Amount", field: "invoiceAmount", flex: 1,
            renderCell: (params) => (
                <Typography>
                    {numberFormat(params.row.invoiceAmount)}
                </Typography>
            ),
        },
        {
            headerName: "Discount Amount", field: "invoiceDiscountAmount", flex: 1,
            renderCell: (params) => (
                <Typography>
                     {numberFormat(params.row.invoiceDiscountAmount)}
                </Typography>
            ),
        },
        {
            headerName: "Amount Paid", field: "amountPaid", flex: 1,
            renderCell: (params) => (
                <Typography>
                     {numberFormat(params.row.amountPaid)}
                </Typography>
            ),
        },
        {
            headerName: "Amount Due", field: "amountDue", flex: 1,
            renderCell: (params) => (
                <Typography>
                    {numberFormat(params.row.amountDue)}
                </Typography>
            ),
        },
        // status
        {
            headerName: "Delivery Status", field: "deliveryStatus",
            renderCell: (params) => (
                <Typography>
                    {titleData.status == "PENDING" ? "PENDING" : titleData.status == "DECLINED" ? "N/A" : params.value}
                </Typography>
            ),
        },
    ];

    //cancelled invoices columns
    //cancelled invoices columns



    //invoice tabs
    const [tabValue, setTabValue] = useState('All')
    const handleTabChange = (event, newValue) => { setTabValue(newValue) };
    const [dataGridPageSize, setPageSize] = useState(10);
    const [pageState, setPageState] = useState({ isLoading: false, data: [], total: 0, page: 1, pageSize: dataGridPageSize, })
    const [searchValue, setSearchValue] = useState('');


    const [formData, setFormData] = useState(
        {
            search: "",
        }
    )

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const getRowId = (row) => {
        return row?.invoiceId || row?._id || row?.id || row?.gridId  || Math.floor(Math.random() * 2000000)
    }

    const fetchInvoicesInBatch = async() => {
        setPageState((old) => ({ ...old, isLoading: true }));
        await HttpComponent({
            method: 'GET',
            url: `/api/get_student_invoices_by_batch?batchId=${batchId}&page=${pageState.page}&limit=${dataGridPageSize}&searchValue=${searchValue}`,
            body: null,
            token: localStorage.getItem("X-Authorization"),
        }).then((data) => {
            console.log("here store yutiqr", data.response?.data);
            if (data?.status === 200) {
                setGridLoading(false)
                setTableData(data?.response?.data)
                setPageState({ ...pageState, isLoading: false, total: data?.response?.count, data: data.response.data })
                setTitleData({
                    count: data.response.count,
                    status: data.response.batchStatus,
                    batchNumber: data.response.batchNumber,
                    totalAmount: data.response.totalAmount,
                    totalAmountPaid: data.response.totalAmountPaid,
                    totalPendingAmount: data.response.totalPendingAmount
                })
              
            } else {
                setErrorShow({ state: true, message: data.response.message })
            }
        }).catch((error) => {
            console.error(error.message);
        })
    }

    useEffect(() => {
        fetchInvoicesInBatch()
    }, [batchId , pageState.page , dataGridPageSize,searchValue]);

    const setData = () => {
        let searchable = [...tableData]
        if (formData.search !== "") {
            const result = searchable.find(invoice =>
                invoice.invoiceNumber.toLowerCase().includes(formData.search) ||
                invoice.studentName.toLowerCase().includes(formData.search) ||
                invoice.studentNumber.toLowerCase().includes(formData.search)

            );
            console.log("search", result)
            setDataToShow([result]);
        } else {
            setDataToShow(tableData)
        }
    }

    // useEffect(() => {
    //     setData()
    //     console.log("search", formData.search)
    // }, [formData.search, tableData,searchValue]);


    const columnItems = [
        {
            textOne: "Batch No:",
            textTwo: titleData.batchNumber
        },
        {
            textOne: "Status:",
            textTwo: titleData.status
        },
        {
            textOne: "Invoices:",
            textTwo: titleData.count
        },
        {
            textOne: "Total Amount:",
            textTwo: ` ${numberFormat(titleData.totalAmount)}`
        },
        {
            textOne: "Amount Paid:",
            textTwo: `${numberFormat(titleData.totalAmountPaid)}`
        },
        {
            textOne: "Amount Due:",
            textTwo: `${numberFormat(titleData.totalPendingAmount)}`
        },
    ]

    const csvColumns = [
        { label: "Invoice No", key: "Invoice No" },
        { label: "Admission No", key: "Admission No" },
        { label: "Student Name", key: "Student Name" },
        { label: "Grade", key: "Grade" },
        { label: "Term", key: "Term" },
        { label: "Invoice Amount", key: "Invoice Amount" },
        { label: "Amount Paid", key: "Amount Paid" },
        { label: "Amount Due", key: "Amount Due" },
    ]

    const fileHeaders = [[
        "Invoice No",
        "Admission No",
        "Student Name",
        "Grade",
        "Term",
        "Invoice Amount",
        "Amount Paid",
        "Amount Due"
    ]]

    const fileData = dataToShow.map((data) => {
        let obj;
        obj = {
            "Invoice No": data?.invoiceNumber,
            "Admission No": data?.studentNumber,
            "Student Name": data?.studentName,
            "Grade": data?.grade,
            "Term": data?.term,
            "Invoice Amount": data?.invoiceAmount,
            "Amount Paid": data?.amountPaid,
            "Amount Due": data?.amountDue
        }
        return obj;
    })

    const fileDataPDF = dataToShow.map((data) => {
        let theData
        theData = [
            data?.invoiceNumber,
            data?.studentNumber,
            data?.studentName,
            data?.grade,
            data?.term,
            data?.invoiceAmount,
            data?.amountPaid,
            data?.amountDue
        ]
        return theData;
    })
    return (
        <Box component="div" sx={{}}>
            <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <ErrorAlert
                        vertical="top"
                        horizontal="right"
                        onClose={() => setErrorShow({ ...errorShow, state: false })}
                        open={errorShow.state}
                        message={errorShow.message} />
            <Box sx={{ display: "flex", margin: 1, justifyContent: "space-between" }}>
                <Box component="div" sx={{ display: "flex", flexDirection: "row", alignItems:'center', gap:2, justifyContent: "center" }}>
                    <ArrowBackIosNewIcon style={{ cursor: "pointer" }} onClick={() => navigate(-1)} />
                    <Typography sx={{ color: "#032541", fontSize: "1.563rem", fontWeight: 600 }}>
                        Invoice Batch
                    </Typography>
                </Box>
            </Box>
            <Box component="div" sx={{ margin: 1, marginBottom: 2, display: "flex", justifyContent: "space-between" }}>
                <Breadcrumbs
                    separator={<FiberManualRecordIcon sx={{ fontSize: "0.625rem", color: "#e1e5e8" }} />}
                    aria-label="breadcrumb"
                >
                    {breadcrumbs}
                </Breadcrumbs>
            </Box>

            <Box component="div" sx={{ display: "flex", backgroundColor: "#fafafa", justifyContent: "space-between", marginX: 5, borderRadius: "10px" }}>
                {columnItems.map((column, index) =>
                    <Box key={index} component="div" sx={{ display: "flex", flexDirection: "column", margin: 2 }}>
                        <Typography sx={{ fontColor: "#032541" }}>
                            {column.textOne}
                        </Typography>
                        <Typography sx={{ fontWeight: 700, fontColor: "#032541" }}>
                            {column.textTwo}
                        </Typography>
                    </Box>
                )}
            </Box>
            <Box component="div" sx={{ display: "flex", flexDirection: "column", margin: 1 }}>
                <Box component="div" sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Box component="div" sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                        {/* <CustomSearchInput name={"search"} value={formData.search} onChange={handleInputChange} placeholder="Search:" /> */}
                        <CustomSearchInput value={searchValue} onChange={(e) => setSearchValue(e.target.value)} placeholder={'search'} name={'searchValue'} />
                    </Box>
                    <ExportMenu
                        csvColumns={csvColumns}
                        fileData={fileData}
                        fileHeaders={fileHeaders}
                        fileDataPDF={fileDataPDF}
                        title={`Invoices For batch ${titleData.batchNumber}`}
                        fileName={`Invoices-${titleData.batchNumber}`}
                    />
                </Box>
            </Box>
            <Grid item>
                <DataGrid
                    components={{ NoRowsOverlay: NoRowsOverlay }}
                    sx={{ height: '400px', width: 'auto', fontFamily: 'Poppins', fontSize: "10px", color: '#272d3d', boxShadow: 0, border: 0 }}
                    rowsPerPageOptions={[1, 5, 10, 20, 50, 100]}
                    rowCount={pageState.total}
                    loading={pageState.isLoading}
                    pagination
                    page={pageState.page - 1}
                    pageSize={dataGridPageSize}
                    paginationMode="server"
                    onPageChange={(newPage) => { setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize })); }}
                    onPageSizeChange={(newPageSize) => {
                        setPageSize(newPageSize);
                        setPageState((old) => ({ ...old, page: 1, pageSize: newPageSize }))
                    }}
                    columns={columns}
                    rows={tableData}
                    getRowId={getRowId} />
            </Grid>
            {/* <CustomTable loading={gridLoading} data={dataToShow} columns={columns} rowsPerPage={5} /> */}
        </Box>
    );
};

export default InvoicesInBatch;

