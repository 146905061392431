import { Breadcrumbs, Grid, Typography } from "@mui/material";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import CustomSearchInput from "../../../School/CustomSearchInput";
import { useEffect, useState } from "react";
import { CustomizedDate } from "../../../customerAccounts/customerComponents/customDate";
import CustomSelectField from "../../../School/CustomSelectField";
import ExportMenu from "../../../School/ExportMenu";
import { DataGrid } from "@mui/x-data-grid";
import { NoRowsOverlay } from "../../../No Rows/noRowsOverlay";
import HttpComponent from "../../../School/MakeRequest";
import DateFormatter from "../../../../utils/dateFormatter";




const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Dashboard</Typography>,
    <Typography key={4} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Pocket Money Bio Registration Report</Typography>
]



export default function BioRegestrationReports() {

    const [searchValue, setSearchValue] = useState('')
    const [studentCount, setStudentCount] = useState(0)

    const [days, setDays] = useState([
        { value: "Default", label: "Default" },
        { value: "Today", label: "Today" },
        { value: "Yesterday", label: "Yesterday" },
        { value: "Last 7 Days", label: "Last 7 Days" },
        { value: "This Month", label: "This Month" },
        { value: "Last Month", label: "Last Month" },
        { value: "Custom Range", label: "Custom Range" },
    ]);

    const [handleDaysChange, setHandleDaysChange] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    const [dataGridPageSize, setPageSize] = useState(10);
    const [pageState, setPageState] = useState({ isLoading: false, data: [], total: 0, page: 1, pageSize: dataGridPageSize, })


    const [dateFrom, setDateFrom] = useState("");
    const [dateTo, setDateTo] = useState("");


    useEffect(() => {
        switch (handleDaysChange) {
            case "Today":
                const today = new Date().toLocaleDateString();
                setStartDate(today);
                setEndDate(today);
                break;
            case "Yesterday":
                let date = new Date();
                const yeseterday = new Date(date.setDate(date.getDate() - 1)).toLocaleDateString();
                // console.log(yeseterday);
                setStartDate(yeseterday);
                setEndDate(yeseterday);
                break;
            case "Last 7 days":
                function Last7Days() {
                    var result = [];
                    for (var i = 0; i < 7; i++) {
                        var d = new Date();
                        result.push(d.setDate(d.getDate() - i));
                    }
                    const lastSevenFirst = new Date(result[0]).toLocaleDateString();
                    const lastSevenLast = new Date(result[result.length - 1]).toLocaleDateString();

                    setStartDate(lastSevenLast);
                    setEndDate(lastSevenFirst);
                }
                Last7Days();
                break;
            case "This Month":
                const date1 = new Date();
                const firstDay = new Date(date1.getFullYear(), date1.getMonth(), 1).toLocaleDateString();
                const currentDay = new Date().toLocaleDateString();
                setStartDate(firstDay);
                setEndDate(currentDay);

                break;
            case "Last Month":
                const date2 = new Date();
                const firstDayLastMonth = new Date(date2.getFullYear(), date2.getMonth() - 1).toLocaleDateString();
                const lastDayLastMonth = new Date(date2.setDate(1)).toLocaleDateString();
                setStartDate(firstDayLastMonth);
                setEndDate(lastDayLastMonth);
                break;
            case "Default":
                setStartDate("");
                setEndDate("");
                break;
            case "Custom Range":
                setStartDate(dateFrom);
                setEndDate(dateTo);
                break;
            default:
                return;
        }
    }, [handleDaysChange, dateFrom, dateTo]);

    const BioColumns = [

        { headerName: "Student Name", field: 'studentName', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >Student Name</strong>) } },

        { headerName: "Reg No", field: 'regNo', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >Reg No</strong>) } },

        // { headerName: "Email", field: 'email', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >Eamil</strong>) } },

        // { headerName: 'PhoneNumber', field: 'phoneNumber', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >Phone No</strong>) } },

        { headerName: "Registered on", field: 'bioAddedOn', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >Registered On</strong>) }, renderCell: (params) => <Typography>{params?.row?.bioAddedOn === 'N/A' ? 'N/A' : DateFormatter(params?.row?.bioAddedOn)}</Typography> },

        { headerName: "status", field: 'status', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} > Status</strong>) }, renderCell: (params) => <Typography style={{ color: '#17ae7b' }}>{params?.row?.status}</Typography> },
    ]

    // fetch data

    function getAllTransactionsList() {
        setPageState((old) => ({ ...old, isLoading: true }));
        try {
            HttpComponent({
                method: "GET",
                url: `/api/v1/getStudentBioRegistartionDetails?page=${pageState.page}&limit=${dataGridPageSize}&searchValue=${searchValue}&startDate=${startDate}&endDate=${endDate}`,
                body: null,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                //console.log(data , 'data >>>>')
                if (data.status === 200) {
                    setPageState({ ...pageState, isLoading: false, total: data?.response?.count, data: data.response.data })
                    setStudentCount(data?.response?.count)
                }
            })
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getAllTransactionsList()
    }, [dataGridPageSize, pageState.page, startDate, searchValue, endDate])

    // exports

    const filedata = pageState?.data?.map((data) => {
        return {
            "Student Name": data?.studentName,
            "Reg No": data?.regNo,
            "Email": data?.email,
            "PhoneNumber": data?.phoneNumber,
            "Registered On": data?.bioAddedOn,
            "Status": data?.status,

        }
    })
    const fileHeaders = [["Student Name", "Reg No", "Email", "PhoneNumber", "Registered On", 'Status']]
    const csvColumns = [
        { label: "Student Name", key: "Student Name" },
        { label: "Reg No", key: "Reg" },
        { label: "Email", key: "Email" },
        { label: "PhoneNumber", key: "PhoneNumber", },
        { label: "Registered On", key: "Registered On" },
        { label: "status", key: "status", },
    ];
    const fileDataPDF = pageState?.data?.map((data) => [data?.studentName, data?.regNo, data?.email, data?.phoneNumber, data?.bioAddedOn, data?.status]);





    return (
        <Grid container direction={'column'}>
            <Grid item display={'flex'} alignItems={'center'}>
                <Typography style={{ color: "#032541", fontWeight: 'bold', fontSize: "20px", fontFamily: "Poppins" }}>Pocket Money Bio Registration Report</Typography>
            </Grid>
            <Grid item display={'flex'} direction={'column'} mt={2}>
                <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                    {breadcrumbs}
                </Breadcrumbs>
            </Grid>
            <Grid item display={'flex'} width={'100%'}>
                <Grid container mt={3} display={'flex'} width={'100%'} justifyContent={'space-between'} alignItems={'center'}>
                    <Grid item mt={1}>
                        <CustomSearchInput value={searchValue} onChange={(e) => setSearchValue(e.target.value)} placeholder={'search'} name={'searchValue'} />
                    </Grid>
                    <Grid item>
                        <Grid container display={'flex'} alignItems={'center'} spacing={1}>
                            <Grid item>
                                <Typography style={{ color: '#032541', fontWeight: 600 }}> {pageState?.data?.length} Students</Typography>
                            </Grid>
                            <Grid item>
                                {handleDaysChange !== "Custom Range" && <CustomSelectField onChange={(e) => setHandleDaysChange(e.target.value)} value={handleDaysChange} placeholder={"Custom Range"} options={days} name={"Transaction Type"} />}
                            </Grid>
                            <Grid item>
                                {handleDaysChange === "Custom Range" && <CustomizedDate setDateFrom={setDateFrom} setDateTo={setDateTo} style={{ display: "flex", alignItems: "center", width: '600px' }} />}
                            </Grid>
                            <Grid item>
                                <ExportMenu csvColumns={csvColumns} fileHeaders={fileHeaders} fileDataPDF={fileDataPDF} fileData={filedata} fileName={'Bio Transactions'} title={'Zed-Pocket Money Bio Transactions'} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item mt={1}>
                <DataGrid
                    components={{ NoRowsOverlay: NoRowsOverlay }}
                    sx={{ height: '550px', width: 'auto', fontFamily: 'Poppins', fontSize: "12px", color: '#272d3d', boxShadow: 0, border: 0 }}
                    rowsPerPageOptions={[1, 5, 10, 20, 50, 100]}
                    rowCount={pageState.total}
                    loading={pageState.isLoading}
                    pagination
                    page={pageState.page - 1}
                    pageSize={dataGridPageSize}
                    paginationMode="server"
                    onPageChange={(newPage) => { setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize })); }}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    columns={BioColumns}
                    rows={pageState?.data}
                    getRowId={row => row?._id} />
            </Grid>
        </Grid>
    )
}