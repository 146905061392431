import { Box, Breadcrumbs, Button, CircularProgress, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { SuccessAlert } from '../../snackBar Alerts/successAlert';
import { ErrorAlert } from '../../snackBar Alerts/errorAlert';
import { useState } from 'react';
import PinInput from 'react-pin-input';
import { useNavigate } from 'react-router-dom';
const baseUrl = process.env.REACT_APP_BASE_URL;

const breadcrumbs = [
    <Typography key={1} style={{ color: '#707070', fontSize: '14px', fontFamily: 'Poppins' }}>
        Dashboard
    </Typography>,
    <Typography key={3} style={{ color: '#707070', fontSize: '14px', fontFamily: 'Poppins' }}>
        Configuration
    </Typography>,
    <Typography key={4} style={{ color: '#dc3545', fontSize: '14px', fontFamily: 'Poppins' }}>
        2 FA
    </Typography>,
];


const ChooseOtpOption = (props) => {
    const [selectedMethod, setSelectedMethod] = useState('');
    const [isCardSelected, setIsCardSelected] = useState(false);
    const [successShow, setSuccessShow] = useState({ state: false, message: '' });
    const [errorShow, setErrorShow] = useState({ state: false, message: '' });
    const handleChange = (event) => {
        setSelectedMethod(event.target.value);
        setIsCardSelected(true);
    };
    const phone = localStorage.getItem('userPhone');
    const userEmail = localStorage.getItem('userEmail');

    async function generateOtp() {
        const response = await fetch(baseUrl + `/api/v1/user/2faotp`, {
            method: 'POST',
            headers: {
                'X-Authorization': localStorage.getItem('X-Authorization'),
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        });
        const data = await response.json();
        if (data?.status === 'SUCCESS') {
            setSuccessShow({ message: data?.message, state: true });
            setTimeout(()=>{
                props.setSendMedium(selectedMethod)
                props.handleNext()
            },1500)
        } else {
            setErrorShow({ message: data?.message, state: true });
        }
    }

    return (
        <Box component={'div'} width={'100%'}>
            <Grid container display={'flex'} flexDirection={'column'} gap={1}>
                <SuccessAlert
                    vertical="top"
                    horizontal="right"
                    onClose={() => setSuccessShow({ ...successShow, state: false })}
                    open={successShow.state}
                    message={successShow.message}
                />
                <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
                <Grid item width={'50%'} display={'flex'} flexDirection={'column'}>
                    <Typography variant="h6" color={'#032541'} fontWeight={'900'}>
                        Two Factor Authentication
                    </Typography>
                    <Typography variant="body2">Add an extra layer of security to your account with 2FA. How would you like to receive one-time passwords (OTP)?</Typography>
                </Grid>
                <Grid item width={'50%'}>
                    <FormControl sx={{ width: '100%' }}>
                        <RadioGroup aria-labelledby="demo-controlled-radio-buttons-group" name="controlled-radio-buttons-group" value={selectedMethod} onChange={handleChange}>
                            <Box
                                component={'div'}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    border: isCardSelected && selectedMethod === 'Phone' ? '1px solid #032541' : '1px solid #bec5d1',
                                    py: 2,
                                    px: 1,
                                    my: 1,
                                }}
                            >
                                <FormControlLabel value="Phone" control={<Radio />} />
                                <Box component={'div'} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                    <Typography variant="h6">Phone Number</Typography>
                                    <Typography variant="body2">
                                        {' '}
                                        {`Receive verification code via your registered mobile
                                        Number ${phone}`}
                                    </Typography>
                                </Box>
                            </Box>
                            <Box
                                component={'div'}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    border: isCardSelected && selectedMethod === 'Email' ? '1px solid #032541' : '1px solid #bec5d1',
                                    py: 2,
                                    px: 1,
                                    my: 1,
                                }}
                            >
                                <FormControlLabel value="Email" control={<Radio />} />
                                <Box component={'div'} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                    <Typography variant="h6">Email</Typography>
                                    <Typography variant="body2">
                                        {' '}
                                        {`Receive verification code via your registered email
                                        address ${userEmail}`}
                                    </Typography>
                                </Box>
                            </Box>
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item width={'50%'} display={'flex'} justifyContent={'flex-end'} gap={2}>
                    <Button style={{ textTransform: 'inherit', height: '45px', width: '125px', border: '1px solid #032541', color: '#032541' }}>Cancel</Button>
                    <Button
                        onClick={generateOtp}
                        disabled={!selectedMethod}
                        style={
                            !selectedMethod
                                ? { background: '#03254166', textTransform: 'inherit', height: '45px', width: '125px', color: '#fff' }
                                : { textTransform: 'inherit', height: '45px', width: '125px', background: '#032541', color: '#fff' }
                        }
                    >
                        Next
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
};

const VerifyOpt = ({sendMedium , is2factorEnabled}) => {
    const [showPin, setShowPin] = useState(false);
    const [pin, setPin] = useState('');
    const phone = localStorage.getItem('userPhone');
    const userEmail = localStorage.getItem('userEmail');
    const [isLoading , setIsLoading] = useState(false)
    const [successShow, setSuccessShow] = useState({ state: false, message: '' });
    const [errorShow, setErrorShow] = useState({ state: false, message: '' });
    const navigate = useNavigate()

    console.log(is2factorEnabled , 'is2factorEnabled')

    const handleEnable2FA = async() =>{
        setIsLoading(true)
        try{
            const response = await fetch(baseUrl + '/api/v1/user/enable2fa',{
                method:'POST',
                headers: {
                    'X-Authorization': localStorage.getItem('X-Authorization'),
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
                body:JSON.stringify({
                    otp: pin,
                    sendMedium: sendMedium,
                    is2factorEnabled: !is2factorEnabled
                })
            })
            const data = await response.json()
            if(data?.status === 'SUCCESS'){
                setIsLoading(false)
                setSuccessShow({state:true, message:data?.message})
                setTimeout(()=>{
                    window.location.reload()
                    navigate(-1)
                },1500)
            }else{
                setErrorShow({state:true, message:data?.message})
            }
        }catch(err){
            setErrorShow({message:err?.message , state:true})
        }

    }
    return (
        <Box component={'div'} width={'100%'} sx={{ justifyContent: 'center', flexDirection: 'column', display: 'flex' }}>
             <SuccessAlert
                vertical="top"
                horizontal="right"
                onClose={() => setSuccessShow({ ...successShow, state: false })}
                open={successShow.state}
                message={successShow.message}
            />
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            <Grid container direction={'column'} mt={10}>
                <Grid item display={'flex'} direction={'column'} justifyContent={'center'} textAlign={'center'}>
                    <Typography style={{ fontSize: '25px', fontWeight: 600, color: '#032541' }}>We've sent a 4-digit One-Time Pin to</Typography>
                    <Typography style={{ fontSize: '16px', marginTop: '10px', color: '#707070' }}>
                        Your email <span style={{ color: 'black', fontWeight: '900' }}> {userEmail}</span> & WhatsApp{' '}
                        <span style={{ color: 'black', fontWeight: '900' }}>{phone}</span>{' '}
                    </Typography>
                </Grid>
                <Grid item mt={1} display={'flex'} justifyContent={'center'}>
                    <PinInput
                        length={4}
                        initialValue=""
                        secret={true}
                        secretDelay={100}
                        onChange={(pin) => setPin(pin)}
                        type={showPin ? 'text' : 'password'}
                        inputMode="numeric"
                        style={{ padding: '10px' }}
                        inputStyle={{ borderColor: '#bec5d1', width: '92px', height: '108px', fontSize: '50px' }}
                        inputFocusStyle={{ borderColor: 'blue' }}
                        autoSelect={true}
                        regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                    />
                </Grid>
                <Grid item display={'flex'} justifyContent={'center'} alignItems={'center'} marginY={3}>
                    <Button
                        style={
                            pin.length < 4
                                ? { textTransform: 'inherit', background: '#03254166', color: '#fff', width: '170px', height: '45px' }
                                : { textTransform: 'inherit', width: '170px', height: '45px', background: '#032541', color: '#fff' }
                        }
                        disabled={pin.length < 4}
                        onClick={handleEnable2FA}
                    >
                        {' '}
                        {isLoading ? (
                            <Box component={'div'} sx={{ display: 'flex', alignItems: 'center' }} gap={4}>
                                Verifying OTP <CircularProgress size={24} sx={{ color: '#fff' }} />
                            </Box>
                        ) : (
                            'Verify'
                        )}
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
};

const Enable2FAPage = (props) => {
    const [successShow, setSuccessShow] = useState({ state: false, message: '' });
    const [errorShow, setErrorShow] = useState({ state: false, message: '' });
    const [activeStep, setActiveStep] = useState(1);
    const [sendMedium , setSendMedium] = useState('')
    const is2FAEnabled = props?.is2FAEnabled ? props?.is2FAEnabled:false
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    const renderFormContent = () => {
        switch (activeStep) {
            case 1:
                return <ChooseOtpOption handleNext={handleNext} sendMedium={sendMedium} setSendMedium={setSendMedium}/>;
            case 2:
                return (
                    <Grid item>
                        <VerifyOpt is2factorEnabled={is2FAEnabled} sendMedium={sendMedium} setSendMedium={setSendMedium}/>
                    </Grid>
                );
            default:
                return null;
        }
    };

    return (
        <Grid container direction={'column'} width={'100%'}>
            <SuccessAlert
                vertical="top"
                horizontal="right"
                onClose={() => setSuccessShow({ ...successShow, state: false })}
                open={successShow.state}
                message={successShow.message}
            />
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            <Grid item display={'flex'} alignItems={'center'}>
                <ArrowBackIosNewIcon style={{ color: '#707070' }} />
                <Typography variant="h4" style={{ color: '#032541', fontWeight: 'bold', fontSize: '20px', fontFamily: 'Poppins' }}>
                    Two Factor Authentication
                </Typography>
            </Grid>
            <Grid item display={'flex'} mt={2}>
                <Breadcrumbs
                    style={{ fontFamily: 'Poppins', fontSize: '14px' }}
                    aria-label="breadcrumb"
                    separator={<FiberManualRecordIcon style={{ fontSize: '0.625rem', fontFamily: 'Poppins', color: '#e1e5e8' }} />}
                >
                    {breadcrumbs}
                </Breadcrumbs>
            </Grid>
            <Grid item mt={2}>
                {renderFormContent()}
            </Grid>
        </Grid>
    );
};

export default Enable2FAPage;
