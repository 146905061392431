import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import {Breadcrumbs, Button, Checkbox, Grid, TextField, Typography} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link, useNavigate, useParams} from "react-router-dom";
import {handleAddCharge, handleDeleteCharge} from "../../features/tenantsDataSlice";
import HttpComponent from "./MakeRequest";
import {NoRowsOverlay} from "../No Rows/noRowsOverlay"
import {
    addService,
    addToCurrentService,
    clearServicesToAdd, removeFromCurrentServices,
    removeFromServicesToAdd
} from "../../features/servicesSlice";


let localCurrency = localStorage.getItem('localCurrency')

if (!localCurrency || localCurrency === 'undefined' || localCurrency === undefined ) {
    localCurrency = 'KES'
  }
  
const numberFormat = (value) => new Intl.NumberFormat("en-US", { style: "currency", currency: localCurrency}).format(value);


export default function EditSchoolInvoice(props) {
    const { invoiceNumber } = useParams();
    const [dataGridPageSize, setPageSize] = useState(10);
    const [textFieldStatus, setTextFieldStatus] = useState(true)
    const [invoiceData, setInvoiceData] = useState();
    const [pageState, setPageState] = useState({ isLoading: false, data: [], total: 0, page: 1, pageSize: dataGridPageSize})
    try {

        // navigation
        function handleCreateService () {
            props?.setFlagCreateService()
            console.log(`Props`, props)
        }

        //Redux Store
        const navigate = useNavigate()
        const dispatch = useDispatch()
        const service = useSelector((store) => store.services.currentServices)
        const newServices = useSelector((store) => store.services.servicesToAdd)
        // const {unitId, amount, itemNumber, billingDate, unitType} = useSelector((store) => store.tenantData.tenantHouseData)

        useEffect(() => {
           console.log("........newServices", newServices)
        }, [newServices]);

        useEffect(() => {
            dispatch(clearServicesToAdd())
        }, []);


        //Auth
        const { userId ,X_Authorization } = useSelector((store) => store.user);
        const [unitCharges, setUnitCharges] = useState([])

        const handleProductPriceChange = (value, product) => {
            let productId = product?._id
            const updatedData = pageState.data.map((row) =>
                row._id === productId ? { ...row, productPrice: value } : row
            );
            setPageState((prevState) => ({ ...prevState, data: updatedData }));

            const index = service.findIndex((service) => service.productName === product.productName);
            if(index != -1){
                dispatch(removeFromCurrentServices({index}))
            }

            const secondIndex = newServices.findIndex((service) => service.productName === product.productName);
            if(secondIndex != -1){
                dispatch(removeFromServicesToAdd({index:secondIndex}))
            }

            let data = {productId : product._id, productName: product.productName, quantity:1, variablePrice: true, amount: parseInt(value), priceStatus: product?.priceStatus}

            dispatch(addToCurrentService(data))
            dispatch(addService(data));
        };

        //Handle Checkbox Change
        const handleCheckboxChange = (event, product) => {
            console.log('function callllllll')
            if (
                product?.priceStatus !== "Variable Price Item" ||
                product?.priceStatus !== "Variable Price Service"
            ) {
                console.log("setting data here");
                setTextFieldStatus(false);
            }

            setTextFieldStatus(false);

            let data = { productId: product._id, productName: product.productName, quantity: 1 };
            
            if (event.target.checked) {
                dispatch(addToCurrentService(data));
                dispatch(addService(data));
            } else {
                const index = service.findIndex(
                    (serviceItem) => serviceItem.productName === product.productName
                );
                if (index !== -1) {
                    dispatch(removeFromCurrentServices({ index }));
                }
    
                const secondIndex = newServices.findIndex(
                    (serviceItem) => serviceItem.productName === product.productName
                );
                if (secondIndex !== -1) {
                    dispatch(removeFromServicesToAdd({ index: secondIndex }));
                }
            }
        };

        const getInvoiceDetails = async () => {
            try {
                HttpComponent({
                  method: "GET",
                  url: `/api/get_invoice_by_invoice_number?invoiceNumber=${invoiceNumber}`,
                  token: localStorage.getItem('X-Authorization'),
                }).then((data) => {
                  if (data.status === 200) {
                    setInvoiceData(data.response.data)
                  }
                })
              } catch (error) {
              }
          }
        useEffect(() => {
            getInvoiceDetails()
        }, [])

        const updateInvoice = () =>{
            let data =
                {
                    invoiceNumber,
                    products:newServices
                }
            HttpComponent({
                method : 'POST',
                url : `/api/add_products_to_invoice`,
                body:data,
                token : X_Authorization
            }).then((data)=>{
                console.log("here store is data",data);
                if(data.status === 200){
                    navigate(`/school/invoice/${invoiceNumber}`)
                }
                else{
                    console.error("Error setting info")
                }
            }).catch((error)=>{
                console.error(error.message);
            })
        }

        const servicesColumns = [
            {
                field: 'productName',
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                renderCell: (params) => (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                     {invoiceData?.items.some((inv) => inv.productId === params.row._id) ? (
                        <Checkbox
                            style={{ color: '#dc3545' }}
                            disabled
                            checked
                        />
                        ) : (
                           
                        <Checkbox
                            style={{ color: '#dc3545' }}
                            checked={service.some((s) => s.productId === params.row._id)}
                            onChange={(event) => {
                            handleCheckboxChange(event, params.row);
                            console.log(params.row);
                            }}
                        />
                        )}

                        <span style={{ color: '#707070', fontSize: '14px' }}>{params.value}</span>
                    </div>
                ),
                renderHeader: () => (
                    <strong style={{ color: '#032541' }}>Service Name</strong>
                ),
            },
            {
                field: 'productCategory',
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                renderHeader: () => (
                    <strong style={{ color: '#032541' }}>Category</strong>
                ),
            },
            {
                field: 'priceStatus',
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                renderHeader: () => (
                    <strong style={{ color: '#032541' }}>Amount Type</strong>
                ),
                renderCell: (params) => (
                    <Typography
                        style={{
                            color:
                                params.row.priceStatus === "Variable Price Service" ||
                                params.row.priceStatus === "Variable Price Item"
                                    ? "#FFA500"
                                    : "inherit",
                        }}
                    >
                        {params.row.priceStatus === "Variable Price Service" ||
                        params.row.priceStatus === "Variable Price Item"
                            ? "Variable Price"
                            : "Fixed Price"}
                    </Typography>
                ),
            },
            {
                field: 'productPrice',
                flex: 2,
                align: 'center',
                headerAlign: 'center',
                minWidth: 100,
                renderHeader: () => (
                    <strong style={{ color: '#032541' }}>Amount</strong>
                ),
                renderCell: (params) => (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                        }}
                    >
                        {params.row.priceStatus === "Variable Price Service" ||
                        params.row.priceStatus === "Variable Price Item" ? (
                            <TextField
                            id="outlined-basic"
                            size="small"
                            inputProps={{
                                readOnly: textFieldStatus,
                            }}
                            sx={{
                                width: 100,
                                "& .MuiInputBase-root": {
                                    height: 30,
                                },
                            }}
                            value={params.row?.productPrice || ""}
                            onChange={(e) => handleProductPriceChange(e.target.value, params.row)}
                            variant="outlined"
                        />
                        ) : (
                            numberFormat(params.value)
                        )}
                    </div>
                ),
            },
        ];
        
        const breadcrumbs = [
            <Typography style={{fontFamily : 'Poppins', fontSize : '14px'}} key="3" color="text.inactive">Dashboard</Typography>,
            <Typography style={{fontFamily : 'Poppins', fontSize : '14px'}} key="3" color="text.inactive">Invoice Batches</Typography>,
            <Typography style={{fontFamily : 'Poppins', fontSize : '14px'}} key="3" color="text.inactive">Invoices In Batch</Typography>,
            <Typography style={{fontFamily : 'Poppins', fontSize : '14px'}} key="3" color="text.inactive">Invoice</Typography>,
            <Typography style={{color : '#dc3545', fontFamily : 'Poppins', fontSize : '14px'}} key="3" color="text.error">Update Invoice</Typography>
        ];

        //Fetch services per unit
        function fetchServices() {
            HttpComponent({
                method : 'GET',
                url : `/api/listProducts/Active?search&page=${pageState.page}&limit=${dataGridPageSize}&productService=Service`,
                token : X_Authorization
            }).then((data)=>{
                if(data.status === 200){
                    setUnitCharges(data.response.data)
                    setPageState({...pageState , total:data?.response?.count, data:data.response.data, isLoading:false})
                }else{
                    console.log(`Error getting data`)
                }
            }).catch((error)=>{
                console.error(error.message);
            })
        }

        useEffect(() => {
            fetchServices()
        }, [dataGridPageSize, pageState?.page])  

        console.log(`charges`, unitCharges)
        return (
            <div style={{fontFamily : 'Poppins'}}>

                {/*BreadCrumb container*/}
                <Breadcrumbs style={{fontFamily : 'Poppins', fontSize : '14px'}} separator={<FiberManualRecordIcon style={{fontSize:"0.625rem", fontFamily : 'Poppins', color:"#e1e5e8"}} />} aria-label="breadcrumb">
                    {breadcrumbs}
                </Breadcrumbs>

                {/*Top Container*/}
                <Grid container justifyContent={'space-between'} mt={7}>

                    {/*Header label*/}
                    <Grid item>
                        <span style={{color : '#707070', fontFamily : 'Poppins', fontSize : '16px'}}>List of Service</span>
                    </Grid>

                    {/*Button Create Service*/}
                    <Grid item>
                        <Button disabled onClick={handleCreateService} style={{textTransform : "none",textDecoration : "none", marginTop : '5px',background : '#032541', color : '#fff', fontSize : `14px`, fontWeight : 600, height : '35px'}} >Create Service</Button>
                    </Grid>
                </Grid>

                {/*Table Container*/}
                <div style={{ overflowY: 'auto', maxHeight: '100%' }}>
                <DataGrid
                    components={{ NoRowsOverlay: NoRowsOverlay }}
                    autoHeight={false} // Disable autoHeight to allow scrolling
                    autoWidth
                    sx={{
                        minHeight: '500px',
                        width: 'auto',
                        fontFamily: 'Poppins',
                        fontSize: '14px',
                        color: '#707070',
                        boxShadow: 0,
                        border: 0,
                    }}
                    rowsPerPageOptions={[5, 10, 20, 50, 100]}
                    columns={servicesColumns}
                    rows={pageState.data}
                    getRowId={(row) => row._id}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowCount={pageState?.total}
                    loading={pageState?.isLoading}
                    pagination
                    page={pageState?.page - 1}
                    pageSize={dataGridPageSize}
                    paginationMode="server"
                    onPageChange={(newPage) => { setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize })); }}

                />
            </div>


                {/*Update Button*/}
                <Grid container justifyContent={'flex-end'}>
                    <Grid item>
                        <Button onClick={updateInvoice} style={{textDecoration : "none", marginTop : '5px',background : '#17ae7b', color : '#fff', fontSize : `14px`, fontWeight : 600, height : '35px'}} >Update Invoice</Button>
                    </Grid>
                </Grid>
            </div>
        )
    } catch (e) {
        console.log(`Error Doing This`, e.message)
    }
}

