import { Box, Breadcrumbs, Grid, MenuItem, Paper, Select, Table, TableCell, TableContainer, TableHead, TableRow, Typography, Checkbox, Button, TableBody } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useEffect, useState } from "react";
import BankDetailsFt from "./bankInfo";
import AddInputersApprovals from "./approvalsandinputers";
import HttpComponent from "../MakeRequest";
import { ErrorAlert } from "../../snackBar Alerts/errorAlert";
import { SuccessAlert } from "../../snackBar Alerts/successAlert";
import ViewInvoiceFt from "./viewInvoiceFt";


const breadcrumbs = [
    <Typography style={{ fontFamily: 'Poppins', fontSize: '14px' }} key="3" color="text.inactive">Dashboard</Typography>,
    <Typography style={{ fontFamily: 'Poppins', fontSize: '14px' }} key="3" color="text.inactive">Invoice</Typography>,
    <Typography style={{ fontFamily: 'Poppins', fontSize: '14px' }} key="3" color="text.inactive">Preview Invoice</Typography>,
    <Typography style={{ color: '#dc3545', fontFamily: 'Poppins', fontSize: '14px' }} key="3" color="text.error">Fund Transfer SetUp</Typography>
];


const ListInputerBreadCrumbs = [
    <Typography style={{ fontFamily: 'Poppins', fontSize: '14px' }} key="3" color="text.inactive">Dashboard</Typography>,
    <Typography style={{ fontFamily: 'Poppins', fontSize: '14px' }} key="3" color="text.inactive">Invoice</Typography>,
    <Typography style={{ fontFamily: 'Poppins', fontSize: '14px' }} key="3" color="text.inactive">Preview Invoice</Typography>,
    <Typography style={{ fontFamily:'Poppins', fontSize: '14px' }} key="3">Fund Transfer SetUp</Typography>,
    <Typography style={{ color: '#dc3545', fontFamily: 'Poppins', fontSize: '14px' }} key="3" color="text.error">Assign Inputers</Typography>
]

const ListApproverBreadCrumbs = [
    <Typography style={{ fontFamily: 'Poppins', fontSize: '14px' }} key="3" color="text.inactive">Dashboard</Typography>,
    <Typography style={{ fontFamily: 'Poppins', fontSize: '14px' }} key="3" color="text.inactive">Invoice</Typography>,
    <Typography style={{ fontFamily: 'Poppins', fontSize: '14px' }} key="3" color="text.inactive">Preview Invoice</Typography>,
    <Typography style={{ fontFamily: 'Poppins', fontSize: '14px' }} key="3">Fund Transfer SetUp</Typography>,
    <Typography style={{ color: '#dc3545', fontFamily: 'Poppins', fontSize: '14px' }} key="3" color="text.error">Assign Approvers</Typography>

]

const ListInputers = (props) => {
    const [allInputers, setAllInputers] = useState([])
    const [errorShow, setErrorShow] = useState({ state: false, message: "" });
    const [successShow, setSuccessShow] = useState({ state: false, message: "" });
    const {showInputersAdd, setShowInputersAdd , selectedInputers , setSelectedInputers} = props
    const [isChecked , setIsChecked] = useState(false)
    const navigate = useNavigate()

    // INPUTERS
    function getAllInputers() {
        try {
            HttpComponent({
                method: 'GET',
                url: `/api/v1/funds_transfer/users`,
                token: localStorage.getItem('X-Authorization'),
            }).then((data) => {
                if (data?.status === 200 || data?.status === 201) {
                    setAllInputers(data?.response?.data)
                }
            })
        } catch (error) {

        }

    }

    useEffect(() => {
        getAllInputers()
    }, [])

    // select inputer
    const handleSelectInputer = (e, newInputer) => {
        if (e.target.checked) {
            setSelectedInputers([...selectedInputers, newInputer]);
            setSuccessShow({ state: true, message: "Successfully added inputer" });
            setIsChecked(e.target.checked)
        } else {
            setSelectedInputers(selectedInputers?.filter((selectedInputer) => selectedInputer !== newInputer)); 
            setSuccessShow({ state: true, message: "Successfully removed inputer" });
            setIsChecked(e.target.checked)
        }

    }
    
    return (
        <Grid container width={'50%'} display={'flex'} gap={2} flexDirection={'column'}>
             <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
             <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            <Grid item width={'100%'} sx={{ display: 'flex', flexDirection: 'column' }} gap={1}>
                <Box component={'div'} display={'flex'} alignItems={'center'} gap={1}>
                    <ArrowBackIosIcon sx={{ color: "#707070" }} onClick={() => navigate(-1)} />
                    <Typography variant="h6" sx={{ color: "#032541", fontWeight: 700 }}>Assign Inputers</Typography>
                </Box>
                <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />} aria-label="breadcrumb">
                    {ListInputerBreadCrumbs}
                </Breadcrumbs>
            </Grid>
            <Box component={'div'} width={'100%'}>
                <TableContainer>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell align="right">Phone Number</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {allInputers?.map((row) => (
                                <TableRow key={row?._id}>
                                    <TableCell>
                                        <Checkbox 
                                            color={isChecked ? 'success':'default'}
                                            checked={selectedInputers?.includes(row) ? true:false} 
                                            onChange={(e) => handleSelectInputer(e, row)} 
                                        />  
                                        {row?.userName}
                                    </TableCell>
                                    <TableCell align="right">{row?.userPhone}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            <Box component={'div'} gap={2} display={'flex'} alignItems={'center'} justifyContent={'flex-end'}>
                <Button onClick={() => setShowInputersAdd(false)}  style={{ color: '#dc3545', border: '1px solid #dc3545', width: '120px', textTransform:'inherit' }}>Cancel</Button>
                <Button  onClick={() => setShowInputersAdd(false)} disabled={selectedInputers?.length === 0} style={selectedInputers?.length > 0 ? { backgroundColor: '#032541', color: '#fff', width: "120px", textTransform: 'inherit' } : { backgroundColor: '#bec5d1', color: '#fff', width: "120px", textTransform: 'inherit' }}>Assign</Button>
            </Box>
        </Grid>
    )
}

const ListApprovers = (props) => {
    const [allApprovers, setAllApprovers] = useState([])
    const [approverRoles, setApproverRoles] = useState([])
    const [errorShow, setErrorShow] = useState({ state: false, message: "" });
    const [successShow, setSuccessShow] = useState({ state: false, message: "" });
    const selectedApprovers = props?.selectedApprovers 
    const setSelectedApprovers = props?.setSelectedApprovers
    const LevelAssignLevel = props?.LevelAssignLevel
    const setShowApproversAdd = props?.setShowApproversAdd
    const showApproversAdd = props?.showApproversAdd
    const navigate = useNavigate()

    console.log(selectedApprovers,LevelAssignLevel,allApprovers , 'selectedApprovers')


    // approvers
    function getAllApprovers() {
        try {
            HttpComponent({
                method: 'GET',
                url: `/api/v1/funds_transfer/users`,
                token: localStorage.getItem('X-Authorization'),
            }).then((data) => {
                if (data?.status === 200 || data?.status === 201) {
                    setAllApprovers(data?.response?.data)
                }
            })
        } catch (error) {

        }

    }

    //roles

    const getFundsTranferRoles = async () => {
        HttpComponent({
            method: "GET",
            url: '/api/v1/funds_transfer/approval_roles',
            token: localStorage.getItem('X-Authorization'),
        }).then((data) => {
            if (data?.status === 200) {
                let roles = data.response.data
                roles = roles?.map((item) => {
                    return { value: item?.type, label: item?.name }
                })
                setApproverRoles(roles)
            }
        })
    }

    useEffect(() => {
        getAllApprovers();
        getFundsTranferRoles()
    }, [])


    // select the approver role

    const updateApproverStatus = (e, index, id) => {
        let newUsers = [...allApprovers]
        let user = newUsers?.find((item) => item?._id === id)
        user.approvalStatus = e
        newUsers[index] = user
        setAllApprovers(newUsers)
    }

    // checked usrs

    const checkedStatus = (user, LevelAssignLevel) => {
        let status = selectedApprovers?.findIndex((item) => item?._id === user?._id && item?.levelNumber === LevelAssignLevel)
        if (status === -1) {
            return false
        } else {
            return true
        }
    }

    // handle add approvers

    const handleSelectApproverRow = (event, user, LevelAssignLevel) => {
        let newSelectedApprove = [...selectedApprovers]
        let currentIndex = newSelectedApprove.findIndex((item) => item?._id === user?._id && item?.levelNumber === LevelAssignLevel)
        if (currentIndex === -1) {
            let newuser = { ...user, levelNumber: LevelAssignLevel }
            if (newuser?.approvalStatus === undefined) {
                return setErrorShow({ state: true, message: "Please select approval status" })
            }
            if (newSelectedApprove?.findIndex((item) => item?._id === user?._id) !== -1) {
                let userInSelectedApprove = newSelectedApprove?.find((item) => item?._id === user?._id)
                let message = `${user.userName} already exist exist in level ${parseInt(userInSelectedApprove?.levelNumber) + 1}`
                return setErrorShow({ state: true, message: message })
            }
            newSelectedApprove.push(newuser)

            setSuccessShow({ state: true, message: "Successfully added approver" });
        } else {
            newSelectedApprove.splice(currentIndex, 1)
            setSuccessShow({ state: true, message: "Successfully removed approver" });

        }

        setSelectedApprovers(newSelectedApprove)
    }

    return (
        <Grid container width={'50%'} display={'flex'} flexDirection={'column'}>
             <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            <Grid item width={'100%'} sx={{ display: 'flex', flexDirection: 'column' }} gap={1}>
                <Box component={'div'} display={'flex'} alignItems={'center'} gap={1}>
                    <ArrowBackIosIcon sx={{ color: "#707070" }} onClick={() => navigate(-1)} />
                    <Typography variant="h6" sx={{ color: "#032541", fontWeight: 700 }}>Assign Approvers</Typography>
                </Box>
                <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />} aria-label="breadcrumb">
                    {ListApproverBreadCrumbs}
                </Breadcrumbs>
            </Grid>
            <Box component={'div'} width={'100%'}>
                <TableContainer style={{width:'100%'}}>
                    <Table  aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell align="left">Phone Number</TableCell>
                                <TableCell sx={{width:'200px', display:'flex', justifyContent:'flex-end'}} align="right">Approval Type</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {allApprovers?.map((row , index) => (
                                <TableRow key={row?._id}>
                                    <TableCell>
                                        <Checkbox sx={{"&.Mui-checked":{color :checkedStatus(row,LevelAssignLevel) ? '#17ae7b':"#bec5d1"}}}  checked={checkedStatus(row,LevelAssignLevel)} onChange={(event) =>handleSelectApproverRow(event, row, LevelAssignLevel)} />  {row?.userName}
                                    </TableCell>
                                    <TableCell align="left">{row?.userPhone}</TableCell>
                                    <TableCell align="right"  sx={{display:'flex', justifyContent:'flex-end', width:'200px'}}>
                                         <Select
                                            value={row?.approvalStatus}
                                            key={row?._id}
                                            placeholder="Approver Type"
                                            sx={{width:'100%', fontSize:"14px"}}
                                            variant="outlined"
                                            onChange={(e) => updateApproverStatus(e.target.value , index, row?._id)}
                                        >
                                            {approverRoles?.map((role) =>  <MenuItem key={index} sx={{fontSize:'14px'}}  value={role?.value}>{role?.label}</MenuItem>)}
                                        </Select>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            <Box component={'div'} gap={2} mt={2} display={'flex'} alignItems={'center'} justifyContent={'flex-end'}>
                <Button onClick={() => setShowApproversAdd(false)}  style={{ color: '#dc3545', border: '1px solid #dc3545', width: '120px', textTransform:'inherit' }}>Cancel</Button>
                <Button onClick={() =>setShowApproversAdd(false)} disabled={selectedApprovers?.length === 0} style={selectedApprovers?.length > 0 ? { backgroundColor: '#032541', color: '#fff', width: "120px", textTransform: 'inherit' } : { backgroundColor: '#bec5d1', color: '#fff', width: "120px", textTransform: 'inherit' }}>Assign</Button>
            </Box>
        </Grid>
    )
}

export default function FundTransferCustomerSetUp(props) {
    const navigate = useNavigate()
    const [showInputersAdd, setShowInputersAdd] = useState(false)
    const [showApproversAdd, setShowApproversAdd] = useState(false)
    const [selectedInputers , setSelectedInputers] = useState([])
    const [selectedApprovers , setSelectedApprovers] = useState([])
    const [selectedRange, setSelectedRange] = useState(null)
    const [selectedNoofApprovers, setSelectedNoOfApprover] = useState(null)
    const [LevelAssignLevel, setLevelAssignLevel] = useState()

    return (
        <Grid container width={'100%'} flex={1} flexDirection={'column'} gap={2}>
            {showInputersAdd ? <ListInputers selectedRange={selectedRange} selectedNoofApprovers={selectedNoofApprovers} selectedInputers={selectedInputers} setSelectedInputers={setSelectedInputers} showInputersAdd={showInputersAdd} setShowInputersAdd={setShowInputersAdd}  /> : showApproversAdd ? <ListApprovers selectedApprovers={selectedApprovers} setSelectedApprovers={setSelectedApprovers}  LevelAssignLevel={LevelAssignLevel} setLevelAssignLevel={setLevelAssignLevel}   setShowApproversAdd={setShowApproversAdd} showApproversAdd={showApproversAdd} /> : <>
                <Grid item width={'50%'} sx={{ display: 'flex', flexDirection: 'column' }} gap={1}>
                    <Box component={'div'} display={'flex'} alignItems={'center'} gap={1}>
                        <ArrowBackIosIcon sx={{ color: "#707070" }} onClick={() => navigate(-1)} />
                        <Typography variant="h6" sx={{ color: "#032541", fontWeight: 700 }}>Fund Transfer Payment Setup</Typography>
                    </Box>
                    <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />} aria-label="breadcrumb">
                        {breadcrumbs}
                    </Breadcrumbs>
                    <Typography variant="body2" sx={{ color: "#707070" }}>Setup you KCB Bank Debit accounts and approvers to easily pay for invoices from your bank account.</Typography>
                </Grid>
                {/* <Grid item width={'100%'}>
                <BankDetailsFt/>
            </Grid> */}
                <Grid item width={'100%'}>
                    <AddInputersApprovals 
                         showInputersAdd={showInputersAdd} 
                         setShowInputersAdd={setShowInputersAdd} 
                         showApproversAdd={showApproversAdd} 
                         setShowApproversAdd={setShowApproversAdd}
                         setSelectedInputers={setSelectedInputers}
                         selectedInputers={selectedInputers}
                         selectedApprovers={selectedApprovers}
                         setSelectedApprovers={setSelectedApprovers}
                         setSelectedNoOfApprover={setSelectedNoOfApprover}
                         setSelectedRange={setSelectedRange}
                         selectedRange={selectedRange}
                         selectedNoofApprovers={selectedNoofApprovers}
                         setLevelAssignLevel={setLevelAssignLevel}
                         LevelAssignLevel={LevelAssignLevel}
                     />
                </Grid>
                <Grid item width={'100%'}>
                    <ViewInvoiceFt/>
                </Grid>
            </>}
        </Grid>
    )
}