import { Box, Grid, IconButton, InputAdornment, OutlinedInput, TextField ,Checkbox, Button} from "@mui/material"
import CustomInputLabel from "./CustomInputLabel"
import CustomTextFieldSmall from "./CustomTextFieldSmall"
import { useState } from "react"
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useDispatch } from "react-redux";
import { handleIsMpesaSetup, setMpesaStatus } from "../../features/paymentOptionsSlice";
import HttpComponent from "./MakeRequest";

const TillMpesaSetUp = (props) => {
    let {setShowDarajaSetup,setSuccessShow,setErrorShow} = props
    const [formData, setFormData] = useState({ businessName: '', consumerKey: '', consumerSecret: '', accountReference: '', passKey: '',businessShortCode:'',TransactionType:'CustomerBuyGoodsOnline', thirdPartyCallback: 'https://api.dev.zed.business/api/stkCallback', storeNumber: '', tillNumber: "", hoNumber: '' })
    const handleFormdataChange = (e) => { setFormData({ ...formData, [e.target.name]: e.target.value }) }
    const [isConsumerSecretHidden, setIsConsumerSecretHidden] = useState(true)
    const [isPassKeyHidden, setIsPasskeySecretHidden] = useState(true)
    const [isConsumerKeyHidden, setIsConsumerkeySecretHidden] = useState(true)
    const [checked, setChecked] = useState(true);
    const dispatch = useDispatch()

    const noStyle = {
        "borderRadius":"4px",
        "border":"solid 1px #dc3545",
        "color":"#dc3545",
        textTransform : "none",
        textDecoration : "none"
    }

    const buttonWidthHeight = {
        width:"9.063rem",
        height:"3.125rem"
    }

    const yesStyle = {
        "borderRadius": "4px",
        "backgroundColor": "#032541",
        textTransform : "none",
        textDecoration : "none",
        color:"white",
        '&:hover': {
            backgroundColor: '#032541',
            color: 'white'
        }
    }

    const handleCheckBoxChange = (event) => {
        setChecked(event.target.checked);
    };

  console.log(formData , 'handleFormdataChange')



    const handleMpesa = async () => {
        if(!formData.businessName || !formData.consumerKey || !formData.consumerSecret || !formData.passKey || !formData.tillNumber || !formData.storeNumber){
            return setErrorShow({state:true, message:"Missing fields"})
        }
        try{
            HttpComponent({
                method: 'POST',
                url: `/api/activate_daraja_for_business`,
                body: {...formData,accountReference:formData.tillNumber, businessShortCode:formData.hoNumber},
                token: localStorage.getItem('X-Authorization')
            }).then((data)=>{
                console.log("here store is data",data);
                if(data.status === 201){
                    setSuccessShow({
                        state:true,
                        message:data?.response?.message
                    })
                    dispatch(handleIsMpesaSetup(true));
                    dispatch(setMpesaStatus(true));
                    props.setMpesaStatus(true)
                    setShowDarajaSetup(false)
                }else{
                    console.error("Error setting info")
                    setErrorShow({state:true, message:data?.response?.message})
                }
            })
        }catch(error){
            setErrorShow({state:true, message:error.message})
        }
    }
    
    return (
        <Grid container flexDirection={'column'} sx={{marginX:1}} flex={1} width={'100%'}>
            <Box component="div" sx={{ display: "flex", flexDirection:'column', marginX: 1 }}>
                <CustomInputLabel required={true} label={"Business Name"} />
                <TextField
                    name={"businessName"}
                    value={formData.businessName}
                    onChange={handleFormdataChange}
                    placeholder={"Business Name"}
                    variant="outlined"
                    margin="dense"
                    sx={{ marginBottom: 2, width: '100%' }}
                    InputProps={{
                        sx: {
                            width: '31.063rem',
                            height: '3.438em',
                            fontSize: "0.875rem",
                            "& fieldset": {
                                borderColor: "#bec5d1"
                            }
                        }
                    }}
                />
            </Box>
            <Box component="div" sx={{ display: "flex" , marginX: 1 }}>
                <Box component="div" sx={{ }}>
                    <CustomInputLabel required={true} label={"Till No"} />
                    <CustomTextFieldSmall  value={formData.tillNumber}  onChange={handleFormdataChange} name={"tillNumber"} placeholder={"Till No"} />
                </Box>

                <Box component="div" sx={{ marginLeft: 2 }}>
                    <CustomInputLabel required={true} label={"Store Number"} />
                    <CustomTextFieldSmall value={formData.storeNumber} onChange={handleFormdataChange} name={"storeNumber"} placeholder={"Store No"} />
                </Box>
            </Box>
            <Box component="div" sx={{ display: "flex", flexDirection:'column', marginX: 1 }}>
                <CustomInputLabel required={true} label={"HO Number"} />
                <TextField
                    name={"hoNumber"}
                    value={formData.hoNumber}
                    onChange={handleFormdataChange}
                    placeholder={"HO Number"}
                    variant="outlined"
                    margin="dense"
                    sx={{ marginBottom: 2}}
                    InputProps={{
                        sx: {
                            width: '31.063rem',
                            height: '3.438em',
                            fontSize: "0.875rem",
                            "& fieldset": {
                                borderColor: "#bec5d1"
                            }
                        }
                    }}
                />
            </Box>
            <Box component="div" sx={{ marginX: 1 }}>
                <CustomInputLabel required={true} label={"Consumer Secret"} />
                <OutlinedInput
                    name={"consumerSecret"}
                    value={formData.consumerSecret}
                    onChange={handleFormdataChange}
                    placeholder={"Consumer Secret"}
                    variant="outlined"
                    type={isConsumerSecretHidden ? 'password' : 'text'}
                    margin="dense"
                    sx={{ marginBottom: 2, width: "91%" }}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                onClick={() => setIsConsumerSecretHidden(!isConsumerSecretHidden)}
                                edge="end"
                            >
                                {isConsumerSecretHidden ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    }
                    InputProps={{
                        sx: {
                            width: '31.063rem',
                            height: '3.438em',
                            fontSize: "0.875rem",
                            "& fieldset": {
                                borderColor: "#bec5d1"
                            }
                        }
                    }}
                />
            </Box>
            <Box component="div" sx={{ marginX: 1 }}>
                <CustomInputLabel required={true} label={"Consumer Keys"} />
                <OutlinedInput
                    name={"consumerKey"}
                    value={formData.consumerKey}
                    onChange={handleFormdataChange}
                    placeholder={"Consumer Keys"}
                    variant="outlined"
                    type={isConsumerKeyHidden ? 'password' : 'text'}
                    margin="dense"
                    sx={{ marginBottom: 2, width: "91%" }}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                onClick={() => setIsConsumerkeySecretHidden(!isConsumerKeyHidden)}
                                edge="end"
                            >
                                {isConsumerKeyHidden ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    }
                    InputProps={{
                        sx: {
                            width: '31.063rem',
                            height: '3.438em',
                            fontSize: "0.875rem",
                            "& fieldset": {
                                borderColor: "#bec5d1"
                            }
                        }
                    }}
                />
            </Box>
            <Box component="div" sx={{ marginX: 1 }}>
                <CustomInputLabel required={true} label={"Pass Key"} />
                <OutlinedInput
                    name={"passKey"}
                    value={formData.passKey}
                    onChange={handleFormdataChange}
                    placeholder={"Pass Key"}
                    variant="outlined"
                    type={isPassKeyHidden ? 'password' : 'text'}
                    margin="dense"
                    sx={{ marginBottom: 2, width: "91%" }}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                onClick={() => setIsPasskeySecretHidden(!isPassKeyHidden)}
                                edge="end"
                            >
                                {isPassKeyHidden ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    }
                    InputProps={{
                        sx: {
                            width: '31.063rem',
                            height: '3.438em',
                            fontSize: "0.875rem",
                            "& fieldset": {
                                borderColor: "#bec5d1"
                            }
                        }
                    }}
                />
            </Box>
            <Box component="div" sx={{}}>
                <Checkbox onChange={handleCheckBoxChange} checked={checked} inputProps={{ 'aria-label': 'controlled' }} />
                <span>By ticking, you are agreeing to the terms and conditions.</span>
            </Box>
            <Box component="div" sx={{display:"flex", flexDirection:"column", justifyContent:"center", marginBottom:2}}>
                <Box component="div" sx={{alignSelf:"center"}}>
                    <Button onClick={()=>setShowDarajaSetup(false)} sx={{...noStyle,...buttonWidthHeight, marginX:1}}>
                        Cancel
                    </Button>

                    <Button hidden={!checked} onClick={handleMpesa} sx={{...yesStyle,...buttonWidthHeight,marginX:1}}>
                        Activate
                    </Button>
                </Box>
            </Box>
        </Grid>
    )
}
export default TillMpesaSetUp