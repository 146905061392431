import React, { useState } from "react";
import { Box, Grid } from "@mui/material";
import PosCards from "./poscomponents/poscards";
import Unpaidbills from "./poscomponents/unpaidbills";
import PaidBills from "./poscomponents/paidbills";
import { useParams } from "react-router-dom";
import CancelledBills from "./poscomponents/cancelledbills";
import Reports from "./poscomponents/reports";
import PosHeaders from "./poscomponents/posheaders";
import RequestPayFromCustomer from "../customerAccounts/customerComponents/requestPayFromCustomer";
import Partial from "./poscomponents/partial";

const OrdersListing = () => {
  const [showRequestPayButton, setShowRequestPayment] = useState(false);
  const [navigateToPayment, setNavigateToPayment] = useState(false);
  const { tabValue } = useParams();

  // orders to totalValue
  const [paidTotals, setPaidTotals] = useState(0);
  const [unpaidTotals, setUnPaidTotals] = useState(0);
  const [partialTotals, setPartialPaidTotals] = useState(0);
  const [cancelledTotals, setCancelledTotals] = useState(0);

  // date filters
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [searchValue, setSearchValue] = useState("");

  const COMPONENTTORENDER = () => {
    if (tabValue === "unpaid") {
      return <Unpaidbills searchValue={searchValue} dateFrom={dateFrom} dateTo={dateTo} setUnPaidTotals={setUnPaidTotals} setShowRequestPayment={setShowRequestPayment} navigateToPayment={navigateToPayment} />;
    }
    if (tabValue === "paid") {
      return <PaidBills searchValue={searchValue} dateFrom={dateFrom} dateTo={dateTo} setPaidTotals={setPaidTotals} />;
    }
    if (tabValue === "cancelled") {
      return <CancelledBills searchValue={searchValue} dateFrom={dateFrom} dateTo={dateTo} setCancelledTotals={setCancelledTotals} />;
    }
    if (tabValue === "reports") {
      return <Reports searchValue={searchValue} dateFrom={dateFrom} dateTo={dateTo} />;
    }
    // if(tabValue === "requestPayment"){
    //   return <RequestPayFromCustomer orderIDS={[]} />;
    // }
    if (tabValue === "partial") {
      return <Partial searchValue={searchValue} dateFrom={dateFrom} dateTo={dateTo} setPartialPaidTotals={setPartialPaidTotals} setShowRequestPayment={setShowRequestPayment} navigateToPayment={navigateToPayment} />;
    }
  };

  return (
    <Grid container spacing={2}  >
      {tabValue !== "requestPayment" && (
        <>
          <Grid item  sm={0} md={12} lg={2} >
            <PosCards paidTotals={paidTotals} unpaidTotals={unpaidTotals} partialTotals={partialTotals} cancelledTotals={cancelledTotals} />
          </Grid>
          <Grid item sm={12} md={12} lg={10}>
            <PosHeaders setSearchValue={setSearchValue} setDateFrom={setDateFrom} setDateTo={setDateTo} setNavigateToPayment={setNavigateToPayment} showRequestPayButton={showRequestPayButton} />

            <Box>{COMPONENTTORENDER()}</Box>
          </Grid>
        </>
      )}
      {tabValue ===  "requestPayment" &&  <RequestPayFromCustomer orderIDS={[]} /> } ;
    </Grid>
  );
};

export default OrdersListing;
