import React, { useState } from "react";
import { Box, Grid, TextField, Typography } from "@mui/material";
import { ArrowBackIos } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import Breadcrumbcomponent from "../../products/stockComponents/breadcrumb";
import CategoryList from "./categorylist";
import Productlist from "./productlist";
import StartOrderOverview from "./startorderoverview";

const StartOrder = () => {
  const navigate = useNavigate();
  const group = localStorage.getItem('group');
  const businessName = localStorage.getItem('businessName')
  const branchName = localStorage.getItem("branchName")
  const breadcrumbs = [<span style={{ color: "#707070" }}>Dashboard</span>, <span style={{ color: "#707070" }}>Bills</span>, <span style={{ color: "#dc3545" }}>Start Order</span>];

  // set CategoryName;
  const [categoryName, setCategoryName] = useState("")
  return (
    <>
      {(group === "Supervisor" || group === "Cashier") && (
        <Box display="flex" flexDirection="column">
          <TextField label="Branch Name" value={branchName} variant="outlined" sx={{ width: "250px" }} margin="normal"
            InputProps={{readOnly: true,}}
          />
          <TextField label="Business Name" value={businessName} variant="outlined" sx={{ width: "250px" }} margin="normal"
            InputProps={{readOnly: true,}}
          />
        </Box>
      )}
      <Grid container direction={"column"}>
        <Grid item mb={2}>
          <Box onClick={() => navigate(`/pos/unpaid`)} sx={{ display: "flex", alignItems: "center", gap: "10px", cursor: "pointer" }}>
            <ArrowBackIos /> <span style={{ color: "#032541", fontSize: "25px" }}>StartOrder</span>
          </Box>
        </Grid>

        <Grid item mb={2}>
          <Breadcrumbcomponent breadcrumbs={breadcrumbs} />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item mb={2} sm={2.5}>
          <CategoryList setCategoryName={setCategoryName} categoryName={categoryName} />
        </Grid>

        <Grid item sm={3.5}>
          <Productlist categoryName={categoryName} />
        </Grid>

        <Grid item sm={6.0}>
          <StartOrderOverview />
        </Grid>
      </Grid>
    </>
  );
};

export default StartOrder;
