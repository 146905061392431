import { Box, Breadcrumbs, Button, Grid, Typography, Tab } from "@mui/material";
import React, { useEffect, useState } from "react";
import CircleIcon from '@mui/icons-material/Circle';
import HttpComponent from "../MakeRequest";
import DateFormatter from "../../../utils/dateFormatter";
import { DataGrid } from '@mui/x-data-grid';
import TabList from "@mui/lab/TabList";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import CustomSearchInput from "../CustomSearchInput";
import { NoRowsOverlay } from "../../No Rows/noRowsOverlay";
import moment from "moment-timezone";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import CustomSelectField from "../CustomSelectField";

const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>DashBoard</Typography>,
    <Typography key={2} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Student Promotions</Typography>,
]

const headerStyles = {
    fontSize: "16px",
    fontWeight: "700",
    color: "#032541",
    textAlign: "left",
};

const cellStyles = {
    fontSize: "14px",
    fontWeight: "300",
    color: "#032541",
    textAlign: "left",
};
export default function StudentPromotionNotification() {
    let baseUrl = process.env.REACT_APP_BASE_URL;
    const [allNotifications, setAllNotification] = useState([])
    const [pageSize, setPageSize] = React.useState(10);
    const [pageState, setPageState] = useState({
        isLoading: false,
        data: [],
        count: 0,
        page: 1,
        pageSize: pageSize,
    });
    const [tabValue, setTabValue] = useState("ACTIVE");
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };
    const [searchValue, setSearchValue] = useState('')
    const [isNotification, setIsNotification] = useState(true)
    const [tableData, setTableData] = useState([])
    const navigate = useNavigate()
    const { schoolTypeName, schoolTypeId } = useSelector((store) => store.schoolType.schoolTypeDetail)
    const [stream, setStream] = useState('')
    const [grade, setGrade] = useState('')
     const [gradeOptions, setGradeOptions] = useState([])
    const [courseOptions, setCourseOptions] = useState([]);
    const [streamsOptions, setStreamesOptions] = useState([]);
    const [termOptions, setTermOptions] = useState([]);
    

    const activeColumns = [
        {
            headerName: "Admission No", field: "itemNumber", flex: 1,

            renderHeader: () => (
                <Typography style={headerStyles}>
                    Admission No
                </Typography>
            ),

            renderCell: params => (
                <Typography sx={{ color: "#032541", textDecoration: "underline", cursor: "pointer" }} onClick={() => navigate(`/school/student-promotions?studentId=${params?.row?.studentId}&itemNo=${params.row.customerId}&itemNumber=${params.row.itemNumber}`)}>
                    {params.value}
                </Typography>
            )
        },
        {
            headerName: "Student Name", field: "studentName", flex: 1,

            renderHeader: () => (
                <Typography style={headerStyles}>
                    Student Name
                </Typography>
            ),
            renderCell: params => (
                <Typography style={cellStyles}>
                    {params.row.firstName} {params.row.lastName}
                </Typography>
            )
        },
        {
            headerName: "Current Grade", field: "prevgrade", flex: 1,
            renderHeader: () => (
                <Typography style={headerStyles}>
                    Grade
                </Typography>
            ),
            renderCell: params => (
                <Typography style={cellStyles}>
                    {params.row?.studentPromotions[0]?.fromGrade}
                </Typography>
            )
        },
        {
            headerName: "Grade joined", field: "grade", flex: 1,
            renderHeader: () => (
                <Typography style={headerStyles}>
                    Grade Joined
                </Typography>
            ),
            renderCell: params => (
                <Typography style={cellStyles}>
                    {params.value}
                </Typography>
            )
        },
        {
            headerName: "Current Stream", field: "stream", flex: 1,
            renderHeader: () => (
                <Typography style={headerStyles}>
                    Current Stream
                </Typography>
            ),
            renderCell: params => (
                <Typography style={cellStyles}>
                    {params.value}
                </Typography>
            )
        }
    ]

    const completedColumn = [
        {
            headerName: "Admission No", field: "itemNumber", flex: 1, renderCell: params => (
                <Typography sx={{ color: "#33699d", textDecoration: "underline", cursor: "pointer" }} onClick={() => navigate(`/school/student-promotions?studentId=${params.row.studentId}&itemNo=${params.row.customerId}&itemNumber=${params.row.itemNumber}`)}>
                    {params?.row?.itemNumber}
                </Typography>
            )
        },
        { headerName: "studentName", field: 'studentName', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Student Name</strong>) }, renderCell: (params) => <span>{params?.row?.firstName} {params?.row?.lastName}</span> },
        {
            headerName: "grade joined",
            field: 'grade joined',
            headerAlign: 'left',
            align: 'left',
            flex: 1,
            renderHeader: () => {
                return (
                    <strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Grade Joined</strong>
                )
            },
            renderCell: (params) => <span> {params.row?.studentPromotions[params.row.studentPromotions.length - 1]?.fromGrade}</span>
        },
        { headerName: "stream", field: 'stream', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Stream</strong>) } },
        {
            headerName: "date joined",
            field: 'date joined',
            headerAlign: 'left',
            align: 'left',
            flex: 1,
            renderHeader: () => {
                return (
                    <strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Date Joined</strong>
                )
            },
            renderCell: (params) => <span>{moment(params?.row?.createdAt).format("llll")}</span>
        },
        {
            headerName: "grade completed",
            field: 'grade completed',
            headerAlign: 'left',
            align: 'left',
            flex: 1,
            renderHeader: () => {
                return (
                    <strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Grade Completed</strong>
                )
            },
            renderCell: (params) => <span>{params.row?.studentPromotions[0]?.fromGrade}</span>
        },
        {
            headerName: "Date completed",
            field: 'date completed',
            headerAlign: 'left',
            align: 'left',
            flex: 1,
            renderHeader: () => {
                return (
                    <strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Date Completed</strong>
                )
            },
            renderCell: (params) => <span>{moment(params.row?.studentPromotions[params.row.studentPromotions.length - 1]?.createdAt).format("llll")}</span>
        },
    ]

    //get notifications
    function getAllStudentNotification() {
        try {
            HttpComponent({
                method: 'GET',
                url: `/api/v1/students/getPromotions`,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data.status === 200) {
                    setAllNotification(data?.response?.data)
                }
            })
        } catch (error) {

        }
    }
    useEffect(() => {
        getAllStudentNotification()
    }, [])

    function fetchStudents() {
        let urlStudents = `/api/get_students_in_school?page=${pageState.page}&limit=${pageSize}&status=${tabValue}&searchValue=${searchValue}`
        setPageState((old) => ({ ...old, isLoading: true }));
        HttpComponent({
            method: 'GET',
            url: urlStudents,
            body: null,
            token: localStorage.getItem('X-Authorization')
        }).then((data) => {
            console.log("here is parent  data", data.response.data);
            if (data.status === 200) {
                setTableData(data.response.data)
                setPageState({ ...pageState, isLoading: false, count: data?.response?.count, data: data.response.data })
            } else {
                console.error("Error setting info")
                setErrorShow({ state: true, message: data.response.message })
            }
        }).catch((error) => {
            console.error(error.message);
        })
    }

    useEffect(() => {
        fetchStudents()
    }, [tabValue, pageState?.page, pageState?.pageSize, searchValue]);

    const GetGrades = async () => {
            const response = await fetch(`${baseUrl}/api/get_school_grades?schoolType_id=${schoolTypeId}`, {
                headers: {
                    "Content-Type": "application/json",
                    "X-Authorization": localStorage.getItem('X-Authorization'),
                },
            });
            const data = await response.json();
            console.log(data, "Grades");
    
            if (response.status === 201) {
                setGradeOptions(data.data.map((itemGrade) => {
                    return { value: itemGrade.schoolGrades, label: itemGrade.schoolGrades }
                }
                ))
            }
        };
        const GetStreams = async () => {
            const response = await fetch(`${baseUrl}/api/get_school_streams`, {
                headers: {
                    "Content-Type": "application/json",
                    "X-Authorization": localStorage.getItem('X-Authorization'),
                },
            });
            const data = await response.json();
            console.log(data, "Streams");
            if (response.status === 201) {
                setStreamesOptions(data.data.map((itemStream) => {
                    return { value: itemStream.streamName, label: itemStream.streamName }
                }
                ))
            }
        };
    
        const GetStreamsTerms = async () => {
            const response = await fetch(`${baseUrl}/api/get_school_terms?schoolType_id=${schoolTypeId}`, {
                headers: {
                    "Content-Type": "application/json",
                    "X-Authorization": localStorage.getItem('X-Authorization'),
                },
            });
            const data = await response.json();
            console.log(data, "Streams");
            if (response.status === 201) {
                setTermOptions(data.data.map((terms) => {
                    return { value: terms.schoolGrades, label: terms.schoolGrades }
                }
                ))
            }
        };
        useEffect(() => {
            if (schoolTypeId) {
                GetGrades();
                GetStreams();
                GetStreamsTerms();
            }
    
        }, [schoolTypeId]);


    return (
        <Grid direction={'column'} container>
            <Grid item mt={2}>
                <Box>
                    <Button
                        onClick={() => setIsNotification(true)}
                        style={{
                            textTransform: "none",
                            marginTop: "10px",
                            textDecoration: "none",
                            background: isNotification ? "#cb444a" : "#f5f6f7",
                            color: isNotification ? "#fff" : "#707070",
                            fontSize: "14px",
                            fontWeight: 600,
                            height: "45px",
                            width: "219px",
                        }}
                    >
                        Previous Activity
                    </Button>

                    <Button
                        onClick={() => setIsNotification(false)}
                        style={{
                            textTransform: "none",
                            marginTop: "10px",
                            textDecoration: "none",
                            background: !isNotification ? "#cb444a" : "#f5f6f7",
                            color: !isNotification ? "#fff" : "#707070",
                            fontSize: "14px",
                            fontWeight: 600,
                            height: "45px",
                            width: "219px",
                            marginLeft: "10px",
                        }}
                    >
                        Student History
                    </Button>
                </Box>


                {isNotification ? (
                    <Box>
                        <Typography style={{ fontWeight: 600, fontSize: "18px", color: "#032541", marginBottom: '20px', marginTop: '20px' }}>Previous Activity</Typography>

                        {allNotifications?.map((student) => {
                            return (
                                <Grid item mb={2}>
                                    <Grid container display={'flex'} spacing={1} direction={'column'}>
                                        <Grid item display={'flex'} alignItems={'center'}>
                                            <CircleIcon style={{ color: '#e0e0e0', width: '10px', height: '10px' }} />
                                            <Typography style={{ color: '#032541', marginLeft: '10px', fontSize: '14px', fontWeight: 600 }}>{student?.fromGrade}<span style={{ marginLeft: '10px', marginRight: '10px' }}>students promoted to</span>{student?.toGrade}</Typography>
                                        </Grid>
                                        <Grid item marginLeft={'15px'}>
                                            <Typography sx={{ color: '#6c6c6c', fontSize: '14px' }}>{student?.description} at {DateFormatter(student?.createdAt)}</Typography>
                                        </Grid>
                                        {/* <Grid item marginLeft={'15px'}>
                                            <Typography>{DateFormatter(student?.createdAt)}</Typography>
                                        </Grid> */}
                                    </Grid>
                                </Grid>
                            )
                        })}

                    </Box>) : (<Box>
                        <TabContext value={tabValue}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList
                                    onChange={handleTabChange}
                                    TabIndicatorProps={{
                                        style: { display: 'none' },
                                    }}
                                >
                                    <Tab label="Active" value="ACTIVE" centered sx={{
                                        color: '#6e7074',
                                        '&.Mui-selected': {
                                            color: '#dc3545',
                                        }
                                    }} />
                                    <Tab label="Completed" value="COMPLETED" centered sx={{
                                        color: '#6e7074',
                                        '&.Mui-selected': {
                                            color: '#dc3545',
                                        }
                                    }} />

                                </TabList>
                            </Box>

                            <TabPanel value="ACTIVE">
                                <Box component="div" sx={{ display: "flex", flexDirection: "column" }}>
                                    <Box component="div" sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                        <Box component="div" sx={{ display: "flex", alignItems: "center" }}>
                                            <CustomSearchInput name={"search"} value={searchValue} onChange={(e) => setSearchValue(e.target.value)} placeholder="Search:" />

                                            {schoolTypeName === 'Kindergarten/Junior/High School' ?
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <FormControl style={{ width: "200px", marginTop: "-10px", marginLeft: "10px", height: "3.438rem", border: "solid 1px #cdd39d9", color: "#fff" }}>
                                                    <InputLabel id="year-select-label">School Type</InputLabel>
                                                    <Select
                                                        labelId="year-select-label"
                                                        id="year-select"
                                                        value={selectedSchoolType}
                                                        label="School Type"
                                                        onChange={(e) => setSelectedSchoolType(e.target.value)}
                                                    >
                                                        <MenuItem value="">
                                                            Select School
                                                        </MenuItem>
                                                        {allSchoolCategory.map((school) => (
                                                            <MenuItem key={school} value={school}>
                                                                {school}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </div> : null
                                        }
                                        <CustomSelectField style={{ width: "200px", marginLeft: "5px", marginRight: "5px" }} value={grade} onChange={(e) => setGrade(e.target.value)} name={"grade"} placeholder={schoolTypeName?.includes("University") ? 'Select Year' : "Select Grade"} options={gradeOptions} sm={{ maxWidth: "200px" }} />
                                        {schoolTypeName?.includes("University") ? null :
                                            <CustomSelectField style={{ width: "200px", marginLeft: "5px", marginRight: "5px" }} value={stream} onChange={(e) => setStream(e.target.value)} name={"stream"} placeholder={"Select Streams"} options={streamsOptions} sm={{ maxWidth: "200px" }} />
                                        }

                                        </Box>

                                    </Box>
                                    <Grid item>
                                        <DataGrid
                                            components={{ NoRowsOverlay: NoRowsOverlay }}
                                            sx={{ height: '550px', width: 'auto', fontFamily: 'Poppins', fontSize: "10px", color: '#272d3d', boxShadow: 0, border: 0 }}
                                            rowsPerPageOptions={[1, 5, 10, 20, 50, 100]}
                                            rowCount={pageState?.count}
                                            loading={pageState?.isLoading}
                                            pagination
                                            page={pageState.page - 1}
                                            pageSize={pageSize}
                                            paginationMode="server"
                                            onPageChange={(newPage) => { setPageState((old) => ({ ...old, page: newPage + 1, pageSize: pageSize })); }}
                                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                            columns={activeColumns}
                                            rows={pageState?.data}
                                            getRowId={row => row.customerId} />

                                    </Grid>
                                    {/* <CustomTable loading={gridLoading} data={rows} columns={schoolTypeName?.includes("University") ? activeColumnsColleges : activeColumns} rowsPerPage={5} /> */}
                                </Box>

                            </TabPanel>

                            <TabPanel value="COMPLETED">
                                <Box component="div" sx={{ display: "flex", flexDirection: "column" }}>
                                <Box component="div" sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                        <Box component="div" sx={{ display: "flex", alignItems: "center" }}>
                                            <CustomSearchInput name={"search"} value={searchValue} onChange={(e) => setSearchValue(e.target.value)} placeholder="Search:" />

                                            {schoolTypeName === 'Kindergarten/Junior/High School' ?
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <FormControl style={{ width: "200px", marginTop: "-10px", marginLeft: "10px", height: "3.438rem", border: "solid 1px #cdd39d9", color: "#fff" }}>
                                                    <InputLabel id="year-select-label">School Type</InputLabel>
                                                    <Select
                                                        labelId="year-select-label"
                                                        id="year-select"
                                                        value={selectedSchoolType}
                                                        label="School Type"
                                                        onChange={(e) => setSelectedSchoolType(e.target.value)}
                                                    >
                                                        <MenuItem value="">
                                                            Select School
                                                        </MenuItem>
                                                        {allSchoolCategory.map((school) => (
                                                            <MenuItem key={school} value={school}>
                                                                {school}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </div> : null
                                        }
                                        <CustomSelectField style={{ width: "200px", marginLeft: "5px", marginRight: "5px" }} value={grade} onChange={(e) => setGrade(e.target.value)} name={"grade"} placeholder={schoolTypeName?.includes("University") ? 'Select Year' : "Select Grade"} options={gradeOptions} sm={{ maxWidth: "200px" }} />
                                        {schoolTypeName?.includes("University") ? null :
                                            <CustomSelectField style={{ width: "200px", marginLeft: "5px", marginRight: "5px" }} value={stream} onChange={(e) => setStream(e.target.value)} name={"stream"} placeholder={"Select Streams"} options={streamsOptions} sm={{ maxWidth: "200px" }} />
                                        }

                                        </Box>

                                    </Box>
                                    <Grid item>
                                        <DataGrid
                                            components={{ NoRowsOverlay: NoRowsOverlay }}
                                            sx={{ height: '550px', width: 'auto', fontFamily: 'Poppins', fontSize: "10px", color: '#272d3d', boxShadow: 0, border: 0 }}
                                            rowsPerPageOptions={[1, 5, 10, 20, 50, 100]}
                                            rowCount={pageState?.count}
                                            loading={pageState?.isLoading}
                                            pagination
                                            page={pageState.page - 1}
                                            pageSize={pageSize}
                                            paginationMode="server"
                                            onPageChange={(newPage) => { setPageState((old) => ({ ...old, page: newPage + 1, pageSize: pageSize })); }}
                                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                            columns={completedColumn}
                                            rows={pageState?.data}
                                            getRowId={row => row?.studentId} />

                                    </Grid>
                                </Box>
                            </TabPanel>
                        </TabContext>

                    </Box>
                )}
            </Grid>
        </Grid>
    )
}