import { Box, Checkbox, Collapse, LinearProgress, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import React, { useEffect, useState } from "react";
import startOrderFunction from "./posmethods";
import { Add, FiberManualRecord, KeyboardArrowDownRounded } from "@mui/icons-material";
import DateFormatter from "../../../utils/dateFormatter";
import { currencyconverter } from "../../../common/currencyconverter";
import greenarrowup from "../../../common/images/greenarrowup.svg"
import CustomTableSkeleton from "./customTableSkeleton";
import { useNavigate } from "react-router-dom";


const PaidBills = ({ setPaidTotals, dateFrom, dateTo, searchValue }) => {
  // initialize methods;
  const startOrderFunctions = new startOrderFunction();
  // get all unpaidBills:
  const navigate = useNavigate()
  const [page, setPage] = useState(1);
  const [noOfPages, setPageNumbers] = useState(0);
  const rowsPerPageOptions = [5, 10, 25, 50, 100];
  // handle pagination;
  const handlePage = (event, value) => {
    setPage(value);
  };

  const [dataGridPageSize, setPageSize] = React.useState(10);
  const [pageState, setPageState] = useState({ isLoading: true, data: [], count: 0, page: 1, pageSize: dataGridPageSize });
  useEffect(() => {
    setPageState({ ...pageState, isLoading: true });
    startOrderFunctions.getBillsByStatus("paid", page, dataGridPageSize, dateFrom, dateTo, searchValue).then((data) => {
      if (data?.status === 200 || data?.status === 201) {
        setPageState({ ...pageState, data: data?.response?.transaction, count: data?.response?.count, isLoading: false });

        setPageNumbers(Math.ceil(data?.response?.count / dataGridPageSize));
        console.log("ata?.response", data?.response)
        setPaidTotals(data?.response?.total || 0)
      }
    });
  }, [page, dataGridPageSize, dateFrom, dateTo, searchValue]);

  //   collapse controls;
  const [collapse, setOpenCollapse] = useState(null);
  const handleRowsPerPageChange = (event) => {
    setPageSize(event.target.value);
    setPage(1);
  };
  // HANDLE PAID BILLS;


  return (
    <Box>
      {pageState.isLoading && <LinearProgress color="success" />}
      <TableContainer sx={{ marginBottom: 2, height: '550px' }}>
        <Table>
          <TableHead sx={{ color: "#032541", fontSize: "14px" }}>
            <TableRow>
              <TableCell></TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Order No</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>No of Items</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Served By</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Date & Time</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Amount</TableCell>
            </TableRow>
          </TableHead>
          {pageState.isLoading && (
            <CustomTableSkeleton length={dataGridPageSize} numberOfRows={6} />
          )}

          <TableBody sx={{ color: "#707070" }}>
            {!pageState.isLoading && pageState.data.map((bill, index) => {
              const orderNumbers = [bill.orderNumber, ...bill.childOrders.map(child => child.orderNumber)].join(", ");
              return (
                <>
                  {bill?.childOrders?.length === 0 ? (
                    <TableRow key={index}
                      onClick={() => navigate(`/detailedbill/${bill._id}`)}
                      // sx={{ border: "1px solid #e1d6d6", color: "#707070" }}
                      sx={{ '& > *': { mb: ' !important', cursor: 'pointer' } }}
                    >
                      <TableCell sx={{ display: "flex", alignItems: "center", gap: 1, cursor: "pointer" }}>

                        <FiberManualRecord sx={{ color: '#17ae7b', fontSize: '15px' }} />
                        {/* <Add color="#707070" /> */}
                      </TableCell>
                      <TableCell style={{ textDecoration: 'underline' }}>{orderNumbers}</TableCell>
                      <TableCell>{bill.items.length}</TableCell>
                      <TableCell>{bill.cashier}</TableCell>
                      <TableCell>{DateFormatter(bill.transtime)}</TableCell>
                      <TableCell>{currencyconverter(bill.transamount)}</TableCell>
                    </TableRow>
                  ) : (
                    <>
                      <TableRow key={index}
                        sx={{
                          '& > *': { mb: ' !important', cursor: 'pointer' },
                          backgroundColor: collapse === bill._id ? '#f0f0f0' : 'transparent'
                        }}
                        onClick={() => setOpenCollapse(collapse === bill._id ? null : bill._id)}
                      >
                        <TableCell sx={{ width: "40px", textAlign: "center", cursor: "pointer", color: "#707070" }}>
                          <img src={greenarrowup} alt="greenarrow" />
                        </TableCell>
                        <TableCell onClick={() => navigate(`/detailedbill/${bill._id}`)} style={{ textDecoration: 'underline' }}>{bill.orderNumber}</TableCell>
                        <TableCell>{bill.items.length}</TableCell>
                        <TableCell>{bill.cashier}</TableCell>
                        <TableCell>{DateFormatter(bill.transtime)}</TableCell>
                        <TableCell>{currencyconverter(bill.transamount)}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell colSpan={6} style={{ paddingBottom: 0, paddingTop: 0 }}>
                          <Collapse in={collapse === bill._id} timeout="auto" unmountOnExit>
                            <Table>
                              <TableBody>
                                {bill?.childOrders?.map((childOrder, index) => (
                                  <TableRow key={index}
                                    sx={{
                                      '& > *': { mb: ' !important', cursor: 'pointer' },
                                    }}
                                    onClick={() => navigate(`/detailedbill/${childOrder._id}`)} 
                                  >
                                    <TableCell sx={{ width: "40px" }}></TableCell>
                                    <TableCell sx={{ textDecoration: 'underline' }}>{childOrder.orderNumber}</TableCell>
                                    <TableCell sx={{ textAlign: 'center' }}>{childOrder.items.length}</TableCell>
                                    <TableCell sx={{ textAlign: 'right' }}>{childOrder.cashier}</TableCell>
                                    <TableCell sx={{ textAlign: 'center' }}>{DateFormatter(childOrder.transtime)}</TableCell>
                                    <TableCell sx={{ textAlign: 'center' }}>{currencyconverter(childOrder.transamount)}</TableCell>
                                  </TableRow>
                                ))}
                                <TableRow sx={{ backgroundColor: collapse === bill._id ? '#f0f0f0' : 'transparent' }}>
                                  <TableCell colSpan={5} style={{ textAlign: 'right', fontWeight: 'bold' }}>
                                    Total:
                                  </TableCell>
                                  <TableCell sx={{ textAlign: 'center', fontWeight: 'bold' }}>{currencyconverter(bill.billTotal)}</TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                </>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <FormControl variant="outlined" sx={{ marginBottom: 2, minWidth: 60, height: 40 }}>
          <InputLabel>Rows per page</InputLabel>
          <Select
            value={dataGridPageSize}
            onChange={handleRowsPerPageChange}
            label="Rows per page"
          >
            {rowsPerPageOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Pagination count={noOfPages} page={page} defaultPage={1} onChange={handlePage} variant="outlined" shape="rounded" />
      </Box>
    </Box>
  );
};

export default PaidBills;