import { Box, Breadcrumbs, Button, Grid,Typography,Tab } from "@mui/material";
import React, { useEffect, useState } from "react";
import CircleIcon from '@mui/icons-material/Circle';
import HttpComponent from "../MakeRequest";
import DateFormatter from "../../../utils/dateFormatter";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import profilePicture from "../Images/studentImg.svg";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'

const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>DashBoard</Typography>,
    <Typography key={2} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Student Promotions</Typography>,
    <Typography key={2} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Student History</Typography>,
]

const theColumns = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: "center"
}

export default function PromoteStudentHistory() {
    const [allNotifications, setAllNotification] = useState([])
    const navigate = useNavigate()
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const studentId = queryParams.get("studentId");
    const customerId = queryParams.get("itemNo");
    const itemNumber = queryParams.get("itemNumber");
    const [currentStudent, setCurrentStudent] = useState()
    let baseUrl = process.env.REACT_APP_BASE_URL
    
    
    function fetchStudentHistory(){
        let urlStudents = `/api/get_students_promotions?studentId=${studentId}`
        HttpComponent({
            method: 'GET',
            url: urlStudents, 
            body: null,
            token: localStorage.getItem('X-Authorization')
        }).then((data) => {
            console.log("here is parent  data", data.response.data);
            if (data.status === 200) {
                setAllNotification(data.response.data)
            } else {
                console.error("Error setting info")
                setErrorShow({ state: true, message: data.response.message })
            }
        }).catch((error) => {
            console.error(error.message);
        })
    }

    useEffect(() => {
        fetchStudentHistory()
    }, []);

    const fetchStudentData = async () => {
        let url = baseUrl + "/api/getBillableItems";
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "X-Authorization": localStorage.getItem("X-Authorization"),
            },
            body: JSON.stringify({ customerId })
        });
        const data = await response.json();
        if (response?.status === 200) {
            const theStudent = data.data.find(student => student.itemNumber === itemNumber);
            setCurrentStudent(theStudent);
        }
    };

    useEffect(() => {
        fetchStudentData()
    }, []);

    return (
        <Grid direction={'column'} container>
            <Grid item mt={2}>
                 <Box>
                    <Box sx={{ display: "flex"}}>
                        <ArrowBackIosNewIcon style={{ cursor: "pointer", marginTop: '7px' }} onClick={() => navigate(-1)} />
                        <Typography style={{ fontWeight: 600, fontSize: "26px", color: "#032541", marginBottom: '10px' }}>Student History</Typography>
                    </Box>
                    <Grid item>
                            <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                                {breadcrumbs}
                            </Breadcrumbs>
                    </Grid>

                    <div style={{ display: "flex",  height: "150px", width: "100%",
                        //  boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.16)", 
                         borderRadius: "7px", marginTop: "3%", padding: "10px", marginBottom: '1px' }}>
                        <div style={{marginRight:"12px"}}>
                            <img src={profilePicture} alt="Profile" style={{ height: "110px", width: "110px" }} />
                        </div>
                        <div style={{marginRight:"7px"}}>
                            <div style={{ color: '#032541', fontSize: "25px", fontWeight: "bold", paddingTop: "0.125rem", paddingBottom: "0.125rem" }}>{currentStudent?.firstName} {currentStudent?.lastName}</div>
                            <div style={{display: "flex"}}>
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", paddingTop: "0.125rem", paddingBottom: "0.125rem" }}>
                                    <div style={{width: '80px'}}> 
                                        <p style={{ fontSize: "15px", color: "#666f76", paddingTop: "0.125rem", paddingBottom: "0.125rem" }}>Student ID:</p>
                                    </div>
                                    <div style={{marginLeft: "20px", width: "100px"}}>
                                        <span style={{fontWeight: 'bold', color: '#666f76'}}>{currentStudent?.itemNumber}</span>                                
                                    </div>
                                </div>

                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", paddingTop: "0.125rem", paddingBottom: "0.125rem", marginLeft: "20px" }}>
                                    <div style={{width: '120px'}}>
                                        <p style={{ fontSize: "15px", color: "#666f76", paddingTop: "0.125rem", paddingBottom: "0.125rem" }}>Current Level:</p>
                                    </div>

                                    <div style={{marginLeft: "10px"}}>
                                    {currentStudent?.status === "COMPLETED" ? <span style={{fontWeight: 'bold', color: '#17ae7b'}}>COMPLETED</span> :<span style={{fontWeight: 'bold', color: '#666f76'}}>{currentStudent?.grade}</span>}
                                    </div>
                                </div>
                            </div>

                            <div style={{display: "flex"}}>
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", paddingTop: "0.125rem", paddingBottom: "0.125rem" }}>
                                    <div style={{width: '80px'}}>
                                        <p style={{ fontSize: "15px", color: "#666f76", paddingTop: "0.125rem", paddingBottom: "0.125rem" }}>Status:</p>
                                    </div>
                                    <div style={{marginLeft: "20px",  width: "100px" }}>
                                        <span style={{ fontWeight: 'bold', color: currentStudent?.status === "COMPLETED" ? "#17ae7b" : "#666f76" }}>{currentStudent?.status}</span>   
                                    </div>
                                </div>

                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", paddingTop: "0.125rem", paddingBottom: "0.125rem", marginLeft: "20px" }}>
                                <div style={{width: '120px'}}>
                                        <p style={{ fontSize: "15px", color: "#666f76" , paddingTop: "0.125rem", paddingBottom: "0.125rem" }}>Current Term:</p>
                                    </div>

                                    <div style={{marginLeft: "10px"}}>
                                        {currentStudent?.status === "COMPLETED" ? <span style={{fontWeight: 'bold', color: '#17ae7b'}}>COMPLETED</span> :<span style={{fontWeight: 'bold', color: '#666f76'}}>{currentStudent?.term}</span>}
                                    </div>
                                </div>
                            </div>
                        </div>                          
                        
                    </div>

                    {allNotifications?.map((student) => {
                        return (
                            <Grid item  mb={2}>
                                <Grid container display={'flex'} spacing={1} direction={'column'}>
                                    <Grid item display={'flex'} alignItems={'center'}>
                                        <CircleIcon style={{color:'#e0e0e0' , width:'10px' , height:'10px'}}/>
                                        <Typography style={{color:'#032541' , marginLeft:'10px', fontSize:'14px', fontWeight: 200}}>Promoted from {student?.fromGrade}<span> to </span>{student?.toGrade} on {DateFormatter(student?.createdAt)} by {student?.changedBy}</Typography>
                                    </Grid>
                                    {/* <Grid item  marginLeft={'15px'}>
                                        <Typography sx={{color: '#666f76'}}>{DateFormatter(student?.createdAt)}</Typography>
                                    </Grid> */}
                                </Grid>
                            </Grid>
                        )
                    })}

                </Box>
            </Grid>
        </Grid>
    )
}