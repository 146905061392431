import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ErrorAlert } from "../snackBar Alerts/errorAlert";
import { SuccessAlert } from "../snackBar Alerts/successAlert";
import { Box, Breadcrumbs, Button, Grid, IconButton, Typography } from "@mui/material";
import { ArrowBackIos } from "@mui/icons-material";
import { v4 as uuidv4 } from 'uuid'; 
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import CustomInputLabel from "../School/CustomInputLabel";
import CustomTextField from "../School/CustomTextField"; 
import PhoneInput from "react-phone-input-2";

function CreateEvoucherReceipient({handleCancelReceipient, addRecipient}) {
    const navigate = useNavigate();
    const [errorShow, setErrorShow] = useState({ state: false, message: "" });
    const [successShow, setSuccessShow] = useState({ state: false, message: "" });
    const localCurrency = localStorage.getItem("localCurrency") || "$";
    const handleCancel = () => navigate(-1);
    const [formData, setFormData] = useState({ receipientName: "", phone: "", email: "", eVoucherAmount: "" });
    const breadcrumbs = [
        <Typography key="X" sx={{ color: "#707070", fontSize: "0.875rem" }}>
            Dashboard
        </Typography>,
        <Typography key={"add"} sx={{ color: "#707070", fontSize: "0.875rem" }}>
            Open E-Vouchers
        </Typography>,
        <Typography key={"add"} sx={{ color: "#707070", fontSize: "0.875rem" }}>
            Create Personal E-Voucher
        </Typography>,
        <Typography key={"add"} sx={{ color: "#dc3545", fontSize: "0.875rem" }}>
            Add Recipient
        </Typography>
    ];
    const isFormValid = () => {
        return formData.receipientName && formData.phone && formData.email && formData.eVoucherAmount;
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        if (isFormValid()) {
            addRecipient({
                id: uuidv4(),
                receipientName: formData.receipientName,
                phone: formData.phone,
                email: formData.email,
                eVoucherAmount: formData.eVoucherAmount,
            }); 
            setFormData({ receipientName: "", phone: "", email: "", eVoucherAmount: "" });
        }
    };
    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    return (
        <div>
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <div style={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
                <div style={{ flexGrow: 1, overflow: "hidden" }}>
                    <Box sx={{ flexGrow: 1, overflowY: "auto", overflowX: "hidden", p: 2, display: 'flex', justifyContent: 'space-between' }}>
                        <Box>
                            <IconButton style={{ position: 'relative', top: '40px', right: '10px' }} onClick={handleCancel}>
                                <ArrowBackIos />
                            </IconButton>
                            <Typography variant="h3" sx={{ color: "#032541", fontSize: "1.563rem", fontWeight: 700, marginLeft: '30px' }}>
                                Add Recipient
                            </Typography>
                            <Breadcrumbs sx={{ marginTop: '20px' }} separator={<FiberManualRecordIcon sx={{ fontSize: "0.625rem", color: "#e1e5e8" }} />} aria-label="breadcrumb">
                                {breadcrumbs}
                            </Breadcrumbs>
                        </Box>
                    </Box>
                    <Typography style={{ fontSize: "16px", color: "#707070", marginTop: "20px", fontFamily: 'Poppins', lineHeight: '2.06', marginLeft: '20px' }}>
                        Add recipient details
                    </Typography>
                    <Grid container spacing={2} style={{ width: '50%', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Grid item xs={12} style={{ display: 'flex' }}>
                            <Box component="div" sx={{ display: 'flex', flexDirection: "column", marginX: 1, marginTop: '10px' }}>
                                <Box sx={{ marginLeft: '10px' }}>
                                    <CustomInputLabel required={true} label={'Recipient Name'} />
                                    <CustomTextField value={formData.receipientName} onChange={handleInputChange} name={'receipientName'} placeholder={"Recipient Name"} />
                                </Box>
                                <Box sx={{ marginLeft: '10px' }}>
                                    <CustomInputLabel label={'Mobile Number'} required={true} />
                                    <Box component="div" sx={{ marginTop: 1, marginBottom: 2 }}>
                                        <PhoneInput country={"ke"} value={formData.phone} onChange={(value) => setFormData({ ...formData, phone: `+${value}` })} name={'phone'} placeholder={'Phone Number'}
                                            inputStyle={{ height: "55px", width: "408px" }}
                                        />
                                    </Box>
                                </Box>
                                <Box sx={{ marginLeft: '10px' }}>
                                    <CustomInputLabel required={true} label={'Email Address'} />
                                    <CustomTextField value={formData.email} onChange={handleInputChange} name={'email'} placeholder={"Email Address"} />
                                </Box>
                                <Box sx={{ marginLeft: '10px' }}>
                                    <CustomInputLabel required={true} label={'E-Voucher Amount'} />
                                    <CustomTextField value={formData.eVoucherAmount} onChange={handleInputChange} name={'eVoucherAmount'} placeholder={`E-Voucher Amount (${localCurrency})`}
                                        InputProps={{
                                            startAdornment: <span style={{ marginRight: '8px' }}>{localCurrency}</span>,
                                        }}
                                    />
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                    <form onSubmit={handleSubmit}>
                        <Box style={{ display: "flex", marginTop: "15px", justifyContent: "center" }}>
                            <Button style={{ height: "45px", width: "125px", marginRight: '20px', borderRadius: "4px", border: '1px solid #1a2e4c', color: "#032541" }} onClick={handleCancelReceipient}>
                                Cancel
                            </Button>
                            <Button type="submit" variant="contained" style={{ backgroundColor: isFormValid() ? '#032541' : 'rgba(3, 37, 65, 0.4)', color: '#fff', width: '125px', height: '45px' }} disabled={!isFormValid()}>
                                Add
                            </Button>
                        </Box>
                    </form>
                </div>
            </div>
        </div>
    )

}

export default CreateEvoucherReceipient